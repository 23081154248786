import React from "react";
import Box from "components/Box";
import clsx from "clsx";

import "styles/App/Drawer/DrawerScrollContent.scss";

const DrawerScrollContentWrap = ({ children, className, labelsList = false, ...props }) => {
  return (
    <Box {...props} className={clsx(className, "AppDrawerScrollContent-container", { labelsList })}>
      {children}
    </Box>
  );
};

const DrawerScrollContentSticky = ({ children, className, ...props }) => {
  return (
    <Box {...props} className={clsx(className, "AppDrawerScrollContent-sticky")}>
      <Box className="drawerBody--sticky--element">{children}</Box>
    </Box>
  );
};

const DrawerScrollContentElement = ({ children, className, builderWrap, ...props }) => {
  return (
    <Box {...props} className={clsx(className, "AppDrawerScrollContent-element", { builderWrap })}>
      {children}
    </Box>
  );
};

export const DrawerScrollContent = {
  Wrap: DrawerScrollContentWrap,
  Sticky: DrawerScrollContentSticky,
  Element: DrawerScrollContentElement,
};
