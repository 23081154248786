import { useQuery } from "@apollo/client";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import uniq from "lodash/uniq";
import useDataFilter from "hooks/useDataFilter";
import useDataSort from "hooks/useDataSort";
import { useWorkspace } from "contexts/Workspace";
import { useFormLayoutNamespace } from "contexts/FormLayoutNamespace";

import { buildQueryNamespace } from "./index";
import { filterSkipQueryProperties } from "./filterProperties";
import { usePaginationQueryReducer } from "./usePaginationQueryReducer";

const usePaginationQuery = (
  query,
  { input = {}, skip, onlySearch, fetchPolicy = "cache-and-network", ...rest } = {}
) => {
  const { namespace } = useWorkspace() || {};
  const { formLayoutId } = useFormLayoutNamespace();
  const mainNamespace = input.namespace || { ...namespace, formLayoutId };

  const permanentFilter = input.filter || [];
  const permanentSort = input.sort || [];

  const {
    page,
    size,
    search,
    loaded,
    sort,
    filter,
    setPage,
    setSize,
    setSearch,
    setLoaded,
    setFilter,
    setSort,
    viewState,
    setViewState,
  } = usePaginationQueryReducer({ input, skip });

  const dataFilter = useDataFilter({ filter, setFilter });
  const dataSort = useDataSort({ sort, setSort });

  const searchInput = {
    ...input,
    namespace: buildQueryNamespace(mainNamespace),
    page,
    size,
    search,
    sort: uniq(compact([...dataSort.applySort, ...permanentSort])),
    filter: uniq(compact([...dataFilter.applyFilter, ...permanentFilter])),
  };

  const queryVariables = parseQueryVariables(query, { input: searchInput });

  const {
    data = {},
    refetch,
    fetchMore,
    ...restResponse
  } = useQuery(query, {
    ...rest,
    skip: skip || onlySearch ? isEmpty(search) : false,
    variables: queryVariables,
    onCompleted: () => setLoaded(true),
    fetchPolicy,
  });

  const dataResponse = Object.values(data || {})[0];
  const { content, ...metadata } = dataResponse || {};
  const { totalPages = 0, totalElements = 0 } = metadata || {};

  // Pagination
  const handleNextPage = page + 1 === totalPages ? null : () => setPage(page + 1);
  const handlePrevPage = page === 0 ? null : () => setPage(page - 1);
  const fetchedElements = size + (page === 0 ? 0 : (page - 1) * size);
  const restElements = totalElements - fetchedElements;
  const nextElements = restElements > size ? size : restElements;
  const prevElements = page === 0 ? 0 : size;

  const fakeRefetch = () => {};

  const onLoadMore = () =>
    fetchMore({
      variables: {
        input: { ...queryVariables.input, size: metadata.size, page: metadata.page + 1 },
      },
      updateQuery: fetchMoreCollectionCache,
    });

  const queryMetadata = {
    ...metadata,
    pagination: {
      page,
      size,
      setSize,
      setPage,
      totalPages,
      totalElements,
      nextElements,
      prevElements,
      handleNextPage,
      handlePrevPage,
    },
  };

  return {
    ...restResponse,
    loadingRender: isEmpty(content) && restResponse.loading,
    data,
    content,
    loaded,
    fetchMore,
    onLoadMore,
    handleLoadMore: onLoadMore,
    metadata: queryMetadata,
    refetch: loaded ? refetch : fakeRefetch,
    search,
    setSearch,
    onSearch: setSearch,
    searchInput,
    totalCount: queryMetadata.pagination.totalElements,
    filter,
    setFilter,
    sort,
    setSort,
    viewsManagerProps: {
      viewState,
      setViewState,
    },
  };
};

const parseQueryVariables = (searchQuery, { input, ...variables } = {}) => {
  const { page, size, sort, filter, search, ...inputProps } = input;

  const query = isEmpty(search) ? "" : search;

  const queryInput = {
    ...inputProps,
    page,
    size,
    query,
    filter: parseInputFilter(filter),
    sort: parseSort(sort),
  };

  return {
    ...variables,
    input: filterSkipQueryProperties(searchQuery, queryInput),
  };
};

export const parseOutputFilter = (filter) => {
  if (isEmpty(filter)) return [];

  return filter.map((item) => ({ field: item.field, value: item.value, operation: item.op }));
};

export const parseInputFilter = (filter) => {
  if (isEmpty(filter)) return [];

  const filters = compact(filter).map((filterItem) => {
    const { operation, field, value } = filterItem;

    if (operation === "BETWEEN") {
      return [
        { field, value: value[0], op: "GT" },
        { field, value: value[1], op: "LT" },
      ];
    }

    return { field, value: parseInputFilterValue(value), op: operation };
  });

  return flatten(filters);
};

const parseInputFilterValue = (value) => {
  if (isArrayWithObjects(value)) {
    return value.map((item) => item.id ?? item.value).join(",");
  }

  return value;
};

function isArrayWithObjects(input) {
  if (!Array.isArray(input)) return false;

  return input.every((item) => typeof item === "object" && item !== null);
}

const parseSort = (sort) => {
  if (isEmpty(sort)) return [{ property: "createdDate", direction: "desc" }];

  const sorts = compact(sort).map(({ field, value }) => ({
    property: field,
    direction: value.toLowerCase(),
  }));

  return sorts;
};

// ------ Others helpers -------

export const fetchMoreCollectionCache = (cache, { fetchMoreResult }) => {
  const contentKey = Object.keys(cache)[0];

  if (!cache) return;
  if (!cache[contentKey]) return;

  const { content: prevCollection, ...prevMetadata } = cache[contentKey];

  if (!prevCollection) return;

  const { content: nextCollection, ...nextMetadata } = fetchMoreResult[contentKey];

  const existElement = prevCollection.find((item) => item.id === nextCollection[0]?.id);

  if (existElement) {
    return;
  }

  return Object.assign({}, cache, {
    ...cache,
    [contentKey]: {
      content: [...prevCollection, ...nextCollection],
      ...prevMetadata,
      ...nextMetadata,
    },
  });
};

export const updateFragment = (cache, payload, { fragment, name, typename }) => {
  const record = Object.values(payload.data)[0];

  cache.updateFragment(
    {
      id: `${typename}:${record.id}`,
      fragment: fragment,
      fragmentName: name,
    },
    (data) => ({ ...data, ...record, __typename: typename })
  );
};

export const removeFragment = (apolloClient, response, ids, typename, queries = []) => {
  if (!isSuccessResponse(response)) return;

  const cacheIds = Array.from([ids])
    .flat()
    .map((id) => `${typename}:${id}`);

  // Update queries
  const activeQueries = fetchActiveQueriesDatas(apolloClient, queries);

  activeQueries.forEach(({ query, variables }) => {
    apolloClient.cache.updateQuery({ query, variables }, (data) => {
      const dataKey = Object.keys(data)[0];

      if (!data[dataKey].totalElements) return data;

      const withId = data[dataKey].content?.find((item) =>
        cacheIds.includes(`${item.__typename}:${item.id}`)
      );

      if (!withId) return data;

      const finalData = {
        ...data,
        [dataKey]: { ...data[dataKey], totalElements: data[dataKey].totalElements - 1 },
      };

      return finalData;
    });
  });

  // Remove fragments
  cacheIds.forEach((id) => apolloClient.cache.evict({ id }));
  apolloClient.cache.gc();
};

export const refetchQueries = (apolloClient, queries = []) => {
  return fetchActiveQueriesDatas(apolloClient, queries);
};

const fetchActiveQueriesDatas = (apolloClient, queries) => {
  // Fetch active quereis
  const activeQueries = Array.from(
    apolloClient.queryManager.getObservableQueries("active").values()
  ).filter((observableQuery) => queries.includes(observableQuery.options.query));

  return uniq(
    activeQueries.map(({ options: { query, variables } }) => ({
      query,
      variables,
    }))
  );
};

export const isSuccessResponse = (response) => {
  const { data } = response;
  const responseData = Object.values(data)[0];
  const errors = responseData.errors || responseData.failed;

  return isEmpty(errors);
};

export default usePaginationQuery;
