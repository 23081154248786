import React from "react";
import { SkeletonCell } from "components/TablePro/TableCells/Cells";

import TableCell from "./TableCell";
import { useTablePro } from "../Provider";

const TableRowsStub = ({ rowsCount = 0 }) => {
  const { classes, loading, loadingMore, columns, widthMap } = useTablePro();

  if (!loadingMore && !loading) return <></>;

  return Array.from(Array(rowsCount)).map((_, rowIndex) => (
    <TableRowStub
      key={`TableRowStub-${rowIndex}`}
      classes={classes}
      rowIndex={rowIndex}
      widthMap={widthMap}
      columns={columns}
    />
  ));
};

export const TableRowStub = ({ classes, rowIndex, widthMap, columns }) => {
  return (
    <div key={`loading-${rowIndex}`} className={classes.row}>
      {columns.map((column, colIndex) => (
        <TableCell
          classes={classes}
          key={`loading-${column.id}-${rowIndex}-${colIndex}`}
          column={column}
          columns={columns}
          widthMap={widthMap}
        >
          <SkeletonCell
            rowIndex={rowIndex}
            classes={classes}
            cellWidth={widthMap[column.id]}
            column={column}
            fieldId={column.id}
          />
        </TableCell>
      ))}
    </div>
  );
};

export default TableRowsStub;
