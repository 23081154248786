import React, { useReducer, useMemo, useEffect, useState, useRef } from "react";
import Box from "components/Box";
import uniq from "lodash/uniq";
import omit from "lodash/omit";
import { createActionCreators, createReducerFunction } from "vendor/immer-reducer";
import { MappingPath } from "models/abstract/MappingPath";
import { TableIcon } from "iconsNew";

import { DndBuilderReducer, initialState } from "./DndBuilderReducer";
import { DndKitContext } from "./DndKitContext";

const dndAction = createActionCreators(DndBuilderReducer);
const dndReducer = createReducerFunction(DndBuilderReducer);
const DndBuilderContext = React.createContext(null);

export const useDndBuilderContext = () => React.useContext(DndBuilderContext);

export const CONTAINER_TYPE = "CONTAINER";
export const SECTION_TYPE = "SECTION";
export const ITEM_TYPE = "ITEM";
export const CONTAINER_SIDE_PLACEHOLDER_ID = "CONTAINER_SIDE_PLACEHOLDER_ID";
export const SECTION_SIDE_PLACEHOLDER_ID = "SECTION_SIDE_PLACEHOLDER_ID";
export const EMPTY_SECTION_ID = "EMPTY_SECTION_ID";
export const TRASH_ID = "TRASH_ID";
export const CONTAINERS_LIMIT = 3;

// System elements
export const STORAGE_SECTION_ID = "STORAGE_SECTION_ID";
export const STORAGE_CONTAINER_ID = "STORAGE_CONTAINER_ID";
export const STORAGE_ID = "STORAGE_ID";
export const TABLE_CONTAINER_ID = "TABLE_CONTAINER_ID";

export const SYSTEM_ELEMENTS_PROPS = {
  [TABLE_CONTAINER_ID]: { label: "New table", icon: TableIcon },
};

export const isSystemElement = (id) =>
  [STORAGE_SECTION_ID, STORAGE_CONTAINER_ID, STORAGE_ID, TABLE_CONTAINER_ID].includes(id);

export const LABELS = {
  [CONTAINER_TYPE]: "Section",
  [SECTION_TYPE]: "Container",
  [ITEM_TYPE]: "Item",
};

const SYSTEM_SECTION = {
  id: STORAGE_SECTION_ID,
  containers: [
    { id: STORAGE_CONTAINER_ID, type: STORAGE_CONTAINER_ID, items: [] },
    { id: TABLE_CONTAINER_ID, type: TABLE_CONTAINER_ID, items: [] },
  ],
};

export const Provider = ({
  children,
  components,
  loading,
  items: allItems,
  sections: defaultSectionsTree = [],
  cancelDrop,
  selectableItems = false,
  addTableItemMode,
  resetOnLoading = false,
}) => {
  const ref = useRef(false);
  const [state, dispatch] = useReducer(dndReducer, initialState);

  const [previewMode, setPreviewMode] = useState(false);

  const togglePreviewMode = () => setPreviewMode((prev) => !prev);

  const allItemsIds = useMemo(() => allItems.map((item) => item.id), [allItems]);

  const allItemsMap = useMemo(
    () => allItems.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
    [allItems]
  );

  const resetToDefault = (newStructure) => {
    const state = buildInitialState(newStructure ?? defaultSectionsTree, allItemsIds);
    dispatch(dndAction.reset(state));
  };

  useEffect(() => {
    if (resetOnLoading) {
      resetToDefault();
      return;
    }

    if (loading || ref.current) return;

    resetToDefault();
    ref.current = true;
  }, [loading]);

  return (
    <DndBuilderContext.Provider
      value={{
        loading,
        components,
        state,
        cancelDrop,
        dispatch,
        allItemsIds,
        allItemsMap,
        previewMode,
        togglePreviewMode,
        resetToDefault,
        selectableItems,
        addTableItemMode,
      }}
    >
      <DndKitContext>
        <Box display="flex" flexDirection="column" fullHeight>
          {children}
        </Box>
      </DndKitContext>
    </DndBuilderContext.Provider>
  );
};

export const useDndBuilderManager = () => {
  const { state, dispatch, ...rest } = useDndBuilderContext();

  const {
    sectionsProps,
    containersProps,
    itemsProps,
    notCompletedId,
    sections,
    containers,
    items,
    activeId,
    activeStorageId,
    selectedContainerId,
    selectedContainerMode,
    hoverElementId,
  } = state;

  const containersIds = Object.keys(items);

  const itemsIds = Object.keys(items)
    .filter((key) => key !== STORAGE_CONTAINER_ID)
    .map((key) => items[key])
    .flat();

  const constructorSections = sections.filter((sectionId) => sectionId !== STORAGE_SECTION_ID);

  const storageItemsIds = items[STORAGE_CONTAINER_ID] || [];

  const storageItemsIdsMap = useMemo(
    () => storageItemsIds.reduce((result, item) => ({ ...result, [item]: true }), {}),
    [storageItemsIds]
  );

  const removeContainer = ({ containerId, clean }) => {
    dispatch(dndAction.removeContainer({ containerId, clean }));
  };

  const removeSection = (sectionId) => {
    dispatch(dndAction.removeSection({ sectionId }));
  };

  const toggleSelectedContainerId = (containerId) => {
    dispatch(dndAction.toggleSelectedContainerId(containerId));
  };

  const setSelectedContainerId = ({ containerId, mode }) => {
    dispatch(dndAction.setSelectedContainerId({ containerId, mode }));
  };

  const addContainer = (sectionId) => {
    dispatch(dndAction.addContainer({ sectionId }));
  };

  const removeItem = ({ itemId, clean }) => {
    dispatch(dndAction.removeItem({ itemId, clean }));
    dispatch(dndAction.cleanEmptyEntities());
  };

  const addItem = ({ itemId, containerId }) => {
    dispatch(dndAction.addItem({ itemId, containerId }));
  };

  const fetchBuilderInput = () => {
    return prepareBuilderInput({
      sections: constructorSections,
      containers,
      items,
      sectionsProps,
      containersProps,
      itemsProps,
    });
  };

  const isSystemElement = (id) => [TABLE_CONTAINER_ID, STORAGE_CONTAINER_ID].includes(id);

  const updateSectionProps = (id, props) => {
    dispatch(dndAction.updateSectionProps({ id, props }));
  };

  const updateItemProps = (id, props) => {
    dispatch(dndAction.updateItemProps({ id, props }));
  };

  return {
    ...rest,
    selectedContainerId,
    selectedContainerMode,
    setSelectedContainerId,
    hoverElementId,
    toggleSelectedContainerId,
    storageItemsIds,
    storageItemsIdsMap,
    notCompletedId,
    containersIds,
    itemsIds,
    sections,
    containers,
    items,
    activeId,
    activeStorageId,
    removeContainer,
    removeSection,
    constructorSections,
    addContainer,
    fetchBuilderInput,
    removeItem,
    addItem,
    isSystemElement,
    sectionsProps,
    updateSectionProps,
    containersProps,
    itemsProps,
    updateItemProps,
  };
};

export const buildInitialState = (defaultSectionsTree, itemsIds = []) => {
  const sectionsTree = [...defaultSectionsTree, SYSTEM_SECTION];

  const sectionsProps = sectionsTree.reduce(
    (result, section) => ({ ...result, [section.id]: section }),
    {}
  );

  const sections = Object.keys(sectionsProps);
  const [containers, containersProps] = buildContainersMap(sectionsTree);
  const [items, itemsProps] = buildItemsMap(sectionsTree);

  const usedItemsIds = Object.keys(items)
    .filter((id) => id !== STORAGE_CONTAINER_ID)
    .flatMap((id) => items[id]);

  const notUsedItems = itemsIds.filter((id) => !usedItemsIds.includes(id));

  const nextSections = uniq([STORAGE_SECTION_ID, ...sections]);

  const nextContainers = {
    ...containers,
    [STORAGE_SECTION_ID]: [STORAGE_CONTAINER_ID, TABLE_CONTAINER_ID],
  };

  const nextItems = { ...items, [STORAGE_CONTAINER_ID]: notUsedItems, [TABLE_CONTAINER_ID]: [] };

  return {
    ...initialState,
    sections: nextSections,
    containers: nextContainers,
    items: nextItems,
    sectionsProps,
    containersProps,
    itemsProps,
  };
};

const prepareBuilderInput = ({
  sections,
  containers,
  items,
  sectionsProps = {},
  containersProps = {},
  itemsProps = {},
}) => {
  const structure = sections.map((sectionId) => ({
    id: sectionId,
    ...sectionsProps[sectionId],
    containers: containers[sectionId].map((containerId) => ({
      id: containerId,
      ...containersProps[containerId],
      items: items[containerId].map((itemId) => ({
        ...omit(itemsProps[itemId], ["id"]),
        path: MappingPath.convertPathIdToPath(itemId),
      })),
    })),
  }));

  return { structure };
};

const buildContainersMap = (sections) => {
  let containersProps = {};

  const containers = sections.reduce((result, section) => {
    return {
      ...result,
      [section.id]: section.containers.map((container) => {
        containersProps[container.id] = container;

        return container.id;
      }),
    };
  }, {});

  return [containers, containersProps];
};

const buildItemsMap = (sections) => {
  let itemsProps = {};
  const containers = sections.flatMap((section) => section.containers);

  const items = containers.reduce((result, container) => {
    return {
      ...result,
      [container.id]: container.items.map((item) => {
        const pathId = MappingPath.convertPathToPathId(item.path);

        itemsProps[pathId] = item;

        return pathId;
      }),
    };
  }, {});

  return [items, itemsProps];
};
