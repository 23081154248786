import React from "react";
import { CssBaseline } from "@mui/material";
import { SideNavigation } from "./SideNavigation";
import { CompanyWorkspaceProvider, ProjectWorkspaceProvider } from "contexts/Workspace";
import { LayoutProvider } from "contexts/Layout";
import { useLayoutStyles } from "theme/components/DashboardLayout";
import { Outlet } from "react-router-dom";
import { useLayout } from "contexts/Layout";
import { GlobalSearchProvider } from "containers/GlobalSearch/Provider";

export const LayoutContent = ({ sideNavigation = true }) => {
  const { openedSidebar } = useLayout();
  const classes = useLayoutStyles({ open: openedSidebar });

  return (
    <div className={classes.root}>
      {sideNavigation && <SideNavigation />}

      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  );
};

const DashboardLayout = (props) => (
  <LayoutProvider>
    <CssBaseline />

    <GlobalSearchProvider>
      <LayoutContent {...props} />
    </GlobalSearchProvider>
  </LayoutProvider>
);

export const CompanyWorkspaceLayer = () => (
  <CompanyWorkspaceProvider>
    <Outlet />
  </CompanyWorkspaceProvider>
);

export const ProjectWorkspaceLayer = () => (
  <ProjectWorkspaceProvider>
    <Outlet />
  </ProjectWorkspaceProvider>
);

export default DashboardLayout;
