import { indexMap } from "utils/collection";

export const replaceErrorKey = (errorKey, mapping) => {
  if (mapping[errorKey]) return mapping[errorKey];

  let resultKey = errorKey || "";

  Object.keys(mapping).forEach((key) => (resultKey = resultKey.replaceAll(key, mapping[key])));

  return resultKey;
};

export const setErrors = (setError, errors, keyMapper = null) => {
  const errorsObject = parseErrors(errors, keyMapper);

  Object.keys(errorsObject).forEach((errorKey) => {
    setError(errorKey, errorsObject[errorKey]);
  });
};

export const parseErrors = (errors, keyMapper = null) => {
  return Object.keys(errors).reduce((result, key) => {
    const errorKey = keyMapper ? keyMapper(key) || key : key;

    return {
      ...result,
      [errorKey]: { type: "manual", message: errors[key].message || errors[key] },
    };
  }, []);
};

export const buildFields = (control, fields) =>
  Object.keys(fields).reduce((result, name) => {
    result[name] = {
      name: name,
      control,
      rules: fields[name],
    };

    return result;
  }, {});

export const submitProxy = (submitFunc) => (props) =>
  [
    (input) => {
      return submitFunc({ input, ...props });
    },
    (errors) => {
      return props?.onFailure && props?.onFailure(errors);
    },
  ];

export const submitCurry = (submit, onSubmit) => {
  return (props) => submit(...onSubmit(props));
};

export const replaceFieldsIndexMap = (allFields, fields, uniqProperty) => {
  const hiddenFieldsMap = indexMap(fields, uniqProperty);

  return allFields.reduce(
    (result, field, index) => ({ ...result, [hiddenFieldsMap[field[uniqProperty]]]: index }),
    {}
  );
};

export const replaceErrorIndex = (errorKey, fieldKey, indexMap) => {
  const errorRegexp = new RegExp(`${fieldKey}\\[\\d+\\]`);
  const errorField = (errorKey.match(errorRegexp) || [])[0];

  if (!errorField) return errorKey;

  const errorIndex = errorField.match(/[\d+]/)[0];

  if (indexMap[errorIndex] === undefined) return errorKey;

  const newErrorField = errorField.replace(errorIndex, indexMap[errorIndex]);

  return errorKey.replace(errorField, newErrorField);
};

// const fromIndexToEntityId = (errorKey, values, replaceFields) => {
//   const fieldArrayRegexp = (key) => new RegExp(`${key}\\[\\d+\\]`);

//   const fieldKey = replaceFields.find((key) => errorKey.match(fieldArrayRegexp(key)));

//   if (!fieldKey) return;

//   const matchField = (errorKey.match(fieldArrayRegexp(fieldKey)) || [])[0];

//   const entities = values[fieldKey] || [];
//   const index = parseInt(matchField.match(/\d+/)[0]);

//   const entity = entities[index];

//   if (!entity?.id) return errorKey;

//   const fieldWithId = matchField.replace(/\d+/, entity.id);

//   return errorKey.replace(matchField, fieldWithId);
// };
