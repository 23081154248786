import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import clsx from "clsx";

import { TableAddRecordRow } from "./TableNavigationRow";
import TableRowsStub from "./TableRowsStub";
import TableHeader from "./TableHeader";
import { useTablePro } from "../Provider";

const EmptyTable = ({ emptyLabel, style, loading = false }) => {
  const {
    classes,
    headerClasses,
    variant,
    columns,
    widthMap,
    MultiselectHeader,
    multiSelectable,
    onAdd,
    tableAddRowComponent,
  } = useTablePro();

  const TableAddRowComponent = tableAddRowComponent || TableAddRecordRow;

  return (
    <div className={clsx(classes.emptyTable, { oneField: columns.length < 2 })} style={style}>
      <TableHeader
        variant={variant}
        columns={columns}
        widthMap={widthMap}
        MultiselectHeader={MultiselectHeader}
        multiSelectable={multiSelectable}
      />

      {loading && <TableRowsStub rowsCount={40} />}

      {!loading && (
        <>
          <Box className={clsx(headerClasses.row)}>
            <Box className={headerClasses.cell}>
              <Box className={classes.cellContainer}>
                <Typography component="div" color="grey">
                  {emptyLabel || emptyLabel === "" ? emptyLabel : "No columns added yet.."}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {onAdd && <TableAddRowComponent onAdd={onAdd} />}
    </div>
  );
};

export default EmptyTable;
