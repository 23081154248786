import React from "react";
import { ComponentTypeInput } from "components/ComponentType/ComponentTypeInput";
import { LIST_TYPE, OBJECT_TYPE, TABLE_CONNECTOR_TYPE } from "models/abstract/FieldType";
import { FormInputProxy } from "components/Inputs/Form/FormInput";

import { ListInput } from "./ListType/ListInput";
import { ObjectInput } from "./ObjectType/ObjectInput";
import { TableConnectorInput } from "./TableConnectorType/TableConnectorInput";

export const FormFieldInput = FormInputProxy(({ formField, space, isFilterInput, ...props }) => {
  const inputProps = {
    space: { mb: 0, ...space },
    disabled: formField.disabled,
    placeholder: formField.placeholder || "Add value...",
    ...props,
  };

  const DYNAMIC_INPUT_COMPONENT = {
    [LIST_TYPE]: ListInput,
    [OBJECT_TYPE]: ObjectInput,
    [TABLE_CONNECTOR_TYPE]: TableConnectorInput,
  };

  const DynamicInputComponent = DYNAMIC_INPUT_COMPONENT[formField.type];

  if (DynamicInputComponent) {
    return (
      <DynamicInputComponent
        {...inputProps}
        formField={formField}
        multiple={isFilterInput && formField.type === LIST_TYPE ? true : undefined}
      />
    );
  }

  return (
    <ComponentTypeInput
      {...inputProps}
      type={formField.componentType}
      config={formField.componentTypeConfig}
    />
  );
});
