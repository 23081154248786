import { stopPropagation } from "utils/input";
import isEmpty from "lodash/isEmpty";
import { flatCompact } from "utils/object";

export const useFreeSoloOptions = ({ parseFreeSolo, onAdd, value, optionsMap, freeSolo }) => {
  const addFreeSoloOption = (event) => {
    stopPropagation(event);

    if (event.key === "Enter") {
      event.preventDefault();
      event.target.blur();
      const value = parseFreeSolo ? parseFreeSolo(event.target.value) : event.target.value;

      if (isEmpty(value)) return;

      onAdd(value);
    }
  };

  const freeSoloOptions = freeSolo ? parseFreeSoloOptions(value, optionsMap) : [];

  return { addFreeSoloOption, freeSoloOptions };
};

const parseFreeSoloOptions = (value, optionsMap) =>
  flatCompact(value)
    // This fix needs for autocomplete and free solo selects (for example: select inputs in form builder)
    .filter((itemValue) => itemValue?.match(/__ULTIMATE_FREE_SOLO__/) || !optionsMap[itemValue])
    .map((item) => ({
      id: item,
      label: item.replace(/__ULTIMATE_FREE_SOLO__/, ""),
    }));
