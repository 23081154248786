import { gql } from "@apollo/client";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import truncate from "lodash/truncate";
import { delegate, memoize } from "utils/entities";
import { keyByValue } from "utils/collection";
import {
  // Types
  NUMBER_TYPE,
  SINGLE_LINE_TEXT_TYPE,
  LIST_TYPE,
} from "models/abstract/FieldType";

export class Item {
  constructor(origin) {
    this.origin = origin;

    this.cache = {};
    this.memoize = memoize(this.cache);

    const valuesProps = keyByValue(this.origin.values, { key: "columnUid" });
    const valuesIdsProps = keyByValue(this.origin.values, { key: "columnId" });

    delegate(this, { ...this.origin, ...valuesProps, ...valuesIdsProps });
  }

  static getListItemTitle({ item, columnIds = [], length = 64 }) {
    const title =
      valueByColumnIds(item, columnIds) ||
      item._name ||
      item._label ||
      item._title ||
      item.values.find((column) => column.columnUid?.match(/(title|name|label)/) && column.value)
        ?.value ||
      item.values.find((item) => SINGLE_LINE_TEXT_TYPE === item.type)?.value ||
      item.values.find((item) => NUMBER_TYPE === item.type)?.value ||
      item.values.find((field) => typeof field.value === "string")?.value ||
      "No title";

    return truncate(title, { length });
  }

  get tableViewId() {
    return this.listStructure.tableViewId;
  }

  get tableStructure() {
    return this.listStructure.tableStructure;
  }

  get typeId() {
    return `ListItem-${this.id}`;
  }

  get title() {
    return this.memoize("title", () => Item.getListItemTitle({ item: this }));
  }

  get label() {
    return this.title;
  }

  get name() {
    return this.title;
  }

  get valuesMap() {
    return this.memoize("valuesMap", () => keyByValue(this.values, { key: "columnId" }));
  }

  get tableValues() {
    return {
      ...pick(this, ["id", "createdDate", "lastModifiedDate"]),
      ...this.valuesMap,
    };
  }

  get columnsCount() {
    return this.memoize("columnsCount", () => {
      return this.listStructure.columnsCount;
    });
  }

  get sku() {
    return this.memoize("sku", () => {
      const field = this.values.find(
        (column) =>
          (column.columnUid?.match(/item_sku/) || column.columnUid?.match(/sku/)) && column.value
      );

      return field?.value;
    });
  }
}

export const ITEM_FRAGMENT = `
  id
  createdDate
  lastModifiedDate
  uid
  allowComments
  historyVersion
  values
  tableId
`;

Item.fragment = gql`
  fragment ItemFragment on GetItemPayload {
    ${ITEM_FRAGMENT}
  }
`;

const valueByColumnIds = (item, columnIds = []) => {
  if (isEmpty(columnIds)) return;

  const labelValues = fetchNestedValues(
    item.values.filter((item) => columnIds?.includes(item.columnId))
  );

  if (isEmpty(labelValues)) return;

  return labelValues;
};

const fetchNestedValues = (data) => {
  let values = [];

  data.forEach((item) => {
    if (item.type === LIST_TYPE) {
      item.value.forEach((subItem) => {
        values.push(subItem.value);
      });
    } else {
      values.push(item.value);
    }
  });

  return values.join(", ");
};
