import { gql } from "@apollo/client";
import { delegate, memoize, serialize } from "utils/entities";

import { TableAccess } from "./TableAccess";

export class PrimaryTableAccessLink {
  constructor(origin) {
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin);

    serialize(this, { primaryTable: TableAccess });
  }

  get lookupPrimaryColumnIds() {
    return this.mapping.filter((item) => item.lookup).map((item) => item.primaryColumnId);
  }
}

PrimaryTableAccessLink.fragment = gql`
  fragment PrimaryTableAccessLinkFragment on PrimaryTableAccessLink {
    id
    name
    uid
    primaryTable {
      ...TableAccessFragment
    }
    mapping {
      primaryColumnId
      secondaryColumnId
      hidden
      overwrite
      dynamic
      lookup
    }
  }

  ${TableAccess.fragment}
`;
