import { gql } from "@apollo/client";
import { delegate, memoize, serialize } from "utils/entities";

import { FieldTypeConfig, FIELD_TYPE_CONFIG } from "models/abstract/FieldTypeConfig";
import { FieldFull } from "models/Field/FieldFull";
import { TableColumn } from "./TableColumn";

export class TablePrimaryColumn {
  constructor(origin, props = {}) {
    const { lastModifiedDate } = props;

    this.origin = { lastModifiedDate, ...origin };
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, origin, ["mapping"]);

    serialize(this, { field: FieldFull });
  }

  get fieldId() {
    return this.field.id;
  }

  get subtype() {
    return this._configEntity.subtype || this._fieldConfigEntity.subtype;
  }

  get config() {
    return { ...this._fieldConfigEntity.config, ...this._configEntity.config };
  }

  get mapping() {
    return this.memoize("mapping", () => {
      return this.origin.mapping.map((item) => ({
        ...item,
        column: new TableColumn(item, {
          lastModifiedDate: this.lastModifiedDate,
          tableId: item.table.id,
        }),
        tableId: item.table.id,
        columnId: item.column.id,
      }));
    });
  }

  // PRIVATE

  get _configEntity() {
    return this.memoize("_configEntity", () => new FieldTypeConfig({ ...this.origin }));
  }

  get _fieldConfigEntity() {
    return this.memoize("_fieldConfigEntity", () => new FieldTypeConfig({ ...this.origin.field }));
  }
}

TablePrimaryColumn.fragment = gql`
  fragment TablePrimaryColumnFragment on TablePrimaryColumn {
    id
    createdDate
    inUse
    uid
    name
    description
    type
    unique
    dynamic
    lookup
    ${FIELD_TYPE_CONFIG}
    mapping {
      table {
        id
        name
      }
      column {
        ...TableColumnFragment
      }
    }
    field {
      ...FieldFullFragment
    }
  }

  ${FieldFull.fragment}
  ${TableColumn.fragment}
`;
