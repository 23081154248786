import React from "react";
import Label from "components/Label";
import isEmpty from "lodash/isEmpty";
import { useGlobalEntities } from "contexts/GlobalEntities";
import { CountryIcon } from "components/Inputs/CountriesSelect";

const PhoneLabel = ({ value, ...props }) => {
  const { countriesMap } = useGlobalEntities();
  const country = countriesMap[value?.countryId];

  const label = [country?.callingCode, value?.value].join("");

  return (
    <Label leftIcon={CountryIcon(country?.name)} color="grey" {...props}>
      {isEmpty(label) ? "Empty" : label}
    </Label>
  );
};

export default PhoneLabel;
