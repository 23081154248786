import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import Collapse from "components/Collapse";
import Box from "components/Box";
import { useSideNavigationListStyles } from "theme/components/SideNavigation";
import caller from "utils/caller";

import { CollapseMenuItem } from "components/Menu/CollapseMenuItem";
import { ListItem } from "./RenderOptions";
import { useSelectMenuStyles } from "../index";

export const RenderTreeOptions = ({
  markVariant,
  optionComponent,
  virtualized,
  isSelected,
  options,
  onChange,
  treeProperty,
  allSkipOptionsIds,
}) => {
  const navigationListClasses = useSideNavigationListStyles();
  const selectClasses = useSelectMenuStyles();

  const [collapsedMap, setCollapsedMap] = useState({});

  if (virtualized) {
    throw new Error("Virtualized is not supported yet");
  }

  return options.map((option, optionIndex) => (
    <RenderTreeItem
      key={`${option.id}-${optionIndex}}-${0}`}
      level={0}
      option={option}
      collapsedMap={collapsedMap}
      setCollapsedMap={setCollapsedMap}
      onChange={onChange}
      isSelected={isSelected}
      optionComponent={optionComponent}
      markVariant={markVariant}
      treeProperty={treeProperty}
      allSkipOptionsIds={allSkipOptionsIds}
      classes={{
        groupLabel: selectClasses.groupLabel,
        cornerIcon: navigationListClasses.cornerIcon,
      }}
    />
  ));
};

export const RenderTreeItem = ({
  classes,
  option,
  level,
  onChange,
  isSelected,
  optionComponent,
  markVariant,
  collapsedMap,
  setCollapsedMap,
  treeProperty,
  allSkipOptionsIds,
}) => {
  const optionTreeId = `${option.id}-${level}`;
  const isCollapsed = !collapsedMap[optionTreeId];
  const descendants = treeProperty ? caller(option, treeProperty) : option.descendants;

  if (allSkipOptionsIds.includes(option.id)) {
    return <></>;
  }

  if (isEmpty(descendants)) {
    return (
      <ListItem
        size="small"
        level={level}
        key={`${option.id}-${level}`}
        isSelected={isSelected}
        option={option}
        markVariant={markVariant}
        optionComponent={optionComponent}
        onChange={onChange}
      />
    );
  }

  return (
    <Box style={{ minWidth: "fit-content" }}>
      <CollapseMenuItem
        size="small"
        collapsed={isCollapsed}
        className={classes.groupLabel}
        label={option.label}
        optionId={optionTreeId}
        iconClassName={classes.cornerIcon}
        collapsedMap={collapsedMap}
        setCollapsedMap={setCollapsedMap}
      />

      <Collapse in={isCollapsed} unmountOnExit>
        <Box space={{ ml: 3 }}>
          {descendants.map((descendantOption, index) => (
            <RenderTreeItem
              key={`${descendantOption.id}-${index}-${level + 1}`}
              classes={classes}
              option={descendantOption}
              level={level + 1}
              onChange={onChange}
              isSelected={isSelected}
              optionComponent={optionComponent}
              markVariant={markVariant}
              treeProperty={treeProperty}
              collapsedMap={collapsedMap}
              setCollapsedMap={setCollapsedMap}
              allSkipOptionsIds={allSkipOptionsIds}
            />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};
