import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";

const TitleCount = ({ title, totalCount, space }) => {
  return (
    <Box display="flex" space={space}>
      <Typography component="span" color="greydark" mr={0.5}>
        {title}
      </Typography>

      <Typography style={{ minWidth: 32 }} component="div" color="hint">
        {` • ${totalCount}`}
      </Typography>
    </Box>
  );
};

export default TitleCount;
