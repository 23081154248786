import { useMemo } from "react";
import { gql } from "@apollo/client";
import fragments from "graphql/fragments";
import usePaginationQuery from "hooks/useQuery/usePaginationQuery";
import { useWorkspace } from "contexts/Workspace";
import { ProjectFull } from "./ProjectFull";
import { ProjectShort } from "./ProjectShort";
import useQuery from "hooks/useQuery";

export const GET_PROJECT = gql`
  query getProject($input: BaseGetInput) {
    getProject(input: $input) {
      ...ProjectFullFragment
    }
  }
  ${ProjectFull.fragment}
`;

export const GET_PROJECTS = gql`
  query SearchProjects($input: BaseSearchInput) {
    searchProjects(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...ProjectShortFragment
      }
    }
  }

  ${ProjectShort.fragment}
`;

export const useSearchProjects = ({ searchInput } = {}) => {
  const { currentCompany } = useWorkspace();

  return useSearchCompanyProjects({ companyId: currentCompany?.id, searchInput });
};

export const useSearchCompanyProjects = ({ companyId, searchInput } = {}) => {
  const {
    content = [],
    loading,
    ...props
  } = usePaginationQuery(GET_PROJECTS, {
    input: {
      namespace: { companyId },
      ...searchInput,
      sort: [{ field: "createdDate", value: "ASC" }],
    },
    skip: !companyId,
  });

  const projects = useMemo(() => content.map((project) => new ProjectShort(project)), [content]);

  return {
    projects,
    loading,
    ...props,
  };
};

export const useGetProject = (id) => {
  const { currentCompany } = useWorkspace();

  const { payload, loading, ...rest } = useQuery(GET_PROJECT, {
    skip: !id,
    input: { id: id, namespace: { companyId: currentCompany.id } },
  });

  const project = loading ? null : new ProjectFull(payload);

  return { project, payload, loading, ...rest };
};
