import React, { useState } from "react";
import { useDidMountEffect } from "utils/effects";
import { useOpener } from "utils/state";
import { useGlobalSearchBarStyles } from "theme/components/GlobalSearch";
import SearchEntities from "containers/GlobalSearch/SearchEntities";
import { ExecuteFormLayoutDrawer } from "drawers/ExecuteFormLayout";
import { SideDrawer } from "components/Drawer";
import { useLocation } from "react-router-dom";

const GlobalSearchContext = React.createContext(null);

export const useGlobalSearch = () => React.useContext(GlobalSearchContext);

export const GlobalSearchProvider = ({ children }) => {
  const location = useLocation();
  const classes = useGlobalSearchBarStyles();
  const [open, handleOpen, handleClose, toggleOpen] = useOpener(false);
  const [executeFormId, setExecuteFormId] = useState(null);

  useDidMountEffect(() => {
    if (!executeFormId) return;

    setExecuteFormId(null);
  }, [location.pathname]);

  return (
    <GlobalSearchContext.Provider
      value={{
        open,
        handleOpen,
        handleClose,
        toggleOpen,
        executeFormId,
        setExecuteFormId,
      }}
    >
      {children}

      <SideDrawer open={open} classes={classes}>
        <SearchEntities />
      </SideDrawer>

      <ExecuteFormLayoutDrawer
        formLayoutId={executeFormId}
        open={!!executeFormId}
        onClose={() => setExecuteFormId(null)}
      />
    </GlobalSearchContext.Provider>
  );
};
