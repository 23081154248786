import { gql } from "@apollo/client";
import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import useQuery from "hooks/useQuery";
import usePaginationQuery from "hooks/useQuery/usePaginationQuery";
import { useGetTableView, useSearchTableViews } from "models/TableView/queries";
import { ListStructure } from "models/ListItem/ListStructure";
import fragments from "graphql/fragments";
import { useWorkspace } from "contexts/Workspace";
import { useFormLayoutNamespace } from "contexts/FormLayoutNamespace";
import { ListItem, ListItemByTableColumn, FullListItem } from "./ListItem";
import { Item } from "./Item";
import { FullItem } from "./FullItem";

export const GET_ITEM = gql`
  query GetItem($input: GetItemInput) {
    getItem(input: $input) {
      ...ItemFragment
    }
  }

  ${Item.fragment}
`;

const GET_FULL_ITEM = gql`
  query GetFullItem($input: GetItemInput) {
    getFullItem(input: $input) {
      ...FullItemFragment
    }
  }

  ${FullItem.fragment}
`;

export const GET_ITEMS = gql`
  query SearchItems($input: SearchItemsInput) {
    searchItems(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...ItemFragment
      }
    }
  }

  ${Item.fragment}
`;

export const LOOKUP_LIST_ITEMS = gql`
  query LookupItems($input: LookupItemsInput) {
    lookupItems(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...ItemFragment
      }
    }
  }

  ${Item.fragment}
`;

export const SEARCH_SUB_ITEMS = gql`
  query SearchSubItems($input: SearchSubItemsInput) {
    searchSubItems(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...ItemFragment
      }
    }
  }

  ${Item.fragment}
`;

const SEARCH_ITEMS_BY_TABLE_COLUMN = gql`
  query searchItemsByTableColumn($input: SearchItemsByTableColumnInput) {
    searchItemsByTableColumn(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...ListItemByTableColumnFragment
      }
    }
  }

  ${ListItemByTableColumn.fragment}
`;

export const useSearchItems = ({ searchInput, skip } = {}) => {
  const { content: itemsPayload = [], ...rest } = usePaginationQuery(GET_ITEMS, {
    input: {
      ...searchInput,
      sort: [{ field: "createdDate", value: "ASC" }],
    },
    skip,
  });

  const itemsChecksum = itemsPayload.map((item) => `${item.id}.${item.lastModifiedDate}`).join(".");

  const items = useMemo(
    () => itemsPayload.map((item) => new Item(item)),
    // eslint-disable-next-line
    [itemsChecksum]
  );

  return { items, ...rest };
};

export const useGetOnlyListItem = ({ listStructure, id: itemId, tableLinkId }) => {
  const { payload, loading, ...props } = useQuery(GET_ITEM, {
    skip: !itemId || !listStructure?.tableViewId,
    input: { id: itemId, tableViewId: listStructure?.tableViewId, tableLinkId },
  });

  const listItem =
    payload && listStructure ? new ListItem(payload, { listStructure, tableLinkId }) : null;

  return {
    listStructure,
    listItem,
    loading,
    ...props,
  };
};

export const useGetOnlyFullListItem = ({ listStructure, id: itemId, tableLinkId }) => {
  const { payload, loading, ...props } = useQuery(GET_FULL_ITEM, {
    skip: !itemId || !listStructure?.tableViewId,
    input: { id: itemId, tableViewId: listStructure?.tableViewId, tableLinkId },
  });

  const listItem =
    payload && listStructure ? new FullListItem(payload, { listStructure, tableLinkId }) : null;

  return {
    listStructure,
    listItem,
    loading,
    ...props,
  };
};

export const useGetFulItem = ({ id: itemId, tableViewId, tableLinkId }) => {
  const { payload, loading, ...props } = useQuery(GET_FULL_ITEM, {
    skip: !itemId || !tableViewId,
    input: { id: itemId, tableViewId, tableLinkId },
  });

  const fullItem = payload ? new FullItem(payload) : null;

  return { fullItem, loading, ...props };
};

export const useGetListItems = ({ tableViewId, searchInput = {}, skip = false } = {}) => {
  const { tableView, loading: loadingTableView } = useGetTableView(tableViewId);
  const listStructure = tableView ? new ListStructure({ tableView }) : null;

  const itemsQuery = useGetOnlyListItems({ listStructure, searchInput, skip });

  return { listStructure, ...itemsQuery, loading: loadingTableView || itemsQuery.loading };
};

export const useGetOnlyListItems = ({
  listStructure,
  tableLinkId,
  searchInput = {},
  skip = false,
}) => {
  const tableView = listStructure?.tableView;

  const { content: listItemsPayload = [], ...rest } = usePaginationQuery(GET_ITEMS, {
    input: buldItemsSearchInput(tableView, { ...searchInput, tableLinkId }) || {},
    skip: !listStructure || skip,
  });

  const listItemsChecksum = listItemsPayload
    .map((item) => `${item.id}.${item.lastModifiedDate}`)
    .join(".");

  const listItems = useMemo(
    () => listItemsPayload.map((item) => new ListItem(item, { listStructure, tableLinkId })),
    // eslint-disable-next-line
    [listStructure?.checksum, listItemsChecksum]
  );

  return { listStructure, listItems, ...rest };
};

export const useLookupListItems = ({ tableViewId, tableLinkId, skip = false }) => {
  const { tableView, loading: loadingTableView } = useGetTableView(tableViewId);
  const listStructure = tableView ? new ListStructure({ tableView }) : null;

  const {
    content: listItemsPayload = [],
    loading,
    ...rest
  } = usePaginationQuery(LOOKUP_LIST_ITEMS, {
    input: { tableViewId, tableLinkId },
    skip: !listStructure || skip || !tableViewId || !tableLinkId,
  });

  const listItemsChecksum = listItemsPayload
    .map((item) => `${item.id}.${item.lastModifiedDate}`)
    .join(".");

  const listItems = useMemo(
    () => listItemsPayload.map((item) => new ListItem(item, { listStructure, tableLinkId })),
    // eslint-disable-next-line
    [listStructure?.checksum, listItemsChecksum]
  );

  return { listStructure, listItems, loading: loading || loadingTableView, ...rest };
};

export const useSearchSubItems = ({ listItemId, tableViewId, tableLinkId, skip = false }) => {
  const { tableView } = useGetTableView(tableViewId);
  const listStructure = tableView ? new ListStructure({ tableView }) : null;

  const {
    content: listItemsPayload = [],
    loading,
    refetch,
    ...rest
  } = usePaginationQuery(SEARCH_SUB_ITEMS, {
    input: {
      id: listItemId,
      tableViewId,
      tableLinkId,
    },
    skip: !listStructure || !tableView || skip || !tableLinkId,
  });

  const listItemsChecksum = listItemsPayload
    .map((item) => `${item.id}.${item.lastModifiedDate}`)
    .join(".");

  const listItems = useMemo(
    () => listItemsPayload.map((item) => new ListItem(item, { listStructure, tableLinkId })),
    // eslint-disable-next-line
    [listStructure?.checksum, listItemsChecksum]
  );

  return { listStructure, listItems, loading, refetch, ...rest };
};

export const useSearchItemsOptionsByDefaultTableView = ({ projectId, columnId, tableId } = {}) => {
  const { companyId } = useWorkspace();
  const { formLayoutId } = useFormLayoutNamespace();
  const skip = !tableId;

  const { tableViews, loading: loadingViews } = useSearchTableViews({
    searchInput: {
      namespace: { companyId, projectId, formLayoutId },
      filter: [{ field: "table.id", operation: "EQ", value: tableId }],
      sort: [{ field: "createdDate", value: "ASC" }],
    },
    skip,
  });

  const tableView = tableViews.find((view) => view.byDefault) || tableViews[0];

  const { content: listItemsPayload = [], ...rest } = usePaginationQuery(GET_ITEMS, {
    input: {
      namespace: { companyId, projectId, formLayoutId },
      tableViewId: tableView?.id,
      filter: [{ field: columnId, value: null, operation: "NE" }],
      sort: [{ field: "createdDate", value: "ASC" }],
    },
    skip: !tableView?.id || skip,
  });

  const listStructure = tableView ? new ListStructure({ tableView }) : null;

  if (!listStructure) {
    return { options: [], ...rest, loading: loadingViews || rest.loading };
  }

  const formField = listStructure.formFields.find((formField) => formField.id === columnId);

  let totalCount = rest.totalCount;

  const options = listItemsPayload.reduce((result, item) => {
    const itemValue = item.values.find((item) => columnId === item.columnId)?.value;
    const value = formField.parseValue(itemValue);

    if (isEmpty(value)) {
      totalCount -= 1;
      return result;
    }

    return [{ id: item.id, label: value }, ...result];
  }, []);

  return { options, ...rest, totalCount, loading: rest.loading || loadingViews };
};

export const useSearchItemsOptionsByTableColumn = ({
  tableViewId,
  columnId,
  skip = false,
  searchInput = {},
}) => {
  const { tableView, loading: loadingTableView } = useGetTableView(tableViewId);

  const { content: listItemsPayload = [], ...rest } = usePaginationQuery(
    SEARCH_ITEMS_BY_TABLE_COLUMN,
    {
      input: {
        tableViewId,
        columnId,
        filter: [...(searchInput.filter || [])],
      },
      skip: skip || !tableViewId || !columnId,
    }
  );

  const listStructure = tableView ? new ListStructure({ tableView }) : null;

  if (!listStructure) {
    return { listItems: [], ...rest, loading: loadingTableView || rest.loading };
  }

  const listItems = listItemsPayload.map(
    (item) => new ListItemByTableColumn(item, { listStructure })
  );

  return { listItems, ...rest, loading: loadingTableView || rest.loading };
};

// Private

const buldItemsSearchInput = (tableView, input) => {
  if (!tableView) return;

  return {
    ...input,
    tableViewId: tableView.id,
    filter: [...tableView.searchFilter, ...(input.filter || [])],
    sort: [...tableView.searchSort, ...(input.filter || [])],
  };
};
