import { AccessRule } from "./AccessRule";

const accessRule = (props) => new AccessRule(props);

// Graphql: PermissionType
// COMPANY_EDIT;
// COMPANY_DELETE;
// FIELD;
// FIELD_FOLDER;
// TABLE;
// CHANNEL;
// TASK;
// MAPPING;
// ACTION;
// PROJECT_CREATE;
// PROJECT_EDIT;
// PROJECT_DELETE;
// ITEM_GET;
// ITEM_EDIT;
// ITEM_DELETE;
// ITEM_COMMENT;
// COMPANY_MEMBER;
// PROJECT_MEMBER;

export const VIEW_COMPANY_SETTINGS = accessRule({
  any: ["COMPANY_EDIT", "COMPANY_DELETE", "FIELD", "FIELD_FOLDER", "TABLE", "CHANNEL"],
});

export const VIEW_PROJECT_SETTINGS = accessRule({
  any: ["PROJECT_CREATE", "PROJECT_EDIT", "PROJECT_DELETE", "PROJECT_MEMBER"],
});

export const VIEW_PROJECT_MEMBERS = accessRule({ any: ["PROJECT_MEMBER"] });
export const VIEW_COMPANY_MEMBERS = accessRule({ any: ["COMPANY_MEMBER"] });
export const MODIFY_PROJECT_MEMBERS = accessRule({ any: ["PROJECT_MEMBER"] });
export const MODIFY_COMPANY_MEMBERS = accessRule({ any: ["COMPANY_MEMBER"] });

export const MODIFY_PROJECT_SETTINGS = accessRule({ any: ["PROJECT_EDIT"] });
export const MODIFY_COMPANY_SETTINGS = accessRule({ any: ["COMPANY_EDIT"] });
export const CREATE_COMPANY_PROJECT = accessRule({ any: ["PROJECT_CREATE"] });

export const CREATE_FORM_LAYOUT = accessRule({ any: ["COMPANY_EDIT"] });
export const REMOVE_FORM_LAYOUT = accessRule({ any: ["COMPANY_EDIT"] });

export const MODIFY_ORG_OWNER = accessRule({ any: ["COMPANY_EDIT"] });

export const MODIFY_CHANNELS = accessRule({ any: ["CHANNEL"] });
export const MODIFY_TABLES = accessRule({ any: ["TABLE"] });
export const MODIFY_FIELDS = accessRule({ any: ["FIELD"] });
export const MODIFY_ACTIONS = accessRule({ any: ["ACTION"] });
export const MODIFY_TASKS = accessRule({ any: ["TASK"] });

export const REMOVE_COMPANY_PROJECTS = accessRule({ any: ["PROJECT_DELETE"] });
export const TABLE_VIEWS_MANAGER = accessRule({ any: ["PROJECT_EDIT"] });

export const REMOVE_COMPANY = accessRule({ any: ["COMPANY_DELETE"] });

export const MODIFY_TABLE_VIEW = accessRule({ any: ["ITEM_EDIT"] });
export const REMOVE_TABLE_VIEW = accessRule({ any: ["VIEW_REMOVE"] });

export const MODIFY_LIST_ITEM = accessRule({ any: ["ITEM_EDIT"] });
