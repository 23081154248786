export const ROOT = "/";

const companySettings = "/:companyId/settings";

export const COMPANY_GENERAL = `${companySettings}/general`;
export const COMPANY_PROFILE = `${companySettings}/profile`;
export const COMPANY_MEMBERS = `${companySettings}/members`;
export const COMPANY_PROJECTS = `${companySettings}/projects`;
export const COMPANY_CHANNELS = `${companySettings}/channels`;
export const COMPANY_CHANNEL = `${companySettings}/channels/:channelId`;
export const COMPANY_ALL_FIELDS = `${companySettings}/fields/all`;
export const COMPANY_FOLDER_FIELDS = `${companySettings}/fields/:folderId`;
export const COMPANY_TABLES = `${companySettings}/tables`;
export const COMPANY_TABLE = `${companySettings}/tables/:id`;

const projectNamespace = "/:companyId/:projectId";

export const PROJECT_ROOT = `${projectNamespace}`;
export const PROJECT_LIST = `${projectNamespace}/lists/:tableId`;
export const PROJECT_LIST_ITEM = `${projectNamespace}/lists/:tableId/:id`;
export const PROJECT_VIEW = `${projectNamespace}/views/:tableViewId`;
export const PROJECT_VIEW_ITEM = `${projectNamespace}/views/:tableViewId`;

const projectSettings = "/:companyId/:projectId/settings";

export const PROJECT_GENERAL = `${projectSettings}/general`;
export const PROJECT_MEMBERS = `${projectSettings}/members`;
export const PROJECT_LISTINGS = `${projectSettings}/views`;

export const COMPANIES_CREATE = "/companies/create";
export const PROJECT_CREATE = "/:companyId/projects/create";
export const SELECT_ACCOUNT_TYPE = "/auth/select-account-type";
