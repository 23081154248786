import React, { useEffect, useRef, useState } from "react";
import Box from "components/Box";
import Input from "components/Inputs/Input";
import Typography from "components/Typography";
import { useSearchMenu } from "theme/components/SearchMenu";
import Menu from "components/Menu";
import { Fade } from "@mui/material";
import clsx from "clsx";
import { InputWithActions } from "components/Inputs/InputComponents";

export const MenuInput = ({
  anchorEl,
  setAnchorEl,
  children,
  onClose,
  onOpen,
  onClick,
  PaperProps = {},
  MenuListProps = {},
  space,
  size,
  menuLabel,
  actions = {},
  height,
  footer,
  ...inputProps
}) => {
  const [width, setWidth] = useState(0);
  const open = Boolean(anchorEl);
  const classes = useSearchMenu();
  const anchorRef = useRef();
  const anchorWidth = anchorRef?.current?.getBoundingClientRect()?.width ?? 0;

  const handleOpen = (event) => {
    onClick && onClick(event);
    setAnchorEl && setAnchorEl(anchorRef.current);
  };

  const handleClose = (event) => {
    onClose && onClose(event);
    setAnchorEl && setAnchorEl(null);
  };

  useEffect(() => {
    open && onOpen && onOpen();
  }, [open]);

  useEffect(() => {
    setWidth(anchorWidth);
  }, [anchorWidth]);

  return (
    <>
      <Input
        {...inputProps}
        space={space}
        size={size}
        onClick={handleOpen}
        readOnly
        aria-controls="menuButton"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        anchorComponent={<Box className="anchorEl" ref={anchorRef} />}
        inputComponent={InputWithActions}
        inputComponentProps={{ actions }}
      />

      <Menu
        id="menuButton"
        classes={classes}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        TransitionComponent={Fade}
        PaperProps={{ className: classes.paper, style: { marginTop: 0 }, ...PaperProps }}
        MenuListProps={{ className: classes.list, ...MenuListProps }}
      >
        <Box style={{ minWidth: width }} className={classes.container}>
          <Box
            className={clsx(classes.contentContainer, classes.scrollContainer)}
            space={{ pt: 0 }}
          >
            <Box className={classes.searchContainer}>
              <Typography space={{ py: 1 }} component="div" color="grey" variant="caption">
                {menuLabel}
              </Typography>
            </Box>

            <Box style={{ maxHeight: height }} className={classes.scrollContent} space={{ pb: 1 }}>
              {children}
            </Box>
          </Box>

          {footer && <Box className={classes.footer}>{footer}</Box>}
        </Box>
      </Menu>
    </>
  );
};
