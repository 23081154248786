import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import ViewsManager from "components/ViewsManager";
import Divider from "components/Divider";
import MenuItem from "components/Menu/MenuItem";
import { PlusIcon, TrashIcon } from "icons";
import { useViewsManagerStyles } from "theme/components/ViewsManager";

import { useFilter } from "containers/TableFilter/FilterProvider";
import { FiltersLabels } from "containers/TableFilter/FiltersLabels";
import { FiltersSearchMenu } from "containers/TableFilter/FiltersMenu";
import { useViewsManager } from "./Provider";

const ViewsManagerFilter = () => {
  const { filters, onClean } = useFilter();
  const classes = useViewsManagerStyles();
  const { onClose } = useViewsManager();

  return (
    <ViewsManager>
      <ViewsManager.Header onClose={onClose}>
        <Typography variant="h4">Filter</Typography>
      </ViewsManager.Header>

      <ViewsManager.SubHeader>
        <Box>
          <Typography space={{ mb: 1 }} color="grey">{`${filters.length} applied`}</Typography>

          <FiltersLabels itemProps={{ className: classes.label }} />
        </Box>
      </ViewsManager.SubHeader>

      <Divider variant="light" space={{ my: 1 }} />

      <ViewsManager.Body>
        <FiltersSearchMenu
          button={MenuItem}
          buttonProps={{ icon: PlusIcon, size: "small" }}
          buttonTitle="Add filter"
        />

        <MenuItem size="small" onClick={onClean} icon={TrashIcon}>
          Clear all
        </MenuItem>
      </ViewsManager.Body>
    </ViewsManager>
  );
};

export default ViewsManagerFilter;
