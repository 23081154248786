import React from "react";
import Typography from "components/Typography";

const MenuTitle = ({ children, space }) => (
  <Typography
    variant="caption"
    component="div"
    weight="title"
    color="grey"
    space={{ mb: 1, ml: 1, ...space }}
  >
    {children}
  </Typography>
);

export default MenuTitle;
