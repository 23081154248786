import React, { useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";

const fetchInputProps = (props) => {
  const { ref, inputProps, label, ...rest } = props;

  return { inputRef: ref, ...inputProps, ...rest };
};

const DateRange = ({ renderInputs, onChange }) => {
  const [value, setValue] = useState([null, null]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <CalendarPicker
        disableCloseOnSelect={true}
        startText={null}
        endText={null}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          onChange && onChange(newValue);
        }}
        renderInput={(startProps, endProps) => {
          return renderInputs(fetchInputProps(startProps), fetchInputProps(endProps));
        }}
      />
    </LocalizationProvider>
  );
};

export default DateRange;
