import React, { useState, useEffect } from "react";
import Input from "./Input";
import isEmpty from "lodash/isEmpty";

const InputDelay = ({
  disabled,
  value: defaultValue,
  onChange,
  delayTime = 300,
  onSubmit,
  ...props
}) => {
  const [changedEvent, setChangedEvent] = useState(null);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    const eventValue = changedEvent?.target?.value;

    if (eventValue === defaultValue) return;
    if (isEmpty(eventValue) && isEmpty(defaultValue)) return;

    const timeOutId = setTimeout(() => changedEvent && onChange(eventValue), delayTime);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [changedEvent?.target?.value]);

  useEffect(() => {
    setValue(defaultValue);
    // eslint-disable-next-line
  }, [defaultValue]);

  const handleChange = (event) => {
    event.preventDefault();
    setChangedEvent(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setChangedEvent(null);
    onChange(event.target[0].value);
    onSubmit?.();
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      setValue(event.target.value);
      event.preventDefault();
      onSubmit?.(event);
    }
  };

  return (
    <form
      style={{ width: "100%" }}
      disabled={disabled}
      onSubmit={handleSubmit}
      onChange={handleChange}
    >
      <Input
        onKeyDown={onKeyDown}
        {...props}
        disabled={disabled}
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
    </form>
  );
};

export default InputDelay;
