import React, { useState } from "react";
import IconButton from "components/IconButton";
import { useMenuStyles } from "theme/components/Menu";
import { MoreHorizontalIcon } from "icons";
import Menu from "components/Menu";

const MoreMenu = ({ children, onClose, onClick, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useMenuStyles();

  const handleOpen = (event) => {
    onClick && onClick(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    onClose && onClose(event);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        icon={MoreHorizontalIcon}
        {...props}
        aria-haspopup="true"
        onClick={handleOpen}
        iconSize="small"
      />

      <Menu classes={classes} anchorEl={anchorEl} open={open} onClose={handleClose}>
        {children}
      </Menu>
    </>
  );
};

export default MoreMenu;
