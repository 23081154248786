import React from "react";
import Box from "components/Box";
import clsx from "clsx";
import IconButton from "components/IconButton";
import { CaretDownIcon } from "icons";

const CollapseSpace = ({
  column,
  colIndex,
  nestedLevel,
  collapseLevel,
  setCollapse,
  collapsed,
}) => {
  return (
    <>
      {nestedLevel > 0 && colIndex === 1 && (
        <Box
          style={{
            paddingLeft: `calc(${nestedLevel + 1} * var(--icon-button-size-table))`,
          }}
          space={{ ml: nestedLevel }}
        />
      )}

      {collapseLevel > 0 && !setCollapse && colIndex === 1 && <Box space={{ ml: 1 }} />}

      {setCollapse && colIndex === 1 && (
        <IconButton
          className={clsx(`${column.id}-action`, { collapsed })}
          space={{ mr: 1 }}
          size="table"
          color="grey"
          icon={CaretDownIcon}
        />
      )}
    </>
  );
};

export default CollapseSpace;
