import React, { useMemo } from "react";
import { createPortal } from "react-dom";
import { DragOverlay, defaultDropAnimationSideEffects } from "@dnd-kit/core";
import { fieldTypeIcon } from "models/abstract/FieldType";

import { useDndBuilderManager, TABLE_CONTAINER_ID, SYSTEM_ELEMENTS_PROPS } from "./index";
import { findSection } from "./utils";
import { Item } from "../components/Item";
import { StorageListItem } from "../components/StorageListItem";
import { Container } from "../components/Container";
import { Section } from "../components/Section";

const dropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

export const DndItemsOverlay = ({ handle = true }) => {
  const {
    sectionsProps,
    allItemsMap,
    components,
    itemsIds,
    sections,
    containers,
    containersIds,
    items,
    storageItemsIds,
    activeId,
    activeStorageId,
    containersProps,
  } = useDndBuilderManager();

  return (
    <>
      {createPortal(
        <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
          {activeId && renderDragOverlay(activeId)}
        </DragOverlay>,
        document.body
      )}
    </>
  );

  function renderDragOverlay(activeId) {
    if (sections.includes(activeId)) {
      return renderSectionDragOverlay(activeId);
    }

    if (containersProps[activeId]?.type === TABLE_CONTAINER_ID) {
      return renderTableContainerDragOverlay(activeId, activeId !== TABLE_CONTAINER_ID);
    }

    if (containersIds.includes(activeId)) {
      return renderContainerDragOverlay(activeId);
    }

    if (storageItemsIds.includes(activeId) || activeStorageId === activeId) {
      return renderSortableListItemDragOverlay(activeId);
    }

    if (itemsIds.includes(activeId)) {
      return renderSortableListItemDragOverlay(activeId, true);
    }

    return null;
  }

  function renderSortableListItemDragOverlay(id, isItem) {
    const itemStyles = isItem ? { marginTop: 10 } : {};

    return (
      <div style={{ maxWidth: 250, ...itemStyles }}>
        <StorageListItem
          label={allItemsMap[id]?.label}
          icon={fieldTypeIcon(allItemsMap[id]?.entity?.fieldType)}
          placeholder
          value={id}
          handle={handle}
          dragOverlay
        />
      </div>
    );
  }

  function renderTableContainerDragOverlay(id, isItem) {
    const itemStyles = isItem ? { marginTop: -10, marginLeft: -10 } : {};

    return (
      <div style={{ maxWidth: 250, ...itemStyles }}>
        <StorageListItem
          {...SYSTEM_ELEMENTS_PROPS[TABLE_CONTAINER_ID]}
          placeholder
          value={id}
          handle={handle}
          dragOverlay
        />
      </div>
    );
  }

  function renderContainerDragOverlay(containerId, nested = false) {
    const droppableElement = document.getElementById(`${activeId}-droppable`);
    const overlayWidth = droppableElement?.getBoundingClientRect()?.width;

    return (
      <Container
        placeholder
        selected={activeId === containerId}
        id={`${containerId}-drag-overlay`}
        style={{
          height: nested ? undefined : "100%",
          width: nested ? undefined : overlayWidth,
        }}
      >
        {items[containerId].map((item, index) => (
          <Item
            component={components.item}
            builderItem={allItemsMap[item]}
            key={`${item}-${index}-drag-overlay}`}
            value={item}
            handle={handle}
          />
        ))}
      </Container>
    );
  }

  function renderSectionDragOverlay(sectionId) {
    const droppableElement = document.getElementById(`${sectionId}-droppable`);
    const overlayWidth = droppableElement?.getBoundingClientRect()?.width;

    return (
      <Section
        placeholder
        selected={activeId === sectionId}
        id={`${sectionId}-drag-overlay`}
        {...sectionsProps[sectionId]}
        style={{
          height: "100%",
          width: overlayWidth,
        }}
      >
        {containers[sectionId]?.map((containerId) => renderContainerDragOverlay(containerId, true))}
      </Section>
    );
  }
};
