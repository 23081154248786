import React from "react";
import { ComponentTypeView } from "components/ComponentType/ComponentTypeView";

export const FormFieldView = ({ hint, formField, className, loading, value, onClick }) => {
  return (
    <ComponentTypeView
      hint={hint}
      className={className}
      type={formField.componentType}
      onClick={onClick}
      loading={loading}
      originValue={value}
      value={formField.parseValue(value)}
      emptyMessage={formField.editable ? "Empty" : "No editable"}
    />
  );
};
