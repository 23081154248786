import React, { useRef, useState } from "react";
import Box from "components/Box";
import Button from "components/Button";
import InputDelay from "components/Inputs/InputDelay";
import isEmpty from "lodash/isEmpty";
import { SearchIcon } from "icons";
import { useOnClickOutsideEffect } from "utils/effects";
import clsx from "clsx";

const SearchInput = ({ space, hiddable, className, search, onSearch }) => {
  const formRef = useRef();
  const [hide, setHide] = useState(hiddable);

  const toggleHide = () => setHide((prev) => !prev);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);

    onSearch((formProps.search || "").trim());
  };

  const handleChange = (value) => onSearch((value || "").trim());

  useOnClickOutsideEffect(formRef, () => setHide(true));

  const isHideInput = hiddable && hide && isEmpty(search);

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <Box style={{ height: 30 }} display="flex" alignItems="center">
        {hiddable && (
          <Button
            size="compact"
            variant="grey"
            leftIcon={() => <SearchIcon />}
            onClick={toggleHide}
            space={{ mr: isHideInput ? 0.5 : 0 }}
          />
        )}

        {!isHideInput && (
          <InputDelay
            name="search"
            autoFocus={hiddable}
            className={clsx(className, { notHiddable: !hiddable })}
            leftIcon={hiddable ? null : SearchIcon}
            variant="ghost"
            space={space}
            size="small"
            fullWidth
            placeholder="search"
            onChange={handleChange}
            value={search}
          />
        )}
      </Box>
    </form>
  );
};

export default SearchInput;
