import React from "react";
import { Link as MuiLink } from "@mui/material";
import NavLinkRoute from "components/NavLink";
import withClasses from "components/withClasses";
import { useWorkspace } from "contexts/Workspace";

const Link = withClasses(
  ({ innerRef, variantProps, ...props }) => <MuiLink ref={innerRef} {...props} />,
  "MuiLink",
  {}
);

const LinkComponent = ({ leftIcon: LeftIcon, children, workspace, to, ...props }) => {
  const { currentCompany } = useWorkspace() || {};
  const toPath = workspace && currentCompany.id ? `/${currentCompany.id}${to}` : to;

  return (
    <Link {...props} to={toPath} component={NavLinkRoute} display="flex" alignItems="center">
      {LeftIcon && <LeftIcon space={{ mr: 1, mb: -0.5 }} />}
      {children}
    </Link>
  );
};

export default LinkComponent;
