import React from "react";
import MenuItem from "components/Menu/MenuItem";
import Box from "components/Box";
import { Virtuoso } from "react-virtuoso";

import { optionName } from "../RenderTags";

export const RenderOptions = ({
  markVariant,
  optionComponent,
  virtualized,
  isSelected,
  options,
  totalCount,
  onChange,
  onLoadMore,
  virtuosoHeight,
}) => {
  if (!virtualized) {
    return options.map((option, index) => (
      <ListItem
        key={`${option.id}-${index}`}
        isSelected={isSelected}
        option={option}
        markVariant={markVariant}
        optionComponent={optionComponent}
        onChange={onChange}
      />
    ));
  }

  return (
    <Virtuoso
      style={{ height: virtuosoHeight }}
      overscan={100}
      data={options}
      totalCount={totalCount}
      endReached={onLoadMore}
      itemContent={(index) => {
        const option = options[index];

        if (!option) return <></>;

        return (
          <ListItem
            isSelected={isSelected}
            option={option}
            onChange={onChange}
            markVariant={markVariant}
            optionComponent={optionComponent}
          />
        );
      }}
    />
  );
};

export const ListItem = ({
  option,
  markVariant,
  isSelected,
  optionComponent: OptionComponent,
  onChange,
  size,
}) => {
  const handleChange = () => onChange(option.id);

  return (
    <MenuItem
      ellipses
      size={size}
      component="div"
      markVariant={markVariant}
      loading={option.id === "stubLoading"}
      tickIcon={markVariant === "tick"}
      onMouseDown={handleChange}
      selected={isSelected(option.id)}
    >
      <Box display="flex" alignItems="center">
        {OptionComponent ? <OptionComponent option={option} /> : optionName(option)}
      </Box>
    </MenuItem>
  );
};
