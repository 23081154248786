import { useEffect, useRef, useState } from "react";

export const useEventEffect = (ref, eventType, callback) => {
  const refCurrent = ref?.current;

  useEffect(() => {
    if (refCurrent && callback) {
      const listener = (event) => {
        if (callback) {
          callback(event);
        }
      };
      refCurrent?.addEventListener(eventType, listener);

      return () => {
        refCurrent.removeEventListener(eventType, listener);
      };
    }
    // eslint-disable-next-line
  }, [refCurrent]);
};

export const useOnClickOutsideEffect = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref?.current || ref?.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

const generateKeyboardEffect =
  (condition) =>
  (ref, callback, { eventType = "keydown" } = {}) => {
    useEffect(() => {
      if (callback) {
        const listener = (event) => {
          if (callback && condition(event)) {
            event.preventDefault();
            callback(event);
          }
        };
        document.addEventListener(eventType, listener);

        return () => {
          document.removeEventListener(eventType, listener);
        };
      }
      // eslint-disable-next-line
    }, [ref, callback]);
  };

export const useOnEnterEffect = generateKeyboardEffect(
  (event) => event.keyCode === 13 && !event.shiftKey
);

export const useOnEscEffect = generateKeyboardEffect((event) => event.keyCode === 27);

export const useDidMountEffect = (func, inputs) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      func();
    }

    didMountRef.current = true;
    // eslint-disable-next-line
  }, inputs);
};

export const useDelayMountStatus = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
};
