import { gql } from "@apollo/client";
import { User } from "models/fragments/User";
import { FIELD_TYPE_CONFIG } from "models/abstract/FieldTypeConfig";
import { FieldShort } from "./FieldShort";

export class FieldFull extends FieldShort {}

FieldFull.fragment = gql`
  fragment FieldFullFragment on GetFieldWithRefPayload {
    id
    createdDate
    lastModifiedDate
    inUse
    name
    description
    uid
    type
    folderId
    ${FIELD_TYPE_CONFIG}
    createdBy {
      ...UserFragment
    }
    fieldRefs {
      ...FieldShortFragment
    }
  }

  ${User.fragment}
  ${FieldShort.fragment}
`;
