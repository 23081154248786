import React from "react";
import Box from "components/Box";
import IconButton from "components/IconButton";
import { MessageSmallIcon, EditIcon } from "icons";
import { ArrowUpRightIcon, MoreHorizontalIcon } from "iconsNew";

import { useStateFields } from "components/TablePro/Contexts/StateFieldsContext";

const TableRowActions = ({ viewable, commentable, tableColumn, entity }) => {
  const { toggleSelect, selectTags } = useStateFields();

  // Check if entity has editable rule as boolean
  const isEntityEditableRule = entity.editable === true || entity.editable === false;

  const editable = isEntityEditableRule ? entity.editable : tableColumn.editable;

  const VIEW = selectTags.view || "VIEW";
  const COMMENT = selectTags.comment || "COMMENT";
  const EDIT = selectTags.edit || "EDIT";

  return (
    <Box className="AppTablePro--Row-actions">
      {viewable && (
        <IconButton
          space={{ mr: 1 }}
          className={`${tableColumn.id}-action`}
          size="collapse"
          color="grey"
          onClick={() => toggleSelect({ id: entity.id, mode: VIEW })}
          icon={ArrowUpRightIcon}
        />
      )}

      {commentable && (
        <IconButton
          className={`${tableColumn.id}-action`}
          space={{ mr: 1 }}
          size="collapse"
          color="grey"
          onClick={() => toggleSelect({ id: entity.id, mode: COMMENT })}
          icon={MessageSmallIcon}
        />
      )}

      {editable && (
        <IconButton
          className={
            isEntityEditableRule ? `${tableColumn.id}-permanentAction` : `${tableColumn.id}-action`
          }
          space={{ mr: 0 }}
          size="collapse"
          color="grey"
          onClick={() => toggleSelect({ id: entity.id, mode: EDIT })}
          icon={EditIcon}
        />
      )}
      <IconButton size="collapse" color="grey" onClick={() => {}} icon={MoreHorizontalIcon} />
    </Box>
  );
};

export default TableRowActions;
