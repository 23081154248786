import uniq from "lodash/uniq";
import keyBy from "lodash/keyBy";
import { FormField } from "models/abstract/FormField";

import { FormActionInputCell } from "./FormActionInputCell";
import { VARIANT_KEY } from "../form/mergeResponseInput";
import { filterInputFields } from "../form/filterInputFields";

export const useFormTableContainer = ({ fields, builderItems, filter }) => {
  const renderFields = filterInputFields({ fields, filter });

  const variantColumnsIds = uniq(
    renderFields.flatMap((field) => Object.keys(field).filter((key) => key.includes(VARIANT_KEY)))
  );

  const columns = builderItems.map((builderItem) => ({
    id: builderItem.entity.id,
    label: builderItem.label,
    component: FormActionInputCell,
    formField: builderItem.formField,
    builderItem,
    width: 200,
  }));

  const columnsMap = keyBy(columns, "id");
  let columnsToHide = [];

  const variantColumns = sortVariantColumns(variantColumnsIds).reduce((result, id) => {
    const mainColumn = columnsMap[id.split("_")[0]];
    const index = parseInt(id.split("_")[2]);

    if (mainColumn) {
      columnsToHide.push(mainColumn.id);

      const newColumn = {
        ...mainColumn,
        id: id,
        label: `${mainColumn.label} ${index + 1}`,
        formField: new FormField({ ...mainColumn.formField.origin, id }),
      };

      return [...result, newColumn];
    }
  }, []);

  const formTableColumns = [
    ...columns.filter((column) => !columnsToHide.includes(column.id)),
    ...variantColumns,
  ];

  const viewsManagerColumns = formTableColumns.map((column) => ({
    id: column.id,
    label: column.label,
    formField: column.formField,
  }));

  return { originColumns: columns, formTableColumns, viewsManagerColumns, entities: renderFields };
};

const variantRegexp = new RegExp(`${VARIANT_KEY}_(\\d+)`);

const sortVariantColumns = (data) => {
  // Sort the array using the `sort` method
  return data.sort((a, b) => {
    // Extract the variant numbers and the IDs
    let variantA = a.match(variantRegexp)[1];
    let variantB = b.match(variantRegexp)[1];
    let idA = a.split(`_${VARIANT_KEY}`)[0];
    let idB = b.split(`_${VARIANT_KEY}`)[0];

    // First, compare the VARIANT numbers
    if (variantA !== variantB) {
      return variantA - variantB;
    }

    // If VARIANT numbers are equal, then compare the IDs
    if (idA < idB) return -1;
    if (idA > idB) return 1;

    // If both are equal, keep the original order (stable sort)
    return 0;
  });
};
