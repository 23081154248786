import { MappingPath } from "models/abstract/MappingPath";
import { FormField } from "models/abstract/FormField";
import {
  AUTO_INCREMENT_TYPE,
  SINGLE_LINE_TEXT_TYPE,
  MULTI_LINE_TEXT_TYPE,
} from "models/abstract/FieldType";

export const isLookupType = (type) =>
  [AUTO_INCREMENT_TYPE, SINGLE_LINE_TEXT_TYPE, MULTI_LINE_TEXT_TYPE].includes(type);

export class BuilderItem {
  constructor({ entity, required, blank, lookup }) {
    this.entity = entity;
    this.required = required;
    this.blank = blank;
    this.lookup = lookup;

    this.properties = {
      required,
      blank,
      lookup,
    };
  }

  get label() {
    return this.entity.label ?? this.entity.title ?? this.entity.name;
  }

  get id() {
    return MappingPath.fromEntityToItemPathId(this.entity);
  }

  get modelType() {
    return this.entity.modelType;
  }

  get formField() {
    return new FormField({ ...this.entity.formField.origin, ...this.properties });
  }
}
