import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import UploadFileButton from "components/UploadFileButton";
import { RefreshIcon, ArrowUpIcon2 } from "icons";
import { PlusIcon } from "iconsNew";
import CircularProgress from "components/CircularProgress";
import FileLabel from "components/Labels/FileLabel";
import UploadFileActions from "./UploadFileActions";
import isObject from "lodash/isObject";

import "styles/App/FileInput.scss";

const useFileInputStyles = () => ({
  root: "AppFileInput-root",
});

const FileInput = ({
  name,
  label,
  error,
  space,
  value,
  onChange,
  onChangeHook,
  readOnly,
  loading,
}) => {
  const handleChange = (file) => {
    onChange && onChange(file);
    onChangeHook && onChangeHook(file);
  };

  const classes = useFileInputStyles();

  if (value?.size && !loading) {
    return (
      <Box space={{ py: 1 }} style={{ width: "100%" }} display="flex" flexDirection="column">
        <Box style={{ width: "100%" }}>
          <Box className={classes.root}>
            <FileLabel value={value} disabled={readOnly || loading} />

            <UploadFileActions handleRemove={() => handleChange(null)} />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box space={{ py: 1 }} style={{ width: "100%" }} display="flex" flexDirection="column">
      <UploadFileButton
        space={space}
        disabled={readOnly || loading}
        style={{ width: "100%", maxWidth: 80 }}
        name={name}
        size="small"
        onUpload={onChange}
        leftIcon={loading ? CircularProgress : PlusIcon}
        variant="outlined"
      >
        {label || "Add"}
      </UploadFileButton>

      {error && (
        <Typography space={{ mt: 1 }} error={`${!!error}`} variant="helperText">
          {isObject(error) ? error.message : error}
        </Typography>
      )}
    </Box>
  );
};

export const FileCompactInput = ({ name, disabled, value, onChange, onChangeHook }) => {
  const handleChange = (file) => {
    onChange && onChange(file);
    onChangeHook && onChangeHook(file);
  };

  return (
    <Box style={{ width: "100%" }} display="flex" flexDirection="column">
      <UploadFileButton
        disabled={disabled}
        name={name}
        onUpload={handleChange}
        component={Button}
        variant="outlined"
      >
        {value?.size ? (
          <RefreshIcon />
        ) : (
          <Box display="flex">
            <ArrowUpIcon2 space={{ mr: 1 }} />
            Upload a file
          </Box>
        )}
      </UploadFileButton>
    </Box>
  );
};

export default FileInput;
