import React from "react";
import isEmpty from "lodash/isEmpty";
import flatten from "lodash/flatten";
import compact from "lodash/compact";
import SelectNew, { useDebounceAnchor } from "components/Inputs/Select";
import { useSearchItemsOptionsByTableColumn } from "models/ListItem/queries";
import { useFieldItems } from "hooks/queries/useQueries";
import { ListInput as ComponentTypeListInput } from "components/ComponentType/ComponentTypeInput";

const OPTION_SEPARATOR = "#SEPARATOR#";

export const ListInput = ({ reduceApiRequest = false, ...props }) => {
  const { anchorEl, onOpen, onClose } = useDebounceAnchor();
  const open = reduceApiRequest ? Boolean(anchorEl) : true;

  const handleOpen = (element) => {
    onOpen(element);
    props.onOpen?.();
  };

  const handleClose = () => {
    onClose();
    props.onClose?.();
  };

  if (props.formField.isDynamicList) {
    return (
      <ListItemsByColumnSelect {...props} onOpen={handleOpen} onClose={handleClose} skip={!open} />
    );
  }

  return <FormFieldItemsSelect {...props} onOpen={handleOpen} onClose={handleClose} skip={!open} />;
};

const parseItemsOptions = (options) => {
  return options.map((option) => {
    const label = option.value ?? option.label;

    return [[option.id, label].join(OPTION_SEPARATOR), label];
  });
};

export const FieldItemsSelect = ({
  formField,
  value,
  onChange,
  options,
  valueOptions = [],
  menuInput,
  ...props
}) => {
  const itemsOptions = parseItemsOptions(options);
  const itemsValueOptions = parseItemsOptions(valueOptions);

  const parsedValue = value
    ? flatten([value]).map((item) => [item.id, item.value].join(OPTION_SEPARATOR))
    : [];

  const handleChange = (newValue) => {
    if (isEmpty(newValue)) return onChange([]);

    const parsedValue = [newValue].flat().map((item) => {
      const [id, ...value] = item.split(OPTION_SEPARATOR);
      return { id, value: value.join(OPTION_SEPARATOR) };
    });

    onChange(parsedValue);
  };

  return (
    <ComponentTypeListInput
      selectComponent={SelectNew}
      renderTagsInSearch
      removeableLabel={!menuInput}
      optionVariant="label"
      variant="ghostOutlined"
      {...props}
      value={parsedValue}
      onChange={handleChange}
      options={itemsOptions}
      valueOptions={itemsValueOptions}
      placeholder={formField.placeholder}
      multiple={props.multiple ?? formField.config.isMultiple}
    />
  );
};

const ListItemsByColumnSelect = ({ formField, tableViewId, skip, ...props }) => {
  const itemIds = compact(flatten([props.value])).map((item) => item.id);

  const itemsOptionsQuery = useSearchItemsOptionsByTableColumn({
    tableViewId,
    columnId: formField.id,
    skip,
  });

  const valueItemsOptionsQuery = useSearchItemsOptionsByTableColumn({
    tableViewId,
    columnId: formField.id,
    searchInput: { filter: [{ field: "id", value: itemIds.join(","), operation: "IN" }] },
    skip,
  });

  const options = itemsOptionsQuery.listItems.map((option) => ({
    id: option.id,
    label: option.label,
  }));

  const valueOptions = valueItemsOptionsQuery.listItems
    .filter((item) => itemIds.includes(item.id))
    .map((option) => ({
      id: option.id,
      label: option.label,
    }));

  return (
    <FieldItemsSelect
      {...props}
      formField={formField}
      virtualized
      options={options}
      valueOptions={valueOptions}
      disabled={itemsOptionsQuery.loading || props.disabled || valueItemsOptionsQuery.loading}
      totalCount={itemsOptionsQuery.totalCount}
      onLoadMore={itemsOptionsQuery.onLoadMore}
      onSearch={itemsOptionsQuery.onSearch}
    />
  );
};

const FormFieldItemsSelect = ({ formField, skip, ...props }) => {
  const {
    fieldItemsOptions = [],
    loading,
    totalCount,
    onLoadMore,
    onSearch,
  } = useFieldItems({
    fieldId: formField.fieldId,
    skip: skip || !formField.fieldId,
  });

  const options = fieldItemsOptions.map((option) => ({ id: option[0], value: option[1] }));

  return (
    <FieldItemsSelect
      {...props}
      formField={formField}
      virtualized
      options={options}
      loading={loading}
      disabled={loading || props.disabled}
      totalCount={totalCount}
      onLoadMore={onLoadMore}
      onSearch={onSearch}
    />
  );
};
