import React from "react";
import clsx from "clsx";
import { useTableInputCellStyles } from "theme/components/TablePro";

import { TableCellErrorTooltip } from "./TableCellContainer";

export const TableInputCellContainer = ({ classes, error, children }) => {
  const selectClasses = useTableInputCellStyles();

  return (
    <div className={clsx(classes.cellContainer, selectClasses.cellContainer)}>
      {children}

      <TableCellErrorTooltip error={error} />
    </div>
  );
};
