import React, { forwardRef } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import clsx from "clsx";

const DefaultDragDropComponent = forwardRef(({ children, ...props }, ref) => (
  <div ref={ref} {...props}>
    {children}
  </div>
));

const VerticalMove = ({
  onDragEnd,
  children,
  getItemStyle,
  getListStyle,
  useDraggableElement,
  component: DragDropComponent = DefaultDragDropComponent,
}) => {
  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    onDragEnd(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <DragDropComponent
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={clsx(provided.className, { isDraggingOver: snapshot.isDraggingOver })}
            style={getListStyle ? getListStyle(snapshot.isDraggingOver) : {}}
          >
            {children.map(
              (child, index) =>
                child && (
                  <Draggable
                    key={`draggable-${index}-${child.props.id}`}
                    draggableId={`draggable-${index}-${child.props.id}`}
                    index={child.props.draggableIndex || index}
                  >
                    {(provided, snapshot) => {
                      return React.cloneElement(child, {
                        ...child.props,
                        className: clsx(child.props.className, { isDragging: snapshot.isDragging }),
                        key: `draggableItem-${child.props.id}`,
                        ref: provided.innerRef,
                        ...provided.draggableProps,
                        ...(useDraggableElement
                          ? { handleDraggableProps: provided.dragHandleProps }
                          : provided.dragHandleProps),
                        style: {
                          ...child.props.style,
                          ...(getItemStyle ? getItemStyle(snapshot.isDragging) : {}),
                          ...provided.draggableProps.style,
                        },
                      });
                    }}
                  </Draggable>
                )
            )}
            {provided.placeholder}
          </DragDropComponent>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default VerticalMove;
