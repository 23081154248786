export const isFile = (value) => value instanceof File || value instanceof Blob;

export const fetchDroppedFile = (event, bulk = false) => {
  let files = [];

  if (event.dataTransfer?.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (var i = 0; i < event.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (event.dataTransfer.items[i].kind === "file") {
        var file = event.dataTransfer.items[i].getAsFile();

        if (bulk) {
          files = [...files, file];
        } else {
          return file;
        }
      }
    }
  } else {
    if (bulk) {
      files = event.dataTransfer.files;
    } else {
      return event.dataTransfer.files[0];
    }
  }

  return files;
};

export const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (!bytes) return "n/a";

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

  if (i === 0) return `${bytes} ${sizes[i]}`;

  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};
