import { TABLE_VIEW_MODEL_TYPE, PROJECT_MODEL_TYPE } from "models/types";
import * as accessRules from "./accessRules";

export class AccessManager {
  constructor({ user, company, permissionsConfig }) {
    this.user = user;
    this.company = company;
    this.userPermissionsMap = company?.permissionsMap;
    this.permissionsConfig = permissionsConfig;

    this.valid = !!this.company && !!this.userPermissionsMap && !!this.permissionsConfig;
  }

  hasAccess(accessRule, accessEntity) {
    if (!this.valid) return false;

    const isAccessible = (permissionType, accessEntity) =>
      this._hasPermissionTypeAccess(permissionType, accessEntity);

    return accessRule.checkAccess({ accessEntity, isAccessible });
  }

  // PRIVATE

  _hasPermissionTypeAccess(permissionType, accessEntity) {
    if (!this.valid) return false;

    const requiredRoles = this.permissionsConfig.permissionTypesRolesMap[permissionType] || [];

    return !!requiredRoles.find((requiredRole) => this.hasRoleAccess(requiredRole, accessEntity));
  }

  hasRoleAccess(requiredRole, accessEntity) {
    if (!accessEntity) return false;

    const accessEntityType = accessEntity.modelType ?? accessEntity.type;
    const entityRole = this.userPermissionsMap?.permissionFor(accessEntity)?.role;

    if (accessEntityType === TABLE_VIEW_MODEL_TYPE) {
      if (accessEntity.createdBy.id === this.user.id) return true;

      return (
        compareRoleAccess(requiredRole, entityRole) ||
        this.hasRoleAccess(requiredRole, accessEntity.project) ||
        this.hasRoleAccess(requiredRole, this.company)
      );
    }

    if (accessEntityType === PROJECT_MODEL_TYPE) {
      return (
        compareRoleAccess(requiredRole, entityRole) ||
        this.hasRoleAccess(requiredRole, this.company)
      );
    }

    return compareRoleAccess(requiredRole, entityRole);
  }

  get canViewSettings() {
    const userPermissions = this.userPermissionsMap?.array || [];
    const projects = userPermissions
      .filter((permission) => permission.entity.type === PROJECT_MODEL_TYPE)
      .map(({ entity }) => entity);

    return (
      this.hasAccess(accessRules.VIEW_COMPANY_SETTINGS, this.company) ||
      projects.some((accessEntity) =>
        this.hasAccess(accessRules.VIEW_PROJECT_SETTINGS, accessEntity)
      )
    );
  }

  get canModifyFields() {
    return this.hasAccess(accessRules.MODIFY_FIELDS, this.company);
  }

  get canRemoveProjects() {
    return this.hasAccess(accessRules.REMOVE_COMPANY_PROJECTS, this.company);
  }

  get canRemoveCompany() {
    return this.hasAccess(accessRules.REMOVE_COMPANY, this.company);
  }

  get canModifyTables() {
    return this.hasAccess(accessRules.MODIFY_TABLES, this.company);
  }

  get canModifyTasks() {
    return this.hasAccess(accessRules.MODIFY_TASKS, this.company);
  }

  get canCreateProject() {
    return this.hasAccess(accessRules.CREATE_COMPANY_PROJECT, this.company);
  }

  get canCreateForm() {
    return this.hasAccess(accessRules.CREATE_FORM_LAYOUT, this.company);
  }

  get canRemoveForms() {
    return this.hasAccess(accessRules.REMOVE_FORM_LAYOUT, this.company);
  }

  get canModifyOrgOwner() {
    return this.hasAccess(accessRules.MODIFY_ORG_OWNER, this.company);
  }
}

const compareRoleAccess = (requiredRole, roleToCheck) => {
  if (!roleToCheck) return false;

  switch (requiredRole) {
    case "COMPANY_OWNER":
      return requiredRole === roleToCheck;
    case "COMPANY_ADMIN":
      return requiredRole === roleToCheck || compareRoleAccess("COMPANY_OWNER", roleToCheck);
    case "COMPANY_MEMBER":
      return requiredRole === roleToCheck || compareRoleAccess("COMPANY_ADMIN", roleToCheck);
    case "COMPANY_GUEST":
      return requiredRole === roleToCheck || compareRoleAccess("COMPANY_MEMBER", roleToCheck);
    case "PROJECT_OWNER":
      return requiredRole === roleToCheck || compareRoleAccess("COMPANY_ADMIN", roleToCheck);
    case "PROJECT_ADMIN":
      return requiredRole === roleToCheck || compareRoleAccess("PROJECT_OWNER", roleToCheck);
    case "PROJECT_EDITOR":
      return requiredRole === roleToCheck || compareRoleAccess("PROJECT_ADMIN", roleToCheck);
    case "PROJECT_VIEWER":
      return requiredRole === roleToCheck || compareRoleAccess("PROJECT_EDITOR", roleToCheck);
    case "TABLE_VIEW_EDITOR":
      return requiredRole === roleToCheck || compareRoleAccess("PROJECT_ADMIN", roleToCheck);
    case "TABLE_VIEW_VIEWER":
      return requiredRole === roleToCheck || compareRoleAccess("TABLE_VIEW_EDITOR", roleToCheck);
    case "FORM_ACCESS":
      return requiredRole === roleToCheck;
    default:
      return false;
  }
};

const PROJECT_PERMISSIONS = ["PROJECT_OWNER", "PROJECT_EDITOR", "PROJECT_ADMIN", "PROJECT_VIEWER"];
const COMPANY_PERMISSIONS = ["COMPANY_OWNER", "COMPANY_ADMIN", "COMPANY_MEMBER", "COMPANY_GUEST"];
const TABLE_VIEW_PERMISSIONS = ["TABLE_VIEW_EDITOR", "TABLE_VIEW_VIEWER"];
const FORM_LAYOUT_PERMISSIONS = ["FORM_ACCESS"];

export const FORM_ACCESS_PERMISSION = "FORM_ACCESS";

export const PROJECT_PERMISSIONS_OPTIONS = PROJECT_PERMISSIONS.map((permission) => [
  permission,
  permission,
]);

export const COMPANY_PERMISSIONS_OPTIONS = COMPANY_PERMISSIONS.map((permission) => [
  permission,
  permission,
]);

export const TABLE_VIEW_PERMISSIONS_OPTIONS = TABLE_VIEW_PERMISSIONS.map((permission) => [
  permission,
  permission,
]);

export const FORM_LAYOUT_PERMISSIONS_OPTIONS = FORM_LAYOUT_PERMISSIONS.map((permission) => [
  permission,
  permission,
]);
