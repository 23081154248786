import React, { useState, memo, useCallback } from "react";
import Typography from "components/Typography";
import Box from "components/Box";
import { ChevronDownIcon } from "iconsNew";
import { isReactComponent } from "utils/detect";
import { useStateFields } from "components/TablePro/Contexts/StateFieldsContext";
import { useStickFields } from "components/TablePro/Contexts/StickFieldsContext";
import JSum from "vendor/jsum";
import isEqual from "lodash/isEqual";

import { useTablePro } from "../Provider";
import TableCell from "./TableCell";
import { TableHeaderCellActions } from "./TableHeaderCellActions";
import { TableNavigationRow } from "./TableNavigationRow";
import CollapseSpace from "./CollapseSpace";
import { ResizeFieldBorder } from "./ResizeBorders";

const TableHeader = ({ innerRef } = {}) => {
  const {
    headerClasses,
    columns,
    variant,
    sort,
    onSort,
    MultiselectHeader,
    multiSelectable,
    withDescendants,
    widthMap,
    setWidthMap,
    tableHeight,
    onBulkRemove,
    resizeBorder,
  } = useTablePro();

  const { bulkSelect, toggleBulkSelect, checkedIds, checkedCount, toggleCheckedId, totalCount } =
    useStateFields();

  const stateFieldProps = { bulkSelect, toggleBulkSelect, checkedIds, toggleCheckedId, totalCount };

  const handleSort = useCallback(
    (column, direction) => {
      sort[column.id] === direction ? onSort({}) : onSort({ [column.id]: direction });
    },
    [sort, onSort]
  );

  return (
    <div ref={innerRef} className={headerClasses.root}>
      <TableHeaderRowMemo
        resizeBorder={resizeBorder}
        onBulkRemove={onBulkRemove}
        MultiselectHeader={MultiselectHeader}
        checkedCount={checkedCount}
        variant={variant}
        columns={columns}
        withDescendants={withDescendants}
        headerClasses={headerClasses}
        widthMap={widthMap}
        sort={sort}
        handleSort={handleSort}
        stateFieldProps={stateFieldProps}
        multiSelectable={multiSelectable}
        setWidthMap={setWidthMap}
        tableHeight={tableHeight}
      />
    </div>
  );
};

export const TableHeaderRow = ({
  resizeBorder,
  onBulkRemove,
  MultiselectHeader,
  checkedCount,
  variant,
  columns,
  withDescendants,
  headerClasses,
  widthMap,
  sort,
  handleSort,
  stateFieldProps,
  multiSelectable,
  setWidthMap,
  tableHeight,
}) => {
  return (
    <>
      {MultiselectHeader && checkedCount > 0 && (
        <TableNavigationRow multiselect>
          <MultiselectHeader
            onBulkRemove={onBulkRemove}
            onRemove={onBulkRemove}
            variant={variant}
          />
        </TableNavigationRow>
      )}

      <div className={headerClasses.row}>
        {columns.map((column, colIndex) => (
          <TableHeaderCell
            key={`header-${column.id}-${colIndex}`}
            collapseLevel={withDescendants ? 1 : 0}
            classes={headerClasses}
            columns={columns}
            widthMap={widthMap}
            colIndex={colIndex}
            column={column}
            sort={sort}
            onSort={handleSort}
            stateFieldProps={stateFieldProps}
            multiSelectable={multiSelectable}
            setWidthMap={setWidthMap}
            tableHeight={tableHeight}
            resizeBorder={resizeBorder}
          />
        ))}
      </div>
    </>
  );
};

const checksum = (value) => JSum.digest(value, "SHA256", "hex");

const isEqualRow = (prev, next) => {
  if (!isEqual(prev.resizeBorder, next.resizeBorder)) {
    return false;
  }

  if (!isEqual(prev.MultiselectHeader, next.MultiselectHeader)) {
    return false;
  }

  if (!isEqual(prev.onBulkRemove, next.onBulkRemove)) {
    return false;
  }

  if (!isEqual(prev.checkedCount, next.checkedCount)) {
    return false;
  }

  if (!isEqual(prev.variant, next.variant)) {
    return false;
  }

  if (!isEqual(prev.withDescendants, next.withDescendants)) {
    return false;
  }

  if (!isEqual(prev.headerClasses, next.headerClasses)) {
    return false;
  }

  if (!isEqual(prev.sort, next.sort)) {
    return false;
  }

  if (!isEqual(prev.handleSort, next.handleSort)) {
    return false;
  }

  if (!isEqual(prev.stateFieldProps, next.stateFieldProps)) {
    return false;
  }

  if (!isEqual(prev.multiSelectable, next.multiSelectable)) {
    return false;
  }

  if (!isEqual(prev.setWidthMap, next.setWidthMap)) {
    return false;
  }

  if (!isEqual(prev.tableHeight, next.tableHeight)) {
    return false;
  }

  if (checksum(Object.values(prev.widthMap)) !== checksum(Object.values(next.widthMap))) {
    return false;
  }

  if (checksum(prev.columns) !== checksum(next.columns)) {
    return false;
  }

  return true;
};

const TableHeaderRowMemo = memo(TableHeaderRow, isEqualRow);

// TODO
const isInputCell = (_column) => false;

export const TableHeaderCell = ({
  column,
  sort,
  onSort,
  stateFieldProps,
  multiSelectable,
  collapseLevel,
  colIndex,
  classes,
  columns,
  widthMap,
  setWidthMap,
  tableHeight,
  resizeBorder,
}) => {
  const { stickedColumnId, toggleStick, lockableColumns, withCellMenu } = useStickFields();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <TableCell
      header
      className={classes.cell}
      column={column}
      columns={columns}
      widthMap={widthMap}
      stickedColumnId={stickedColumnId}
    >
      <Box className={classes.cellContainer}>
        <Box className={classes.cellContent} onClick={handleOpen}>
          <CollapseSpace column={column} colIndex={colIndex} collapseLevel={collapseLevel} />

          {isReactComponent(column.label) ? (
            <column.label
              column={column}
              stateFieldProps={stateFieldProps}
              multiSelectable={multiSelectable}
            />
          ) : (
            <Typography space={{ mr: 1, pl: isInputCell(column) ? 1.5 : 0, ...column.labelSpace }}>
              {column.label}
            </Typography>
          )}

          {resizeBorder && colIndex !== columns.length - 1 && (
            <ResizeFieldBorder
              height={tableHeight}
              fieldId={column.id}
              widthMap={widthMap}
              setWidthMap={setWidthMap}
            />
          )}

          {/* {onSort && sort && !column.unSort && !isSystem && ( */}
          {/* <div className={classes.sortAction}> */}
          {/* <IconButton
                icon={SortArrowUpIcon}
                className={clsx({ visible: sort[column.id], active: sort[column.id] === -1 })}
                onClick={() => onSort(column, -1)}
                size="xs"
              />

              <IconButton
                icon={SortArrowDownIcon}
                className={clsx({ visible: sort[column.id], active: sort[column.id] === 1 })}
                onClick={() => onSort(column, 1)}
                size="xs"
              /> */}
          {/* </div> */}
          {/* )} */}

          {withCellMenu && (
            <Box className={classes.arrowIcon}>
              <ChevronDownIcon />
            </Box>
          )}
        </Box>

        {withCellMenu && (
          <TableHeaderCellActions
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            column={column}
            lockableColumns={lockableColumns}
            stickedColumnId={stickedColumnId}
            toggleStick={toggleStick}
          />
        )}
      </Box>
    </TableCell>
  );
};

export default TableHeader;
