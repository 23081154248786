import {
  DATE_TIME_COMPONENT_TYPE,
  DATE_COMPONENT_TYPE,
  TIME_COMPONENT_TYPE,
} from "components/ComponentType/types";

const OPERATIONS = {
  EQ: (a, b) => a === b,
  NE: (a, b) => a !== b,
  LT: (a, b) => a < b,
  GT: (a, b) => a > b,
  STARTS: (a, b) => a.startsWith(b),
  ENDS: (a, b) => a.endsWith(b),
  CONTAINS: (a, b) => a.includes(b),
};

const DATE_TIME_OPERATIONS = {
  EQ: (a, b) => new Date(a).getTime() === new Date(b).getTime(),
  NE: (a, b) => new Date(a).getTime() !== new Date(b).getTime(),
  LT: (a, b) => {
    // Assuming b is the number of days for 'in the last'
    const dateLimit = new Date();
    dateLimit.setDate(dateLimit.getDate() - b);
    return new Date(a) < dateLimit;
  },
  GT: (a, b) => new Date(a) > new Date(b), // Assuming b is a date for 'since'
  BETWEEN: (a, b) => {
    // Assuming b is an array of two dates [startDate, endDate]
    const dateA = new Date(a);
    const startDate = new Date(b[0]);
    const endDate = new Date(b[1]);
    return dateA >= startDate && dateA <= endDate;
  },
};

const isDateTimeType = (componentType) => {
  [DATE_TIME_COMPONENT_TYPE, DATE_COMPONENT_TYPE, TIME_COMPONENT_TYPE].includes(componentType);
};

export const filterCollection = (collection, filters) => {
  // Group filters by fieldId
  const groupedFilters = filters.reduce((acc, filter) => {
    const fieldId = filter.formField.id;

    acc[fieldId] = acc[fieldId] || [];
    acc[fieldId].push(filter);
    return acc;
  }, {});

  // Filter the collection
  return collection.filter((obj) => {
    return Object.entries(groupedFilters).every(([fieldId, fieldFilters]) => {
      // Apply 'OR' condition for filters with the same fieldId
      return fieldFilters.some((filter) => {
        const formField = filter.formField;

        const operation = isDateTimeType(formField.componentType)
          ? DATE_TIME_OPERATIONS[filter.operation]
          : OPERATIONS[filter.operation];

        const objectValue = formField.parseValue(obj[fieldId]);
        const filterValue = formField.parseValue(filter.value);

        return operation ? operation(objectValue, filterValue) : true;
      });
    });
  });
};
