import React, { useReducer, useEffect } from "react";

import isEmpty from "lodash/isEmpty";
import { ImmerReducer, createActionCreators, createReducerFunction } from "vendor/immer-reducer";

const DrawerLayoutContext = React.createContext(null);

const initialState = {
  selectedId: null,
  selectedFieldId: null,
  mode: null,
  commentMode: false,
  context: {},
  history: [],
  visibleSidebar: true,
};

class DrawerLayoutContextReducer extends ImmerReducer {
  setSelectedId(id) {
    this.saveHistory();
    this.draftState.selectedId = id;
  }

  setSelectedFieldId(id) {
    this.saveHistory();
    this.draftState.selectedFieldId = id;
  }

  setMode(mode) {
    this.saveHistory();
    this.draftState.mode = mode;
  }

  setContext(context) {
    this.saveHistory();
    this.draftState.context = context || {};
  }

  cleanSelect() {
    this.saveHistory();
    this.reset();
  }

  setSelect({ id, fieldId, mode, context }) {
    this.saveHistory();

    this.draftState.selectedId = id;
    this.draftState.selectedFieldId = fieldId;
    this.draftState.mode = mode;
    this.draftState.context = context || {};
  }

  backSelect() {
    if (isEmpty(this.draftState.history)) {
      this.reset();
    } else {
      const history = [...this.draftState.history];
      const prev = history[0];

      history.shift();

      this.draftState.history = history;
      this.draftState.selectedId = prev.selectedId;
      this.draftState.selectedFieldId = prev.selectedFieldId;
      this.draftState.mode = prev.mode;
      this.draftState.context = prev.context;
    }
  }

  saveHistory() {
    const { history, ...currentState } = this.draftState;

    this.draftState.history = [currentState, ...history];
  }

  toggleVisibleSidebar() {
    this.draftState.visibleSidebar = !this.draftState.visibleSidebar;
    this.draftState.commentMode = false;
  }

  setVisibleSidebar(value) {
    this.draftState.visibleSidebar = value;
  }

  setCommentMode(commentMode) {
    this.saveHistory();
    this.draftState.commentMode = commentMode;
  }

  reset() {
    this.draftState.selectedId = null;
    this.draftState.selectedFieldId = null;
    this.draftState.mode = null;
    this.draftState.context = {};
  }
}

const reducerAction = createActionCreators(DrawerLayoutContextReducer);
const stateFieldsReducer = createReducerFunction(DrawerLayoutContextReducer);

export const DrawerLayoutProvider = ({
  children,
  tab,
  isEdit,
  setEdit,
  loading,
  setTab,
  isSubmitting,
  onRemove,
  commentMode = false,
  visibleSidebar = true,
}) => {
  const [state, dispatch] = useReducer(stateFieldsReducer, {
    ...initialState,
    visibleSidebar,
    commentMode,
  });

  useEffect(() => {
    dispatch(reducerAction.cleanSelect());
  }, [isEdit]);

  const value = {
    state,
    dispatch,
    tab,
    setTab,
    isEdit,
    edit: isEdit,
    setEdit,
    loading,
    isSubmitting,
    onRemove,
  };

  return <DrawerLayoutContext.Provider value={value}>{children}</DrawerLayoutContext.Provider>;
};

export const useDrawerLayout = () => {
  const { state, dispatch, ...props } = React.useContext(DrawerLayoutContext);
  const { selectedId, selectedFieldId, mode, context, ...rest } = state;

  const setSelectedId = (id) => {
    dispatch(reducerAction.setSelectedId(id));
  };

  const setSelectedFieldId = (id) => {
    dispatch(reducerAction.setSelectedFieldId(id));
  };

  const setMode = (mode) => {
    dispatch(reducerAction.setMode(mode));
  };

  const setContext = (context) => {
    dispatch(reducerAction.setContext(context));
  };

  const setSelect = ({ id, fieldId, mode, context }) => {
    dispatch(reducerAction.setSelect({ id, fieldId, mode, context }));
  };

  const cleanSelect = () => {
    dispatch(reducerAction.cleanSelect());
  };

  const backSelect = () => {
    dispatch(reducerAction.backSelect());
  };

  const setCommentMode = (value) => {
    dispatch(reducerAction.setCommentMode(value));
  };

  const toggleVisibleSidebar = () => {
    dispatch(reducerAction.toggleVisibleSidebar());
  };

  const setVisibleSidebar = (value) => {
    dispatch(reducerAction.setVisibleSidebar(value));
  };

  const cleanMode = () => {
    setMode(null);
    setCommentMode(false);
  };

  return {
    mode,
    setMode,
    setCommentMode,
    selectedId,
    setSelectedId,
    selectedFieldId,
    setSelectedFieldId,
    cleanSelect,
    setSelect,
    context,
    setContext,
    backSelect,
    toggleVisibleSidebar,
    setVisibleSidebar,
    cleanMode,
    editable: !!props.setEdit,
    ...rest,
    ...props,
  };
};
