import React from "react";
import { Skeleton } from "@mui/material";
import Button from "components/Button";
import { MoreHorizontalIcon } from "icons";

const TopPagination = ({ pagination }) => {
  return (
    <Button
      leftIcon={pagination.isCollapsedPage ? null : MoreHorizontalIcon}
      size="xs"
      variant="grey"
      onClick={pagination.handlePrevPage}
    >
      {pagination.isCollapsedPage ? `Show less` : `Load previous ${pagination.pageSize}`}{" "}
    </Button>
  );
};

const BottomPagination = ({ pagination, loading }) => {
  return (
    <Button
      leftIcon={MoreHorizontalIcon}
      size="xs"
      variant="grey"
      onClick={pagination.handleNextPage}
    >
      {pagination.collapseMode ? (
        `Show more`
      ) : (
        <>
          Load next{" "}
          {loading ? (
            <Skeleton variant="rect" height="14px" width="15px" />
          ) : (
            pagination.nextElements
          )}
        </>
      )}
    </Button>
  );
};

export default {
  Top: TopPagination,
  Bottom: BottomPagination,
};
