import lowerCase from "lodash/lowerCase";
import startsWith from "lodash/startsWith";

export const humanize = (string) => {
  return toTitleCase(lowerCase(string));
};

export const toTitleCase = (string) => {
  if (!string) return;

  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const pluralize = (label, count, suffix = "s") => `${label}${count > 1 ? suffix : ""}`;

export const incremenetName = (baseLabel, items) => {
  const similarItems = items.filter((item) => startsWith(item.name, baseLabel));
  const label = similarItems[similarItems.length - 1]?.name || baseLabel;

  return label.match(/\(\d+\)/)
    ? label.replace(/\(\d+\)/, `(${Number(label.match(/\(\d+\)/)[0].match(/\d+/)[0]) + 1})`)
    : `${label} (1)`;
};

export const genRanHex = (size) =>
  [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
