import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import IconButton from "components/IconButton";
import MenuItem from "components/Menu/MenuItem";
import { useViewsManagerStyles } from "theme/components/ViewsManager";
import { ArrowRightIcon, CrossIcon, ArrowLeftIcon2 } from "icons";
import clsx from "clsx";

import { useViewsManager } from "./Provider";

const ViewsManagerHeader = ({ children, onBack, onClose, withBackButton = true }) => {
  const { mode, onOpen } = useViewsManager();
  const classes = useViewsManagerStyles();

  return (
    <Box className={clsx(classes.header)}>
      <Box display="flex" alignItems="center">
        {withBackButton && mode !== "MAIN" && (
          <IconButton
            space={{ ml: -1, mr: 1 }}
            onClick={onBack || onOpen}
            icon={ArrowLeftIcon2}
            size="small"
          />
        )}

        {children}
      </Box>

      <IconButton space={{ mr: -1 }} onClick={onClose} icon={CrossIcon} size="small" />
    </Box>
  );
};

const ViewsManagerBody = ({ children, ...props }) => {
  const classes = useViewsManagerStyles();

  return (
    <Box className={clsx(classes.body)} {...props}>
      {children}
    </Box>
  );
};

const ViewsManagerFooter = ({ children }) => {
  const classes = useViewsManagerStyles();

  return <Box className={clsx(classes.footer)}>{children}</Box>;
};

const ViewsManagerSubHeader = ({ children }) => {
  const classes = useViewsManagerStyles();

  return <Box className={clsx(classes.subHeader)}>{children}</Box>;
};

const ViewsManagerItems = ({ children }) => {
  const classes = useViewsManagerStyles();

  return <Box className={clsx(classes.items)}>{children}</Box>;
};

const ViewsManagerItem = ({ title, onClick, buttonText }) => {
  const classes = useViewsManagerStyles();

  return (
    <MenuItem
      onClick={onClick}
      className={classes.item}
      rightIcon={() => <Typography rightIcon={ArrowRightIcon}>{buttonText}</Typography>}
    >
      {title}
    </MenuItem>
  );
};

const ViewsManager = ({ children }) => {
  const classes = useViewsManagerStyles();

  return <Box className={clsx(classes.root)}>{children}</Box>;
};

ViewsManager.Header = ViewsManagerHeader;
ViewsManager.Body = ViewsManagerBody;
ViewsManager.Footer = ViewsManagerFooter;
ViewsManager.SubHeader = ViewsManagerSubHeader;
ViewsManager.Items = ViewsManagerItems;
ViewsManager.Item = ViewsManagerItem;

export default ViewsManager;
