import React from "react";
import SelectNew from "components/Inputs/Select";
import SelectNewForm from "components/Inputs/Form/SelectNew";
import { useSearchItems } from "models/ListItem/queries";

export const LookupItemValueSelect = ({
  builderItem,
  tableId,
  columnId,
  skip = false,
  ...props
}) => {
  const SelectComponent = props.control ? SelectNewForm : SelectNew;

  const itemsOptionsQuery = useSearchItems({
    searchInput: { tableId },
    skip,
  });

  const { uniqueArray, adjustedTotalCount, itemsMap } = getUniqueValuesAndCount({
    items: itemsOptionsQuery.items,
    columnId,
    totalCount: itemsOptionsQuery.totalCount,
  });

  const options = uniqueArray.map((value) => ({ id: value, label: value }));

  const handleChange = (newValue) => {
    props?.onChange?.(newValue, itemsMap.get(newValue));
  };

  return (
    <SelectComponent
      virtualized
      variant="outlined"
      freeSolo
      options={options}
      disabled={itemsOptionsQuery.loading}
      totalCount={adjustedTotalCount}
      onLoadMore={itemsOptionsQuery.onLoadMore}
      onSearch={itemsOptionsQuery.onSearch}
      {...props}
      onChange={handleChange}
    />
  );
};

const getUniqueValuesAndCount = ({ items, columnId, totalCount }) => {
  const uniqueValues = new Set();
  let itemsMap = new Map();

  for (const item of items) {
    const value = item.valuesMap[columnId];

    if (value) {
      uniqueValues.add(value);
      itemsMap.set(value, item);
    }
  }

  const uniqueArray = Array.from(uniqueValues);
  const adjustedTotalCount = totalCount - (items.length - uniqueArray.length);

  return { uniqueArray, adjustedTotalCount, itemsMap };
};
