import React from "react";
import Typography from "components/Typography";
import ViewsManager from "components/ViewsManager";
import HideMenuOptions from "components/HideFieldsMenu/HideMenuOptions";

import { useViewsManager } from "./Provider";

const ViewsManagerColumns = () => {
  const { onOpen, onClose, onMove, onUpdate, columns, submitListing } = useViewsManager();

  const handleClose = () => {
    onClose && onClose();
    submitListing && submitListing();
  };

  const handleBack = () => {
    onOpen();
    submitListing && submitListing();
  };

  return (
    <ViewsManager>
      <ViewsManager.Header onBack={handleBack} onClose={handleClose}>
        <Typography variant="h4">Columns</Typography>
      </ViewsManager.Header>

      <ViewsManager.Body>
        <HideMenuOptions
          nested
          size="medium"
          fields={columns}
          onChange={onUpdate}
          onMove={onMove}
          label="columns"
        />
      </ViewsManager.Body>
    </ViewsManager>
  );
};

export default ViewsManagerColumns;
