import React from "react";
import { Controller } from "react-hook-form";

const DynamicInput = ({ control, rules = {}, input: Input, onChange, ...props }) => {
  return (
    <Controller
      control={control}
      name={props.name}
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...props}
          invalid={fieldState.invalid?.toString()}
          error={fieldState.error || props.error}
          value={field.value}
          onBlur={field.onBlur}
          onChange={field.onChange}
          onChangeHook={onChange}
          inputRef={field.ref}
        />
      )}
    />
  );
};

export const FormInputProxy =
  (Input) =>
  ({ control, ...props }) => {
    return control ? (
      <DynamicInput control={control} {...props} input={Input} />
    ) : (
      <Input {...props} />
    );
  };
