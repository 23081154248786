import React from "react";
import Box from "components/Box";
import clsx from "clsx";
import { variantsClassNames } from "theme/utils";

import "styles/App/Drawer/DrawerBody.scss";

const useDrawerBodyStyles = ({ variant = "default" } = {}) => ({
  root: variantsClassNames("AppDrawerBody-root", { variant }),
});

export const DrawerBody = ({ innerRef, children, nested }) => {
  const classes = useDrawerBodyStyles();

  return (
    <Box ref={innerRef} className={clsx(classes.root, { nested })}>
      {children}
    </Box>
  );
};
