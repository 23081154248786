import { useMutation } from "@apollo/client";
import parseErrors from "utils/parseErrors";
import isEmpty from "lodash/isEmpty";
import { useWorkspace } from "contexts/Workspace";
import { useFormLayoutNamespace } from "contexts/FormLayoutNamespace";
import { buildQueryNamespace } from "hooks/useQuery";

import { filterSkipMutationProperties } from "hooks/useQuery/filterProperties";

const useMutationProxy = (
  mutation,
  { onFailure: onFailureGlobal, onSuccess: onSuccessGlobal, ...rest } = {}
) => {
  const { namespace } = useWorkspace() || {};
  const { formLayoutId } = useFormLayoutNamespace();

  const [mutationFunc, ...otherFuncs] = useMutation(mutation, rest);

  const mutationFuncWrap = ({ onSuccess, onFailure, ...props } = {}, ...restProps) => {
    const mainNamespace = props.variables.input.namespace || { ...namespace, formLayoutId };
    const mutationProps = { ...props, variables: prepareVariables(mutation, props, mainNamespace) };

    return mutationFunc(mutationProps, ...(restProps || []))
      .then((response) => {
        handleSuccessRespone(response, { onFailureGlobal, onFailure, onSuccessGlobal, onSuccess });
      })
      .catch((error) => {
        handleFailureError(error, { onFailureGlobal, onFailure });
      });
  };

  return [mutationFuncWrap, ...otherFuncs];
};

const prepareVariables = (mutation, props, mainNamespace) => ({
  ...props.variables,
  input: filterSkipMutationProperties(mutation, {
    ...props.variables.input,
    namespace: buildQueryNamespace(mainNamespace),
  }),
});

const handleSuccessRespone = (
  response,
  { onFailureGlobal, onFailure, onSuccessGlobal, onSuccess }
) => {
  const { data } = response;
  const responseData = Object.values(data)[0];
  const errors = responseData.errors || responseData.failed;

  if (!isEmpty(errors)) {
    const parsedErrors = parseErrors(responseData.errors || responseData.failed);
    onFailureGlobal && onFailureGlobal(parsedErrors);
    onFailure && onFailure(parsedErrors);
  } else {
    onSuccessGlobal && onSuccessGlobal(responseData);
    onSuccess && onSuccess(responseData);
  }

  return response;
};

const handleFailureError = (error, { onFailureGlobal, onFailure }) => {
  if (process.env.REACT_APP_DEVELOPMENT) console.error(error);
  const errors = parseErrors(error.graphQLErrors || error);

  if (onFailureGlobal) onFailureGlobal(errors);
  if (onFailure) onFailure(errors);

  return error;
};

export default useMutationProxy;
