import React, { useRef } from "react";
import clsx from "clsx";
import { IMaskInput } from 'react-imask';
import Box from "components/Box";
import Typography from "components/Typography";
import Input from "components/Inputs/Input";
import CountriesSelect, { CountryPlaceholder } from "components/Inputs/CountriesSelect";
import { useCombineInputs } from "theme/components/CombineInputs";
import { HintMessage } from "components/FormControl";

const PhoneInput = ({
  fullWidth,
  space,
  disabled,
  value,
  onChange,
  error,
  hint,
  label,
  style,
  countriesOptions,
  countryName,
  phoneName,
}) => {
  const inputRef = useRef();
  const classes = useCombineInputs();

  const countryFieldName = countryName || "countryId";
  const phoneFieldName = phoneName || "value";

  const handleChangeCountry = (countryId) => {
    onChange({ ...value, [countryFieldName]: countryId });
  };

  const handleChangeInput = (event) => {
    onChange({ ...value, [phoneFieldName]: event.target.value });
  };

  const combineInputWidth = inputRef.current?.getBoundingClientRect()?.width;

  return (
    <Box space={space} fullWidth={fullWidth} style={style}>
      {label && (
        <Box space={{ mb: 1 }}>
          <Typography error={`${!!error}`} variant="inputLabel">
            {label}
          </Typography>
        </Box>
      )}
      <Box ref={inputRef} className={clsx(classes.root, "phoneInput")}>
        <CountriesSelect
          onlyIcons
          error={!!error}
          value={value?.[countryFieldName]}
          onChange={handleChangeCountry}
          formControlClassName={classes.leftFormControl}
          className={classes.leftInput}
          variant="outlined"
          size="medium"
          disabled={disabled}
          placeholder="Select country"
          rightIcon={() => <></>}
          menuContentWidth={combineInputWidth}
          selectedLabelComponent={CountrySelectedLabelComponent}
          optionComponent={CountryLabelComponent}
          name={countryFieldName}
          countriesOptions={countriesOptions}
        />
        <IMaskInput
          mask="(#00) 000-0000"
          definitions={{
            '#': /[1-9]/,
          }}
          error={!!error}
          disabled={disabled}
          size="medium"
          className={clsx(classes.rightInput, classes.maskInput)}
          type="tel"
          value={value?.[phoneFieldName]}
          space={{ mb: 0 }}
          onChange={handleChangeInput}
          fullWidth
          variant="outlined"
          placeholder="Enter value"
          name={phoneFieldName}
        />
      </Box>

      <HintMessage space={{ mt: -0.5 }} showError={error} error={error} hint={hint} />
    </Box>
  );
};

const CountrySelectedLabelComponent = ({ option }) => {
  const country = option?.label;

  return <CountryPlaceholder iconSpace={{ mr: 0.5 }} option={option} title={country.callingCode} />;
};

const CountryLabelComponent = ({ option }) => {
  const country = option?.label;

  return <CountryPlaceholder option={option} title={`${country.callingCode} ${country.name}`} />;
};

export default PhoneInput;
