import { gql } from "@apollo/client";
import { delegate, memoize } from "utils/entities";

export class TableSelect {
  constructor(origin) {
    this.origin = { ...origin, ...origin.table };
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin, ["columns"]);
  }
}

TableSelect.fragment = gql`
  fragment TableSelectFragment on GetFullTablePayload {
    id
    table {
      id
      name
      uid
    }
  }
`;
