import React, { useState } from "react";
import Box from "components/Box";
import { Virtuoso } from "react-virtuoso";
import InputDelay from "components/Inputs/InputDelay";
import LabelButton from "components/LabelButton";
import Typography from "components/Typography";
import isEmpty from "lodash/isEmpty";
import MenuButton from "components/Menu/MenuButton";
import {
  useGlobalSearchBarStyles,
  useGlobalSearchInputStyles,
} from "theme/components/GlobalSearch";

import SearchItem from "./SearchItem";

const LOADING_STUB = new Array(100).fill({ loading: true });

const GlobalSearchContent = ({
  onLoadMore,
  search,
  onSearch,
  onSelect,
  entities: initialEntities,
  totalCount,
}) => {
  const classes = useGlobalSearchBarStyles();
  const classesInput = useGlobalSearchInputStyles();
  const [loadingMore, setLoadingMore] = useState(false);

  const entities = loadingMore ? [...initialEntities, ...LOADING_STUB] : initialEntities;

  const handleLoadMore = () => {
    if (entities.length === totalCount) return;

    setLoadingMore(true);

    onLoadMore().then(() => {
      setLoadingMore(false);
    });
  };

  return (
    <>
      <Box fullHeightContainer>
        <InputDelay
          className={classesInput.root}
          variant="ghost"
          fullWidth
          placeholder="Search for anything"
          space={{ mb: 0 }}
          onChange={onSearch}
          value={search}
        />

        <Box className={classes.filters}>
          <MenuButton
            externalLinkIcon={false}
            component={LabelButton}
            title="Filter"
            variant="select"
            size="large"
          />
        </Box>

        {isEmpty(search) && (
          <Typography color="grey" space={{ mb: 1 }}>
            Start typing to view matches...
          </Typography>
        )}

        {!isEmpty(search) && (
          <Typography color="grey" space={{ mb: 1 }}>
            {`Matched ${entities.length}`}
          </Typography>
        )}

        <Box fullHeightScroll>
          <Virtuoso
            style={{ height: "100%" }}
            overscan={100}
            data={entities}
            itemContent={(index) => <SearchItem onSelect={onSelect} item={entities[index]} />}
            totalCount={totalCount}
            endReached={handleLoadMore}
          />
        </Box>
      </Box>
    </>
  );
};

export default GlobalSearchContent;
