import { useState } from "react";

const useRows = (defaultRows) => {
  const [isDirty, setIsDirty] = useState(false);
  const [rows, setRows] = useState(defaultRows);

  const updateRows = (newRows) => {
    setRows(newRows);
    setIsDirty(true);

    return newRows;
  };

  const updateRow = (id, props) => {
    const rowIndex = rows.findIndex((row) => row.id === id);

    const nextRows = Object.assign([], rows, {
      [rowIndex]: { ...rows[rowIndex], ...props },
    });

    updateRows(nextRows);

    return nextRows;
  };

  const handleSetRows = (newRows) => {
    setRows(newRows);
    setIsDirty(false);
  };

  return {
    isDirty,
    setIsDirty,
    setRows: handleSetRows,
    rows,
    updateRows,
    updateRow,
  };
};

export default useRows;
