import React from "react";
import compact from "lodash/compact";
import { FormFieldInputCell } from "containers/FormField/InputCell";

import { ContainerTable } from "./ContainerTable";
import { TABLE_CONTAINER_ID } from "../../Provider";
import { SortableItems } from "./SortableItems";

export const PreviewContainerItems = ({
  components,
  containerId,
  allItemsMap,
  sectionProps,
  containerProps,
  items,
}) => {
  const containerType = containerProps?.type;

  if (containerType === TABLE_CONTAINER_ID) {
    const ContainerTableProxy = components.containerTable || PreviewTableContainer;
    const builderItems = compact(items.map((item) => allItemsMap[item]));

    return (
      <ContainerTableProxy
        sectionProps={sectionProps}
        containerId={containerId}
        builderItems={builderItems}
      />
    );
  }

  return (
    <SortableItems
      components={components}
      allItemsMap={allItemsMap}
      sectionProps={sectionProps}
      items={items}
    />
  );
};

const PreviewTableContainer = ({ builderItems, sectionProps, containerId }) => {
  const columns = builderItems.map((builderItem) => ({
    id: builderItem.id,
    label: builderItem.label,
    formField: builderItem.formField,
    component: FormFieldInputCell,
    width: 200,
  }));

  const stubEntities = [{ id: 1 }, { id: 2 }, { id: 3 }];

  return (
    <ContainerTable
      containerId={containerId}
      sectionProps={sectionProps}
      entities={stubEntities}
      columns={columns}
      onChange={() => {}}
    />
  );
};
