import React from "react";
import compact from "lodash/compact";
import NameTitle from "components/NameTitle";
import {
  fieldTypeIcon,
  AUTO_INCREMENT_TYPE,
  NUMBER_TYPE,
  FORMULA_TYPE,
} from "models/abstract/FieldType";

const FORMULA_TYPES = {
  [NUMBER_TYPE]: "number",
  [AUTO_INCREMENT_TYPE]: "number",
  [FORMULA_TYPE]: "formula",
};

const Title = ({ name, uid }) => <NameTitle name={name} uid={uid} />;

export const convertFieldsToPropertiesGroup = (fields) => ({
  title: "Fields",
  properties: convertFieldsToProperties(fields),
});

export const convertFieldsToProperties = (fields, { prefix } = {}) => {
  return fields.map((field) => ({
    id: field.id,
    name: field.name,
    uid: field.uid,
    prefix: field.prefix || prefix,
    tag: compact([field.prefix || prefix, field.uid]).join("."),
    icon: fieldTypeIcon(field),
    title: Title,
    type: FORMULA_TYPES[field.type] || "string",
  }));
};
