import { createTheme as createMuiTheme, responsiveFontSizes } from "@mui/material/styles";

import {
  theme,
  layout,
  transitions,
  tableTheme,
  iconSpacing,
  borderRadius,
  PADDING_SIZES,
  ICON_SIZES,
  ICON_BUTTON_SIZES,
  AVATAR_SIZES,
  INPUT_CONTENT_HEIGHT,
  DRAWER_SIZES,
  toPx,
  defaultBoxShadow,
} from "./common";

import typography, { typographyPx } from "./typography";
import palette from "./palette";

export const THEMES = {
  LIGHT: {
    lineHeight: typography.lineHeight,
    typography: typographyPx,
    palette,
    transitions,
    layout: toPx(layout),
    zIndex: theme.zIndex,
    breakpoints: theme.breakpoints.values,
    "icon.space": iconSpacing,
    "border.radius": borderRadius,
    "icon.size": toPx(ICON_SIZES),
    "iconButton.size": toPx(ICON_BUTTON_SIZES),
    "avatar.size": toPx(AVATAR_SIZES),
    "drawer.size": toPx(DRAWER_SIZES),
    table: tableTheme,
    padding: PADDING_SIZES,
    defaultBoxShadow: defaultBoxShadow(),
    spacing: 8,
    inputContentHeight: `${INPUT_CONTENT_HEIGHT}px`,
  },
};

const MUI_THEMES_CONFIG = {
  LIGHT: {
    name: "LIGHT",
    typography,
    palette,
    transitions,
  },
};

export const MUI_THEMES = {
  LIGHT: responsiveFontSizes(createMuiTheme(MUI_THEMES_CONFIG.LIGHT)),
};
