import React, { useState } from "react";
import Box from "components/Box";
import { MoreHorizontalIcon, CircleArrowDownIcon, XCircleIcon } from "icons";
import { useTableFilters, useFilterRuleMenu } from "theme/components/TableFilters";
import LabelButton from "components/LabelButton";
import IconButton from "components/IconButton";
import Popover from "components/Popover";
import Button from "components/Button";
import SelectNew from "components/Inputs/Select";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { humanize } from "utils/string";

import { useSort } from "./SortProvider";

const SortLabel = ({ sort, onChange, onRemove, index }) => {
  const menuId = `SortLabel-${sort.columnId}-${index}`;
  const classes = useTableFilters();
  const menuClasses = useFilterRuleMenu();

  const { label } = sort || {};
  const [value, setValue] = useState(sort.value);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleRevert = () => {
    setValue(sort.value);
    handleClose();
  };

  const handleApply = () => {
    onChange(sort.id, { value }, { apply: true });
    handleClose();
  };

  const handleRemove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleClose();
    onRemove && onRemove(sort.id, { apply: true });
  };

  return (
    <>
      <LabelButton
        disableRipple
        component="div"
        aria-controls={open ? `${menuId}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        leftIcon={CircleArrowDownIcon}
        rightIcon={(props) => <XCircleIcon {...props} onClick={handleRemove} />}
        className={classes.label}
        onClick={handleOpen}
        color="purple"
      >
        <Box component="p">
          <span className="ellipsesTitle">{`${humanize(label)}`}</span>

          {value ? " : " : " "}

          <span className={clsx(classes.labelTitle, "ellipsesTitle")}>{value}</span>
        </Box>
      </LabelButton>

      <Popover
        ContentProps={{ className: menuClasses.list }}
        PaperProps={{ className: menuClasses.paper }}
        id={`${menuId}-menu`}
        aria-labelledby={`${menuId}-button`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleRevert}
      >
        <Box style={{ minWidth: 240 }}>
          <SelectNew
            fullWidth
            variant="outlined"
            name="value"
            space={{ mb: 0 }}
            value={value}
            onChange={setValue}
            placeholder="value"
            weight="normal"
            size="small"
            options={[
              ["ASC", "Ascending"],
              ["DESC", "Descending"],
            ]}
          />

          <Button
            disabled={isEmpty(value)}
            onClick={handleApply}
            space={{ mt: 1 }}
            fullWidth
            size="compact"
            variant="primary"
          >
            Apply
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export const SortsLabels = ({ itemProps = {} } = {}) => {
  const { sorts, onChange, onRemove } = useSort();

  if (sorts.length === 0) return <></>;

  return sorts.map((sort, index) => (
    <Box {...itemProps} key={`sortItem-${sort.field}-${sort.value}-${index}`}>
      <SortLabel sort={sort} onChange={onChange} onRemove={onRemove} />
    </Box>
  ));
};

const SortsLabelsNavigation = () => {
  const { sorts, onClean, isValid, isDirty, onApply } = useSort();

  if (sorts.length === 0) return <></>;

  return (
    <Box space={{ mt: 1, mb: 1 }} display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <SortsLabels itemProps={{ mr: 1 }} />
      </Box>

      <Box display="flex" alignItems="center">
        {isDirty && sorts.length > 0 && (
          <>
            <Button
              space={{ mr: 1 }}
              type="submit"
              disabled={!isValid}
              onClick={onApply}
              size="small"
              variant="text"
            >
              Apply
            </Button>

            <Button space={{ mr: 1 }} onClick={onClean} size="small" variant="text">
              Clear
            </Button>
          </>
        )}

        {!isDirty && sorts.length > 0 && (
          <Button space={{ mr: 1 }} onClick={onClean} size="small" variant="text">
            Clear
          </Button>
        )}

        <IconButton size="small" icon={MoreHorizontalIcon} />
      </Box>
    </Box>
  );
};

export default SortsLabelsNavigation;
