import { gql } from "@apollo/client";
import { delegate, memoize } from "utils/entities";
import compact from "lodash/compact";
import JSum from "vendor/jsum";
import { MappingPath } from "models/abstract/MappingPath";

export class MappingTemplate {
  constructor(origin) {
    this.origin = origin || {};

    delegate(this, this.origin);
    this.memoize = memoize(this.cache);
  }

  get mappingFields() {
    return this.rows.map((row) => {
      const sourceId = MappingPath.convertPathToPathId(row.from?.path);
      const targetId = MappingPath.convertPathToPathId(row.to?.path);

      return {
        sourceId,
        sourceStatic: row.from.static,
        // target
        targetId,
        targetStatic: row.to.static,
        hidden: false,
      };
    });
  }

  get keyTargetIds() {
    return this.rows
      .filter((row) => !!row.to.key)
      .map((row) => MappingPath.convertPathToPathId(row.to?.path));
  }

  mergeWithMappingFields(mappingFields, targetFieldsIds = []) {
    const mappingItemsMap = keyByMappingFields(this.mappingFields);

    const newMappingFields = mappingFields.reduce((result, mappingField) => {
      const templateMappingItem = mappingItemsMap[mappingFieldId(mappingField)];

      if (mappingField.sourceStatic) return result;

      const newTargetId = targetFieldsIds.includes(templateMappingItem?.targetId)
        ? templateMappingItem?.targetId
        : null;

      const field = {
        ...mappingField,
        // target
        targetId: mappingField.hidden ? null : newTargetId,
      };

      return [...result, field];
    }, []);

    const staticRowsFields = this.mappingFields.filter((item) => !!item.sourceStatic);

    return [...newMappingFields, ...staticRowsFields];
  }
}

const mappingFieldId = (mappingField) =>
  JSum.digest(compact([mappingField.sourceId, mappingField.sourceStatic]), "SHA256", "hex");

const keyByMappingFields = (mappingFields) => {
  return mappingFields.reduce((result, mappingField) => {
    return {
      ...result,
      [mappingFieldId(mappingField)]: mappingField,
    };
  }, {});
};

MappingTemplate.fragment = gql`
  fragment MappingTemplateFragment on GetMappingPayload {
    id
    createdDate
    lastModifiedDate
    inUse
    name
    description
    type
    from {
      path {
        id
        type
      }
      static
      key
    }
    to {
      path {
        id
        type
      }
      static
      key
    }
    rows {
      from {
        path {
          id
          type
        }
        static
        key
      }
      to {
        path {
          id
          type
        }
        static
        key
      }
    }
  }
`;
