import React from "react";
import { SideTableDrawer } from "components/Drawer/SideTableDrawer";
import { TabPanel } from "components/Tab";

import Main from "./Main";
import Columns from "./Columns";
import Filter from "./Filter";
import Sort from "./Sort";
import { useViewsManager } from "./Provider";

export const ViewsManagerDrawer = ({ open }) => {
  const { mode } = useViewsManager();

  return (
    <SideTableDrawer open={open}>
      <TabPanel fullHeight tab="MAIN" value={mode}>
        <Main />
      </TabPanel>

      <TabPanel fullHeight tab="COLUMNS" value={mode}>
        <Columns />
      </TabPanel>

      <TabPanel fullHeight tab="FILTERS" value={mode}>
        <Filter />
      </TabPanel>

      <TabPanel fullHeight tab="SORTS" value={mode}>
        <Sort />
      </TabPanel>
    </SideTableDrawer>
  );
};
