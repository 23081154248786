import React, { useState, useEffect } from "react";
import Box from "components/Box";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";
import LabelsGrid from "components/LabelsGrid";
import { FieldsLoader } from "components/Loaders";
import { FormFieldInput } from "containers/FormField/Input";
import { FormField } from "models/abstract/FormField";
import TableViewsSelect from "containers/Inputs/TableViewsSelect";
import { useGetTableView } from "models/TableView/queries";
import { Drawer } from "components/Drawer";
import { DrawerLayout } from "components/Drawer/Layout";
import { DrawerSidebar } from "components/Drawer/Sidebar";

const TableConnectorInputContent = ({ formField, value, onCancel, onChange, onSave }) => {
  const [tableViewId, setTableViewId] = useState(null);
  const { nestedFormFields, loading } = useTableConnectorFormFields(formField, tableViewId);

  const valueMap = keyBy(value, "columnId");

  const handleFieldChange = (index, itemValue) => {
    const newItemValue = itemValue?.target ? itemValue.target.value : itemValue;
    const columnId = nestedFormFields[index].id;

    const newValue = Object.assign({}, valueMap, {
      [columnId]: buildItemFieldValue(nestedFormFields[index], newItemValue),
    });

    onChange(Object.values(newValue));
  };

  useEffect(() => {
    if (loading || !isEmpty(value) || nestedFormFields.length === 0) return;

    onChange(buildDefaultItemValues(nestedFormFields));
  }, [loading, nestedFormFields.length]);

  return (
    <DrawerLayout
      isEdit
      nested
      onDiscard={onCancel}
      onSave={onSave}
      headerProps={{
        title: "Customize column properties",
        icon: () => <DrawerSidebar.BackButton onClick={onCancel} />,
        hideNavigation: true,
      }}
    >
      <DrawerSidebar.Content fullWidth>
        <LabelsGrid minWidth={120} offsetSize="small">
          <TableViewsSelect
            fullWidth
            disabled={loading}
            label="Table View"
            assignDefaultView
            tableId={formField.config.tableId}
            value={tableViewId}
            onChange={setTableViewId}
          />

          {loading && <FieldsLoader fields={nestedFormFields} />}

          {!loading &&
            nestedFormFields.map((formFieldItem, index) => (
              <Box
                fullWidth
                key={`${index}-${formFieldItem.name}`}
                label={formFieldItem.label}
                labelIcon={formFieldItem.icon}
              >
                <FormFieldInput
                  fullWidth
                  name={formFieldItem.name}
                  formField={formFieldItem}
                  onChange={(value) => handleFieldChange(index, value)}
                  value={valueMap[formFieldItem.name]?.value}
                />
              </Box>
            ))}
        </LabelsGrid>
      </DrawerSidebar.Content>
    </DrawerLayout>
  );
};

const buildItemFieldValue = (formFieldItem, value) => ({
  columnId: formFieldItem.id,
  type: formFieldItem.type,
  value,
});

const buildDefaultItemValues = (formFields) => {
  return formFields.map((formFieldItem) =>
    buildItemFieldValue(formFieldItem, formFieldItem.initialFormValue)
  );
};

export const useTableConnectorFormFields = (formField, tableViewId) => {
  const { tableView, loading } = useGetTableView(tableViewId);
  const viewColumnsMap = keyBy(tableView?.viewColumns || [], "id");
  const configColumns = formField.config.columns || [];

  const nestedFormFields = configColumns.reduce((result, item) => {
    const columnFormField = viewColumnsMap[item.id]?.formField;

    if (!columnFormField) return result;

    const nestedFormField = new FormField({
      id: item.id,
      name: item.id,
      fieldId: columnFormField.fieldId,
      type: columnFormField.type,
      subtype: columnFormField.subtype,
      config: columnFormField.config,
      placeholder: columnFormField.placeholder,
      label: viewColumnsMap[item.id]?.name || "Can't fetch name",
    });

    return [...result, nestedFormField];
  }, []);

  return { nestedFormFields, loading };
};

export const TableConnectorItemDrawer = ({ open, onClose, ...props }) => {
  return (
    <Drawer
      position="temporary"
      PaperProps={{ style: { boxShadow: "none" } }}
      size="small"
      hiddenBackdrop
      open={open}
      onClose={onClose}
      fullHeight
    >
      <TableConnectorInputContent {...props} onClose={onClose} />
    </Drawer>
  );
};
