import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { TypographyValue } from "components/Typography";
import { TypeTextIcon } from "icons";

export const FieldsLoader = ({ fields }) => {
  return fields.map((field, index) => (
    <TypographyValue
      key={`${field.id}-${index}`}
      labelIcon={TypeTextIcon}
      label={<Skeleton width="100px" variant="rect" />}
      style={{ minWidth: 75 }}
      value={<Skeleton width="100px" variant="rect" />}
    />
  ));
};
