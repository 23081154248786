import React from "react";
import SelectNew from "components/Inputs/Select";
import { Controller } from "react-hook-form";

const SelectInput = ({ control, rules = {}, onChange, onChangeHook, ...props }) => {
  if (!control) return <SelectNew {...props} onChange={onChange} />;

  const handleChangeHook = (value) => {
    onChange && onChange(value);
    onChangeHook && onChangeHook(value);
  };

  return (
    <Controller
      control={control}
      name={props.name}
      rules={rules}
      render={({ field, fieldState }) => (
        <SelectNew
          {...props}
          invalid={fieldState.invalid?.toString()}
          error={fieldState.error || props.error}
          value={field.value || ""}
          onBlur={field.onBlur}
          onChange={field.onChange}
          onChangeHook={handleChangeHook}
          inputRef={field.ref}
        />
      )}
    />
  );
};

export default SelectInput;
