import { genRanHex } from "utils/string";

export const findSection = (containers, id) => {
  if (id in containers) return id;

  return Object.keys(containers).find((key) => containers[key].includes(id));
};

export const findContainer = (items, id) => {
  if (id in items) return id;

  return Object.keys(items).find((key) => items[key].includes(id));
};

export const generateBuilderId = () => genRanHex(30);
