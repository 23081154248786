import React from "react";
import { Virtuoso } from "react-virtuoso";
import { TABLE_PRO_BODY_CLASS_NAME } from "theme/components/TablePro";
import TableEmpty from "../Components/TableEmpty";
import { TableProRow } from "../Components/TableRow";
import TableHeader from "../Components/TableHeader";
import { useTablePro } from "../Provider";

const MAX_VIEW_HEIGHT = 3000;
const TABLE_HEADER_HEIGHT = 42;

const SKELETON_ROWS = Array.from({ length: 100 }).map((_, index) => ({
  id: index + 1,
  loadingSkeleton: true,
}));

const InfinityTable = () => {
  const { loading, loadingMore, setLoadingMore, totalCount, entities, onLoadMore, setTableHeight } =
    useTablePro();

  const handleLoadMore = () => {
    if (!onLoadMore) return;
    if (entities.length === totalCount) return;

    // TODO
    if (entities.length >= 15) {
      setLoadingMore(true);
    }

    onLoadMore().then(() => {
      setLoadingMore(false);
    });
  };

  const onSetTableHeight = (height) => {
    const viewHeight = (height > MAX_VIEW_HEIGHT ? MAX_VIEW_HEIGHT : height) + TABLE_HEADER_HEIGHT;

    setTableHeight(Math.round(viewHeight));
  };

  if (entities.length === 0) {
    return (
      <div style={{ width: "100%", height: "100%", position: "absolute", top: 0 }}>
        <TableEmpty loading={loading} style={{ height: "100%" }} />
      </div>
    );
  }

  const renderEntities = loadingMore ? [...entities, ...SKELETON_ROWS] : entities;

  return (
    <Virtuoso
      totalListHeightChanged={onSetTableHeight}
      increaseViewportBy={{ bottom: 300, top: 300 }}
      overscan={200}
      totalCount={totalCount}
      data={renderEntities}
      endReached={handleLoadMore}
      style={{ height: "100%" }}
      itemContent={VirtuosoTableRow}
      components={{ List: VirtuosoTable }}
    />
  );
};

const VirtuosoTable = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <div className={TABLE_PRO_BODY_CLASS_NAME}>
      <TableHeader />

      <div {...props} ref={ref}>
        {children}
      </div>
    </div>
  );
});

const VirtuosoTableRow = (rowIndex) => <TableProRow rowIndex={rowIndex} />;

export default InfinityTable;
