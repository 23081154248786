import React from "react";
import clsx from "clsx";
import { useSearchMenu } from "theme/components/SearchMenu";
import Box from "components/Box";
import { calcHeight } from "components/Inputs/Select/SelectOptions";

import { useSelectMenuStyles } from "../index";

export const OptionsContainer = ({
  virtualized,
  children,
  totalCount,
  groupProperty,
  treeProperty,
  fullHeight,
  isFooter,
  searchable,
}) => {
  const menuClasses = useSearchMenu();
  const selectClasses = useSelectMenuStyles();

  if (virtualized) {
    return (
      <Box className={clsx(selectClasses.contentContainer, "virtualized-scroll")}>{children}</Box>
    );
  }

  const maxHeight = fullHeight ? "100%" : calcHeight({ totalCount, groupProperty, treeProperty });

  return (
    <Box
      style={{ overflowY: "auto", maxHeight, boxSizing: "content-box" }}
      space={{ pt: searchable ? 0 : undefined, pb: isFooter ? 0 : undefined }}
      className={clsx(selectClasses.contentContainer, menuClasses.scrollContainer)}
    >
      {children}
    </Box>
  );
};
