import React from "react";
import { FormFieldInputCell } from "containers/FormField/InputCell";
import pick from "lodash/pick";
import { LookupItemValueSelect } from "containers/Inputs/LookupItemValueSelect";
import { isLookupType } from "models/abstract/BuilderItem";
import { TableInputCellContainer } from "components/TablePro/Components/TableInputCellContainer";
import { useDebounceAnchor } from "components/Inputs/Select";

import { useFormProperties } from "./index";

export const FormActionInputCell = (props) => {
  const formField = props.column.formField;
  const builderItem = props.column.builderItem;
  const { valueColumnsList } = useFormProperties();

  if (builderItem.entity.tableId && formField.lookup && isLookupType(formField.type)) {
    return (
      <LookupItemInputCell
        {...props}
        valueColumnsList={valueColumnsList}
        builderItem={builderItem}
        formField={formField}
      />
    );
  }

  return <FormFieldInputCell {...props} />;
};

const LookupItemInputCell = ({ formField, builderItem, valueColumnsList, ...props }) => {
  const { column, entity, rowIndex, classes, error } = props;
  const tableId = builderItem.entity.tableId;
  const columnId = builderItem.entity.id;

  const { anchorEl, onOpen, onClose } = useDebounceAnchor();
  const open = Boolean(anchorEl);

  const handleChange = (newValue, item) => {
    const values = pick(item?.valuesMap || {}, valueColumnsList);

    const valueProps = { ...values, [column.id]: newValue };

    props.onChange({ entity, rowIndex, props: valueProps, tableColumn: column });
  };

  return (
    <TableInputCellContainer classes={classes} error={error}>
      <LookupItemValueSelect
        skip={!open}
        onOpen={onOpen}
        onClose={onClose}
        tableId={tableId}
        columnId={columnId}
        value={props.value}
        onChange={handleChange}
        space={{ mb: 0 }}
      />
    </TableInputCellContainer>
  );
};
