import { gql } from "@apollo/client";
import flatten from "lodash/flatten";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { convertFieldsToProperties } from "models/Table/utils";
import { SubListLink, SecondaryLink, ConsolidatedLink } from "./TablesLinks";
import { TableColumn } from "models/fragments/TableColumn";
import { TablePrimaryColumn } from "models/fragments/TablePrimaryColumn";

import { Table } from "./Table";
import * as tableFragments from "./fragments";

export class TableFull extends Table {
  get columnsChecksum() {
    return this.columns.map((field) => field.id).join(".");
  }

  get linkedTables() {
    return compact(flatten([this.subLists, this.secondaryLinks]));
  }

  get subLists() {
    return this.memoize(
      "subLists",
      () => this.sublist?.map((entity) => new SubListLink(entity, this.origin)) || []
    );
  }

  get secondaryLinks() {
    return this.memoize(
      "secondaryLinks",
      () =>
        this.secondary
          ?.filter((link) => !link.channel?.id)
          ?.map((entity) => new SecondaryLink(entity, this.origin)) || []
    );
  }

  get channelLinks() {
    return this.memoize(
      "channelLinks",
      () =>
        this.secondary
          ?.filter((link) => !!link.channel?.id)
          ?.map((entity) => new SecondaryLink(entity, this.origin)) || []
    );
  }

  get consolidatedLink() {
    if (isEmpty(this.consolidated)) return null;

    return this.memoize("consolidatedLink", () => new ConsolidatedLink(this.consolidated[0], this));
  }

  get channels() {
    return [];
  }

  get formulasMap() {
    return this.memoize("formulasMap", () => {
      return (this.formulas || []).reduce(
        (result, formula) => ({ ...result, [formula.fieldId]: formula.value }),
        {}
      );
    });
  }

  get formulaPropertiesGroups() {
    return this.memoize("formulaPropertiesGroups", () => {
      const tableGroup = {
        title: "Fields",
        properties: convertFieldsToProperties(this.columns || []),
      };

      const linkFieldsFilter = (field) => !field.blank && !field.hidden;

      const subListsGroups = this.subLists.map((link) =>
        link.buildFormulaPropertiesGroup({ filter: linkFieldsFilter, withPrefix: true })
      );

      const consolidatedLinkGroup = this.consolidatedLink?.buildFormulaPropertiesGroup({
        filter: linkFieldsFilter,
        withPrefix: true,
      });

      return compact([tableGroup, ...subListsGroups, consolidatedLinkGroup]);
    });
  }
}

TableFull.fragment = gql`
  fragment TableFullFragment on GetFullTablePayload {
    id
    table {
      ...TableFragment
      formulas {
        ${tableFragments.FORMULA_FRAGMENT}
      }
    }
    sublist {
      ${tableFragments.TABLE_SUB_LIST_LINK}
    }
    secondary {
      ${tableFragments.TABLE_SECONDARY_LINK}
    }
    byField {
      ${tableFragments.TABLE_BY_FIELD_LINK}
    }
    consolidated {
      ${tableFragments.TABLE_CONSOLIDATED_LINK}
    }
  }

  ${Table.fragment}
  ${TableColumn.fragment}
  ${TablePrimaryColumn.fragment}
`;
