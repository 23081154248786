import React, { useState, useRef } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { useFastTypeInputCellStyles } from "theme/components/TablePro";
import { useOnClickOutsideEffect } from "utils/effects";
import CellTypography from "components/TablePro/TableCells/Cells/CellTypography";
import { TableCellErrorTooltip } from "components/TablePro/Components/TableCellContainer";

import useCellInput, { onInputKeyDown } from "./useCellInput";

export const FastTypeInputCell = ({ inputProps, ...props }) => {
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isError = !isEmpty(props.error);

  const classes = useFastTypeInputCellStyles();

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div
      ref={ref}
      onClick={handleOpen}
      className={clsx(props.classes?.cellContainer, classes.cellContainer, { error: isError })}
    >
      <FastTypeInput
        classes={classes}
        innerRef={ref}
        onClose={handleClose}
        open={open}
        cellInputProps={props}
        inputProps={inputProps}
      />
    </div>
  );
};

const FastTypeInput = ({
  open,
  classes,
  innerRef,
  onClose,
  type,
  cellInputProps,
  inputProps = {},
  error,
}) => {
  const { inputValue, setInputValue, onSave, onReset } = useCellInput(cellInputProps);

  if (open) {
    return (
      <EditInput
        className={classes.root}
        innerRef={innerRef}
        onClose={onClose}
        type={type}
        inputValue={inputValue}
        setInputValue={setInputValue}
        onSave={onSave}
        onReset={onReset}
        inputProps={inputProps}
      />
    );
  }

  return (
    <>
      <div className={clsx(classes.cellContent, { blank: isEmpty(inputValue) })}>
        <CellTypography error={error}>{inputValue?.toString()}</CellTypography>
      </div>

      <TableCellErrorTooltip
        style={{
          position: "absolute",
          top: "50%",
          right: 0,
          transform: "translateX(-50%) translateY(-50%)",
        }}
        error={error}
      />
    </>
  );
};

const EditInput = ({
  innerRef,
  onClose,
  className,
  type,
  inputValue,
  setInputValue,
  onSave,
  onReset,
  inputProps,
}) => {
  const classes = useFastTypeInputCellStyles();

  const handleSave = () => onSave({ onClose });

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  useOnClickOutsideEffect(innerRef, handleSave);

  return (
    <div className={clsx(className, classes.inputArea, { opened: true })}>
      <input
        type={type}
        autoFocus={true}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={onInputKeyDown({ onClose, onSave, onReset })}
        {...inputProps}
      />
    </div>
  );
};
