import isEmpty from "lodash/isEmpty";
import { filterCollection } from "containers/TableFilter/utils";
import { parseEntityValue } from "containers/Actions/utils";

import { VARIANT_KEY } from "./mergeResponseInput";

export const buildRequestInput = ({ formLayout, action, allItemsMap, input, filters }) => {
  const keyField = action.request?.rows?.find((row) => !!row.to.key);
  const keyFieldId = keyField?.from?.path?.[keyField?.from?.path?.length - 1]?.id;

  const entitiesMap = createEntitiesMap(allItemsMap);
  let mutationFiles = [];
  let requestData = [];

  // Split data by variants
  requestData = Object.keys(input).reduce((result, key) => {
    if (formLayout.containersIds.includes(key)) {
      const dataBatches = createDataBatches(input[key], keyFieldId);
      return { ...result, [key]: dataBatches };
    }

    return { ...result, [key]: input[key] };
  }, {});

  // Filter input
  if (!isEmpty(filters)) {
    Object.entries(requestData).forEach(([key, value]) => {
      if (formLayout.containersIds.includes(key)) {
        requestData[key] = filterCollection(value, filters);
      }
    });
  }

  // Parse data values
  requestData = Object.entries(requestData).flatMap(([key, value]) => {
    if (formLayout.containersIds.includes(key)) {
      return {
        id: key,
        value: value.flatMap((arrayItem) => ({
          values: Object.keys(arrayItem).reduce((result, entityId) => {
            const value = arrayItem[entityId];

            // To Fix the issue when I received shipment for one item and then add NEW item to the table
            if (!action.requestFieldsIds.includes(entityId)) {
              return result;
            }

            return [...result, ...parseEntityValue(entityId, value, entitiesMap, mutationFiles)];
          }, []),
        })),
      };
    }

    return parseEntityValue(key, value, entitiesMap, mutationFiles);
  });

  return { files: mutationFiles, data: requestData };
};

export const createEntitiesMap = (allItemsMap) => {
  return Object.values(allItemsMap).reduce(
    (acc, item) => ({ ...acc, [item.entity.id]: item.entity }),
    {}
  );
};

export function createDataBatches(inputData, keyFieldId = null) {
  const batches = [];
  const keyFieldRegexp = new RegExp(`^${keyFieldId}_${VARIANT_KEY}_`);

  inputData.forEach((item) => {
    // Find the highest VARIANT index to determine how many batches we need
    const variantCounts = {};

    const isNoVariantKeyField = !Object.keys(item).some((key) => key.match(keyFieldRegexp));

    if (keyFieldId && isNoVariantKeyField) {
      batches.push(item);
      return;
    }

    Object.keys(item).forEach((key) => {
      if (key.includes(`_${VARIANT_KEY}_`)) {
        const baseKey = key.split(`_${VARIANT_KEY}_`)[0];
        const variantIndex = parseInt(key.split(`_${VARIANT_KEY}_`)[1], 10);
        variantCounts[baseKey] = variantCounts[baseKey] || 0;
        if (variantIndex >= variantCounts[baseKey]) {
          variantCounts[baseKey] = variantIndex + 1;
        }
      }
    });

    // Generate the batches for each variant index
    const maxVariants = Math.max(...Object.values(variantCounts));

    for (let i = 0; i < maxVariants; i++) {
      const batchKeyFieldId = `${keyFieldId}_${VARIANT_KEY}_${i}`;

      // Skip the batch creation if the keyFieldId for this variant is blank
      if (keyFieldId && notVariantValue(item[batchKeyFieldId])) {
        continue;
      }

      const batch = {};
      Object.keys(item).forEach((key) => {
        if (key.includes(`_VARIANT_${i}`)) {
          // Replace the VARIANT key with the base key
          const baseKey = key.split(`_${VARIANT_KEY}_`)[0];
          batch[baseKey] = item[key];
        } else if (!key.includes(`_${VARIANT_KEY}_`)) {
          // Copy the non-VARIANT keys as they are
          batch[key] = item[key];
        }
      });
      batches.push(batch);
    }
  });

  return batches;
}

const notVariantValue = (value) => value === undefined || value === "" || value == null;
