import { gql } from "@apollo/client";
import * as fragment from "./fragments";

export const CHECK_EMAIL = gql`
  query CheckEmail($input: CheckEmailInput!) {
    checkEmail(input: $input) {
      success
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountries {
    getCountries {
      id
      name
      callingCode
      numIso
      code2
    }
  }
`;

export const GET_CURRENCIES = gql`
  query GetCurrencies {
    getCurrencies {
      id
      name
    }
  }
`;

export const GET_FIELD_ITEMS = gql`
  query SearchFieldItems($input: SearchFieldItemsInput) {
    searchFieldItems(input: $input) {
      ${fragment.PAGINATION_FRAGMENT}
      content {
        id
        value
        fieldId
        channelValue
      }
    }
  }
`;

export const GET_LIST_CHANNEL_API_TYPES = gql`
  query ListChannelApiTypes($input: ListChannelApiTypesInput) {
    listChannelApiTypes(input: $input) {
      ${fragment.ID_NAME_PAYLOAD}
    }
  }
`;

export const GET_LIST_CHANNEL_API_SOURCES = gql`
  query ListChannelApiSources($input: ListChannelApiSourcesInput) {
    listChannelApiSources(input: $input) {
      ${fragment.ID_NAME_PAYLOAD}
    }
  }
`;
