import React from "react";
import Box from "components/Box";
import { isReactComponent } from "utils/detect";
import Switch from "components/Inputs/Switch";
import MenuItem from "components/Menu/MenuItem";
import { DragIcon } from "icons";

const FieldItem = ({
  field,
  onChange,
  index,
  classes = {},
  handleDraggableProps,
  innerRef,
  ...props
}) => {
  const Label = field.label || field.name;

  const toggleField = () => onChange({ hidden: !field.hidden });

  return (
    <Box ref={innerRef}>
      <MenuItem
        noClickable
        tickIcon={false}
        className={classes.item}
        value={field.id}
        data-id="select-item"
        onClick={toggleField}
        {...props}
      >
        <Box>
          <Box display="flex" alignItems="center">
            {handleDraggableProps && (
              <Box display="flex" alignItems="center" {...handleDraggableProps}>
                <DragIcon />
              </Box>
            )}

            <Switch
              name={field.id}
              size="small"
              space={{ mb: 0, mr: 1 }}
              value={!field.hidden}
              onChange={toggleField}
            />

            <Box>{isReactComponent(Label) ? <Label entity={field} /> : Label}</Box>
          </Box>
        </Box>
      </MenuItem>
    </Box>
  );
};

export default FieldItem;
