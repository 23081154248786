import React, { useState, useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { CalendarIcon, ArrowLeftIcon, ArrowRightIcon, ArrowDownIcon, BellIcon } from "icons";
import moment from "moment";

const fetchInputProps = (props) => {
  const { ref, inputProps, label, InputProps, ...rest } = props;

  return { inputRef: ref, ...inputProps, type: null, ...rest };
};

const DateTimePickerInput = ({
  readOnly = false,
  value,
  renderInput,
  onChange,
  onClose,
  format,
}) => {
  const [open, setOpen] = useState(false);
  const [triggerOnClose, setTriggerOnClose] = useState(false);

  const handleClose = () => {
    if (!open) return;

    setOpen(false);
    setTriggerOnClose(true);
  };

  useEffect(() => {
    if (!triggerOnClose) return;

    onClose && onClose();
    setTriggerOnClose(false);
  }, [triggerOnClose]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        disableCloseOnSelect={true}
        components={{
          LeftArrowIcon: ArrowLeftIcon,
          RightArrowIcon: ArrowRightIcon,
          SwitchViewIcon: ArrowDownIcon,
        }}
        dateRangeIcon={<CalendarIcon />}
        timeIcon={<BellIcon />}
        label={null}
        clearable={true}
        open={open}
        value={value}
        onClose={handleClose}
        onChange={readOnly ? null : onChange}
        renderInput={(props) => {
          const inputProps = fetchInputProps(props);

          return renderInput({
            ...inputProps,
            readOnly,
            onClick: () => setOpen(true),
            value: inputProps.value ? moment(inputProps.value).format(format) : inputProps.value,
          });
        }}
      />
    </LocalizationProvider>
  );
};

export default DateTimePickerInput;
