import { useCallback, useRef } from "react";
import { closestCenter, pointerWithin, rectIntersection, getFirstCollision } from "@dnd-kit/core";

/**
 * Custom collision detection strategy optimized for multiple containers
 *
 * - First, find any droppable containers intersecting with the pointer.
 * - If there are none, find intersecting containers with the active draggable.
 * - If there are no intersecting containers, return the last matched intersection
 *
 */
export const useCollisionDetectionStrategy = ({ activeId, containers, items, trashId }) => {
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);

  const collisionDetectionStrategy = useCallback(
    (args) => {
      // If the active draggable is in a section, return the closest droppable within that section
      if (activeId && activeId in containers) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in containers
          ),
        });
      }

      if (activeId && activeId in items) {
        return resolveItemsColisions({
          args,
          activeId,
          items: containers,
          trashId,
          lastOverId,
          recentlyMovedToNewContainer,
        });
      }

      return resolveItemsColisions({
        args,
        activeId,
        items,
        trashId,
        lastOverId,
        recentlyMovedToNewContainer,
      });
    },
    [activeId, items, containers]
  );

  return { collisionDetectionStrategy, recentlyMovedToNewContainer };
};

const resolveItemsColisions = ({
  args,
  activeId,
  items,
  trashId,
  lastOverId,
  recentlyMovedToNewContainer,
}) => {
  // Start by finding any intersecting droppable
  const pointerIntersections = pointerWithin(args);
  const intersections =
    pointerIntersections.length > 0
      ? // If there are droppables intersecting with the pointer, return those
        pointerIntersections
      : rectIntersection(args);
  let overId = getFirstCollision(intersections, "id");

  if (overId != null) {
    if (overId === trashId) {
      // If the intersecting droppable is the trash, return early
      // Remove this if you're not using trashable functionality in your app
      return intersections;
    }

    if (overId in items) {
      const containerItems = items[overId];

      // If a container is matched and it contains items (columns 'A', 'B', 'C')
      if (containerItems.length > 0) {
        // Return the closest droppable within that container
        overId = closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id !== overId && containerItems.includes(container.id)
          ),
        })[0]?.id;
      }
    }

    lastOverId.current = overId;

    return [{ id: overId }];
  }

  // When a draggable item moves to a new container, the layout may shift
  // and the `overId` may become `null`. We manually set the cached `lastOverId`
  // to the id of the draggable item that was moved to the new container, otherwise
  // the previous `overId` will be returned which can cause items to incorrectly shift positions
  if (recentlyMovedToNewContainer.current) {
    lastOverId.current = activeId;
  }

  // If no droppable is matched, return the last match
  return lastOverId.current ? [{ id: lastOverId.current }] : [];
};
