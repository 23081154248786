import { useState, useEffect } from "react";
import { useNotification } from "contexts/Notifications";
import JSum from "vendor/jsum";

const isValueEqual = (value, inputValue) => {
  return JSum.digest(value, "SHA256", "hex") === JSum.digest(inputValue, "SHA256", "hex");
};

const useInputMenu = ({ value, name, onChange, onSave, entity }) => {
  const { notifyError } = useNotification();
  const [inputValue, setInputValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const readOnly = !(onChange && onSave) || entity?.readOnly;

  const reset = () => setInputValue(value);

  const handleChange = (newInputValue) => {
    onChange(name, newInputValue);
  };

  const handleSave = ({ onClose }) => {
    if (loading || isValueEqual(value, inputValue)) {
      onClose && onClose();
      return;
    }

    const oldValue = value;

    handleChange(inputValue);

    setLoading(true);

    onSave({
      onSuccess: () => {
        setLoading(false);
        onClose && onClose();
      },
      onFailure: (errors) => {
        setLoading(false);
        handleChange(oldValue);
        notifyError(errors);
      },
    })();
  };

  useEffect(() => {
    if (isValueEqual(value, inputValue)) return;

    setInputValue(value);
  }, [JSum.digest(value, "SHA256", "hex")]);

  return {
    inputValue,
    setInputValue,
    loading,
    setLoading,
    reset,
    onReset: reset,
    handleChange,
    handleSave,
    readOnly,
  };
};

export const useInputSaveMenu = ({ entity, name, value, onChange, onSave, onRemove }) => {
  const { notifyError } = useNotification();

  const readOnly = !(onChange && onSave) || entity?.readOnly;

  const menuProps = useInputMenu({
    name,
    value,
    onChange,
    onSave,
    entity,
  });

  const { inputValue, setInputValue, loading, setLoading } = menuProps;

  const setInputValueAndSave = (newInputValue) => {
    if (loading) return;

    const oldValue = inputValue;

    setInputValue(newInputValue);
    setLoading(true);

    onSave({
      entity,
      input: { [name]: newInputValue },
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: (errors) => {
        setLoading(false);
        setInputValue(oldValue);
        notifyError(errors.submit || errors);
      },
    })();
  };

  const handleRemove = onRemove
    ? ({ onSuccess, onFailure } = {}) => {
        if (!onRemove) return;
        if (loading) return;

        setLoading(true);

        onRemove({
          entity,
          onSuccess: (data) => {
            setLoading(false);
            onSuccess && onSuccess(data);
          },
          onFailure: (errors) => {
            setLoading(false);
            notifyError(errors.submit || errors);
            onFailure && onFailure(errors);
          },
        });
      }
    : null;

  return {
    ...menuProps,
    handleRemove: readOnly ? null : handleRemove,
    setInputValueAndSave,
    readOnly,
  };
};

export default useInputMenu;
