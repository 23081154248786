import { delegate, memoize } from "utils/entities";
import uniqBy from "lodash/uniqBy";
import { convertFieldsToProperties } from "models/Table/utils";

import { TablePrimaryColumn } from "models/fragments/TablePrimaryColumn";
import { TableColumn } from "models/fragments/TableColumn";

export class ConsolidatedLink {
  constructor(origin, table) {
    this.table = table;
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);
    this.linkType = "consolidated";

    delegate(this, origin, ["primaryColumns"]);
  }

  get label() {
    return this.id;
  }

  get primaryColumns() {
    return this.memoize("primaryColumns", () => {
      const columns = this.origin.primaryColumns || [];
      const lastModifiedDate = this.table.lastModifiedDate;

      return columns.map((column) => new TablePrimaryColumn(column, { lastModifiedDate }));
    });
  }

  get additionalColumns() {
    return this.memoize("additionalColumns", () => {
      const columns = this.origin.columns || [];
      const lastModifiedDate = this.table.lastModifiedDate;

      return columns.map((column) => new TableColumn(column, { lastModifiedDate }));
    });
  }

  get mappingColumnsIdsMap() {
    return {};
  }

  get tables() {
    return this.memoize("tables", () => {
      return this.primaryColumns[0]?.mapping?.map((mappingItem) => mappingItem.table) || [];
    });
  }

  get tablesIds() {
    return this.tables.map((table) => table.id);
  }

  get formulaPropertiesGroups() {
    return this.memoize("formulaPropertiesGroups", () => {
      const fieldsGroup = this.buildFormulaPropertiesGroup({
        name: this.name,
        filter: (field) => !field.blank,
      });

      return [fieldsGroup];
    });
  }

  buildFormulaPropertiesGroup({ name, filter = () => true, withPrefix = false } = {}) {
    const primaryColumns = this.primaryColumns.filter(filter);
    const additionalColumns = this.additionalColumns.filter(filter);
    const formulaFields = uniqBy([...primaryColumns, ...additionalColumns], "fieldId");

    return {
      title: name || `Consolidated table ${this.name}`,
      properties: convertFieldsToProperties(formulaFields, {
        prefix: withPrefix ? this.uid : null,
      }),
    };
  }

  get formulasMap() {
    return this.memoize("formulasMap", () => {
      return (this.formulas || []).reduce(
        (result, formula) => ({ ...result, [formula.fieldId]: formula.value }),
        {}
      );
    });
  }

  get tableEntities() {
    return this.memoize("tableEntities", () => {
      return this.origin.primaryColumns.map((column) => ({
        ...column,
        ...column.mapping.reduce(
          (result, mapping) => ({ ...result, [mapping.table.id]: mapping.column.name }),
          {}
        ),
      }));
    });
  }
}
