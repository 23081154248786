import { gql } from "@apollo/client";
import { delegate, serialize } from "utils/entities";

import { COMPANY_MODEL_TYPE } from "models/types";
import { Timezone } from "models/Timezone/Timezone";
import { User } from "models/fragments/User";

export class CompanyShort {
  constructor(origin) {
    this.origin = origin;

    delegate(this, origin);

    serialize(this, { timezone: Timezone, owner: User });

    this.permissions = [];
  }

  get timezoneId() {
    return this.timezone?.id;
  }

  get modelType() {
    return COMPANY_MODEL_TYPE;
  }
}

CompanyShort.fragment = gql`
  fragment CompanyShortFragment on GetCompanyPayload {
    id
    name
    createdDate
    lastModifiedDate
    email
    timezone {
      ...TimezoneFragment
    }
    owner {
      ...UserFragment
    }
  }

  ${Timezone.fragment}
  ${User.fragment}
`;
