import React, { useState } from "react";

const StickFieldsContext = React.createContext(null);

export const StickFieldsProvider = ({
  loading,
  columns,
  onChange,
  children,
  lockableColumns = true,
}) => {
  const initialStickedColumnId = columns.find((column) => column.lock)?.id;
  const [stickedColumnId, setStickedColumnId] = useState(initialStickedColumnId);

  const handleSetStickedColumnId = (columnId) => {
    if (loading) return;

    setStickedColumnId(columnId);
    onChange && onChange(columnId);
  };

  const toggleStick = (columnId) => {
    stickedColumnId === columnId
      ? handleSetStickedColumnId(null)
      : handleSetStickedColumnId(columnId);
  };

  const value = {
    stickedColumnId,
    toggleStick,
    lockableColumns,
  };

  return <StickFieldsContext.Provider value={value}>{children}</StickFieldsContext.Provider>;
};

export const useStickFields = () => React.useContext(StickFieldsContext);
