import React from "react";
import { ProfileThinIcon, SlidersThinIcon, BoxWideIcon } from "icons";
import NavigationItem from "./NavigationItem";
import Divider from "components/Divider";
import Avatar from "components/Avatar";
import Typography from "components/Typography";
import clsx from "clsx";
import { useSearchProjects } from "models/Project/queries";
import { useAuth } from "contexts/Auth";
import { useSideNavigationListStyles } from "theme/components/SideNavigation";
import { useWorkspace } from "contexts/Workspace";
import { AvatarInitials } from "components/Avatar";
import { useAccessManager } from "contexts/AccessManager";

import { AccessLoadingSection, AccessSection, accessRules } from "containers/AccessSection";

export const SettingsSection = () => {
  const { currentProfile } = useAuth();
  const { accessManager } = useAccessManager();
  const { currentCompany } = useWorkspace();
  const classes = useSideNavigationListStyles();

  const { projects } = useSearchProjects();

  return (
    <>
      <Typography className={clsx(classes.title, classes.cornerIcon)}>Owner</Typography>

      <NavigationItem
        noHover
        className="top"
        title={currentProfile.email}
        icon={(props) => <Avatar size="sidebar" {...props} src={currentProfile.avatar?.url} />}
      />

      <NavigationItem
        className="top"
        title="Profile"
        icon={ProfileThinIcon}
        to="/:companyId/settings/profile"
      />

      <NavigationItem disabled className="top" title="My Notifications" icon={SlidersThinIcon} />

      <NavigationItem disabled className="top" title="My Apps" icon={BoxWideIcon} />

      <AccessSection can={accessManager.canViewSettings}>
        <Divider space={{ my: 1.5, mx: 1 }} variant="light" />

        <Typography className={clsx(classes.title, classes.cornerIcon)}>Projects</Typography>

        {projects.map((project) => (
          <AccessSection
            key={`project-access-${project.id}`}
            accessRule={accessRules.VIEW_PROJECT_SETTINGS}
            accessEntity={project}
          >
            <ProjectNavigationItem key={project.id} project={project} />
          </AccessSection>
        ))}

        <AccessLoadingSection
          accessRule={accessRules.VIEW_COMPANY_SETTINGS}
          accessEntity={currentCompany}
        >
          {({ loading }) => (
            <>
              <Divider space={{ my: 1.5, mx: 1 }} variant="light" />

              <Typography className={clsx(classes.title, classes.cornerIcon)}>Company</Typography>

              <CompanyNavigationItem disabled={loading} company={currentCompany} />
            </>
          )}
        </AccessLoadingSection>
      </AccessSection>
    </>
  );
};

const CompanyNavigationItem = ({ disabled, company }) => {
  if (!company) return;

  return (
    <NavigationItem
      noMore
      noSelected
      disabled={disabled}
      icon={(props) => <AvatarInitials {...props} name={company.name} />}
      title={company.name}
      to="/:companyId/settings/general"
      nested={[
        {
          title: "General",
          to: `/${company.id}/settings/general`,
          accessProps: {
            accessRule: accessRules.MODIFY_COMPANY_SETTINGS,
            accessEntity: company,
          },
        },
        {
          title: "Members",
          to: `/${company.id}/settings/members`,
          accessProps: {
            accessRule: accessRules.VIEW_COMPANY_MEMBERS,
            accessEntity: company,
          },
        },
        { title: "Channels", to: `/${company.id}/settings/channels` },
        { title: "Projects", to: `/${company.id}/settings/projects` },
        { title: "Fields", to: `/${company.id}/settings/fields/all` },
        { title: "Tables", to: `/${company.id}/settings/tables` },
        { title: "Forms", to: `/${company.id}/settings/forms` },
        { title: "Actions" },
        { title: "Billing" },
        { title: "Plan & Usage" },
        { title: "Security" },
      ]}
    />
  );
};

const ProjectNavigationItem = ({ project }) => (
  <NavigationItem
    noMore
    noSelected
    icon={(props) => <AvatarInitials {...props} name={project?.name} />}
    title={project?.name}
    to={`/:companyId/${project.id}/settings/general`}
    nested={[
      {
        title: "General",
        to: `/:companyId/${project.id}/settings/general`,
        accessProps: {
          accessRule: accessRules.MODIFY_PROJECT_SETTINGS,
          accessEntity: project,
        },
      },
      {
        title: "Members",
        to: `/:companyId/${project.id}/settings/members`,
        accessProps: {
          accessRule: accessRules.VIEW_PROJECT_MEMBERS,
          accessEntity: project,
        },
      },
      {
        title: "Forms",
        to: `/:companyId/${project.id}/settings/forms`,
      },
      {
        title: "Views & lists",
        to: `/:companyId/${project.id}/settings/views`,
        accessProps: {
          accessRule: accessRules.TABLE_VIEWS_MANAGER,
          accessEntity: project,
        },
      },
      { title: "Import/Export" },
    ]}
  />
);
