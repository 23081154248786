import React, { useRef, useLayoutEffect, useState } from "react";
import { Drawer } from "components/Drawer";
import { useStateFields } from "components/TablePro/Contexts/StateFieldsContext";

export const SideTableDrawer = ({ children, open }) => {
  const [tableHeight, setTableHeight] = useState(0);
  const { tableRef } = useStateFields();
  const ref = useRef();

  const tableParent =
    tableRef?.current?.closest(".AppContent-pageTable") || tableRef?.current?.closest("#pageTable");

  const tableNavigation = tableParent?.querySelector(".AppTableNavigation-root");
  const fetchHeight = (element) => element?.getBoundingClientRect()?.height || 0;

  const pageTableHeight = fetchHeight(tableParent) - fetchHeight(tableNavigation); // space(2);

  useLayoutEffect(() => {
    setTableHeight(pageTableHeight);
  }, [open, pageTableHeight]);

  const height = Math.round(tableHeight) - 10 + 0.5;

  if (!open) return <></>;

  return (
    <Drawer
      size="table"
      open={open}
      innerRef={ref}
      height={height}
      position="permanent"
      hiddenBackdrop
      PaperProps={{ style: { right: 0, paddingRight: 20 } }}
    >
      {children}
    </Drawer>
  );
};
