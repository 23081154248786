import React from "react";
import Box from "components/Box";
import Menu from "components/Menu";
import MenuItem from "components/Menu/MenuItem";
import MenuTitle from "components/Menu/MenuTitle";
import {
  LockIcon,
  LockOpenIcon,
  ArrowUpRightIcon,
  ImportExportIcon,
  Filter2Icon,
  EyeOffIcon,
} from "iconsNew";
import Divider from "components/Divider";

export const TableHeaderCellActions = ({
  column,
  anchorEl,
  open,
  handleClose,
  toggleStick,
  lockableColumns,
  stickedColumnId,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        sx: { minWidth: anchorEl?.offsetWidth },
      }}
    >
      <Box space={{ px: 1 }}>
        <MenuTitle space={{ mt: 1 }}>Actions</MenuTitle>

        <MenuItem onClick={() => {}} size="small" icon={ArrowUpRightIcon}>
          View field details
        </MenuItem>

        {lockableColumns && (
          <MenuItem
            onClick={() => toggleStick(column.id)}
            size="small"
            icon={stickedColumnId === column.id ? LockOpenIcon : LockIcon}
          >
            {stickedColumnId === column.id ? "Unfreeze column" : "Freeze column"}
          </MenuItem>
        )}
      </Box>

      <Divider variant="light" space={{ my: 1 }} />

      <Box space={{ px: 1 }}>
        {/* {onSort && sort && !column.unSort && !isSystem && ( */}
        <>
          <MenuItem
            // onClick={() => onSort(column, -1)}
            size="small"
            icon={ImportExportIcon}
            disabled
          >
            {"Sort 0 -> 9"}
          </MenuItem>

          <MenuItem
            // onClick={() => onSort(column, 1)}
            size="small"
            icon={ImportExportIcon}
            disabled
          >
            {"Sort 9 -> 0"}
          </MenuItem>
        </>
        {/* )} */}

        <MenuItem onClick={() => {}} size="small" icon={Filter2Icon}>
          Apply filter
        </MenuItem>
      </Box>

      <Divider variant="light" space={{ my: 1 }} />

      <Box space={{ px: 1 }}>
        <MenuItem onClick={() => {}} size="small" icon={EyeOffIcon}>
          Hide from view
        </MenuItem>

        <MenuItem onClick={() => {}} size="small" icon={ArrowUpRightIcon}>
          Manage properties
        </MenuItem>
      </Box>
    </Menu>
  );
};
