import React from "react";
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
} from "@mui/material";
import { TransparentBackdrop, useDialogStyles } from "theme/components/Dialog";
import withStyles from "components/withStyles";
import Box from "components/Box";
import Button from "components/Button";
import IconButton from "components/IconButton";
import Typography from "components/Typography";
import { CrossIcon } from "iconsNew";

const DialogComponent = withStyles(({ innerRef, ...props }) => (
  <MuiDialog ref={innerRef} {...props} />
));

export const Dialog = ({
  children,
  disableBackdropClick = true,
  disableEscapeKeyDown = false,
  transparent,
  onClose,
  open,
  size = "fullHeightLarge",
  verticalActions,
  ...props
}) => {
  const classes = useDialogStyles({ size, verticalActions });

  const handleClose = (event, reason) => {
    if (!open) return;
    if (disableBackdropClick && reason === "backdropClick") return false;
    if (disableEscapeKeyDown && reason === "escapeKeyDown") return false;
    if (typeof onClose === "function") onClose();
  };

  const transparentProps = transparent ? { BackdropComponent: TransparentBackdrop } : {};

  return (
    <DialogComponent
      classes={classes}
      open={open}
      onClose={handleClose}
      {...transparentProps}
      {...props}
      TransitionProps={{ ...props.TransitionProps, unmountOnExit: true }}
    >
      {children}
    </DialogComponent>
  );
};

export const DialogTitle = MuiDialogTitle;

export const DialogContent = MuiDialogContent;

export const DialogActions = MuiDialogActions;

export const DialogLayout = ({
  children,
  size = "fullHeightLarge",
  title,
  onClose,
  onSave,
  saveTitle,
  disabled,
}) => {
  const classes = useDialogStyles({ size });

  return (
    <Box fullHeightContainer>
      <Box
        className={classes.title}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" weight="bold">
          {title}
        </Typography>

        <Box>
          {onClose && (
            <Button size="long" space={{ mr: 1 }} onClick={onClose}>
              Discard
            </Button>
          )}

          {onSave && (
            <Button disabled={disabled} size="long" variant="primary" onClick={onSave}>
              {saveTitle}
            </Button>
          )}
        </Box>
      </Box>

      <Box fullHeightScroll style={{ height: "100%" }}>
        <DialogContent className={classes.content}>{children}</DialogContent>
      </Box>
    </Box>
  );
};

export const ActionDialog = ({
  id,
  onClose,
  title,
  description,
  content,
  actions,
  verticalActions = true,
  size = "medium",
  children,
  noCancelButton,
  isCrossButton,
  ...rest
}) => {
  const uuid = id || "modal";
  const classes = useDialogStyles({ size, verticalActions });

  return (
    <Dialog
      {...rest}
      maxWidth={false}
      scroll="body"
      size={size}
      onClose={onClose}
      classes={classes}
      aria-labelledby={uuid}
      verticalActions={verticalActions}
    >
      {isCrossButton && (
        <Box className={classes.crossButton}>
          <IconButton onClick={onClose} color="darkGrey" icon={CrossIcon} size="xs" />
        </Box>
      )}

      <DialogTitle id={uuid}>{title}</DialogTitle>

      {description && (
        <DialogContent>
          <Typography>{description}</Typography>
        </DialogContent>
      )}

      {(content || children) && <DialogContent>{content || children}</DialogContent>}

      {actions && (
        <DialogActions>
          {!noCancelButton && <Button onClick={onClose}>Cancel</Button>}
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Dialog;
