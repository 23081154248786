import React, { useState, useEffect } from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import { useSearchMenu } from "theme/components/SearchMenu";
import { searchFilter } from "utils/search";
import keyBy from "lodash/keyBy";
import clsx from "clsx";
import Input from "components/Inputs/Input";
import { Tabs, Tab } from "components/Tab";
import { reorder, replaceRecordByIndex, indexMap } from "utils/collection";

import FieldsList from "./FieldsList";

const HideMenuOptions = ({
  fields: initialFields,
  changed = false,
  onChange,
  onMove,
  label = "fields",
  size = "small",
  nested = false,
}) => {
  const classes = useSearchMenu();

  const [mode, setMode] = useState("SHOW");
  const [search, setSearch] = useState();
  const [fields, setFields] = useState(initialFields);

  const initialFieldsIndexMap = indexMap(initialFields);

  const renderFields = searchFilter(fields, search).map((field) => ({
    ...field,
    index: initialFieldsIndexMap[field.id],
  }));

  const handleChangeField = (index, props) => {
    const newProps = { ...initialFields[index], ...props };
    const fieldIndex = fields.findIndex((field) => field.id === newProps.id);
    const newFields = replaceRecordByIndex(fields, newProps, fieldIndex);

    setFields(newFields);
    onChange && onChange(initialFields[index].id, newProps);
  };

  const handleMoveField = (fromIndex, toIndex) => {
    const fieldsMap = keyBy(fields, "id");

    const nextFields = reorder(initialFields, fromIndex, toIndex).filter(
      (field) => fieldsMap[field.id]?.id
    );

    setFields(nextFields);
    onMove && onMove(fromIndex, toIndex);
  };

  useEffect(() => {
    if (mode === "SHOW") {
      setFields(initialFields.filter((field) => !field.hidden));
    } else {
      setFields(initialFields.filter((field) => field.hidden));
    }
  }, [mode, changed]);

  return (
    <>
      <Box
        style={{ minWidth: 300, maxHeight: nested ? "100%" : null }}
        className={clsx(classes.contentContainer, classes.scrollContainer)}
      >
        <Box className={classes.searchContainer}>
          <Input
            size={size}
            space={{ mb: 1 }}
            fullWidth
            placeholder="searchByName"
            variant="outlined"
            value={search}
            onChange={(event) => setSearch(event.target.value || "")}
          />
        </Box>

        <Tabs
          variant="menu"
          color="grey"
          value={mode}
          onChange={setMode}
          aria-label="tasks tabs"
          space={{ ml: 2, mb: 1 }}
        >
          <Tab label="Shown" value="SHOW" />
          <Tab label="Hidden" value="HIDE" />
        </Tabs>

        <Box
          space={{ mb: 1, ml: 2, pr: 1 }}
          style={{ minHeight: 24 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="div" color="grey">
            {`${mode === "SHOW" ? "Shown" : "Hidden"} ${label} • ${fields.length}`}
          </Typography>

          {/* {mode === "HIDE" && (
                <Button onClick={showAllFields} size="xs">
                  Show all
                </Button>
              )} */}
        </Box>

        <Box
          className={classes.scrollContent}
          style={{ maxHeight: nested ? "100%" : 250 }}
          space={{ pb: 1 }}
        >
          <FieldsList
            draggable={mode === "SHOW"}
            classes={{ item: clsx(classes.item, "small") }}
            fields={renderFields}
            onChange={handleChangeField}
            onMove={handleMoveField}
          />
        </Box>
      </Box>
    </>
  );
};

export default HideMenuOptions;
