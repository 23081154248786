import React, { useState } from "react";
import IconButton from "components/IconButton";
import Button from "components/Button";
import Box from "components/Box";
import clsx from "clsx";
import Divider from "components/Divider";
import MoreMenu from "components/Menu/MoreMenu";
import MenuItem from "components/Menu/MenuItem";
import Typography from "components/Typography";
import { ActionDialog } from "components/Dialog";
import { CONTENT } from "constants/content";
import { VoltIcon, ClockIcon } from "icons";
import { MaximizeIcon, MinimizeIcon } from "iconsNew";

import {
  CrossIcon,
  EditIcon,
  TrashIcon,
  ChevronsRightIcon,
  ChevronsLeftIcon,
  ArrowLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MessageSquareIcon,
} from "iconsNew";

import { useDrawerLayout } from "./Provider";
import { useDrawer } from "./index";

import "styles/App/Drawer/DrawerHeader.scss";

const useDrawerHeaderStyles = () => ({
  root: "AppDrawerHeader-root",
  editActions: "AppDrawerHeader-editActions",
  actions: "AppDrawerHeader-actions",
});

export const DrawerHeader = ({
  title,
  icon,
  onDown,
  onUp,
  onDiscard,
  onSave,
  onClose,
  onAction,
  onComment,
  onWatchHistory,
  customActions,
  hideNavigation = false,
  visibleToggler = true,
  hideMovingActions = false,
  saveTitle,
  saveDisabled,
  style,
}) => {
  const {
    loading,
    edit: isEdit,
    setEdit,
    isSubmitting,
    onRemove,
    toggleVisibleSidebar,
    visibleSidebar,
    setCommentMode,
  } = useDrawerLayout();

  const { fullScreen } = useDrawer() || {};

  const classes = useDrawerHeaderStyles();

  const toggleCommentsMode = () => {
    toggleVisibleSidebar();
    setCommentMode(true);
  };

  return (
    <Box className={clsx(classes.root, { fullScreen })} style={style}>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Box display="flex" alignItems="center" flexGrow="1">
          {(title || icon) && <DrawerTitle title={title} icon={icon} />}

          {!hideNavigation && (
            <NavigationButtons
              onClose={onClose}
              onDown={onDown}
              onUp={onUp}
              hideMovingActions={hideMovingActions}
            />
          )}
        </Box>

        <RightButtons
          isEdit={isEdit}
          setEdit={setEdit}
          onDiscard={onDiscard}
          disabled={loading || isSubmitting || saveDisabled}
          onSave={onSave}
          onRemove={onRemove}
          customActions={customActions}
          saveTitle={saveTitle}
        >
          {onAction && (
            <IconButton
              disabled
              space={{ mr: 0.5 }}
              color="darkGrey"
              icon={VoltIcon}
              size="small"
            />
          )}

          {onComment && (
            <IconButton
              onClick={toggleCommentsMode}
              space={{ mr: 0.5 }}
              color="darkGrey"
              icon={MessageSquareIcon}
              size="small"
              iconSize="large"
            />
          )}

          {onWatchHistory && (
            <IconButton
              disabled
              space={{ mr: 0.5 }}
              color="darkGrey"
              icon={ClockIcon}
              size="small"
            />
          )}

          {visibleToggler && (
            <IconButton
              onClick={toggleVisibleSidebar}
              space={{ mr: 0.5 }}
              color="darkGrey"
              icon={visibleSidebar ? ChevronsRightIcon : ChevronsLeftIcon}
              size="small"
            />
          )}
        </RightButtons>
      </Box>
    </Box>
  );
};

export const DrawerTitle = ({ icon: Icon, title }) => (
  <Box display="flex" alignItems="center" flexGrow="1">
    {Icon && <Icon space={{ mr: 1, ml: -0.5 }} />}

    {title && (
      <Typography variant="h4" color="dark">
        {title}
      </Typography>
    )}
  </Box>
);

export const DrawerHeaderTitle = ({ title, onBack, onClose }) => {
  const classes = useDrawerHeaderStyles();
  const { fullScreen } = useDrawer() || {};

  const BackIcon = onBack
    ? (props) => (
        <IconButton {...props} size="xs" variant="grey" icon={ArrowLeftIcon} onClick={onBack} />
      )
    : null;

  return (
    <Box className={clsx(classes.root, { fullScreen })}>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Box display="flex" alignItems="center" flexGrow="1">
          <DrawerTitle title={title} icon={BackIcon} />

          {onClose && (
            <IconButton color="darkGrey" icon={CrossIcon} onClick={onClose} size="small" />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const DrawerEditActions = ({
  bottomActions,
  title,
  icon,
  saveTitle,
  disabled,
  onSave,
  onCancel,
}) => {
  const classes = useDrawerHeaderStyles();

  return (
    <Box className={clsx(classes.editActions, { bottomActions })}>
      <Box display="flex" alignItems="center" flexGrow="1">
        {(title || icon) && <DrawerTitle title={title} icon={icon} />}
      </Box>

      <RightButtons
        isEdit
        onDiscard={onCancel}
        disabled={disabled}
        onSave={onSave}
        saveTitle={saveTitle}
      />
    </Box>
  );
};

const NavigationButtons = ({ onClose, onDown, onUp, hideMovingActions }) => {
  const { fullScreen, toggleFullScreen } = useDrawer() || {};

  return (
    <>
      <IconButton color="darkGrey" icon={CrossIcon} onClick={onClose} size="small" />

      <IconButton
        color="darkGrey"
        onClick={toggleFullScreen}
        size="small"
        icon={fullScreen ? MinimizeIcon : MaximizeIcon}
      />

      {!hideMovingActions && (
        <IconButton
          color="darkGrey"
          icon={ChevronDownIcon}
          disabled={!onDown}
          onClick={onDown}
          size="small"
        />
      )}

      {!hideMovingActions && (
        <IconButton
          color="darkGrey"
          icon={ChevronUpIcon}
          disabled={!onUp}
          onClick={onUp}
          size="small"
        />
      )}
    </>
  );
};

// Sub components

const RightButtons = ({
  isEdit,
  setEdit,
  onDiscard,
  disabled,
  onSave,
  onRemove,
  customActions,
  saveTitle,
  children,
}) => {
  const classes = useDrawerHeaderStyles();
  const toggleEdit = () => setEdit && setEdit(!isEdit);
  const isMoreMenu = setEdit || onRemove;

  return (
    <>
      {isEdit && (
        <Box className={classes.actions}>
          <Button size="small" space={{ mr: 1 }} onClick={onDiscard}>
            Discard
          </Button>

          <Button disabled={disabled} onClick={onSave} size="small" variant="primary">
            {saveTitle || "Save"}
          </Button>
        </Box>
      )}

      {!isEdit && (
        <Box display="flex" alignItems="center">
          {customActions}

          {children}

          {isMoreMenu && (
            <MoreMenu color="darkGrey" disabled={disabled} size="small">
              {setEdit && (
                <MenuItem onClick={toggleEdit}>
                  <Typography leftIcon={EditIcon}>Edit</Typography>
                </MenuItem>
              )}

              {setEdit && onRemove && <Divider space={{ my: 1 }} />}

              {onRemove && <RemoveButton loading={disabled} onRemove={onRemove} />}
            </MoreMenu>
          )}
        </Box>
      )}
    </>
  );
};

const RemoveButton = ({ onRemove, loading }) => {
  const [removeModal, setRemoveModal] = useState();
  const toggleRemoveModal = () => setRemoveModal((prev) => !prev);

  return (
    <>
      <MenuItem onClick={toggleRemoveModal}>
        <Typography leftIcon={TrashIcon}>Remove</Typography>
      </MenuItem>

      <ActionDialog
        {...CONTENT.confirmRemoveTable}
        open={removeModal}
        onClose={toggleRemoveModal}
        actions={
          <Button
            disabled={loading}
            variant="danger"
            onClick={() => onRemove({ onCloseModal: toggleRemoveModal })}
          >
            Remove
          </Button>
        }
      />
    </>
  );
};
