import React from "react";
import { ComponentTypeInputCell } from "components/ComponentType/ComponentTypeInputCell";
import { LIST_TYPE, OBJECT_TYPE, TABLE_CONNECTOR_TYPE } from "models/abstract/FieldType";
import { TableInputCellContainer } from "components/TablePro/Components/TableInputCellContainer";

import { ListInput } from "./Input/ListType/ListInput";
import { ObjectInput } from "./Input/ObjectType/ObjectInput";
import { TableConnectorInput } from "./Input/TableConnectorType/TableConnectorInput";

const DYNAMIC_INPUT_COMPONENT = {
  [LIST_TYPE]: ListInput,
  [OBJECT_TYPE]: ObjectInput,
  [TABLE_CONNECTOR_TYPE]: TableConnectorInput,
};

export const FormFieldInputCell = (props) => {
  const { column, entity, rowIndex, classes, error } = props;
  const formField = props.column.formField;

  const DynamicInputComponent = DYNAMIC_INPUT_COMPONENT[formField.type];

  const inputProps = {
    disabled: formField.disabled,
    placeholder: formField.placeholder || "Add value...",
    ...props,
    space: { mb: 0 },
  };

  const handleChange = (newValue) => {
    const valueProps = { [column.id]: newValue };

    props.onChange({ entity, rowIndex, props: valueProps, tableColumn: column });
  };

  if (DynamicInputComponent) {
    return (
      <TableInputCellContainer classes={classes} error={error}>
        <DynamicInputComponent
          {...inputProps}
          renderTagsInSearch={formField.type === LIST_TYPE ? false : undefined}
          onChange={handleChange}
          formField={formField}
        />
      </TableInputCellContainer>
    );
  }

  return (
    <ComponentTypeInputCell
      type={formField.componentType}
      config={formField.componentTypeConfig}
      {...inputProps}
      onChange={handleChange}
      originOnChange={props.onChange}
    />
  );
};
