import React from "react";
import Checkbox from "components/Inputs/Checkbox";
import { useStateFields } from "components/TablePro/Contexts/StateFieldsContext";

const CheckboxLabel = ({ multiSelectable } = {}) => {
  const { bulkSelect, toggleBulkSelect, totalCount } = useStateFields();

  const handleToggle = (event) => {
    event.stopPropagation();
    toggleBulkSelect();
  };

  return multiSelectable ? (
    <Checkbox
      disabled={totalCount === 0}
      space={{ p: 0, mb: 0 }}
      value={bulkSelect}
      onClick={handleToggle}
    />
  ) : (
    <div></div>
  );
};

export default CheckboxLabel;
