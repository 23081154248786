import { ImmerReducer } from "vendor/immer-reducer";
import { arrayMove } from "@dnd-kit/sortable";
import { findSection, findContainer, generateBuilderId } from "./utils";

import {
  STORAGE_SECTION_ID,
  STORAGE_CONTAINER_ID,
  STORAGE_ID,
  EMPTY_SECTION_ID,
  CONTAINERS_LIMIT,
  TABLE_CONTAINER_ID,
} from "./index";

export const initialState = {
  notCompletedId: null,
  sections: [],
  containers: {},
  items: {},
  sectionsProps: {},
  containersProps: {},
  itemsProps: {},
  activeId: null,
  activeStorageId: null,
  selectedContainerId: null,
  hoverElementId: null,
  clonedItems: null,
  clonedContainers: null,
  selectedContainerMode: null,
};

export class DndBuilderReducer extends ImmerReducer {
  reset(state) {
    this.draftState = state;
  }

  setNotCompletedId(notCompletedId) {
    this.draftState.notCompletedId = notCompletedId;
  }

  setSections(sections) {
    this.draftState.sections = sections;
  }

  setContainers(containers) {
    this.draftState.containers = containers;
  }

  setItems(items) {
    this.draftState.items = items;
  }

  setActiveId(activeId) {
    this.draftState.activeId = activeId;
  }

  setActiveStorageId(activeStorageId) {
    this.draftState.activeStorageId = activeStorageId;
  }

  setSelectedContainerId({ containerId, mode = null }) {
    this.draftState.selectedContainerId = containerId;
    this.draftState.selectedContainerMode = mode;
  }

  toggleSelectedContainerId(selectedContainerId) {
    if (this.draftState.selectedContainerId === selectedContainerId) {
      this.setSelectedContainerId({ containerId: null });
    } else {
      this.setSelectedContainerId({ containerId: selectedContainerId });
    }
  }

  setClonedItems(clonedItems) {
    this.draftState.clonedItems = clonedItems;
  }

  setClonedContainers(clonedContainers) {
    this.draftState.clonedContainers = clonedContainers;
  }

  cleanTempIds() {
    if (this.draftState.activeId === this.draftState.selectedContainerId) {
      this.setSelectedContainerId({ containerId: this.draftState.activeId });
    } else {
      this.setSelectedContainerId({ containerId: null });
    }

    this.setActiveId(null);
    this.setActiveStorageId(null);
    this.setNotCompletedId(null);
  }

  cleanTempData() {
    this.cleanTempIds();
    this.setClonedItems(null);
    this.setClonedContainers(null);
  }

  dragStart({ active }) {
    const { items, containers } = this.draftState;

    const container = findContainer(items, active.id);

    if (container === STORAGE_CONTAINER_ID) {
      this.setActiveStorageId(active.id);
    }

    this.setSelectedContainerId({ containerId: active.id });
    this.setActiveId(active.id);
    this.setClonedItems(items);
    this.setClonedContainers(containers);
  }

  dragEndSections({ active, over }) {
    const { sections } = this.draftState;

    const activeIndex = sections.indexOf(active.id);
    const overIndex = sections.indexOf(over.id);
    const newSections = arrayMove(sections, activeIndex, overIndex);

    this.cleanTempIds();
    this.setSections(newSections);
  }

  replaceTempContainerId({ active }) {
    const { items, containers } = this.draftState;

    if (active.id === TABLE_CONTAINER_ID) {
      const activeSection = findSection(containers, active.id);
      const overSection = STORAGE_SECTION_ID;
      const newContainerId = generateBuilderId();

      const newContainers = replaceItems({
        active,
        over: { id: newContainerId },
        items: containers,
        activeContainer: activeSection,
        overContainer: overSection,
      });

      const newItems = { ...items, [newContainerId]: [] };

      this.setItems(newItems);
      this.setContainers(newContainers);
      this.updateContainerProps({ id: newContainerId, props: { type: TABLE_CONTAINER_ID } });
      this.cleanTempIds();

      return;
    }
  }

  dragEndContainers({ active, over }) {
    const { containers } = this.draftState;

    const activeContainer = findSection(containers, active.id);
    const overContainer = findSection(containers, over.id);

    if (!activeContainer) {
      this.cleanTempIds();
      return;
    }

    if (over?.id === STORAGE_ID) {
      this.removeContainer({ containerId: active.id });
      return;
    }

    if (!overContainer) {
      this.cleanTempIds();
      return;
    }

    const newContainers = arrayMoveElements({
      items: containers,
      active,
      over,
      activeContainer,
      overContainer,
    });

    newContainers && this.setContainers(newContainers);
    this.cleanTempIds();
  }

  dragEndItems({ active, over }) {
    const { items } = this.draftState;

    const activeContainer = findContainer(items, active.id);

    if (over?.id === EMPTY_SECTION_ID) {
      this.replaceItemToNewSection({ active, newIndex: 0 });
      return;
    }

    if (!activeContainer) {
      this.cleanTempIds();
      return;
    }

    if (over?.id === STORAGE_ID) {
      this.removeItem({ itemId: active.id });
      return;
    }

    const overContainer = findContainer(items, over?.id);

    if (!overContainer) {
      this.cleanTempIds();
      return;
    }

    const newItems = arrayMoveElements({
      items,
      active,
      over,
      activeContainer,
      overContainer,
    });

    newItems && this.setItems(newItems);
    this.cleanTempIds();
  }

  dragOverContainers({ active, over }) {
    const { containers } = this.draftState;

    if (over.id === EMPTY_SECTION_ID) {
      return this.replaceContainerToNewSection({ active, newIndex: 0 });
    }

    const overContainer = findSection(containers, over?.id);
    const activeContainer = findSection(containers, active.id);

    if (!overContainer || !activeContainer) return;
    if (activeContainer === overContainer) return;

    const { overContainerItems, activeContainerItems } = replaceElements({
      active,
      over,
      items: containers,
      activeContainer,
      overContainer,
    });

    this.setContainers({
      ...containers,
      [activeContainer]: activeContainerItems,
      [overContainer]: overContainerItems,
    });
  }

  // dragOverSections({ active, over }) {
  //   const { sections, containers } = this.draftState;

  //   if (active.id in containers !== true) return;
  //   if (over?.id in containers !== true) return;

  //   const activeIndex = sections.indexOf(active.id);
  //   const overIndex = sections.indexOf(over.id);

  //   const newSections = arrayMove(sections, activeIndex, overIndex);
  //   this.setSections(newSections);
  // }

  dragOverItems({ active, over }) {
    const { items, selectedContainerId } = this.draftState;

    if (over.id === EMPTY_SECTION_ID) {
      return this.replaceItemToNewSection({ active, newIndex: 0 });
    }

    const overContainer = findContainer(items, over?.id);
    const activeContainer = findContainer(items, active.id);

    if (selectedContainerId !== overContainer) {
      this.setSelectedContainerId({ containerId: null });
    }

    if (!overContainer || !activeContainer) return;
    if (activeContainer === overContainer) return;

    const { overContainerItems, activeContainerItems } = replaceElements({
      active,
      over,
      items,
      activeContainer,
      overContainer,
    });

    this.setItems({
      ...items,
      [activeContainer]: activeContainerItems,
      [overContainer]: overContainerItems,
    });
  }

  cleanEmptyEntities() {
    const { items, containers, sections, containersProps } = this.draftState;

    const newItems = { ...items };
    const newContainers = {};
    const emptySections = [];

    Object.entries(containers).forEach(([sectionId, sectionContainers]) => {
      if (sectionId === STORAGE_SECTION_ID) {
        newContainers[sectionId] = sectionContainers;
        return;
      }

      const filteredContainers = sectionContainers.filter((containerId) => {
        const containerType = containersProps[containerId]?.type;

        if ([STORAGE_CONTAINER_ID, TABLE_CONTAINER_ID].includes(containerType)) {
          return true;
        }

        if (items[containerId] && items[containerId].length === 0) {
          delete newItems[containerId];
          return false;
        }

        return true;
      });

      if (filteredContainers.length > 0) {
        newContainers[sectionId] = filteredContainers;
      } else if (sectionId !== STORAGE_SECTION_ID) {
        emptySections.push(sectionId);
      }
    });

    const newSections = sections.filter((sectionId) => !emptySections.includes(sectionId));

    this.setSections(newSections);
    this.setContainers(newContainers);
    this.setItems(newItems);
  }

  replaceItemToNewSection({ active, newIndex }) {
    const { sections, items, containers } = this.draftState;

    const systemSections = sections.filter((id) => id !== STORAGE_SECTION_ID);

    const newContainerId = generateBuilderId();
    const newSectionId = generateBuilderId();
    const activeContainer = findContainer(items, active.id);

    const nextItems = {
      ...items,
      [activeContainer]: items[activeContainer]?.filter((id) => id !== active.id) || [],
      [newContainerId]: [active.id],
    };

    const nextContainers = {
      ...containers,
      [newSectionId]: [newContainerId],
    };

    const nextSections = [
      STORAGE_SECTION_ID,
      ...systemSections.slice(0, newIndex - 1),
      newSectionId,
      ...systemSections.slice(newIndex - 1, systemSections.length),
    ];

    this.setNotCompletedId(newSectionId);
    this.setItems(nextItems);
    this.setContainers(nextContainers);
    this.setSections(nextSections);
  }

  replaceContainerToNewSection({ active, newIndex }) {
    const { sections, containers } = this.draftState;

    const systemSections = sections.filter((id) => id !== STORAGE_SECTION_ID);

    const newSectionId = generateBuilderId();
    const activeSection = findSection(containers, active.id);

    const nextContainers = {
      ...containers,
      [activeSection]: containers[activeSection]?.filter((id) => id !== active.id) || [],
      [newSectionId]: [active.id],
    };

    const nextSections = [
      STORAGE_SECTION_ID,
      ...systemSections.slice(0, newIndex - 1),
      newSectionId,
      ...systemSections.slice(newIndex - 1, systemSections.length),
    ];

    this.setNotCompletedId(newSectionId);
    this.setContainers(nextContainers);
    this.setSections(nextSections);
  }

  addContainer({ sectionId }) {
    const { containers, items } = this.draftState;

    if (containers[sectionId].length === CONTAINERS_LIMIT) return;

    const newContainerId = generateBuilderId();

    const nextContainers = {
      ...containers,
      [sectionId]: [...containers[sectionId], newContainerId],
    };

    const newItems = { ...items, [newContainerId]: [] };

    this.setContainers(nextContainers);
    this.setItems(newItems);
  }

  setNewContainer({ active, sectionId, newIndex }) {
    const { items, containers } = this.draftState;

    const newContainerId = generateBuilderId();
    const activeContainer = findContainer(items, active.id);

    const nextItems = {
      ...items,
      [activeContainer]: items[activeContainer].filter((id) => id !== active.id),
      [newContainerId]: [active.id],
    };

    const overContainerItems = [
      ...containers[sectionId].slice(0, newIndex),
      newContainerId,
      ...containers[sectionId].slice(newIndex, containers[sectionId].length),
    ];

    const nextContainers = {
      ...containers,
      [sectionId]: overContainerItems,
    };

    this.setNotCompletedId(newContainerId);
    this.setItems(nextItems);
    this.setContainers(nextContainers);
  }

  removeSection({ sectionId }) {
    const { sections, containers, items } = this.draftState;

    const newSections = sections.filter((id) => id !== sectionId);

    const newContainers = Object.assign({}, containers);
    delete newContainers[sectionId];

    const containersToRemove = containers[sectionId];

    const newItems = Object.assign({}, items);

    containersToRemove.forEach((containerId) => {
      delete newItems[containerId];
      newItems[STORAGE_CONTAINER_ID] = [...newItems[STORAGE_CONTAINER_ID], ...items[containerId]];
    });

    this.setSections(newSections);
    this.setContainers(newContainers);
    this.setItems(newItems);
    this.cleanTempIds();
  }

  removeContainer({ containerId, clean = true }) {
    const { containers, items } = this.draftState;

    const sectionId = findSection(containers, containerId);

    const newContainers = {
      ...containers,
      [sectionId]: containers[sectionId].filter((id) => id !== containerId),
    };

    const newItems = {
      ...items,
      [containerId]: [],
      [STORAGE_CONTAINER_ID]: [...items[STORAGE_CONTAINER_ID], ...items[containerId]],
    };

    this.setContainers(newContainers);
    this.setItems(newItems);

    if (clean) {
      this.cleanTempIds();
      this.cleanEmptyEntities();
    }
  }

  removeItem({ itemId, clean = true }) {
    const { items } = this.draftState;

    const containerId = findContainer(items, itemId);

    if (!containerId) return;

    const newItems = {
      ...items,
      [containerId]: items[containerId].filter((id) => id !== itemId),
      [STORAGE_CONTAINER_ID]: [...items[STORAGE_CONTAINER_ID], itemId],
    };

    this.setItems(newItems);

    if (clean) {
      this.cleanTempIds();
      this.cleanEmptyEntities();
    }
  }

  addItem({ itemId, containerId }) {
    const { items } = this.draftState;

    const itemContainerId = findContainer(items, itemId);

    const newItems = {
      ...items,
      [containerId]: [...items[containerId], itemId],
      [itemContainerId]: items[itemContainerId].filter((id) => id !== itemId),
    };

    this.setItems(newItems);
  }

  updateSectionProps({ id, props }) {
    this.draftState.sectionsProps[id] = { ...props };
  }

  updateContainerProps({ id, props }) {
    this.draftState.containersProps[id] = { ...props };
  }

  updateItemProps({ id, props }) {
    this.draftState.itemsProps[id] = { ...props };
  }
}

// PRIVATE METHODS

const arrayMoveElements = ({ items, active, over, activeContainer, overContainer }) => {
  const activeIndex = items[activeContainer].indexOf(active.id);
  const overIndex = items[overContainer].indexOf(over.id);

  if (activeIndex !== overIndex) {
    return {
      ...items,
      [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
    };
  }
};

const replaceElements = ({ active, over, items, activeContainer, overContainer }) => {
  const activeItems = items[activeContainer];
  const overItems = items[overContainer];
  const overIndex = overItems.indexOf(over.id);
  const activeIndex = activeItems.indexOf(active.id);

  let newIndex;

  if (over.id in items) {
    newIndex = overItems.length + 1;
  } else {
    const isBelowOverItem =
      over &&
      active.rect.current.translated &&
      active.rect.current.translated.top > over.rect.top + over.rect.height;

    const modifier = isBelowOverItem ? 1 : 0;

    newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
  }

  const overContainerItems = [
    ...items[overContainer].slice(0, newIndex),
    items[activeContainer][activeIndex],
    ...items[overContainer].slice(newIndex, items[overContainer].length),
  ];

  const activeContainerItems = items[activeContainer].filter((item) => item !== active.id);

  return { activeContainerItems, overContainerItems };
};

const replaceItems = ({ active, over, items, activeContainer, overContainer }) => {
  let activeIndex = items[activeContainer]?.indexOf(active.id) ?? 0;
  let overIndex = items[overContainer]?.indexOf(over.id) ?? 0;
  activeIndex = Math.max(activeIndex, 0);
  overIndex = Math.max(overIndex, 0);

  const activeItems = Object.assign([], items[activeContainer], {
    [activeIndex]: over.id,
  });

  const overItems = Object.assign([], items[STORAGE_SECTION_ID], {
    [overIndex]: active.id,
  });

  return {
    ...items,
    [activeContainer]: activeItems,
    [overContainer]: overItems,
  };
};
