import React, { useEffect, useRef } from "react";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensors,
  useSensor,
  MeasuringStrategy,
} from "@dnd-kit/core";
import { createActionCreators } from "vendor/immer-reducer";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";

import {
  useDndBuilderContext,
  CONTAINER_SIDE_PLACEHOLDER_ID,
  SECTION_SIDE_PLACEHOLDER_ID,
  TRASH_ID,
  CONTAINERS_LIMIT,
  EMPTY_SECTION_ID,
} from "./index";

import { findSection, findContainer } from "./utils";
import { DndBuilderReducer } from "./DndBuilderReducer";
import { DndItemsOverlay } from "./DndItemsOverlay";
import { multipleContainersCoordinateGetter } from "./dndKitUtils/multipleContainersKeyboardCoordinates";
import { useCollisionDetectionStrategy } from "./dndKitUtils/useCollisionDetectionStrategy";

const dndAction = createActionCreators(DndBuilderReducer);

const DELAY_CREATE_CONTAINER = 600;
const DELAY_CREATE_SECTION = 600;
const modifiers = [restrictToWindowEdges];

const activationConstraint = {
  delay: 100,
  tolerance: 5,
};

export const DndKitContext = ({ children }) => {
  const {
    cancelDrop,
    cleanEmptyEntities,
    containers,
    items,
    onDragOver: onDragOverFunc,
    onDragEnd,
    onDragCancel,
    onDragStart,
    activeId,
  } = useDndKitManager();

  const onDragOver = ({ active, over }) => {
    onDragOverFunc({
      active,
      over,
    });

    recentlyMovedToNewContainer.current = true;
  };

  const { collisionDetectionStrategy, recentlyMovedToNewContainer } = useCollisionDetectionStrategy(
    { activeId, items, containers, trashId: TRASH_ID }
  );

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint }),
    useSensor(TouchSensor, { activationConstraint }),
    useSensor(KeyboardSensor, {
      coordinateGetter: multipleContainersCoordinateGetter,
    })
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items, containers]);

  useEffect(() => {
    if (activeId) return;

    const timeOutId = setTimeout(
      () =>
        requestAnimationFrame(() => {
          cleanEmptyEntities();
        }),
      200
    );
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [activeId]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      {children}

      <DndItemsOverlay />
    </DndContext>
  );
};

const useDndKitManager = () => {
  const delayFuncRef = useRef();
  const { state, dispatch, cancelDrop } = useDndBuilderContext();
  const { containers, items, activeId, notCompletedId, clonedItems, clonedContainers } = state;

  const itemsIds = Object.values(items).flat();

  const onDragCancel = () => {
    delayFuncRef.current && clearTimeout(delayFuncRef.current);

    if (clonedItems) {
      // Reset items to their original state in case items have been
      dispatch(dndAction.setItems(clonedItems));
    }

    if (clonedContainers) {
      // Reset containers to their original state in case items have been
      dispatch(dndAction.setContainers(clonedContainers));
    }

    dispatch(dndAction.cleanTempData());
  };

  const onDragStart = ({ active }) => {
    dispatch(dndAction.dragStart({ active }));
  };

  const onDragOver = ({ active, over }) => {
    delayFuncRef.current && clearTimeout(delayFuncRef.current);

    const isItem = itemsIds.includes(active?.id);
    const isContainer = active?.id in items;
    const isSection = active?.id in containers;

    if (!over?.id || over?.id === TRASH_ID) return;

    // Section placeholder
    if (isSectionPlaceholder(over?.id) && !notCompletedId) {
      const [_sectionId, _placeholder, newIndex] = over?.id?.split("-") || [];

      if (!newIndex) return;

      if (isItem) {
        delayFuncRef.current = setTimeout(() => {
          dispatch(dndAction.replaceItemToNewSection({ active, newIndex }));
        }, DELAY_CREATE_SECTION);

        return;
      }

      if (isContainer) {
        delayFuncRef.current = setTimeout(() => {
          dispatch(dndAction.replaceContainerToNewSection({ active, newIndex }));
        }, DELAY_CREATE_SECTION);

        return;
      }
    }

    // Contaner placeholder
    if (isContainerPlaceholder(over?.id) && !notCompletedId) {
      const [containerId, _placeholder, newIndex] = over?.id?.split("-") || [];

      if (!newIndex) return;

      const sectionId = findSection(containers, containerId);

      if (containers[sectionId]?.length === CONTAINERS_LIMIT) return;

      delayFuncRef.current = setTimeout(() => {
        dispatch(dndAction.setNewContainer({ active, sectionId, newIndex }));
      }, DELAY_CREATE_CONTAINER);

      return;
    }

    // Replace containers
    if (isContainer) {
      if (over?.id === EMPTY_SECTION_ID) {
        dispatch(dndAction.dragOverContainers({ active, over }));
        return;
      }

      const sectionId = findSection(containers, over?.id);
      const containerId = findContainer(items, active?.id);

      if (containerId === over?.id) return;
      if (!sectionId || containers[sectionId]?.length === CONTAINERS_LIMIT) return;

      dispatch(dndAction.dragOverContainers({ active, over }));

      return;
    }

    // Replace items
    if (isItem) {
      const activeContainerId = findContainer(items, active?.id);
      const overContainerId = findContainer(items, over?.id);

      if (activeContainerId === overContainerId) return;

      dispatch(dndAction.dragOverItems({ active, over }));
    }
  };

  const onDragEnd = ({ active, over }) => {
    delayFuncRef.current && clearTimeout(delayFuncRef.current);

    // Replace sections
    if (active.id in containers && over?.id) {
      dispatch(dndAction.dragEndSections({ active, over }));
      return;
    }

    // Replace Containers
    if (active.id in items && over?.id) {
      dispatch(dndAction.dragEndContainers({ active, over }));

      // Delay function needs to allow re-register useDraggable element
      delayFuncRef.current = setTimeout(() => {
        dispatch(dndAction.replaceTempContainerId({ active }));
      }, 50);

      return;
    }

    // Replace Items
    dispatch(dndAction.dragEndItems({ active, over }));
  };

  const cleanEmptyEntities = () => {
    dispatch(dndAction.cleanEmptyEntities());
  };

  return {
    cancelDrop,
    cleanEmptyEntities,
    containers,
    items,
    onDragOver,
    onDragEnd,
    onDragCancel,
    onDragStart,
    activeId,
  };
};

const placeholderRegexp = (value) => new RegExp(`-${value}-`);

const isSectionPlaceholder = (id) => id.match(placeholderRegexp(SECTION_SIDE_PLACEHOLDER_ID));
const isContainerPlaceholder = (id) => id.match(placeholderRegexp(CONTAINER_SIDE_PLACEHOLDER_ID));
