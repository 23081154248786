import React, { useState, useEffect } from "react";
import Box from "components/Box";
import DateRangePicker from "components/DatePickers/DateRangePicker";
import Input from "components/Inputs/Input";

const DateRangeInput = ({ value = [null, null], space = {}, size, onChange, ...props }) => {
  const [dates, setDates] = useState(value);
  const startDate = dates[0];
  const endDate = dates[1];

  useEffect(() => {
    if (!startDate || !endDate) return;

    onChange && onChange([startDate.valueOf(), endDate.valueOf()]);
    // eslint-disable-next-line
  }, [startDate, endDate]);

  return (
    <DateRangePicker
      {...props}
      onChange={setDates}
      renderInputs={(startProps, endProps) => (
        <>
          <Input {...startProps} space={{ mb: 0, ...space }} size={size} placeholder="setDate" />
          <Box space={{ mx: 1 }}>and</Box>
          <Input {...endProps} space={{ mb: 0, ...space }} size={size} placeholder="setDate" />
        </>
      )}
    />
  );
};

export default DateRangeInput;
