import { gql } from "@apollo/client";
import { delegate, memoize } from "utils/entities";
import { TableColumn } from "models/fragments/TableColumn";

import * as tableFragments from "./fragments";
import { SubListLink } from "./TablesLinks";
import { Table } from "./Table";

export class TableSubListSelect {
  constructor(origin) {
    this.origin = { ...origin, ...origin.table };
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin, ["columns"]);
  }

  get subLists() {
    return this.memoize(
      "subLists",
      () => this.sublist?.map((entity) => new SubListLink(entity, this.origin)) || []
    );
  }
}

TableSubListSelect.fragment = gql`
  fragment TableSubListSelectFragment on GetFullTablePayload {
    id
    table {
      id
      name
      uid
    }
    sublist {
      ${tableFragments.TABLE_SUB_LIST_LINK}
    }
  }

  ${Table.fragment}
  ${TableColumn.fragment}
`;
