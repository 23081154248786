import "styles/App/ViewsManager.scss";

export const useViewsManagerStyles = () => ({
  root: "AppViewsManager-root",
  header: "AppViewsManager-header",
  footer: "AppViewsManager-footer",
  subHeader: "AppViewsManager-subheader",
  body: "AppViewsManager-body",
  items: "AppViewsManager-items",
  item: "AppViewsManager-item",
  label: "AppViewsManager-label",
});
