import { layout } from "theme/common";

import "styles/App/DashboardLayout.scss";

export const DRAWER_WIDTH = layout.drawerWidth;
export const MIN_DRAWER_WIDTH = layout.minDrawerWidth;
export const HEADER_HEIGHT = layout.headerHeight;
export const FOOTER_PADDING = layout.footerPadding;

export const useLayoutStyles = () => ({
  root: "AppLayoutStyles-root",
  content: "AppLayoutStyles-root__content",
});

export const useSideBarStyles = () => ({
  root: "AppSideBar-root",
  stub: "AppSideBar-stub",
  paper: "AppSideBar-paper",
  topMenuWrap: "AppSideBar-topMenuWrap",
  backLink: "AppSideBar-backLink",
  avatarTitle: "AppSideBar-avatarTitle",
  profileButton: "AppSideBar-profileButton",
});

export const useHeaderStyles = () => ({
  root: "AppHeaderStyles-root",
  toolbar: "AppHeaderStyles-root__toolbar",
  actions: "AppHeaderStyles-root__actions",
});

export const usePageStyles = () => ({
  root: "AppPageStyles-root",
  pageTitle: "AppPageStyles-pageTitle",
  fixedContaier: "AppPageStyles-root__fixed-container",
  container: "AppPageStyles-root__container",
  content: "AppPageContent-root",
});

export const useEmptyPageStyles = () => ({
  root: "AppPageStyles-root--empty",
  container: "AppPageStyles-root--empty__container",
});
