import React from "react";
import isEmpty from "lodash/isEmpty";
import Typography from "components/Typography";
import clsx from "clsx";

import { isSystemElement } from "../../Provider";
import { ConstructorContainer, ConstructorSkeleton } from "../Constructor";
import { Section } from "../Section";
import { Container } from "../Container";
import { ActionsWrap } from "../../Actions";

import { PreviewContainerItems } from "./PreviewContainerItems";

import "styles/App/DndBuilder/Preview.scss";

const usePreviewStyles = () => ({
  root: "AppDndBuilder-Preview-root",
  content: "AppDndBuilder-Preview-content",
  header: "AppDndBuilder-Preview-header",
  footer: "AppDndBuilder-Preview-footer",
});

export const Preview = ({
  title,
  icon,
  loading,
  sectionsProps,
  containersProps,
  actionsProps,
  allItemsMap,
  sections,
  containers,
  items,
  actions = [],
  components,
  isExecutableForm = false,
}) => {
  const classes = usePreviewStyles();

  if (loading) return <ConstructorSkeleton />;

  const renderSections = sections.filter((sectionId) => !isSystemElement(sectionId));

  const renderContainers = (containers) =>
    containers.filter((containerId) => !isSystemElement(containerId));

  const ActionComponent = components.action;

  return (
    <div className={clsx(classes.root, { isExecutableForm })}>
      {title && (
        <div className={classes.header}>
          <Typography leftIcon={icon} variant="h2">
            {title}
          </Typography>
        </div>
      )}

      <div className={classes.content}>
        <ConstructorContainer>
          {renderSections.map((sectionId, index) => (
            <Section
              preview
              key={`${sectionId}-section`}
              id={sectionId}
              index={index + 1}
              {...sectionsProps[sectionId]}
            >
              {renderContainers(containers[sectionId]).map((containerId, index) => (
                <Container
                  preview
                  scrollable
                  key={`${containerId}-container-key`}
                  index={index}
                  id={containerId}
                >
                  <PreviewContainerItems
                    components={components}
                    sectionId={sectionId}
                    containerId={containerId}
                    allItemsMap={allItemsMap}
                    sectionProps={sectionsProps[sectionId]}
                    containerProps={containersProps[containerId]}
                    items={items[containerId]}
                  />
                </Container>
              ))}
            </Section>
          ))}
        </ConstructorContainer>
      </div>

      {!isEmpty(actions) && (
        <div className={classes.footer}>
          <Container preview single selected={false}>
            <ActionsWrap>
              {actions.map((action) => (
                <ActionComponent
                  key={action.id}
                  action={action}
                  actionsProps={actionsProps}
                  space={{ ml: 1, mb: 1 }}
                />
              ))}
            </ActionsWrap>
          </Container>
        </div>
      )}
    </div>
  );
};
