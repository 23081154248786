import { gql } from "@apollo/client";
import { delegate, serialize } from "utils/entities";
import { Content } from "models/fragments/Content";

export class Profile {
  constructor(origin) {
    this.origin = origin;

    delegate(this, { ...origin, ...origin.profile }, ["displayName"]);
    serialize(this, { avatar: Content });
  }

  get displayName() {
    return this.origin.profile.displayName || this.origin.profile.fullName;
  }

  get phone() {
    return this.origin.profile.phoneData.fullNumber;
  }
}

Profile.fragment = gql`
  fragment ProfileFragment on GetProfilePayload {
    id
    createdDate
    lastModifiedDate
    lastLoginDate
    avatar {
      ...ContentFragment
    }
    profile {
      displayName
      firstName
      fullName
      lastName
      email
      phoneData {
        countryId
        digits
        fullNumber
      }
      timezoneId
      dateFormat
      timeFormat
    }
  }

  ${Content.fragment}
`;
