export const USA_STATES = [
  "Alaska",
  "Alabama",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Gregoria",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Utah",
  "Oregon",
  "Ohio",
  "New York",
  "Nevada",
  "Washington",
  "Virginia",
  "Texas",
  "West Virginia",
  "Vermont",
  "Wyoming",
  "South Dakota",
  "Oklahoma",
  "New Jersey",
  "New Mexico",
  "New Hemisphere",
  "Mississippi",
  "Montana",
];
