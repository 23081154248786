import "styles/App/TableFilters.scss";

export const useTableFilters = () => ({
  label: "AppTableFilters-label",
  labelTitle: "AppTableFilters-labelTitle",
});

export const useFilterRuleMenu = () => ({
  paper: "AppFilterRuleMenu-paper",
  list: "AppFilterRuleMenu-list",
});
