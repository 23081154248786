import React, { useState, useRef } from "react";
import { Tab as MuiTab, Tabs as MuiTabs } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import { useTabsStyles, useTabsColorsStyles } from "theme/components/Tab";
import clsx from "clsx";
import IconButton from "components/IconButton";
import { CrossIcon, ErrorInfoIcon } from "icons";
import CollapseButton from "components/Buttons/CollapseButton";
import Popover from "components/Popover";
import { useMenuStyles } from "theme/components/Menu";
import withStyles from "components/withStyles";

const MuiTabComponent = withStyles(({ innerRef, ...props }) => (
  <MuiTab ref={innerRef} {...props} />
));

const MuiTabsComponent = withStyles(({ innerRef, ...props }) => (
  <MuiTabs ref={innerRef} {...props} />
));

export const TabPanel = ({
  children,
  condition = true,
  fullHeight,
  tab,
  component,
  value,
  ...rest
}) => {
  if (tab !== value) return <></>;
  if (!condition) return <></>;

  const TabComponent = component || Box;

  return (
    <TabComponent
      role="tabpanel"
      hidden={tab !== value}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      style={{ ...rest, height: fullHeight ? "100%" : null }}
      {...rest}
    >
      {children}
    </TabComponent>
  );
};

export const Tabs = ({
  variant,
  color,
  scrollable,
  children,
  className,
  compact,
  onChange,
  ...props
}) => {
  const classes = useTabsStyles({ compact });
  const classesColors = useTabsColorsStyles();

  return (
    <MuiTabsComponent
      className={clsx(classes.root, classes[variant], classesColors[color], className)}
      variant={scrollable ? "scrollable" : null}
      onChange={(event, value) => onChange(value)}
      {...props}
    >
      {children}
    </MuiTabsComponent>
  );
};

export const Tab = ({
  leftIcon,
  rightIcon,
  icon: Icon,
  label,
  weight = "title",
  error,
  ...props
}) => {
  return (
    <MuiTabComponent
      {...props}
      label={
        Icon ? (
          <Icon space={{ mx: 1 }} />
        ) : (
          <Typography
            variant="body2"
            weight={weight}
            leftIcon={leftIcon}
            rightIcon={error ? ErrorInfoIcon : rightIcon}
          >
            {label}
          </Typography>
        )
      }
    />
  );
};

export const TabMore = ({ leftIcon, icon: Icon, label, moreMenu: MoreMenuContent, ...props }) => {
  const tabRef = useRef();
  const menuClasses = useMenuStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = () => setAnchorEl(tabRef.current);
  const handleClose = () => setAnchorEl(null);

  const disableParent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const RightIcon = () => (
    <CollapseButton space={{ ml: 1 }} onMouseDown={disableParent} onClick={handleOpen} />
  );

  return (
    <>
      <MuiTabComponent
        {...props}
        ref={tabRef}
        onDoubleClick={handleOpen}
        component="div"
        label={
          Icon ? (
            <Icon space={{ mx: 1 }} />
          ) : (
            <Typography
              component="div"
              variant="body2"
              weight="title"
              leftIcon={leftIcon}
              rightIcon={RightIcon}
            >
              {label}
            </Typography>
          )
        }
      />

      {MoreMenuContent && (
        <Popover
          ContentProps={{ className: menuClasses.list }}
          PaperProps={{ className: menuClasses.paper }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MoreMenuContent />
        </Popover>
      )}
    </>
  );
};

export const CrossTab = ({ leftIcon, onCancel, label, value, ...props }) => {
  const handleRemove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onCancel(value);
  };

  const rightIcon = (props) => (
    <IconButton
      {...props}
      disableRipple
      size="xs"
      iconSize="small"
      variant="ghost"
      icon={CrossIcon}
      onMouseDown={handleRemove}
    />
  );

  return (
    <MuiTabComponent
      {...props}
      value={value}
      label={
        <Typography weight="title" leftIcon={leftIcon} rightIcon={rightIcon}>
          {label}
        </Typography>
      }
    />
  );
};
