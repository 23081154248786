import React from "react";
import pick from "lodash/pick";
import { FormFieldInput } from "containers/FormField/Input";
import { isLookupType } from "models/abstract/BuilderItem";
import { useDebounceAnchor } from "components/Inputs/Select";
import { LookupItemValueSelect } from "containers/Inputs/LookupItemValueSelect";

import { useFormProperties } from "./index";

export const FormActionFieldInput = ({ componentProps, ...props }) => {
  const { formField } = props;
  const { builderItem } = componentProps;
  const { valueColumnsList, setValue } = useFormProperties();

  const tableId = builderItem?.entity?.tableId;
  const columnId = builderItem?.entity?.id;

  const { anchorEl, onOpen, onClose } = useDebounceAnchor();
  const open = Boolean(anchorEl);

  if (tableId && formField.lookup && isLookupType(formField.type)) {
    const handleChange = (value, item) => {
      setValue(formField.id, value);

      const values = pick(item?.valuesMap || {}, valueColumnsList);

      Object.keys(values).forEach((key) => {
        setValue(key, values[key]);
      });
    };

    return (
      <LookupItemValueSelect
        {...props}
        skip={!open}
        onOpen={onOpen}
        onClose={onClose}
        tableId={tableId}
        columnId={columnId}
        space={{ mb: 0 }}
        onChange={handleChange}
      />
    );
  }

  return <FormFieldInput {...props} />;
};
