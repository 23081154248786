import { gql } from "@apollo/client";
import keyBy from "lodash/keyBy";
import { delegate, memoize } from "utils/entities";
import { ID_NAME_PAYLOAD } from "graphql/fragments";
import { TableColumn } from "models/fragments/TableColumn";
import { MemberShort } from "models/Member/MemberShort";
import pick from "lodash/pick";
import JSum from "vendor/jsum";
import { parseOutputFilter } from "hooks/useQuery/usePaginationQuery";
import { User } from "models/fragments/User";
import { TableStructure } from "models/fragments/TableAccess/TableStructure";
import { TABLE_VIEW_MODEL_TYPE } from "models/types";

export class TableView {
  constructor(origin) {
    this.origin = origin;

    delegate(this, origin);

    this.cache = {};
    this.memoize = memoize(this.cache);
    this.filter = parseOutputFilter(this.origin.filter || []);

    this.sharedMembers = this.members.content.map((member) => new MemberShort(member));
    this.tableStructure = new TableStructure(this.origin.table);
  }

  get label() {
    return this.name;
  }

  get modelType() {
    return TABLE_VIEW_MODEL_TYPE;
  }

  get tableDescription() {
    return this.byDefault ? "Default view" : this.description;
  }

  get tableViewId() {
    return this.origin.id;
  }

  get tableName() {
    return this.tableStructure.table?.name;
  }

  get tableSort() {
    return (this.sort || []).map((item) => ({
      value: item.direction.toUpperCase(),
      field: item.property,
    }));
  }

  get tableFilter() {
    return this.filter || [];
  }

  get searchFilter() {
    return this.filter || [];
  }

  get searchSort() {
    return (this.sort || []).map((item) => pick(item, ["property", "direction"]));
  }

  get tableId() {
    return this?.table?.id;
  }

  get viewColumnsChecksum() {
    return this.memoize("viewColumnsChecksum", () =>
      JSum.digest(this.viewColumns, "SHA256", "hex")
    );
  }

  get visibleViewColumns() {
    return this.memoize("visibleViewColumns", () => {
      return this.viewColumns.filter((column) => !column.hidden);
    });
  }

  get viewColumns() {
    return this.memoize("viewColumns", () => {
      return (this.columns || []).map((viewColumn) => {
        const tableColumn = this._tableColumnsMap[viewColumn.id];
        const columnWidth = viewColumn.width ? parseInt(viewColumn.width) : null;

        return {
          id: tableColumn.id,
          label: tableColumn.name,
          name: tableColumn.name,
          type: tableColumn.type,
          subtype: tableColumn.subtype,
          fieldId: tableColumn.fieldId,
          editable: tableColumn.blank,
          formField: tableColumn.formField,
          componentType: tableColumn.formField.componentType,
          lock: viewColumn.lock,
          width: columnWidth,
          hidden: viewColumn ? false : true,
          disabledFilter: !tableColumn.fieldType.isFiltrableType,
        };
      });
    });
  }

  get viewTableColumns() {
    return this.memoize("viewTableColumns", () => {
      return this._tableColumns.filter(
        (tableColumn) => !!this._viewColumnsSettingsMap[tableColumn.id]
      );
    });
  }

  get totalSharedMembersCount() {
    return this.members.totalElements;
  }

  get disableSharing() {
    return this.byDefault;
  }

  // PRIVATE

  get _tableColumns() {
    return this.tableStructure.table.columns || [];
  }

  get _tableColumnsMap() {
    return keyBy(this._tableColumns, "id");
  }

  get _viewColumnsSettingsMap() {
    return this.memoize("_viewColumnsMap", () => keyBy(this.columns || [], "id"));
  }
}

TableView.fragment = gql`
  fragment TableViewFragment on GetTableViewPayload {
    id
    createdDate
    lastModifiedDate
    name
    description
    byDefault
    isShared
    inUse
    project {
      ${ID_NAME_PAYLOAD}
    }
    table {
      ...TableStructureFragment
    }
    columns {
      id
      lock
      width
    }
    filter {
      field
      op
      value
    }
    sort {
      property
      direction
    }
    members {
      totalElements
      content {
        ...MemberShortFragment
      }
    }
    createdBy {
      ...UserFragment
    }
  }

  ${TableStructure.fragment}
  ${TableColumn.fragment}
  ${MemberShort.fragment}
  ${User.fragment}
`;
