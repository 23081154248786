import React, { useState } from "react";
import Box from "components/Box";
import { XCircleIcon } from "icons";
import { useFilterRuleMenu } from "theme/components/TableFilters";
import Typography from "components/Typography";
import Popover from "components/Popover";
import Button from "components/Button";
import SelectNew from "components/Inputs/Select";
import isEmpty from "lodash/isEmpty";
import { isNone } from "utils/detect";
import { humanize } from "utils/string";

import { useFilter, fetchComponentTypeRulesOptions } from "./FilterProvider";

import FilterInput from "./FilterInput";
import { FilterLabel } from "./FilterLabel";

const FilterEditLabel = ({ disabled, filter, onChange, onRemove, index }) => {
  const menuId = `FilterLabel-${filter.columnId}-${index}`;
  const menuClasses = useFilterRuleMenu();

  const { formField, id, label } = filter || {};
  const [operation, setOperation] = useState(filter.operation);
  const [value, setValue] = useState(filter.value);
  const [anchorEl, setAnchorEl] = useState(null);

  const rulesOptions = fetchComponentTypeRulesOptions(formField?.componentType);

  const open = Boolean(anchorEl);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleRevert = () => {
    setOperation(filter.operation);
    setValue(filter.value);
    handleClose();
  };

  const handleApply = (event) => {
    event?.preventDefault();

    onChange(filter.id, { field: filter.columnId, operation, value }, { apply: true });
    handleClose();
  };

  const handleRemove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleClose();
    onRemove && onRemove(filter.id, { apply: true });
  };

  return (
    <>
      <FilterLabel
        disabled={disabled}
        aria-controls={open ? `${menuId}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        rightIcon={(props) => <XCircleIcon {...props} onClick={handleRemove} />}
        onClick={handleOpen}
        filter={filter}
        filterValue={value}
      />

      <Popover
        ContentProps={{ className: menuClasses.list }}
        PaperProps={{ className: menuClasses.paper }}
        id={`${menuId}-menu`}
        aria-labelledby={`${menuId}-button`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleRevert}
      >
        <Box style={{ minWidth: 240 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography space={{ mr: 0.5 }}>{humanize(label)}</Typography>

              <SelectNew
                maxHeight={300}
                variant="ghost"
                name="operation"
                space={{ mb: 0 }}
                value={operation}
                onChange={setOperation}
                placeholder="operation"
                weight="normal"
                size="small"
                options={rulesOptions}
              />
            </Box>
          </Box>

          {operation && (
            <Box space={{ mt: 1 }}>
              <form onSubmit={handleApply}>
                <FilterInput
                  id={id}
                  value={value}
                  operation={operation}
                  formField={formField}
                  onChange={(id, { value }) => setValue(value)}
                />
              </form>
            </Box>
          )}

          <Button
            disabled={isNone(value) || isEmpty(operation)}
            onClick={handleApply}
            space={{ mt: 1 }}
            fullWidth
            size="compact"
            variant="primary"
          >
            Apply
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export const FiltersLabels = ({ disabled, itemProps = {} } = {}) => {
  const { filters, onChange, onRemove } = useFilter();

  if (filters.length === 0) return <></>;

  return filters.map((filter, index) => (
    <Box {...itemProps} key={`filterItem-${filter.columnId}-${filter.value}-${index}`}>
      <FilterEditLabel
        disabled={disabled}
        filter={filter}
        onChange={onChange}
        onRemove={onRemove}
      />
    </Box>
  ));
};

export const FiltersLabelsNavigation = ({ className, disabled }) => {
  const { filters, onClean } = useFilter();

  if (filters.length === 0) return <></>;

  return (
    <Box
      className={className}
      space={{ mt: 1, mb: 1 }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box className="AppFiltersLabelsNavigation-leftPanel" display="flex" alignItems="center">
        <FiltersLabels disabled={disabled} itemProps={{ mr: 1 }} />
      </Box>

      <Box className="AppFiltersLabelsNavigation-rightPanel" display="flex" alignItems="center">
        {filters.length > 0 && (
          <Button space={{ mr: 1 }} onClick={onClean} size="small" variant="text">
            Clear all
          </Button>
        )}
      </Box>
    </Box>
  );
};
