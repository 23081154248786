import React from "react";
import { Menu as MuiMenu, Fade } from "@mui/material";
import clsx from "clsx";
import { useOsContext } from "providers/OsProvider";

const Menu = ({ children, open, ...props }) => {
  const { isNotMacOS } = useOsContext();

  return (
    <MuiMenu
      className={clsx({ "custom-scroll": isNotMacOS })}
      TransitionComponent={Fade}
      open={open}
      {...props}
      TransitionProps={{ ...props.TransitionProps, unmountOnExit: true }}
    >
      {children}
    </MuiMenu>
  );
};

export default Menu;
