import React from "react";
import { Checkbox as MuiCheckbox } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import FormControl, { FormControlLabel } from "components/FormControl";
import Typography from "components/Typography";
import withClasses from "components/withClasses";
import isObject from "lodash/isObject";
import { CheckboxIcon, CheckboxCheckIcon } from "iconsNew";
import clsx from "clsx";

const Checkbox = withClasses(
  ({ innerRef, variantProps, ...props }) => <MuiCheckbox ref={innerRef} {...props} />,
  "MuiCheckbox",
  {}
);

const CheckboxField = ({
  label,
  error,
  invalid,
  isTouched,
  space = {},
  fullWidth = true,
  style,
  readOnly = false,
  onChange,
  value,
  className,
  showError = true,
  ...props
}) => {
  const handleChange = (event) => onChange && onChange(event.target.checked);

  return (
    <FormControl
      style={{ minWidth: "fit-content" }}
      space={{ mb: 3, ...space }}
      fullWidth={fullWidth}
    >
      <FormControlLabel
        space={{ m: 0 }}
        control={
          <Checkbox
            {...props}
            checked={!!value}
            style={style}
            onChange={readOnly ? null : handleChange}
            className={clsx(className, { "Mui-error": !isEmpty(error) })}
            space={{ mr: label ? 1 : 0 }}
            icon={<CheckboxIcon size="large" />}
            checkedIcon={<CheckboxCheckIcon size="large" />}
          />
        }
        label={
          label ? (
            <Typography error={`${!!error}`} variant="body2" component="span">
              {label}
            </Typography>
          ) : (
            <></>
          )
        }
      />

      {showError && error && (
        <Typography space={{ mt: 1 }} error={`${!!error}`} variant="helperText">
          {isObject(error) ? error.message : error}
        </Typography>
      )}
    </FormControl>
  );
};

export default CheckboxField;
