import React from "react";
import { FastTypeInputCell } from "components/Inputs/TableInputs/FastTypeInputCell";
import { TableInputCellContainer } from "components/TablePro/Components/TableInputCellContainer";
import { ComponentTypeInput } from "./ComponentTypeInput";

import {
  STRING_COMPONENT_TYPE,
  TEXT_COMPONENT_TYPE,
  NUMBER_COMPONENT_TYPE,
  EMAIL_COMPONENT_TYPE,
  HYPERLINK_COMPONENT_TYPE,
} from "components/ComponentType/types";

export const isFastTypeInputAvailable = (componentType) => {
  return Object.keys(FAST_TYPE_INPUTS).includes(componentType);
};

export const ComponentTypeInputCell = ({ type, config, originOnChange, ...props }) => {
  const FastTypeInputComponent = FAST_TYPE_INPUTS[type];

  if (FastTypeInputComponent) {
    return (
      <FastTypeInputComponent
        {...props}
        onChange={originOnChange ?? props.onChange}
        config={config}
      />
    );
  }

  return (
    <TableInputCellContainer classes={props.classes} error={props.error}>
      <ComponentTypeInput {...props} type={type} showError={false} variant="ghost" size="small" />
    </TableInputCellContainer>
  );
};

const NumberInput = ({ config, ...props }) => {
  return (
    <FastTypeInputCell
      {...props}
      inputProps={{
        type: config.type,
        step: config.step,
        min: config.minValue,
        max: config.maxValue,
      }}
    />
  );
};

const SingleLineTextInput = ({ config, ...props }) => {
  return (
    <FastTypeInputCell
      {...props}
      inputProps={{
        type: config.type,
        min: config?.minValue,
        max: config?.maxValue,
      }}
    />
  );
};

const FAST_TYPE_INPUTS = {
  [STRING_COMPONENT_TYPE]: SingleLineTextInput,
  [TEXT_COMPONENT_TYPE]: SingleLineTextInput,
  [NUMBER_COMPONENT_TYPE]: NumberInput,
  [EMAIL_COMPONENT_TYPE]: SingleLineTextInput,
  [HYPERLINK_COMPONENT_TYPE]: SingleLineTextInput,
};
