import React from "react";
import Typography from "components/Typography";
import { useFastTypeInputCellStyles } from "theme/components/TablePro";
import clsx from "clsx";

const CellTypography = ({
  children,
  hiddableContent = false,
  className,
  style,
  component,
  variant,
  space,
  error,
}) => {
  const classes = useFastTypeInputCellStyles();

  return (
    <Typography
      error={!!error}
      variant={variant}
      component={component}
      style={style}
      className={clsx(className, classes.typography, { hiddableContent })}
      space={space}
    >
      {children}
    </Typography>
  );
};

export default CellTypography;
