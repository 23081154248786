import React from "react";
import Avatar from "components/Avatar";
import { SvgProxy } from "./SvgIcon";

import { ReactComponent as Arrow } from "./arrow.svg";
import { ReactComponent as Arrow2 } from "./arrow2.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Heart } from "./heart.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as PlusThin } from "./plusThin.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Bell } from "./bell.svg";
import { ReactComponent as List } from "./list.svg";
import { ReactComponent as Arrows } from "./arrows.svg";
import { ReactComponent as Structure } from "./structure.svg";
import { ReactComponent as BoxWide } from "./boxWide.svg";
import { ReactComponent as StatFill } from "./statFill.svg";
import { ReactComponent as LayersWide } from "./layersWide.svg";
import { ReactComponent as Fields } from "./fields.svg";
import { ReactComponent as Tables } from "./tables.svg";
import { ReactComponent as ReturnArrowLeft } from "./returnArrowLeft.svg";
import { ReactComponent as ReturnArrowRight } from "./returnArrowRight.svg";
import { ReactComponent as Tick } from "./tick.svg";
import { ReactComponent as PlusCircle } from "./plusCircle.svg";
import { ReactComponent as Settings } from "./settings.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as Sad } from "./sad.svg";
import { ReactComponent as Checkbox } from "./checkbox.svg";
import { ReactComponent as CheckboxChecked } from "./checkboxChecked.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as EditThin } from "./editThin.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as CrossXs } from "./crossXs.svg";
import { ReactComponent as Folder } from "./folder.svg";
import { ReactComponent as FileCsv } from "./fileCsv.svg";
import { ReactComponent as MoreHorizontal } from "./moreHorizontal.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as SortArrowDown } from "./sortArrowDown.svg";
import { ReactComponent as SortArrowUp } from "./sortArrowUp.svg";
import { ReactComponent as Activity } from "./activity.svg";
import { ReactComponent as Link } from "./link.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as Collapse } from "./collapse.svg";
import { ReactComponent as Notifications } from "./notifications.svg";
import { ReactComponent as Help } from "./help.svg";
import { ReactComponent as Members } from "./members.svg";
import { ReactComponent as Plan } from "./plan.svg";
import { ReactComponent as Channels } from "./channels.svg";
import { ReactComponent as Channel } from "./channel.svg";
import { ReactComponent as Billing } from "./billing.svg";
import { ReactComponent as ImportExport } from "./importExport.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as EyeOff } from "./eyeOff.svg";
import { ReactComponent as Menu } from "./menu.svg";
import { ReactComponent as DragMenu } from "./dragMenu.svg";
import { ReactComponent as Refresh } from "./refresh.svg";
import { ReactComponent as Locked } from "./locked.svg";
import { ReactComponent as Attachment } from "./attachment.svg";
import { ReactComponent as Target } from "./target.svg";
import { ReactComponent as TargetSimple } from "./targetSimple.svg";
import { ReactComponent as StatusInProgress } from "./statusInProgress.svg";
import { ReactComponent as StatusInQueue } from "./statusInQueue.svg";
import { ReactComponent as TableView } from "./tableView.svg";
import { ReactComponent as Maximize } from "./maximize.svg";
import { ReactComponent as Minimize } from "./minimize.svg";
import { ReactComponent as ChainArrow } from "./chainArrow.svg";
import { ReactComponent as Stop } from "./stop.svg";
import { ReactComponent as CustomTemplate } from "./customTemplate.svg";
import { ReactComponent as FolderOpen } from "./folderOpen.svg";
import { ReactComponent as FolderPlus } from "./folderPlus.svg";
import { ReactComponent as TableViewEdit } from "./tableViewEdit.svg";
import { ReactComponent as DownloadCloud } from "./downloadCloud.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as Key } from "./key.svg";
import { ReactComponent as Sort } from "./sort.svg";
import { ReactComponent as PlusCircle2 } from "./plusCircle2.svg";
import { ReactComponent as Sliders } from "./sliders.svg";
import { ReactComponent as LinkSettings } from "./linkSettings.svg";
import { ReactComponent as Table } from "./table.svg";
import { ReactComponent as SubList } from "./subList.svg";
import { ReactComponent as CaretDown } from "./caretDown.svg";
import { ReactComponent as Star } from "./star.svg";
import { ReactComponent as FullScreen } from "./fullScreen.svg";
import { ReactComponent as Box } from "./box.svg";
import { ReactComponent as BoxThin } from "./boxThin.svg";
import { ReactComponent as DoubleArrowsLeft } from "./doubleArrowsLeft.svg";
import { ReactComponent as MessageSmall } from "./messageSmall.svg";
import { ReactComponent as Comment } from "./comment.svg";
import { ReactComponent as Volt } from "./volt.svg";
import { ReactComponent as Clock } from "./clock.svg";
import { ReactComponent as CrossBold } from "./crossBold.svg";
import { ReactComponent as CircleArrowDown } from "./circleArrowDown.svg";
import { ReactComponent as Flag } from "./flag.svg";
import { ReactComponent as Dollar } from "./dollar.svg";
import { ReactComponent as UploadCloud } from "./uploadCloud.svg";
import { ReactComponent as FileUpload } from "./fileUpload.svg";
import { ReactComponent as LabelButton } from "./hyperLink.svg";
import { ReactComponent as HyperLink2 } from "./hyperLink2.svg";
import { ReactComponent as SortLight } from "./sortLight.svg";
import { ReactComponent as Switcher } from "./switcher.svg";
import { ReactComponent as Open } from "./open.svg";
import { ReactComponent as ArrowUpRight } from "./arrowUpRight.svg";
import { ReactComponent as AlignLeft } from "./alignLeft.svg";
import { ReactComponent as Drag } from "./drag.svg";
import { ReactComponent as MappingLink } from "./mappingLink.svg";
import { ReactComponent as Image } from "./image.svg";
import { ReactComponent as Lookup } from "./lookup.svg";
import { ReactComponent as FunctionEl } from "./function.svg";
import { ReactComponent as Divide } from "./divide.svg";
import { ReactComponent as Multiply } from "./multiply.svg";
import { ReactComponent as Formula } from "./formula.svg";
import { ReactComponent as FormulaActive } from "./formulaActive.svg";
import { ReactComponent as FileEmpty } from "./fileEmpty.svg";
import { ReactComponent as FileFolderOpen } from "./fileFolderOpen.svg";
import { ReactComponent as Copy } from "./copy.svg";
import { ReactComponent as ExternalLinkArrow } from "./external-link-arrow.svg";
import { ReactComponent as ArrowDownThin } from "./arrowDownThin.svg";
import { ReactComponent as ChevronsRight } from "./chevronsRight.svg";
import { ReactComponent as ChevronsLeft } from "./chevronsLeft.svg";
import { ReactComponent as Task } from "./task.svg";
import { ReactComponent as TablesFill } from "./tablesFill.svg";
import { ReactComponent as FolderFill } from "./folderFill.svg";
import { ReactComponent as FolderMinus } from "./folderMinus.svg";
import { ReactComponent as BoxFill } from "./boxFill.svg";
import { ReactComponent as PlayCircle } from "./playCircle.svg";
import { ReactComponent as EmptyAction } from "./emptyAction.svg";
import { ReactComponent as Union } from "./union.svg";
import { ReactComponent as XCircle } from "./x-circle.svg";
import { ReactComponent as BuilderLogo } from "./builderLogo.svg";
import { ReactComponent as Layout } from "./layout.svg";
import { ReactComponent as Tag } from "./tag.svg";
import { ReactComponent as Leading } from "./leading.svg";

// Type Icons
import { ReactComponent as TypeNumber } from "./typeNumber.svg";
import { ReactComponent as TypeFolder } from "./typeFolder.svg";
import { ReactComponent as TypeDecimal } from "./typeDecimal.svg";
import { ReactComponent as TypeFormula } from "./typeFormula.svg";
import { ReactComponent as TypeSku } from "./typeSku.svg";
import { ReactComponent as TypeSkuThin } from "./typeSkuThin.svg";
import { ReactComponent as TypeTag } from "./typeTag.svg";
import { ReactComponent as ProfileThin } from "./profileThin.svg";
import { ReactComponent as SlidersThin } from "./slidersThin.svg";
import { ReactComponent as MailThin } from "./mailThin.svg";
import { ReactComponent as AmazonLogo } from "./amazon.svg";
import { ReactComponent as WalmartLogo } from "./walmart.svg";
import { ReactComponent as MappingArrowHor } from "./mappingArrowHor.svg";
import { ReactComponent as Move } from "./move.svg";
import { ReactComponent as TableConnect } from "./tableConnect.svg";

// Arrow 1
export const ArrowDownIcon = SvgProxy(Arrow);
export const ArrowLeftIcon = (props) => <ArrowDownIcon {...props} rotate={90} />;
export const ArrowRightIcon = (props) => <ArrowDownIcon {...props} rotate={270} />;
export const ArrowUpIcon = (props) => <ArrowDownIcon {...props} rotate={180} />;

// Arrow 2
export const ArrowDownIcon2 = SvgProxy(Arrow2);
export const ArrowLeftIcon2 = (props) => <ArrowDownIcon2 {...props} rotate={90} />;
export const ArrowRightIcon2 = (props) => <ArrowDownIcon2 {...props} rotate={270} />;
export const ArrowUpIcon2 = (props) => <ArrowDownIcon2 {...props} rotate={180} />;
export const ArrowUpCircleIcon = (props) => <ArrowUpIcon2 {...props} circle={true} />;
export const ArrowDownCircleIcon = (props) => <ArrowDownIcon2 {...props} circle={true} />;
export const ArrowsIcon = SvgProxy(Arrows);
export const ArrowsRightIcon = ArrowsIcon;
export const ArrowsLeftIcon = (props) => <ArrowsRightIcon {...props} rotate={180} />;

export const LeadingIcon = SvgProxy(Leading);
export const CalendarIcon = SvgProxy(Calendar);
export const PlusIcon = SvgProxy(Plus);
export const SearchIcon = SvgProxy(Search);
export const BellIcon = SvgProxy(Bell);
export const ListIconComponent = SvgProxy(List);
export const ListIcon = (props) => <ListIconComponent {...props} />;
export const ListActiveIcon = (props) => <ListIconComponent {...props} />;
export const StructureIcon = SvgProxy(Structure);
export const MinusIcon = SvgProxy(Minus);
export const FieldsIcon = SvgProxy(Fields);
export const ReturnArrowLeftIcon = SvgProxy(ReturnArrowLeft);
export const ReturnArrowRightIcon = SvgProxy(ReturnArrowRight);
export const TickIcon = SvgProxy(Tick);
export const PlusCircleIcon = SvgProxy(PlusCircle);
export const LogoutIcon = SvgProxy(Logout);
export const SadIcon = SvgProxy(Sad);
export const CheckboxIcon = SvgProxy(Checkbox);
export const CheckboxCheckedIcon = SvgProxy(CheckboxChecked);
export const EditIcon = SvgProxy(Edit);
export const XCircleIcon = SvgProxy(XCircle);
export const CrossIcon = SvgProxy(Cross);
export const CrossXsIcon = SvgProxy(CrossXs);
export const FolderIcon = SvgProxy(Folder);
export const CsvIcon = SvgProxy(FileCsv);
export const InfoIcon = SvgProxy(Info);
export const EditThinIcon = SvgProxy(EditThin);
export const ActivityIcon = SvgProxy(Activity);
export const LinkIcon = SvgProxy(Link);
export const NotificationsIcon = SvgProxy(Notifications);
export const HelpIcon = SvgProxy(Help);
export const PlanIcon = SvgProxy(Plan);
export const ChannelsIcon = SvgProxy(Channels);
export const BillingIcon = SvgProxy(Billing);
export const EyeIcon = SvgProxy(Eye);
export const EyeOffIcon = SvgProxy(EyeOff);
export const MenuIcon = SvgProxy(Menu);
export const RefreshIcon = SvgProxy(Refresh);
export const LockedIcon = SvgProxy(Locked);
export const AttachmentIcon = SvgProxy(Attachment);
export const TargetIcon = SvgProxy(Target);
export const TargetSimpleIcon = SvgProxy(TargetSimple);
export const StatusInProgressIcon = SvgProxy(StatusInProgress);
export const MaximizeIcon = SvgProxy(Maximize);
export const MinimizeIcon = SvgProxy(Minimize);
export const ChainArrowIcon = SvgProxy(ChainArrow);
export const StopIcon = SvgProxy(Stop);
export const TableViewEditIcon = SvgProxy(TableViewEdit);
export const DownloadIcon = SvgProxy(Download);
export const KeyIcon = SvgProxy(Key);
export const SortIcon = SvgProxy(Sort);
export const PlusCircleIcon2 = SvgProxy(PlusCircle2);
export const SlidersIcon = SvgProxy(Sliders);
export const LinkSettingsIcon = SvgProxy(LinkSettings);
export const TableIcon = SvgProxy(Table);
export const BoxIcon = SvgProxy(Box);
export const BoxThinIcon = SvgProxy(BoxThin);
export const DoubleArrowsLeftIcon = SvgProxy(DoubleArrowsLeft);
export const MessageSmallIcon = SvgProxy(MessageSmall);
export const CommentIcon = SvgProxy(Comment);
export const VoltIcon = SvgProxy(Volt);
export const ClockIcon = SvgProxy(Clock);
export const CrossBoldIcon = SvgProxy(CrossBold);
export const CircleArrowDownIcon = SvgProxy(CircleArrowDown);
export const FlagIcon = SvgProxy(Flag);
export const DollarIcon = SvgProxy(Dollar);
export const UploadCloudIcon = SvgProxy(UploadCloud);
export const FileUploadIconDefault = SvgProxy(FileUpload);
export const HyperLinkIcon = SvgProxy(LabelButton);
export const HyperLink2Icon = SvgProxy(HyperLink2);
export const SortLightIcon = SvgProxy(SortLight);
export const SwitcherIcon = SvgProxy(Switcher);
export const OpenIcon = SvgProxy(Open);
export const ArrowUpRightIcon = SvgProxy(ArrowUpRight);
export const AlignLeftIcon = SvgProxy(AlignLeft);
export const LookupIcon = SvgProxy(Lookup);
export const FunctionIcon = SvgProxy(FunctionEl);
export const DivideIcon = SvgProxy(Divide);
export const MultiplyIcon = SvgProxy(Multiply);
export const FileEmptyIcon = SvgProxy(FileEmpty);
export const FileFolderOpenIcon = SvgProxy(FileFolderOpen);
export const FolderPlusIcon = SvgProxy(FolderPlus);
export const CopyIcon = SvgProxy(Copy);
export const ExternalLinkArrowIcon = SvgProxy(ExternalLinkArrow);
export const ArrowDownThinIcon = SvgProxy(ArrowDownThin);
export const ChevronsLeftIcon = SvgProxy(ChevronsLeft);
export const EmptyActionIcon = SvgProxy(EmptyAction);
export const FolderMinusIcon = SvgProxy(FolderMinus);
export const TableConnectIcon = SvgProxy(TableConnect);
export const UnionIcon = (props) => SvgProxy(Union)({ ...props, property: "fill" });
export const BuilderLogoIcon = (props) => SvgProxy(BuilderLogo)({ ...props, property: "fill" });
export const LayoutIcon = SvgProxy(Layout);
export const TagIcon = SvgProxy(Tag);

// Type icons
export const TypeNumberIcon = SvgProxy(TypeNumber);
export const TypeFolderIcon = SvgProxy(TypeFolder);
export const TypeDecimalIcon = SvgProxy(TypeDecimal);
export const TypeFormulaIcon = SvgProxy(TypeFormula);
export const TypeTagIcon = SvgProxy(TypeTag);
export const TypeTextIcon = SvgProxy(AlignLeft);
export const TypeImageIcon = SvgProxy(Image);
export const TypeSkuThinIcon = SvgProxy(TypeSkuThin);

// Fill Icons

export const PlusThinIcon = (props) => SvgProxy(PlusThin)({ ...props, property: "fill" });
export const AmazonLogoIcon = (props) => SvgProxy(AmazonLogo)({ ...props, property: "fill" });
export const WalmartLogoIcon = (props) => SvgProxy(WalmartLogo)({ ...props, property: "fill" });
export const ChannelIcon = (props) => SvgProxy(Channel)({ ...props, property: "fill" });
export const HeartIcon = (props) => SvgProxy(Heart)({ ...props, property: "fill" });
export const StatFillIcon = (props) => SvgProxy(StatFill)({ ...props, property: "fill" });
export const BoxWideIcon = (props) => SvgProxy(BoxWide)({ ...props, property: "fill" });
export const TablesIcon = (props) => SvgProxy(Tables)({ ...props, property: "fill" });
export const TrashIcon = (props) => SvgProxy(Trash)({ ...props, property: "fill" });
export const SortArrowDownIcon = (props) => SvgProxy(SortArrowDown)({ ...props, property: "fill" });
export const SortArrowUpIcon = (props) => SvgProxy(SortArrowUp)({ ...props, property: "fill" });
export const FilterIcon = (props) => SvgProxy(Filter)({ ...props, property: "fill" });
export const CollapseIcon = (props) => SvgProxy(Collapse)({ ...props, property: "fill" });
export const MembersIcon = (props) => SvgProxy(Members)({ ...props, property: "fill" });
export const ImportExportIcon = (props) => SvgProxy(ImportExport)({ ...props, property: "fill" });
export const DragMenuIcon = (props) => SvgProxy(DragMenu)({ ...props, property: "fill" });
export const StatusInQueueIcon = (props) => SvgProxy(StatusInQueue)({ ...props, property: "fill" });
export const TableViewIcon = (props) => SvgProxy(TableView)({ ...props, property: "fill" });
export const FolderOpenIcon = (props) => SvgProxy(FolderOpen)({ ...props, property: "fill" });
export const DownloadCloudIcon = (props) => SvgProxy(DownloadCloud)({ ...props, property: "fill" });
export const SubListIcon = (props) => SvgProxy(SubList)({ ...props, property: "fill" });
export const MappingArrowHorIcon = (props) =>
  SvgProxy(MappingArrowHor)({ ...props, property: "fill" });
export const MoveIcon = (props) => SvgProxy(Move)({ ...props, property: "fill" });

export const CaretDownIcon = (props) =>
  SvgProxy(CaretDown)({
    ...props,
    size: "initial",
    property: "fill",
    style: {
      paddingLeft: 6,
      paddingRight: 6,
      paddingTop: 7,
      paddingBottom: 7,
    },
  });

export const CaretDownSmallIcon = (props) => (
  <CaretDownIcon
    {...props}
    style={{ ...props, paddingLeft: 6, paddingRight: 6, paddingTop: 7, paddingBottom: 7 }}
  />
);

export const StarIcon = (props) => SvgProxy(Star)({ ...props, property: "fill" });
export const ActiveStarIcon = (props) =>
  SvgProxy(Star)({ ...props, property: "fill", className: "AppStarIcon-root--activeIcon" });
export const FullScreenIcon = (props) => SvgProxy(FullScreen)({ ...props, property: "fill" });
export const DragIcon = (props) => SvgProxy(Drag)({ ...props, property: "fill" });
export const MappingLinkIcon = (props) => SvgProxy(MappingLink)({ ...props, property: "fill" });
export const FormulaIcon = (props) => SvgProxy(Formula)({ ...props, property: "fill" });
export const FormulaActiveIcon = (props) => SvgProxy(FormulaActive)({ ...props, property: "fill" });
export const ChevronsRightIcon = (props) => SvgProxy(ChevronsRight)({ ...props, property: "fill" });
export const TaskIcon = (props) => SvgProxy(Task)({ ...props, property: "fill" });
export const TablesFillIcon = (props) => SvgProxy(TablesFill)({ ...props, property: "fill" });
export const FolderFillIcon = (props) => SvgProxy(FolderFill)({ ...props, property: "fill" });
export const BoxFillIcon = (props) => SvgProxy(BoxFill)({ ...props, property: "fill" });
export const PlayCircleIcon = (props) => SvgProxy(PlayCircle)({ ...props, property: "fill" });
export const TypeSkuIcon = (props) => SvgProxy(TypeSku)({ ...props, property: "fill" });
export const SettingsIcon = (props) => SvgProxy(Settings)({ ...props, property: "fill" });
export const LayersWideIcon = (props) => SvgProxy(LayersWide)({ ...props, property: "fill" });
export const ProfileThinIcon = (props) => SvgProxy(ProfileThin)({ ...props, property: "fill" });
export const SlidersThinIcon = (props) => SvgProxy(SlidersThin)({ ...props, property: "fill" });
export const MailThinIcon = (props) => SvgProxy(MailThin)({ ...props, property: "fill" });

export const MoreHorizontalIcon = (props) =>
  SvgProxy(MoreHorizontal)({ ...props, property: "fill" });

export const CollapseBackIcon = (props) =>
  SvgProxy(Collapse)({ ...props, rotate: 180, property: "fill" });

export const CustomTemplateIcon = (props) =>
  SvgProxy(CustomTemplate)({ ...props, property: "fill" });

// Custom Icons

export const FileUploadIcon = (props) => <FileUploadIconDefault {...props} />;

export const ImportIcon = ({ size, style, ...props }) => (
  <Avatar
    {...props}
    style={size === "xs" ? { width: 25, height: 25, ...style } : style}
    size={size}
    form="round"
    variant="greySquare"
  >
    <ArrowUpIcon2 />
  </Avatar>
);

export const ImportFromChannelIcon = ({ size, style, ...props }) => (
  <Avatar
    {...props}
    style={size === "xs" ? { width: 25, height: 25, ...style } : style}
    size={size}
    form="round"
    variant="greySquare"
  >
    <RefreshIcon />
  </Avatar>
);

export const ExportIcon = ({ size, style, ...props }) => (
  <Avatar
    {...props}
    style={size === "xs" ? { width: 25, height: 25, ...style } : style}
    size={size}
    form="round"
    variant="greySquare"
  >
    <ArrowDownIcon2 />
  </Avatar>
);

export const ActionBlackIcon = (props) => <VoltIcon {...props} />;

export const ErrorInfoIcon = (props) => <InfoIcon {...props} color="error" />;
