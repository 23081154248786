import React from "react";
import Typography from "components/Typography";
import { FileIcon } from "iconsNew";
import { bytesToSize } from "utils/file";
import LabelButton from "components/LabelButton";

const FileLabelComponent = ({ name, fileSize, space, style, spanStyle, ...props }) => (
  <LabelButton externalLinkIcon style={style} color="grey" leftIcon={FileIcon} {...props}>
    <span style={spanStyle} className="label-ellipses">
      <Typography component="span" textOverflow="ellipsis" weight="bold" mb={0.5}>
        {name}
      </Typography>

      {fileSize && (
        <Typography space={{ ml: 1, ...space }} color="grey" component="span" variant="body2">
          {`${bytesToSize(fileSize)}`}
        </Typography>
      )}
    </span>
  </LabelButton>
);

const FileLabel = ({ label, value, ...props }) => {
  return (
    <FileLabelComponent
      component="a"
      href={value?.url}
      target="_blank"
      name={label || value?.label || value?.name || value?.filename}
      fileSize={value?.size}
      {...props}
    />
  );
};

export default FileLabel;
