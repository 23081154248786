import { gql } from "@apollo/client";

import { Item, ITEM_FRAGMENT } from "./Item";
import { FullItem } from "./FullItem";

export class ListItem extends Item {
  constructor(origin, { listStructure, tableLinkId }) {
    super(origin);

    this.listStructure = listStructure;
    this.tableLinkId = tableLinkId;
  }

  get tableViewId() {
    return this.listStructure.tableViewId;
  }

  get tableStructure() {
    return this.listStructure.tableStructure;
  }
}

export class FullListItem extends FullItem {
  constructor(origin, { listStructure, tableLinkId }) {
    super(origin);

    this.listStructure = listStructure;
    this.tableLinkId = tableLinkId;
  }

  get tableViewId() {
    return this.listStructure.tableViewId;
  }

  get tableStructure() {
    return this.listStructure.tableStructure;
  }
}

export class ListItemByTableColumn extends ListItem {}

ListItemByTableColumn.fragment = gql`
  fragment ListItemByTableColumnFragment on GetItemByTableColumnPayload {
    ${ITEM_FRAGMENT}
  }
`;
