import { defaultBoxShadow } from "theme/common";
import palette from "theme/palette";

export const draggableItemStyle = (isDragging) => ({
  ...(isDragging
    ? {
        display: "flex",
        alignItems: "center",
        borderColor: palette.purple[60],
        boxShadow: defaultBoxShadow(),
        background: palette.background.default,
        cursor: "grabbing",
      }
    : {
        cursor: "grab",
      }),
});

export const draggableCursorStyle = (isDragging) => ({
  ...(isDragging
    ? {
        cursor: "grabbing",
      }
    : {
        cursor: "grab",
      }),
});
