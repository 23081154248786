import React from "react";
import { useParams, generatePath } from "react-router-dom";
import Divider from "components/Divider";
import Box from "components/Box";
import clsx from "clsx";
import {
  StatFillIcon,
  TaskIcon,
  StarIcon,
  ActiveStarIcon,
  BoxWideIcon,
  SettingsIcon,
  SearchIcon,
} from "icons";
import { FormIcon } from "iconsNew";
import NavigationItem from "./NavigationItem";
import InputDelay from "components/Inputs/InputDelay";
import { useWorkspace } from "contexts/Workspace";
import { useSideNavigationListStyles } from "theme/components/SideNavigation";
import { useGetTableView } from "models/TableView/queries";
import { useSearchFormLayouts } from "models/FormLayout/queries";
import { useSearchNavigationTables } from "models/NavigationTable/queries";
import { useGlobalSearch } from "containers/GlobalSearch/Provider";
import * as paths from "routes/path";

export const MainSection = ({ toggleSection }) => {
  const params = useParams();
  const { companyId, projectId } = useWorkspace();
  const classes = useSideNavigationListStyles();
  const { executeFormId, setExecuteFormId } = useGlobalSearch();

  const { navigationTables, sortByFavorite } = useSearchNavigationTables({
    searchInput: { namespace: { companyId, projectId } },
  });

  const { formLayouts } = useSearchFormLayouts({
    searchInput: { namespace: { companyId, projectId } },
  });

  const { tableView: currentTableView } = useGetTableView(params.tableViewId);

  const listPath = (tableId) => {
    return generatePath(paths.PROJECT_LIST, { ...params, projectId, tableId });
  };

  const listsItems = sortByFavorite(navigationTables).map((navigationTable) => ({
    title: navigationTable.name,
    to: listPath(navigationTable.id),
    icon: navigationTable.isFavorite ? ActiveStarIcon : StarIcon,
    meta: {
      tableId: navigationTable.id,
    },
  }));

  const formLayoutsItems = formLayouts.map((formLayout) => ({
    title: formLayout.name,
    onClick: () => setExecuteFormId(formLayout.id),
    button: true,
    active: executeFormId === formLayout.id,
    meta: {
      formLayoutId: formLayout.id,
    },
  }));

  const isActiveNestedTableViewSection = (section) => {
    return (
      section.meta.tableId === params.tableId ||
      section.meta.tableId === currentTableView?.table?.id
    );
  };

  return (
    <>
      <Box className={clsx(classes.searchInput, "top")}>
        <SearchIcon size="small" space={{ mr: 0 }} />

        <div className={classes.cornerIcon}>
          <InputDelay
            space={{ mb: 0 }}
            name="search"
            variant="ghost"
            size="small"
            fullWidth
            placeholder="search"
            onChange={() => null}
          />
        </div>
      </Box>

      <NavigationItem
        className="top first"
        title="Launchpad"
        icon={StatFillIcon}
        to={`/${companyId}/${projectId}`}
      />

      <Divider space={{ my: 1, mx: 1 }} variant="light" />

      <NavigationItem
        title="Lists"
        icon={BoxWideIcon}
        nested={listsItems}
        isActiveSection={isActiveNestedTableViewSection}
      />

      <Divider space={{ my: 1, mx: 1 }} variant="light" />

      <NavigationItem title="Tasks" to={`/${companyId}/${projectId}/tasks`} icon={TaskIcon} />

      <NavigationItem title="Settings" icon={SettingsIcon} onClick={toggleSection("SETTINGS")} />

      <NavigationItem title="Forms" icon={FormIcon} nested={formLayoutsItems} />
    </>
  );
};
