import React from "react";
import { FormControl as MuiFormControl } from "@mui/material";
import { FormControlLabel as MuiFormControlLabel } from "@mui/material";
import Typography from "components/Typography";
import isObject from "lodash/isObject";
import withStyles from "components/withStyles";

const FormControl = ({ children, error, showError = true, hint, ...rest }) => {
  const { labelSpace, labelHide, innerRef, ...props } = rest;

  return (
    <MuiFormControl ref={innerRef} {...props}>
      {children}

      <HintMessage error={error} showError={showError} hint={hint} />
    </MuiFormControl>
  );
};

export const FormControlLabel = withStyles(({ innerRef, ...props }) => (
  <MuiFormControlLabel ref={innerRef} {...props} />
));

export const HintMessage = ({ error, showError, hint, space }) => {
  return (
    <>
      {error && showError ? (
        <Typography space={{ mt: 1, ...space }} error={`${!!error}`} variant="helperText">
          {isObject(error) ? error.message : error}
        </Typography>
      ) : hint ? (
        <Typography space={{ mt: 1, ...space }} color="grey">
          {hint}
        </Typography>
      ) : (
        <></>
      )}
    </>
  );
};

export default withStyles(FormControl);
