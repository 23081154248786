import React from "react";
import Box from "components/Box";
import Checkbox from "components/Inputs/Checkbox";
import IconButton from "components/IconButton";
import Typography from "components/Typography";
import { DragMenuIcon } from "icons";
import TableCellContainer from "../../Components/TableCellContainer";
import clsx from "clsx";

import { useStateFields } from "components/TablePro/Contexts/StateFieldsContext";

const CheckboxCell = TableCellContainer(
  ({ entity, tableColumn, disabled, rowIndex, draggableProps, nestedLevel }) => {
    const { toggleCheckedId, isSelected } = useStateFields();

    const { multiSelectable, draggable } = tableColumn;

    const checked = isSelected(entity.id);

    return (
      <Box className={clsx(`${tableColumn.id}-cell`, { checked, noMultiSelect: !multiSelectable })}>
        <Typography color="hint" variant="compact" className={`${tableColumn.id}-label`}>
          {nestedLevel < 1 ? `${rowIndex + 1}` : ""}
        </Typography>

        <Box className={`${tableColumn.id}-actions`}>
          {multiSelectable && (
            <Checkbox
              className={`${tableColumn.id}-column`}
              fullWidth={false}
              disabled={disabled}
              space={{ p: 0, mb: 0, mr: 0 }}
              value={checked}
              onClick={toggleCheckedId(entity.id)}
            />
          )}

          {draggable && (
            <IconButton
              className={`${tableColumn.id}-action`}
              disableRipple
              space={{ ml: 1 }}
              size="table"
              iconSize="chain"
              color="grey"
              {...draggableProps}
              icon={DragMenuIcon}
            />
          )}
        </Box>
      </Box>
    );
  }
);

export default CheckboxCell;
