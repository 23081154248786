import React, { useState, useMemo } from "react";
import isEmpty from "lodash/isEmpty";

import clsx from "clsx";
import compact from "lodash/compact";
import { CheckboxColumn, CHECKBOX_COLUMN_ID } from "components/TablePro/TableCells/Cells";
import useWidthMap from "hooks/useWidthMap";
import { useTableProStyles } from "theme/components/TablePro";
import { useStateFields } from "components/TablePro/Contexts/StateFieldsContext";
import { useTableHeaderStyles } from "theme/components/TablePro";
import { StickFieldsProvider } from "components/TablePro/Contexts/StickFieldsContext";

const TableProContext = React.createContext(null);

export const useTablePro = () => React.useContext(TableProContext);

export const TableProProvider = ({
  id,
  size = "medium",
  tableDrawerOpened,
  nested,
  variant,
  color,
  entities,
  totalCount,
  columns,
  onChange,
  onSave,
  onRemove,
  onBulkRemove,
  onAdd,
  loading,
  errors = {},
  sort,
  onSort,
  MultiselectHeader,
  tableWidthMap,
  setTableWidthMap,
  resizeBorder = true,
  lockableColumns = true,
  withCellMenu = false,
  readOnly = false,
  viewable = true,
  editable = false,
  commentable = false,
  draggable = false,
  multiSelectable = true,
  onMove,
  onDragDropRow,
  onLoadMore,
  static: isStatic,
  onStickColumn,
  children,
  withCheckboxColumn = true,
  openedDescendantsByDefault = false,
  tableAddRowComponent,
  errorsById = false,
}) => {
  const { tableRef } = useStateFields();
  const [loadingMore, setLoadingMore] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);
  const classes = useTableProStyles({ variant, size, color });
  const headerClasses = useTableHeaderStyles({ variant });

  const isEditable = readOnly ? false : editable;

  const checkboxCell = withCheckboxColumn
    ? CheckboxColumn({
        multiSelectable,
        commentable,
        viewable,
        editable: isEditable,
        draggable,
      })
    : null;

  const tableColumns = compact([checkboxCell, ...columns]);
  const checkboxColumn = tableColumns.find((column) => column.id === CHECKBOX_COLUMN_ID);

  const [fieldsWidthMap, setFieldsWidthMap] = useWidthMap({ fields: tableColumns, entities });

  const withDescendants = useMemo(() => !!entities.find((entity) => entity.descendants), []);

  const widthMap = tableWidthMap || fieldsWidthMap;
  const setWidthMap = setTableWidthMap || setFieldsWidthMap;

  if (isEmpty(widthMap)) return <></>;

  return (
    <TableProContext.Provider
      value={{
        errorsById,
        resizeBorder,
        readOnly,
        withDescendants,
        openedDescendantsByDefault,
        tableRef,
        entities,
        columns: tableColumns,
        widthMap,
        setWidthMap: loading ? null : setWidthMap,
        headerClasses,
        classes,
        variant,
        size,
        viewable,
        commentable,
        draggable,
        multiSelectable,
        loadingMore,
        setLoadingMore,
        totalCount,
        editable: isEditable,
        onSave: readOnly ? null : onSave,
        onChange: readOnly ? null : onChange,
        onRemove: readOnly ? null : onRemove,
        onBulkRemove: readOnly ? null : onBulkRemove,
        onMove: readOnly ? null : onMove,
        withCellMenu,
        onDragDropRow,
        loading,
        errors: errors || {},
        sort,
        onSort,
        MultiselectHeader,
        onLoadMore,
        tableHeight,
        setTableHeight,
        onAdd,
        tableAddRowComponent,
        checkboxColumn,
      }}
    >
      <StickFieldsProvider
        lockableColumns={lockableColumns}
        loading={loading}
        onChange={onStickColumn}
        columns={tableColumns}
      >
        <div
          id={id}
          className={clsx(classes.root, {
            nested,
            tableDrawerOpened,
            static: isStatic,
          })}
          ref={tableRef}
        >
          {children}
        </div>
      </StickFieldsProvider>
    </TableProContext.Provider>
  );
};
