import flatten from "lodash/flatten";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";

const isNoneSelectedValue = (optionValue) =>
  Array.isArray(optionValue) ? isEmpty(optionValue) : optionValue === "" || !optionValue;

const useSelect = ({ inputValue, multiple = false }) => {
  const value = multiple ? compact(flatten([inputValue])) : inputValue || "";

  const parseSelectedValue = (selectedValue) => {
    let nextValue;

    const selected = [value].flat().find((item) => item === selectedValue);

    if (selected) {
      nextValue = multiple ? value.filter((item) => item !== selectedValue) : "";
    } else {
      nextValue = multiple ? [...value, selectedValue] : selectedValue;
    }

    return nextValue;
  };

  const isNoneSelected = isNoneSelectedValue(value);
  const isSelected = !isEmpty(value) && !isNoneSelected;

  const isSelectedValue = (optionValue) => flatten([value]).indexOf(optionValue) > -1;

  return {
    value,
    parseSelectedValue,
    isNoneSelected,
    isSelected,
    isSelectedValue,
  };
};

export default useSelect;
