import { gql } from "@apollo/client";
import { delegate, memoize, serialize } from "utils/entities";
import { TABLE_ATTRIBUTES } from "graphql/fragments";

import { User } from "models/fragments/User";
import { TableColumn } from "models/fragments/TableColumn";
import { TABLE_MODEL_TYPE } from "models/types";
import { BuilderItem } from "models/abstract/BuilderItem";
import { MappingPath } from "models/abstract/MappingPath";

export class Table {
  constructor(origin) {
    this.origin = { ...origin, ...origin.table };
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin, ["columns"]);

    serialize(this, { createdBy: User });
  }

  get modelType() {
    return TABLE_MODEL_TYPE;
  }

  get title() {
    return this.name;
  }

  get label() {
    return this.name;
  }

  buildBuilderItems(itemsProps = {}) {
    return this.columns.map(
      (column) =>
        new BuilderItem({
          entity: column,
          ...itemsProps[MappingPath.fromEntityToItemPathId(column)],
        })
    );
  }

  get sharedProjects() {
    return this.origin.projects.content || [];
  }

  get sharedProjectsTotalCount() {
    return this.origin.projects.totalElements;
  }

  get linkedTables() {
    return [];
  }

  get noBlankColumns() {
    return this.columns.filter((field) => !field.blank);
  }

  get columns() {
    return this.memoize("columns", () => {
      const columnsValue = this.origin.columns || [];
      const lastModifiedDate = this.origin.lastModifiedDate;

      return columnsValue.map(
        (column) => new TableColumn(column, { lastModifiedDate, tableId: this.id })
      );
    });
  }
}

Table.fragment = gql`
  fragment TableFragment on GetTablePayload {
    ${TABLE_ATTRIBUTES}
    createdBy {
      ...UserFragment
    }

    columns {
      ...TableColumnFragment
    }

    projects {
      totalElements
      content {
        id
        name
        uid
        type
      }
    }
  }

  ${User.fragment}
  ${TableColumn.fragment}
`;
