import React from "react";
import FrequencyInput from "components/Inputs/FrequencyInput";
import DateRangeInput from "components/Inputs/DateRangeInput";
import { isDatableType } from "components/ComponentType/types";
import { FormFieldInput } from "containers/FormField/Input";

const FilterInput = ({ operation, formField, id, onChange, value }) => {
  const isDateType = isDatableType(formField.componentType);

  const handleChange = (value) => {
    onChange(id, { value: value.target ? value.target.value : value });
  };

  if (isDateType && (operation === "LT" || operation === "GT")) {
    return (
      <FrequencyInput
        size="small"
        variant="outlined"
        past={operation === "LT"}
        value={value}
        onChange={(value) => onChange(id, { value })}
      />
    );
  }

  if (isDateType && operation === "BETWEEN") {
    return (
      <DateRangeInput
        size="small"
        variant="outlined"
        style={{ width: 120 }}
        value={value}
        onChange={(value) => onChange(id, { value })}
      />
    );
  }

  return (
    <FormFieldInput
      isFilterInput
      fullWidth
      formField={formField}
      variant="outlined"
      name="value"
      value={value}
      onChange={handleChange}
      space={{ mb: 0 }}
      size="small"
    />
  );
};

export default FilterInput;
