import { table } from "theme/common";
import { variantsClassNames } from "theme/utils";

export const MIN_TABLE_HEIGHT = table.minTableHeight;
export const PADDING_OFFSET = table.paddingOffset;
export const CELL_PADDING_OFFSET = table.cellPaddingOffset;
export const HEADER_ICONS_WIDTH = table.headerIconsWidth;
export const ROOT_CELL_PADDING = table.rootCellPadding;
export const SIZES = table.sizes;

export const TABLE_PRO_BODY_CLASS_NAME = "AppTablePro--body";

export const useTableProStyles = ({ variant = "default", size, color = "default" } = {}) => ({
  root: variantsClassNames("AppTablePro--root", { variant, size, color }),
  container: "AppTablePro--container",
  virtuoso: "AppTablePro--Virtuoso-root",
  emptyTable: "AppTablePro--emptyTable",
  row: "AppTablePro--Row-root",
  cell: "AppTablePro--Cell-root",
  cellContainer: "AppTablePro--Cell-container",
  actionBar: "AppTablePro--action-bar",
});

export const useTableHeaderStyles = ({ variant = "default" }) => ({
  root: variantsClassNames("AppTablePro--Header-root", { variant }),
  row: "AppTablePro--Header-Row-root",
  cell: "AppTablePro--Header-Cell-root",
  cellContainer: "AppTablePro--Header-Cell-container",
  cellContent: "AppTablePro--Header-Cell-content",
  sortAction: "AppTablePro--Header-Cell-sortAction",
  arrowIcon: "AppTablePro--Header-Cell-arrowIcon",
});

export const useFastTypeInputCellStyles = () => ({
  root: "AppTableProCell--FastInputCell-root",
  cellContainer: "AppTableProCell--FastInputCell-cellContainer",
  cellContent: "AppTableProCell--FastInputCell-cellContent",
  inputArea: "AppTableProCell--FastInputCell-inputArea",
  typography: "AppTablePro--Typography",
});

export const useTableInputCellStyles = () => ({
  cellContainer: "AppTableProCell--TableInputCell-cellContainer",
});
