import React, { useState } from "react";
import { draggableItemStyle } from "theme/components/Draggable";
import VerticalMove from "components/Draggable/VerticalMove";

import { TableProRow } from "./TableRow";
import { useTablePro } from "../Provider";

const DraggableTableRows = ({ renderCount, pagination }) => {
  const { onMove, entities } = useTablePro();

  return (
    <VerticalMove onDragEnd={onMove} getItemStyle={draggableItemStyle} useDraggableElement>
      {Array(renderCount)
        .fill(null)
        .map((_, entityIndex) => {
          const rowIndex =
            pagination.pageOffset === 0 ? entityIndex : entityIndex + pagination.pageOffset;

          return (
            <DraggableItem
              key={`entity-${rowIndex}`}
              draggableIndex={entities[rowIndex]?.draggableIndex}
            >
              {(handleDraggableProps) => (
                <TableProRow rowIndex={rowIndex} draggableProps={handleDraggableProps} />
              )}
            </DraggableItem>
          );
        })}
    </VerticalMove>
  );
};

const DraggableItem = React.forwardRef(
  ({ handleDraggableProps, children, rowIndex, ...props }, ref) => {
    return (
      <div {...props} ref={ref}>
        {children(handleDraggableProps)}
      </div>
    );
  }
);

export const useDragDrop = ({ onDragDrop, rowIndex, tableColumn, entityId }) => {
  const [active, setActive] = useState(null);

  if (!onDragDrop) {
    return { dragActive: false };
  }

  const handleDragOver = (event) => {
    event.preventDefault();
    setActive(true);
  };

  const handleDrop = (event) => {
    onDragDrop && onDragDrop(event, { rowIndex, tableColumn, entityId });
    setActive(false);
  };

  const handleDropLeave = () => {
    setActive(false);
  };

  return {
    onDragOver: handleDragOver,
    onDragLeave: handleDropLeave,
    onDrop: handleDrop,
    dragActive: active,
  };
};

export default DraggableTableRows;
