import React from "react";
import Box from "components/Box";
import { AvatarProduct } from "components/Avatar";
import UploadFileButton from "components/UploadFileButton";
import { BoxThinIcon } from "icons";
import CircularProgress from "components/CircularProgress";
import clsx from "clsx";

import "styles/App/UploadAvatar.scss";

const useUploadAvatarStyles = () => ({
  root: "AppUploadAvatar-root",
  uploadCover: "AppUploadAvatar-root__uploadCover",
  avatarActions: "AppUploadAvatar-root__avatarActions",
});

const UploadAvatar = ({ onUpload, loading, imageAlt, children, size = "titleSize", ...props }) => {
  const classes = useUploadAvatarStyles();

  return (
    <UploadFileButton
      {...props}
      className={clsx(classes.root, { loading })}
      form="square"
      size={size}
      icon={BoxThinIcon}
      onUpload={onUpload}
      component={AvatarProduct}
      imageAlt={imageAlt}
      disabled={loading || props.disabled}
    >
      <Box className={classes.uploadCover}>{loading && <CircularProgress size="large" />}</Box>
      {children}
    </UploadFileButton>
  );
};

export default UploadAvatar;
