import { gql } from "@apollo/client";
import { delegate, memoize } from "utils/entities";
import { MappingRowValueFragment, MAPPING_FRAGMENT } from "models/fragments/Mapping";
import { MappingTemplate } from "models/MappingTemplate/MappingTemplate";

export class FormAction {
  constructor(origin) {
    this.origin = origin;

    delegate(this, origin);

    this.cache = {};
    this.memoize = memoize(this.cache);
  }

  get label() {
    return this.name;
  }

  get requestFieldsIds() {
    return this.request.rows.map((row) => row.from.path[row.from.path.length - 1].id);
  }

  get requestMappingTemplate() {
    return new MappingTemplate({
      from: this.request.from,
      to: this.request.to,
      rows: this.request.rows,
    });
  }

  get responseMappingTemplate() {
    return new MappingTemplate({
      from: this.response.from,
      to: this.response.to,
      rows: this.response.rows,
    });
  }
}

FormAction.fragment = gql`
  fragment FormActionFragment on FormLayoutActionPayload {
    id
    name
    type
    request {
      ${MAPPING_FRAGMENT}
    }
    response {
      ${MAPPING_FRAGMENT}
    }
  }

  ${MappingRowValueFragment}
`;

export const EXECUTE_CHANNEL_OPERATION_FORM_ACTION_TYPE = "EXECUTE_CHANNEL_OPERATION";
export const IMPORT_FORM_TABLE_FORM_ACTION_TYPE = "IMPORT_FORM_TABLE";
export const IMPORT_FORM_SUBLIST_FORM_ACTION_TYPE = "IMPORT_FORM_SUBLIST";

export const FORM_ACTION_TYPE_OPTIONS = [
  { id: EXECUTE_CHANNEL_OPERATION_FORM_ACTION_TYPE, label: "Execute channel operation" },
  { id: IMPORT_FORM_TABLE_FORM_ACTION_TYPE, label: "Import to table" },
  { id: IMPORT_FORM_SUBLIST_FORM_ACTION_TYPE, label: "Import to sub-list" },
];
