import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import CollapseButton from "components/Buttons/CollapseButton";
import MenuItem from "components/Menu/MenuItem";

export const CollapseMenuItem = ({
  label,
  level,
  optionId,
  collapsed,
  collapsedMap,
  setCollapsedMap,
  className,
  iconClassName,
  ...props
}) => {
  const title = !label || label === "null" ? "Select option" : label;
  const disabled = false;

  const toggleCollapse = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setCollapsedMap({ ...collapsedMap, [optionId]: !collapsedMap[optionId] });
  };

  const disableParent = (event) => {
    event.stopPropagation();
  };

  return (
    <MenuItem
      {...props}
      className={className}
      ellipses
      component="div"
      markVariant={null}
      tickIcon={false}
      onMouseDown={disableParent}
      onClick={toggleCollapse}
    >
      <Box display="flex" alignItems="center">
        <CollapseButton
          disabled={disabled}
          open={!collapsed}
          space={{ ml: 0, mr: 1 }}
          className={iconClassName}
          onMouseDown={disableParent}
          onClick={toggleCollapse}
        />

        <Typography weight="title">{title}</Typography>
      </Box>
    </MenuItem>
  );
};
