import mapValues from "lodash/mapValues";
import { createTheme } from "@mui/material/styles";

export const SPACING = 8;

export const theme = createTheme({
  spacing: SPACING,
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: "150ms",
      shorter: "200ms",
      short: "250ms",
      // most basic recommended timing
      standard: "300ms",
      // this is to be used in complex animations
      complex: "375ms",
      // recommended when something is entering screen
      enteringScreen: "225ms",
      // recommended when something is leaving screen
      leavingScreen: "195ms",
    },
  },
});

const leavingTransition = theme.transitions.create(["width", "margin", "padding"], {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
});

const enteringTransition = theme.transitions.create(["width", "margin", "padding"], {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.enteringScreen,
});

export const transitions = { ...theme.transitions, leavingTransition, enteringTransition };
export const spacing = theme.spacing;
export const iconSpacing = "6px";
export const borderRadius = "4px";

export const defaultBoxShadow = (color = "rgba(33, 9, 9, 0.05)") =>
  `0px 0px 2px rgba(0, 0, 0, 0.16), 0px 5px 8px ${color}`;

export const lightBoxShadow = (color = "rgba(9, 11, 33, 0.08)") =>
  `0px 0px 2px rgba(16, 17, 30, 0.2), 0px 4px 13px ${color}`;

export const transition = (props) =>
  theme.transitions.create(props, { duration: transitions.duration.short });

export const PADDING_SIZES_MAP = {
  xxs: [0, 6],
  xs: [2, 12],
  compact: [4, 6],
  small: [4, 12],
  medium: [8, 8],
  long: [6, 16],
  large: [12, 16],
  label: {
    small: [1, 6],
    medium: [4, 8],
    large: [4, 12],
  },
};

const toPadding = (paddings) => ({
  padding: `${paddings[0]}px ${paddings[1]}px`,
  paddingY: `${paddings[0]}px`,
  paddingX: `${paddings[1]}px`,
});

export const PADDING_SIZES = {
  xxs: toPadding(PADDING_SIZES_MAP.xxs),
  xs: toPadding(PADDING_SIZES_MAP.xs),
  compact: toPadding(PADDING_SIZES_MAP.compact),
  small: toPadding(PADDING_SIZES_MAP.small),
  medium: toPadding(PADDING_SIZES_MAP.medium),
  long: toPadding(PADDING_SIZES_MAP.long),
  large: toPadding(PADDING_SIZES_MAP.large),
  label: {
    small: toPadding(PADDING_SIZES_MAP.label.small),
    medium: toPadding(PADDING_SIZES_MAP.label.medium),
    large: toPadding(PADDING_SIZES_MAP.label.large),
  },
};

export const noPx = (object) =>
  mapValues(object, (value) => parseInt(`${value}`.replace("px", "")));
export const toPx = (object) => mapValues(object, (value) => `${value}px`);

export const INPUT_CONTENT_HEIGHT = 20;

export const ICON_SIZES = {
  xxs: 6,
  sort: 10,
  flag: 15,
  xs: 18,
  table: 20,
  small: 20,
  medium: 22,
  large: 24,
  chain: 30,
  xLarge: 32,
  xxLarge: 48,
  megaLarge: 64,
  titleSize: 40,
};

export const ICON_BUTTON_SIZES = {
  xxs: 24,
  xs: 28,
  collapse: 22,
  compact: 29,
  table: 26,
  small: 32,
  medium: 40,
  large: 48,
  labelSmall: 24,
  labelMedium: 28,
};

export const AVATAR_SIZES = {
  xs: 17,
  small: 20,
  sidebar: 24,
  medium: 32,
  listItem: 40,
  large: 48,
  xLarge: 64,
  titleSize: 72,
  drawerField: 240,
};

export const DRAWER_SIZES = {
  xs: 420,
  table: 350,
  small: 520,
  medium: 640,
  large: 1200,
};

const TABLE_PADDING_OFFSET = parseInt(spacing(1));

export const table = {
  minTableHeight: 400,
  paddingOffset: TABLE_PADDING_OFFSET,
  cellPaddingOffset: TABLE_PADDING_OFFSET * 2,
  headerIconsWidth: 50,
  rootCellPadding: 28,
  sizes: {
    small: 32,
    medium: 48,
  },
};

export const tableTheme = {
  ...table,
  paddingOffset: `${table.paddingOffset}px`,
  cellPaddingOffset: `${table.cellPaddingOffset}px`,
  headerIconsWidth: `${table.headerIconsWidth}px`,
  rootCellPadding: `${table.rootCellPadding}px`,
  sizes: toPx(table.sizes),
};

export const layout = noPx({
  drawerWidth: 240,
  minDrawerWidth: 64,
  tabsHeight: 44,
  headerHeight: 48,
  footerPadding: spacing(3),
});

export const layoutTheme = toPx(layout);
