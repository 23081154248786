import { gql } from "@apollo/client";
import { delegate, memoize } from "utils/entities";
import { TableColumn } from "models/fragments/TableColumn";

export class TableAccess {
  constructor(origin) {
    this.origin = { ...origin, ...origin.table };
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin, ["columns"]);
  }

  get columns() {
    return this.memoize("columns", () => {
      const columnsValue = this.origin.columns || [];

      return columnsValue.map((column) => new TableColumn(column));
    });
  }
}

TableAccess.fragment = gql`
  fragment TableAccessFragment on GetTableAccessPayload {
    id
    name
    uid
    columns {
      ...TableColumnFragment
    }
  }

  ${TableColumn.fragment}
`;
