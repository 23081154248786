import { useMemo } from "react";
import { useFieldArray } from "react-hook-form";
import { onFieldsRemove } from "utils/fieldsArray";

const fieldProps = (field) => ({
  field,
  fieldId: field.id,
  hidden: false,
});

const useFieldsManager = ({
  name,
  control,
  clearErrors,
  onAddProps = fieldProps,
  onHideProps,
  watch,
}) => {
  const { fields, append, update, remove, ...fieldsProps } = useFieldArray({
    control,
    name,
  });

  const watchFields = watch ? watch(name) : [];

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFields[index],
    };
  });

  const fieldsEntities = (controlledFields || []).map((field, index) => ({
    index,
    ...field.field,
    ...field,
  }));

  const fieldsIds = useMemo(() => fieldsEntities.map((field) => field.id), [fieldsEntities]);

  const onAppend = (fields) => {
    const newColumns = fields.map((field) => (onAddProps ? onAddProps(field) : field));
    append(newColumns);
  };

  const onUpdate = (id, props) => {
    const rowIndex = fieldsIds.indexOf(id);
    const newProps = { ...fields[rowIndex], ...props };

    if (newProps.hidden && onHideProps) {
      update(rowIndex, onHideProps(newProps));
    } else {
      update(rowIndex, newProps);
    }

    clearErrors(`${name}.${rowIndex}`);
  };

  const onRemove = (ids) => {
    return onFieldsRemove({ ids, collection: fields, remove });
  };

  const onHide = (ids) => {
    ids.forEach((id) => {
      const index = fieldsIds.indexOf(id);

      if (index >= 0) {
        onUpdate(id, { ...fields[index], hidden: true });
      }
    });
  };

  return {
    ...fieldsProps,
    fieldsIds,
    append: onAppend,
    update: onUpdate,
    remove: onRemove,
    hide: onHide,
    formFields: fields,
    fields: fieldsEntities,
  };
};

export default useFieldsManager;
