import React from "react";
import Typography from "components/Typography";
import Divider from "components/Divider";
import Box from "components/Box";
import { PlusCircleIcon, PlusIcon, SettingsIcon } from "icons";
import Button from "components/Button";
import { useMenuStyles } from "theme/components/Menu";
import Popover from "components/Popover";
import MenuItem from "components/Menu/MenuItem";
import { useWorkspace } from "contexts/Workspace";
import { useAuth } from "contexts/Auth";
import { AvatarInitials } from "components/Avatar";
import clsx from "clsx";
import { useSideBarStyles } from "theme/components/DashboardLayout";
import NavLink from "components/NavLink";
import { useAccessManager } from "contexts/AccessManager";
import { AccessSection } from "containers/AccessSection";

import { CompanySelect } from "./CompanySelect";
import { UserSpaceTitle } from "./AccountMenu";

export const WorkspaceMenu = ({ onOpen, onClose, ...props }) => {
  const { accessManager } = useAccessManager();
  const classes = useMenuStyles();
  const sidebarClasses = useSideBarStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { currentCompany, currentProject, setCompanyId, setProjectId, projects } = useWorkspace();
  const { currentProfile, logout } = useAuth();

  const handleOpen = (event) => {
    onOpen && onOpen();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onClose && onClose();
    setAnchorEl(null);
  };

  const handleSelectCompany = (companyId) => {
    setCompanyId(companyId);
  };

  const handleSelectProject = (projectId) => () => {
    setProjectId(projectId);
    // handleClose();
  };

  return (
    <>
      <Button
        leftIcon={() => <AvatarInitials size="sidebar" name={currentProfile.firstName} />}
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
        {...props}
      >
        <span className={clsx(sidebarClasses.avatarTitle, classes.avatarTitle)}>
          {currentProfile.displayName}
        </span>
      </Button>

      <Popover id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box fullHeightContainer style={{ minWidth: "262px" }}>
          <Box className={classes.header}>
            <UserSpaceTitle />
          </Box>

          <Divider variant="light" space={{ my: 1 }} />

          <CompanySelect
            space={{ mb: 0 }}
            value={currentCompany.id}
            onChange={handleSelectCompany}
          />

          {projects.length === 0 ? (
            <Box space={{ mt: 2, ml: 2 }}>
              <Typography color="grey">No projects</Typography>
            </Box>
          ) : (
            <Typography space={{ p: 1 }} component="div" color="grey" variant="caption">
              {`${projects.length} projects`}
            </Typography>
          )}

          <Box fullHeightScroll style={{ height: "100%", maxHeight: 500 }}>
            {projects.map((project, index) => (
              <MenuItem
                component="div"
                key={`company-${index}`}
                selected={currentProject?.id === project.id}
                onClick={handleSelectProject(project.id)}
                icon={(props) => <AvatarInitials size="sidebar" {...props} name={project.name} />}
              >
                <Typography weight="title" variant="body1">
                  {project.name}
                </Typography>
                <Typography variant="description">
                  {project.description || "No description"}
                </Typography>
              </MenuItem>
            ))}
          </Box>

          <Divider variant="light" space={{ my: 1 }} />

          <NavLink to="/:companyId/settings/general">
            <MenuItem icon={SettingsIcon}>Settings</MenuItem>
          </NavLink>

          <NavLink to="/companies/create">
            <MenuItem icon={PlusCircleIcon}>Create a company</MenuItem>
          </NavLink>

          <AccessSection can={accessManager.canCreateProject}>
            <NavLink to="/:companyId/projects/create">
              <MenuItem icon={PlusIcon}>Create a project</MenuItem>
            </NavLink>
          </AccessSection>

          <Divider variant="light" space={{ my: 1 }} />

          <MenuItem onClick={logout}>Log Out</MenuItem>
        </Box>
      </Popover>
    </>
  );
};
