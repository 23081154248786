import React, { useEffect } from "react";
import clsx from "clsx";
import Button from "components/Button";
import { useMenuStyles } from "theme/components/Menu";
import { Popover as MuiPopover, Fade } from "@mui/material";
import { useOsContext } from "providers/OsProvider";

const Popover = ({ children, ContentProps, PaperProps, open, textColor, fullWidth, ...props }) => {
  const classes = useMenuStyles();

  const { isNotMacOS } = useOsContext();

  return (
    <MuiPopover
      className={clsx({ "custom-scroll": isNotMacOS })}
      TransitionComponent={Fade}
      open={open}
      {...props}
      PaperProps={{
        className: classes.paper,
        ...PaperProps,
      }}
      TransitionProps={{ ...props.TransitionProps, unmountOnExit: true }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      indicator={props?.indicator?.toString()}
    >
      <div {...ContentProps}>{children}</div>
    </MuiPopover>
  );
};

export const PopoverButton = ({
  anchorEl,
  setAnchorEl,
  children,
  onClose,
  onOpen,
  onClick,
  title,
  component: Component = Button,
  PaperProps = {},
  ContentProps = {},
  textColor,
  ...componentProps
}) => {
  const open = Boolean(anchorEl);
  const classes = useMenuStyles();

  const handleOpen = (event) => {
    onClick && onClick(event);
    setAnchorEl && setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    onClose && onClose(event);
    setAnchorEl && setAnchorEl(null);
  };

  useEffect(() => {
    open && onOpen && onOpen();
  }, [open]);

  return (
    <>
      <Component
        {...componentProps}
        onClick={handleOpen}
        aria-controls="popoverButton"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
      >
        {title}
      </Component>

      <Popover
        id="popoverButton"
        classes={classes}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={PaperProps}
        ContentProps={ContentProps}
      >
        {children}
      </Popover>
    </>
  );
};

export default Popover;
