import { styled } from "@mui/material/styles";
import { Backdrop } from "@mui/material";
import { variantsClassNames } from "theme/utils";
import clsx from "clsx";

import "styles/App/Dialog.scss";

export const TransparentBackdrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  backgroundColor: "transparent",
  zIndex: -1,
});

export const useDialogStyles = ({ size = "medium", verticalActions } = {}) => ({
  root: clsx(variantsClassNames("AppDialog-root", { size }), { verticalActions }),
  paper: "AppDialog-paper",
  paperScrollBody: "AppDialog-paperScrollBody",
  scrollBody: "AppDialog-scrollBody",
  title: "AppDialog-title",
  crossButton: "AppDialog-cross-btn",
});
