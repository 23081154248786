import React from "react";
import clsx from "clsx";

const getOS = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator?.userAgentData?.platform ?? window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K", "macOS"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "macOS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "windows";
  } else if (/Android/.test(userAgent)) {
    os = "android";
  } else if (!os && /Linux/.test(platform)) {
    os = "linux";
  } else {
    os = "other";
  }

  return os;
};

const OsContext = React.createContext(null);

const OsProvider = ({ children }) => {
  const currentOS = getOS();
  const isNotMacOS = currentOS !== "macOS";

  return (
    <OsContext.Provider value={{ currentOS, isNotMacOS }}>
      <div className={clsx("os-content", { "custom-scroll": isNotMacOS })} id={currentOS}>
        {children}
      </div>
    </OsContext.Provider>
  );
};

export const useOsContext = () => React.useContext(OsContext);

export default OsProvider;
