import React, { useMemo, useEffect } from "react";
import useRows from "hooks/useRows";
import some from "lodash/some";
import keyBy from "lodash/keyBy";
import JSum from "vendor/jsum";

const rowsChecksum = (value) =>
  JSum.digest(
    value.map((item) => ({ ...item, id: null })),
    "SHA256",
    "hex"
  );

const SortContext = React.createContext(null);

const convertSortToRows = (sortColumnsMap, sort = []) =>
  sort.reduce((result, item) => {
    const column = sortColumnsMap[item.columnId];

    if (!column) return result;

    return [
      ...result,
      {
        id: item.id || Date.now(),
        value: item.value,
        columnId: item.columnId,
        field: item.columnId,
        componentType: column.componentType,
        componentTypeConfig: column.componentTypeConfig || {},
        label: column.label,
      },
    ];
  }, []);

export const SortProvider = ({ columns, onApply, onClean, sort, children }) => {
  const sortColumns = useMemo(
    () => columns.filter((column) => !column.disabledSort && !!column.componentType),
    [columns]
  );

  const sortColumnsMap = useMemo(() => keyBy(sortColumns, "id"), [sortColumns]);
  const sortRows = useMemo(() => convertSortToRows(sortColumnsMap, sort), [sortColumnsMap, sort]);

  const { updateRows, updateRow, isDirty, setIsDirty, rows } = useRows(sortRows);

  const sortsColumnsIds = useMemo(() => rows.map((row) => row.columnId), [rows]);

  const handleAdd = (columnId) => {
    const column = sortColumnsMap[columnId];
    const sortColumnId = column.sortId || columnId;

    const newRows = updateRows([
      ...rows,
      {
        id: Date.now(),
        columnId: sortColumnId,
        field: sortColumnId,
        componentType: column.componentType,
        componentTypeConfig: column.componentTypeConfig || {},
        value: "ASC",
        label: sortColumnsMap[columnId].label,
      },
    ]);

    onApply(newRows);
  };

  const handleChange = (id, props, { apply } = {}) => {
    const nextRows = updateRow(id, props);

    if (apply) {
      setIsDirty(false);
      onApply && onApply(nextRows);
    }
  };

  const handleRemove = (id, { apply } = {}) => {
    const newRows = updateRows(rows.filter((row) => row.id !== id));

    if (newRows.length === 0 && onClean) {
      onClean();
      return;
    }

    if (apply) {
      setIsDirty(false);
      onApply && onApply(newRows);
    }
  };

  const handleClean = () => {
    updateRows([]);
    onClean && onClean();
    onApply && onApply([]);
  };

  const isActiveApply = !some(rows, (row) => row.value === null || row.value === undefined);

  const handleApply = () => {
    setIsDirty(false);
    onApply && onApply(rows);
  };

  useEffect(() => {
    if (rowsChecksum(sortRows) === rowsChecksum(rows)) return;

    updateRows(sortRows);
  }, [rowsChecksum(sortRows)]);

  const value = {
    columns: sortColumns,
    sorts: rows,
    sortsColumnsIds,
    isValid: isActiveApply,
    isDirty,
    onAdd: handleAdd,
    onChange: handleChange,
    onRemove: handleRemove,
    onClean: handleClean,
    onApply: handleApply,
  };

  return <SortContext.Provider value={value}>{children}</SortContext.Provider>;
};

export const useSort = () => React.useContext(SortContext);
