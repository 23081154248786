import React, { useEffect, useState } from "react";

import { OptionsContainer } from "./OptionsContainer";
import { RenderGroupOptions } from "./RenderGroupOptions";
import { RenderOptions } from "./RenderOptions";
import { RenderTreeOptions } from "./RenderTreeOptions";

const LOADING_OPTIONS = new Array(3).fill({ id: "stubLoading", label: "stubLoading" });

export const SelectOptions = ({
  fullHeight,
  searchable,
  isFooter,
  markVariant,
  optionComponent,
  virtualized,
  isSelected,
  options,
  allSkipOptionsIds,
  totalCount,
  onChange,
  onLoadMore,
  groupProperty,
  treeProperty,
  loading,
  loadingMore,
  setLoadingMore,
}) => {
  const SelectOptionsComponent = treeProperty
    ? RenderTreeOptions
    : groupProperty
    ? RenderGroupOptions
    : RenderOptions;

  const isLoading = loading || loadingMore;
  const renderOptions = isLoading ? [...options, ...LOADING_OPTIONS] : options;
  const renderTotalCount = isLoading ? totalCount + LOADING_OPTIONS.length : totalCount;

  const handleLoadMore = () => {
    if (!onLoadMore) return;
    if (options.length === totalCount) return;

    setLoadingMore(true);

    onLoadMore().then(() => {
      setLoadingMore(false);
    });
  };

  // TODO: Temp fix of issue when totalCount is less than 0. Reproduces with invite list
  if (virtualized && totalCount < 0) {
    return <></>;
  }

  return (
    <OptionsContainer
      searchable={searchable}
      isFooter={isFooter}
      fullHeight={fullHeight}
      totalCount={totalCount}
      groupProperty={groupProperty}
      treeProperty={treeProperty}
      virtualized={virtualized}
    >
      <SelectOptionsComponent
        virtuosoHeight={calcHeight({ totalCount, groupProperty, treeProperty })}
        markVariant={markVariant}
        optionComponent={optionComponent}
        virtualized={virtualized}
        isSelected={isSelected}
        totalCount={renderTotalCount}
        onChange={onChange}
        groupProperty={groupProperty}
        treeProperty={treeProperty}
        options={renderOptions}
        onLoadMore={handleLoadMore}
        allSkipOptionsIds={allSkipOptionsIds}
      />
    </OptionsContainer>
  );
};

export const calcHeight = ({ totalCount, groupProperty, treeProperty }) => {
  let count = totalCount + 1;
  if (groupProperty) count += 1;

  if (treeProperty) return 300;

  return Math.min(count * 40, 240);
};
