import React from "react";
import Input from "components/Inputs/Input";
import { Controller } from "react-hook-form";

const InputForm = ({ control, rules = {}, ...props }) => {
  if (!control) return <Input {...props} />;

  return (
    <Controller
      control={control}
      name={props.name}
      rules={rules}
      render={({ field, fieldState }) => (
        <>
          <Input
            {...props}
            invalid={fieldState.invalid?.toString()}
            error={fieldState.error || props.error}
            value={field.value || ""}
            onBlur={field.onBlur}
            onChange={field.onChange}
            inputRef={field.ref}
          />
        </>
      )}
    />
  );
};

export default InputForm;
