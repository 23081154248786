const grey = {
  100: "#242533",
  80: "#484964",
  60: "#808192",
  40: "#ABACBE",
  20: "#BCBCC6",
  16: "#E4E4EB",
  8: "#EEEEF2",
  0.8: "rgba(20, 27, 36, 0.12)",
  4: "#F6F6F9",
  0.4: "rgba(228, 228, 235, 0.5)",
  0.32: "rgba(36, 37, 51, 0.32)",
  0.08: "rgba(36, 37, 51, 0.08)",
  0.06: "rgba(36, 37, 51, 0.06)",
  0.05: "rgb(244, 244, 245)", // rgba(36, 37, 51, 0.05)
  0.04: "rgba(36, 37, 51, 0.04)",
  0.03: "rgba(36, 37, 51, 0.03)",
  3: "#F8F9F7",
  2: "#F9F9FC",
  1: "#FBFBFC",
};

const purple = {
  100: "#6361D9",
  80: "#7A80FF",
  60: "#AD9FFF",
  16: "#ECE9FC",
  8: "#F3F1FE",
  6: "#F3F2F8",
  0.16: "rgba(100, 77, 237, 0.12)",
  0.8: "rgba(100, 77, 237, 0.08)",
  0.4: "rgba(69, 75, 203, 0.08)",
};

const orange = {
  16: "#F9ECDC",
};

const red = {
  100: "#FF5D39",
  80: "#E3664B",
  60: "#FFD3CA",
  50: "#FEF1FB",
  40: "#FFEAE5",
  30: "#FFE0F8",
  20: "#FF5473",
  0.5: "rgba(255, 33, 140, 0.5)",
  0.2: "rgba(255, 33, 140, 0.2)",
};

const green = {
  100: "#11AF21",
  50: "#FF218C",
  30: "#9EF0AB",
  8: "#EDF7EE",
};

const common = {
  white: "#FFF",
  black: grey[80],
};

const formula = {
  function: "#2CAE68",
  property: purple[60],
  operator: "#9a6e3a",
  string: grey[100],
  number: "#ce0072",
  none: grey[60],
};

const palette = {
  type: "light",
  common,
  formula,
  sectionBorder: "rgba(188, 188, 198, 0.50)",
  warning: {
    main: "#fb6944",
    100: "#fb6944",
    30: "#ffe1da",
  },
  primary: {
    dark: grey[100],
    main: grey[80],
    light: grey[0.06],
    contrastText: common.white,
  },
  error: {
    main: red[100],
    light: red[0.5],
  },
  secondary: {
    dark: purple[100],
    main: purple[60],
    light: purple[16],
    contrastText: common.white,
  },
  placeholder: {
    main: grey[40],
    hover: purple[100],
  },
  background: {
    default: common.white,
    border: grey[8],
  },
  text: {
    dark: grey[100],
    primary: common.black,
    secondary: "#020B1F",
  },
  table: {
    grey: "#F9F8FB",
  },
  avatar: {
    blue: "#E1E8F5",
    grey: "rgba(36, 37, 51, 0.05)",
    green: "#E1F5EC",
    orange: "#FFF0DD",
    purple: "#ECE9FC",
  },
  tableCell: {
    blank: "rgba(255, 244, 242, 0.5)",
    editable: "rgba(255, 244, 242, 0.9)",
  },
  menu: {
    itemHover: grey[0.05],
  },
  grey,
  purple,
  red,
  green,
  orange,
};

export default palette;
