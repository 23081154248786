import React from "react";
import clsx from "clsx";

import { ActionsTooltip } from "./ActionsTooltip";

import "styles/App/DndBuilder/Container.scss";

const useDndBuilderContainerStyles = () => ({
  root: "AppDndBuilder-Container-root",
  header: "AppDndBuilder-Container-header",
  list: "AppDndBuilder-Container-list",
  placeholder: "AppDndBuilder-Container-placeholder",
  content: "AppDndBuilder-Container-content",
  actions: "AppDndBuilder-Container-actions",
});

export const Container = ({
  children,
  handleProps,
  hover,
  onClick,
  onRemove,
  style,
  scrollable,
  innerRef,
  selected,
  leftPlaceholder,
  rightPlaceholder,
  placeholder,
  preview,
  type,
  items,
  active,
  single,
  ...props
}) => {
  const classes = useDndBuilderContainerStyles();

  return (
    <div
      {...props}
      ref={innerRef}
      style={style}
      className={clsx(classes.root, {
        hover,
        scrollable,
        selected,
        placeholder,
        preview,
        single,
      })}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
    >
      {leftPlaceholder}

      <div className={classes.content}>
        <div className={classes.header}>
          {!active && <ActionsTooltip handleProps={handleProps} onRemove={onRemove} />}
        </div>

        <ul className={classes.list}>{children}</ul>
      </div>

      {rightPlaceholder}
    </div>
  );
};

export const ContainerPlaceholder = ({ id, innerRef, style, hover, disabled }) => {
  const classes = useDndBuilderContainerStyles();

  return (
    <div
      id={id}
      ref={innerRef}
      style={style}
      className={clsx(classes.placeholder, { hover, disabled })}
    />
  );
};
