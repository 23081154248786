import React from "react";
import Button from "components/Button";
import Box from "components/Box";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Container } from "./components/Container";

export const Actions = ({ actionIds, actionsMap, onChange }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = actionIds.indexOf(active.id);
      const newIndex = actionIds.indexOf(over.id);

      const newActionIds = arrayMove(actionIds, oldIndex, newIndex);

      onChange(newActionIds);
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <Container single placeholder selected={false} id="actions-container">
        <ActionsWrap>
          <SortableContext items={actionIds} strategy={horizontalListSortingStrategy}>
            {actionIds.map((actionId) => (
              <SortableAction key={actionId} action={actionsMap[actionId]} space={{ ml: 1 }} />
            ))}
          </SortableContext>
        </ActionsWrap>
      </Container>
    </DndContext>
  );
};

export const ActionsWrap = ({ children }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="flex-end"
    style={{ flexWrap: "wrap" }}
    space={{ mt: -1 }}
  >
    {children}
  </Box>
);

const SortableAction = ({ action, space }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: action.id,
  });

  return (
    <Button
      innerRef={setNodeRef}
      disableRipple
      variant="outlined"
      space={{ mb: 1, ...space }}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        minWidth: 100,
      }}
      {...attributes}
      {...listeners}
    >
      {action.name}
    </Button>
  );
};
