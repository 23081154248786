import React from "react";
import { DrawerHeader } from "./Header";
import { DrawerBody } from "./Body";

import { DrawerLayoutProvider } from "./Provider";

export { useDrawerLayout } from "./Provider";

export const DrawerLayout = (props) => (
  <DrawerLayoutBase {...props} bodyComponent={DrawerBody} headerComponent={DrawerHeader} />
);

const DrawerLayoutBase = ({
  onClose,
  isEdit,
  setEdit,
  tab,
  setTab,
  onUp,
  onDown,
  loading = false,
  onDiscard,
  isSubmitting,
  onRemove,
  onSave,
  children,
  onComment,
  bodyComponent: BodyComponent,
  headerComponent: HeaderComponent,
  headerProps = {},
  visibleSidebar = true,
  nested = false,
  commentMode = false,
}) => {
  const handleSetTab = (tab) => {
    setEdit(false);
    setTab(tab);
  };

  return (
    <DrawerLayoutProvider
      tab={tab}
      setTab={setTab && handleSetTab}
      isEdit={isEdit}
      setEdit={setEdit}
      loading={loading}
      isSubmitting={isSubmitting}
      onRemove={onRemove}
      visibleSidebar={visibleSidebar}
      commentMode={commentMode}
      toggleFullScreen
    >
      <HeaderComponent
        onFullScreen
        onDiscard={onDiscard}
        onSave={onSave}
        onClose={onClose}
        onUp={onUp}
        onDown={onDown}
        onComment={onComment}
        {...headerProps}
      />

      <BodyComponent nested={nested}>{children}</BodyComponent>
    </DrawerLayoutProvider>
  );
};

export default DrawerLayout;
