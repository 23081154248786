import { gql } from "@apollo/client";
import { delegate, memoize } from "utils/entities";

import { CONSOLIDATED_TABLE_ACCESS_LINK_MODEL_TYPE } from "models/types";
import { FormField } from "models/abstract/FormField";
import { TableColumn } from "models/fragments/TableColumn";
import { TablePrimaryColumn } from "models/fragments/TablePrimaryColumn";

export class ConsolidatedTableAccessLink {
  constructor(origin) {
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin, ["primaryColumns"]);
  }

  get modelType() {
    return CONSOLIDATED_TABLE_ACCESS_LINK_MODEL_TYPE;
  }

  get linkType() {
    return "CONSOLIDATED";
  }

  get label() {
    return this.name;
  }

  get tables() {
    return this.memoize("tables", () => {
      return this.primaryColumns[0]?.mapping?.map((mappingItem) => mappingItem.table) || [];
    });
  }

  get tablesIds() {
    return this.tables.map((table) => table.id);
  }

  get primaryColumns() {
    return this.memoize("primaryColumns", () => {
      const columns = this.origin.primaryColumns || [];
      const lastModifiedDate = this.lastModifiedDate;

      return columns.map((column) => new TablePrimaryColumn(column, { lastModifiedDate }));
    });
  }

  get additionalColumns() {
    return this.memoize("additionalColumns", () => {
      const columns = this.origin.columns || [];
      const lastModifiedDate = this.lastModifiedDate;

      return columns.map((column) => new TableColumn(column, { lastModifiedDate }));
    });
  }

  get formFields() {
    return this.memoize("formFields", () => {
      const primaryColumns = this.primaryColumns
        .filter((column) => !column.hidden)
        .map(
          (column) =>
            new FormField({
              id: column.id,
              fieldId: column.fieldId,
              name: column.id,
              label: column.name,
              disabled: false,
              icon: null,
              type: column.type,
              subtype: column.subtype,
              editable: false,
              required: false,
              config: column.config,
              placeholder: column.placeholder,
            })
        );

      const additionalColumns = this.additionalColumns.map(
        (column) =>
          new FormField({
            id: column.id,
            fieldId: column.fieldId,
            name: column.id,
            label: column.name,
            disabled: false,
            icon: null,
            type: column.type,
            subtype: column.subtype,
            editable: true,
            required: column.mandatory,
            config: column.config,
            placeholder: column.placeholder,
          })
      );

      return [primaryColumns, additionalColumns].flat();
    });
  }

  get mappingColumnsIdsMap() {
    return {};
  }
}

ConsolidatedTableAccessLink.fragment = gql`
  fragment ConsolidatedTableAccessLinkFragment on ConsolidatedTableAccessLink {
    id
    name
    uid
    primaryColumns {
      ...TablePrimaryColumnFragment
    }
    columns {
      ...TableColumnFragment
    }
  }

  ${TableColumn.fragment}
  ${TablePrimaryColumn.fragment}
`;
