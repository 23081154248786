import React from "react";
import GuestGuard from "guards/GuestGuard";
import SetupLayout from "layouts/Setup";
import { AuthFormProvider } from "views/auth/AuthFormContext";

import {
  LoginPage,
  SignUpPage,
  ResetPasswordPage,
  SuccessPage,
  SignUpConfirmPage,
  ResetPasswordConfirmPage,
  AuthSuspense,
  CreateAccountPage,
} from "./pages";

const AuthLayer = () => (
  <GuestGuard>
    <AuthFormProvider>
      <SetupLayout />
    </AuthFormProvider>
  </GuestGuard>
);

const routes = [
  {
    path: "/auth",
    element: <AuthLayer />,
    children: [
      {
        path: "login",
        element: AuthSuspense(LoginPage),
      },
      {
        path: "create-account",
        element: AuthSuspense(CreateAccountPage),
      },
      {
        path: "sign-up",
        element: AuthSuspense(SignUpPage),
      },
      {
        path: "reset-password",
        element: AuthSuspense(ResetPasswordPage),
      },
      {
        path: ":authType/success",
        element: AuthSuspense(SuccessPage),
      },
      {
        path: "sign-up/confirm",
        element: AuthSuspense(SignUpConfirmPage),
      },
      {
        path: "reset-password/confirm",
        element: AuthSuspense(ResetPasswordConfirmPage),
      },
    ],
  },
];

export default routes;
