import each from "lodash/each";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import caller from "utils/caller";

export const deepSearchById = (entities, activeId) => {
  let ids = [];

  try {
    each(entities, (entity) => {
      const result = deepSearchObject({ id: activeId, entity });

      if (result?.length > 0) {
        ids = result;
        throw String("exit");
      }
    });
  } catch (e) {
    return ids;
  }

  return ids.reverse();
};

export const searchTreeEntitiesFilter = (treeEntities, search, properties = ["name", "label"]) => {
  if (isEmpty(search)) return treeEntities;

  return searchTreeEntitiesByFilter(treeEntities, (node) =>
    isMatchSearchProperties(node, search, properties)
  );
};

export const searchTreeEntitiesByFilter = (treeEntities, matchFunction) => {
  return compact(treeEntities.map((tree) => searchTreeFilter(tree, matchFunction)));
};

export const searchFilter = (collection, search, properties = ["name", "label"]) => {
  if (isEmpty(search)) return collection;

  return collection.filter((field) => isMatchSearchProperties(field, search, properties));
};

export const searchOptions = (options, search, properties = ["name", "label"]) => {
  if (isEmpty(search)) return options;

  return options.filter((option) => {
    const field = Array.isArray(option) ? option[1] : option.label ?? option.name;

    if (typeof field === "string" || field instanceof String) {
      return isMatchSearch(field, search);
    } else {
      return properties.find((property) => isMatchSearch(caller(field, property), search));
    }
  });
};

export const validSearchValue = (value) =>
  value
    .replaceAll("$", "\\$")
    .replaceAll("+", "\\+")
    .replaceAll("(", "\\(")
    .replaceAll('"', '\\"')
    .replaceAll(")", "\\)")
    .replaceAll("*", "\\*");

// PRIVATE

export const isMatchSearch = (value, search) =>
  isEmpty(value)
    ? false
    : value.toLowerCase().search(validSearchValue(search)?.toLowerCase()) !== -1;

export const isMatchSearchProperties = (entity, search, properties) =>
  properties.find((property) => isMatchSearch(caller(entity, property), search));

const deepSearchObject = ({ id, entity, initialIds = [], treeProperty = "descendants" }) => {
  let ids = initialIds || [];

  try {
    if (String(entity.id) === String(id)) {
      ids = [...ids, entity];
      throw String("exit");
    }

    const isNested = entity[treeProperty]?.length > 0;

    if (!isNested) return [];

    each(entity.descendants, (subEntity) => {
      const nestedIds = deepSearchObject({ id, entity: subEntity, initialIds: ids });

      if (nestedIds?.length > 0) {
        ids = [...ids, ...nestedIds, entity];
        throw String("exit");
      }
    });
  } catch {
    return ids;
  }

  return ids;
};

const searchTreeFilter = (tree, matchFunction) => {
  const traverseTree = (node) => {
    const descendants = node.descendants || [];
    const filteredDescendants = descendants.map((child) => traverseTree(child)).filter(Boolean);
    const isMatch = matchFunction(node);

    if (isMatch || filteredDescendants.length > 0) {
      return {
        ...node,
        descendants: filteredDescendants,
      };
    }

    return null;
  };

  return traverseTree(tree);
};
