import React from "react";
import { Switch as MuiSwitch } from "@mui/material";
import withClasses from "components/withClasses";

const Switcher = withClasses(
  ({ innerRef, variantProps, ...props }) => <MuiSwitch ref={innerRef} {...props} />,
  "MuiSwitch",
  { size: "medium" }
);

export default Switcher;
