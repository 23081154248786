import React from "react";
import Box from "components/Box";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import clsx from "clsx";
import Typography from "components/Typography";
import Label from "components/Label";
import LabelButton from "components/LabelButton";
import { flattenTree } from "utils/object";
import { ArrowDownIcon, CrossIcon } from "icons";

export const optionName = (option) => option.label ?? option.name;

const RenderTags = ({
  classes,
  isMarkSelect,
  optionVariant,
  options,
  optionComponent: OptionComponent,
  placeholder,
  placeholderIcon,
  value,
  onRemoveItem,
  onClickItem,
}) => {
  const values = flatten([value]).filter((item) => !isEmpty(item));

  if (isEmpty(values) || isEmpty(options)) {
    return (
      <Box display="flex" alignItems="center">
        <Typography leftIcon={placeholderIcon} className={classes.placeholder}>
          {placeholder}
        </Typography>

        {isMarkSelect && <ArrowDownIcon className={classes.maskSelectIcon} />}
      </Box>
    );
  }

  const flattenOptions = flattenTree(options);
  const selectedOptionsMap = keyBy(flattenOptions, "id");
  const TagsComponent = optionVariant === "label" ? LabelsTags : TextTags;

  return (
    <TagsComponent
      classes={classes}
      values={values}
      selectedOptionsMap={selectedOptionsMap}
      OptionComponent={OptionComponent}
      isMarkSelect={isMarkSelect}
      onClickItem={onClickItem}
      onRemoveItem={onRemoveItem}
    />
  );
};

// Tags

const LabelsTags = ({
  classes,
  values,
  selectedOptionsMap,
  OptionComponent,
  isMarkSelect,
  onRemoveItem,
  onClickItem,
}) => {
  return (
    <Box style={{ display: "table" }}>
      <div className="AppInputTags-root">
        {values.map((value, index) => {
          const option = selectedOptionsMap[value];
          const isLast = index + 1 === values.length;

          if (!option) return <></>;

          const handleRemoveItem = (event) => {
            event.stopPropagation();
            onRemoveItem(event, option);
          };

          const handleClickItem = (event) => {
            event.stopPropagation();
            onClickItem(event, option);
          };

          const LabelItemComponent = onClickItem ? LabelButton : Label;

          return (
            <LabelItemComponent
              size="small"
              component="div"
              className={clsx(classes.selectValue, "AppInputTags-tag", { isLast })}
              onClick={onClickItem && handleClickItem}
              rightIcon={
                onRemoveItem
                  ? (props) => (
                      <CrossIcon
                        {...props}
                        onMouseDown={handleRemoveItem}
                        className="AppInputTags-tagIcon"
                        size="xs"
                      />
                    )
                  : null
              }
              style={{ float: "left" }}
            >
              <Box display="flex" alignItems="center">
                {OptionComponent ? <OptionComponent option={option} /> : optionName(option)}
              </Box>
            </LabelItemComponent>
          );
        })}

        {isMarkSelect && <ArrowDownIcon className={clsx(classes.maskSelectIcon, "multiline")} />}
      </div>
    </Box>
  );
};

const TextTags = ({ classes, values, selectedOptionsMap, OptionComponent, isMarkSelect }) => {
  const renderValues = values.slice(0, 2);
  const otherValues = values.slice(2, values.length);

  return (
    <Box display="flex" alignItems="center">
      {renderValues.map((value, index) => {
        const withComma = renderValues.length > 0 && index + 1 !== renderValues.length;
        const option = selectedOptionsMap[value];

        if (!option) return <></>;

        return (
          <Typography
            component="div"
            key={`${index}-${value}`}
            className={classes.selectValue}
            space={{ mr: withComma ? 0.5 : 0 }}
            style={{
              display: "flex",
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {OptionComponent ? <OptionComponent option={option} /> : optionName(option)}
            {withComma ? "," : ""}
          </Typography>
        );
      })}

      {otherValues.length > 0 && (
        <Typography space={{ ml: 1, width: 15 }}>
          {"+ "}
          {otherValues.length}
        </Typography>
      )}

      {isMarkSelect && <ArrowDownIcon className={classes.maskSelectIcon} />}
    </Box>
  );
};

export default RenderTags;
