import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import Avatar from "components/Avatar";

import { TableProProvider } from "./Provider";
import InfinityTable from "./Tables/InfinityTable";
import StaticTable from "./Tables/StaticTable";

const TablePro = ({ static: isStatic = false, pageSize = 100, collapseSize, ...props }) => {
  return (
    <TableProProvider {...props} static={isStatic}>
      {isStatic ? (
        <StaticTable pageSize={pageSize} collapseSize={collapseSize} />
      ) : (
        <InfinityTable />
      )}
    </TableProProvider>
  );
};

export const TableProTitle = ({
  label,
  description,
  children,
  icon: Icon,
  iconSize,
  iconColor,
  space,
  ...rest
}) => {
  const TitleIcon = Icon
    ? () => (
        <Avatar size="listItem" color="grey" space={{ mt: 0.5 }} variant="icon">
          <Icon size={iconSize} color={iconColor} />
        </Avatar>
      )
    : null;

  return (
    <Box
      {...rest}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      space={{ mb: 2, ...space }}
    >
      <Box display="flex">
        {TitleIcon && (
          <Box mr={2}>
            <TitleIcon  />
          </Box>
        )}
        <Box>
          <Typography variant="h1" color="dark">
            {label}
          </Typography>

          {description && (
            <Typography variant="body2" color="hint">
              {description}
            </Typography>
          )}
        </Box>
      </Box>

      {children}
    </Box>
  );
};

export default TablePro;
