import { useQuery } from "@apollo/client";
import { useWorkspace } from "contexts/Workspace";
import { useFormLayoutNamespace } from "contexts/FormLayoutNamespace";
import { noEmptyValues } from "utils/object";

import { filterSkipQueryProperties } from "./filterProperties";

export const buildQueryNamespace = ({ companyId, projectId, formLayoutId }) => {
  if (formLayoutId) {
    return [companyId, projectId, formLayoutId].join(",");
  }

  return noEmptyValues([companyId, projectId]).join(",");
};

const useQueryProxy = (query, { input = {}, loading = false, skip, ...rest } = {}) => {
  const { namespace } = useWorkspace() || {};
  const { formLayoutId } = useFormLayoutNamespace();
  const mainNamespace = input.namespace || { ...namespace, formLayoutId };

  const queryProps = {
    ...rest,
    skip: skip || loading,
    variables: {
      ...rest.variables,
      input: filterSkipQueryProperties(query, {
        ...input,
        namespace: buildQueryNamespace(mainNamespace),
      }),
    },
  };

  const { data = {}, ...restResponse } = useQuery(query, queryProps);
  const dataResponse = Object.values(data || {})[0];
  const { content } = dataResponse || {};

  if (loading) {
    return {
      loading: true,
    };
  }

  return {
    data,
    payload: dataResponse,
    content,
    ...restResponse,
  };
};

export default useQueryProxy;
