import {
  MEDIA_TYPE,
  LIST_TYPE,
  OBJECT_TYPE,
  TABLE_CONNECTOR_TYPE,
} from "models/abstract/FieldType";
import { isFile } from "utils/file";
import compact from "lodash/compact";

export const convertToMutationVariables = (formFields, submitInput) => {
  let input = { ...submitInput };
  let mutationFiles = [];

  const values = formFields.map((formField) => ({
    columnId: formField.id,
    type: formField.type,
    value: formFieldMutatiomInputValue(formField.type, input[formField.id], mutationFiles),
  }));

  return { files: mutationFiles, values };
};

export const buildMutationVariables = (formFields, { values, fieldsIds }) => {
  const filtredFormFields = fieldsIds
    ? formFields.filter((field) => fieldsIds.includes(field.id))
    : formFields.filter((field) => field.editable);

  return convertToMutationVariables(filtredFormFields, values);
};

export const formFieldMutatiomInputValue = (type, formValue, mutationFiles) => {
  if (type === LIST_TYPE) {
    return compact([formValue].flat()).map((item) => item.id);
  }

  if (type === OBJECT_TYPE) {
    return Object.values(formValue || {}).map((item) => ({
      ...item,
      value: formFieldMutatiomInputValue(item.type, item.value, mutationFiles),
    }));
  }

  if (type === TABLE_CONNECTOR_TYPE) {
    return compact([formValue].flat()).map((item) => ({
      id: item.id,
      values: compact([item.values].flat()).map((itemValue) => ({
        ...itemValue,
        value: formFieldMutatiomInputValue(itemValue.type, itemValue.value, mutationFiles),
      })),
    }));
  }

  if (isFile(formValue) && type === MEDIA_TYPE) {
    mutationFiles.push(formValue);

    return { filename: formValue.name };
  }

  return formValue;
};
