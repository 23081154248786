import React from "react";
import { ArrowLeftIcon2 } from "icons";
import IconButton from "components/IconButton";
import Box from "components/Box";
import Divider from "components/Divider";
import { variantsClassNames } from "theme/utils";
import clsx from "clsx";

import { useDrawerLayout } from "./Provider";

import "styles/App/Drawer/DrawerSidebar.scss";

const useDrawerSidebarStyles = ({ size = "medium" } = {}) => ({
  root: variantsClassNames("AppDrawerSidebar-root", { size }),
});

const useDrawerSidebarContentStyles = ({ size = "none" } = {}) => ({
  root: variantsClassNames("AppDrawerSidebar-content", { size }),
});

const DrawerSidebarRoot = ({ children, comments = {}, size, style }) => {
  const classes = useDrawerSidebarStyles({ size });
  const { visibleSidebar, commentMode } = useDrawerLayout();

  return (
    <Box style={style} className={clsx(classes.root, { hidden: !visibleSidebar })}>
      {commentMode ? comments : children}
    </Box>
  );
};

const DrawerSidebarContent = ({ children, className, size, topSpace, ...props }) => {
  const classes = useDrawerSidebarContentStyles({ size });

  return (
    <Box {...props} className={clsx(classes.root, className, { topSpace })}>
      {children}
    </Box>
  );
};

const DrawerSidebarCompactContent = (props) => <DrawerSidebarContent {...props} size="compact" />;

const DrawerSidebarDivider = ({ space, builderSidebar }) => {
  return (
    <Divider
      className={clsx("AppDrawerSidebar-divider", { builderSidebar })}
      variant="light"
      space={{ ...space }}
    />
  );
};

const DrawerBackButton = ({ onClick }) => (
  <IconButton space={{ ml: -1, mr: 0.5 }} size="small" icon={ArrowLeftIcon2} onClick={onClick} />
);

export const DrawerSidebar = {
  Root: DrawerSidebarRoot,
  Content: DrawerSidebarContent,
  CompactContent: DrawerSidebarCompactContent,
  Divider: DrawerSidebarDivider,
  BackButton: DrawerBackButton,
};
