import React from "react";
import { IconButton as MuiIconButton } from "@mui/material";
import withClasses from "components/withClasses";
import CircularProgress from "components/CircularProgress";

const ICON_SIZE_MAP = {
  xs: "xs",
  collapse: "small",
  compact: "small",
  table: "small",
  small: "small",
  medium: "medium",
  large: "large",
  labelSmall: "medium",
  labelMedium: "medium",
};

const IconButton = ({
  icon: Icon,
  color,
  children,
  iconSize,
  iconVariant = "default",
  variantProps,
  innerRef,
  readOnly = false,
  circleLoading = false,
  ...btnProps
}) => {
  return (
    <MuiIconButton
      ref={innerRef}
      {...btnProps}
      disabled={readOnly || circleLoading || btnProps.disabled}
    >
      {circleLoading ? (
        <CircularProgress size={iconSize || ICON_SIZE_MAP[variantProps.size]} />
      ) : (
        Icon && (
          <Icon
            color={color}
            variant={iconVariant}
            size={iconSize || ICON_SIZE_MAP[variantProps.size]}
          />
        )
      )}

      {children}
    </MuiIconButton>
  );
};

export default withClasses(IconButton, "MuiIconButton", {
  size: "medium",
  variant: null,
  active: false,
});
