import React, { useMemo, useEffect } from "react";
import useRows from "hooks/useRows";
import some from "lodash/some";
import keyBy from "lodash/keyBy";
import JSum from "vendor/jsum";
import {
  BOOLEAN_COMPONENT_TYPE,
  STRING_COMPONENT_TYPE,
  TEXT_COMPONENT_TYPE,
  NUMBER_COMPONENT_TYPE,
  DATE_TIME_COMPONENT_TYPE,
  DATE_COMPONENT_TYPE,
  TIME_COMPONENT_TYPE,
  isDatableType,
} from "components/ComponentType/types";

const FilterContext = React.createContext(null);

const OPERATIONS = {
  EQ: "is equal",
  NE: "is not equal",
  LT: "is less than",
  GT: "is more than",
  STARTS: "starts from",
  ENDS: "ends with",
  CONTAINS: "contains",
};

const DATE_TIME_OPERATIONS = {
  EQ: "on",
  NE: "is not on",
  LT: "in the last",
  GT: "since",
  BETWEEN: "between",
};

const TYPES_OPTIONS = {
  [BOOLEAN_COMPONENT_TYPE]: ["EQ", "NE"],
  [STRING_COMPONENT_TYPE]: ["EQ", "NE", "STARTS", "ENDS", "CONTAINS"],
  [TEXT_COMPONENT_TYPE]: ["EQ", "NE", "STARTS", "ENDS", "CONTAINS"],
  [NUMBER_COMPONENT_TYPE]: ["EQ", "NE", "LT", "GT"],
  [DATE_TIME_COMPONENT_TYPE]: Object.keys(DATE_TIME_OPERATIONS),
  [DATE_COMPONENT_TYPE]: Object.keys(DATE_TIME_OPERATIONS),
  [TIME_COMPONENT_TYPE]: Object.keys(DATE_TIME_OPERATIONS),
};

const rowsChecksum = (value) =>
  JSum.digest(
    value.map((item) => ({ ...item, id: null })),
    "SHA256",
    "hex"
  );

export const fetchComponentTypeRulesOptions = (componentType) => {
  const operations = isDatableType(componentType) ? DATE_TIME_OPERATIONS : OPERATIONS;
  const ruleOptions = TYPES_OPTIONS[componentType] || Object.keys(operations);
  const operationsOptionsMap = ruleOptions.map((ruleId) => [ruleId, operations[ruleId]]);

  return operationsOptionsMap;
};

const convertFiltersToRows = (filterColumnsMap, filters = []) => {
  return filters.reduce((result, item, index) => {
    const column = filterColumnsMap[item.columnId];

    if (!column) return result;

    return [
      ...result,
      {
        id: item.id || Date.now() + index,
        value: item.value,
        columnId: item.columnId,
        field: item.columnId,
        operation: item.operation,
        formField: column.formField,
        label: column.label,
      },
    ];
  }, []);
};

export const FilterProvider = ({ columns, onApply, onClean, children, filter }) => {
  const filterColumns = useMemo(
    () => columns.filter((column) => !column.disabledFilter && !!column.formField),
    [columns]
  );

  const filterColumnsMap = useMemo(() => keyBy(filterColumns, "id"), [filterColumns]);

  const filterRows = useMemo(
    () => convertFiltersToRows(filterColumnsMap, filter),
    [filterColumnsMap, filter]
  );

  const { updateRows, updateRow, isDirty, setIsDirty, rows } = useRows(filterRows);

  const handleAdd = (columnId) => {
    const column = filterColumnsMap[columnId];
    const operation = fetchComponentTypeRulesOptions(column.formField?.componentType)[0][0];
    const filterColumnId = column.filterId || columnId;

    updateRows([
      ...rows,
      {
        id: Date.now(),
        columnId: filterColumnId,
        formField: column.formField,
        value: null,
        label: filterColumnsMap[columnId].label,
        operation,
      },
    ]);
  };

  const handleChange = (id, props, { apply } = {}) => {
    const nextRows = updateRow(id, props);

    if (apply) {
      setIsDirty(false);
      onApply && onApply(nextRows);
    }
  };

  const handleRemove = (id, { apply } = {}) => {
    const newRows = updateRows(rows.filter((row) => row.id !== id));

    if (newRows.length === 0 && onClean) {
      onClean();
      return;
    }

    if (apply) {
      setIsDirty(false);
      onApply && onApply(newRows);
    }
  };

  const handleClean = () => {
    updateRows([]);
    onClean && onClean();
  };

  const isActiveApply = !some(rows, (row) => row.value === null || row.value === undefined);

  const handleApply = () => {
    setIsDirty(false);
    onApply && onApply(rows);
  };

  useEffect(() => {
    updateRows(filterRows);
  }, [rowsChecksum(filterRows)]);

  const value = {
    columns: filterColumns,
    filters: rows,
    isValid: isActiveApply,
    isDirty,
    onAdd: handleAdd,
    onChange: handleChange,
    onRemove: handleRemove,
    onClean: handleClean,
    onApply: handleApply,
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useFilter = () => React.useContext(FilterContext);
