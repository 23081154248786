import React, { useState } from "react";
import Box from "components/Box";
import isEmpty from "lodash/isEmpty";
import groupBy from "lodash/groupBy";
import { GroupedVirtuoso } from "react-virtuoso";
import caller from "utils/caller";
import { useSideNavigationListStyles } from "theme/components/SideNavigation";

import { CollapseMenuItem } from "components/Menu/CollapseMenuItem";
import { ListItem } from "./RenderOptions";
import { useSelectMenuStyles } from "../index";

export const RenderGroupOptions = ({
  markVariant,
  optionComponent,
  virtualized,
  isSelected,
  options,
  totalCount,
  onChange,
  onLoadMore,
  groupProperty,
  virtuosoHeight,
}) => {
  const navigationListClasses = useSideNavigationListStyles();
  const selectClasses = useSelectMenuStyles();

  const [collapsedMap, setCollapsedMap] = useState({});
  const isCollapsed = (groupKey) => !!collapsedMap[groupKey];

  const groupedOptionsMap = groupBy(options, (option) => caller(option.label, groupProperty));

  const groups = Object.keys(groupedOptionsMap).reduce((result, group, index) => {
    const groupKey = `${group}-${index}`;

    const groupItem = {
      group,
      index,
      key: groupKey,
      options: isCollapsed(groupKey) ? [] : groupedOptionsMap[group],
    };

    return [...result, groupItem];
  }, []);

  const groupCountsArray = groups.map((group) => group.options.length);

  const hiddenGroupsOptionsIds = groups
    .filter((group) => isCollapsed(group.key))
    .flatMap((group) => groupedOptionsMap[group.group])
    .map((option) => option.id);

  const renderOptions = options.filter((option) => !hiddenGroupsOptionsIds.includes(option.id));
  const renderTotalCount = totalCount - hiddenGroupsOptionsIds.length;

  if (!virtualized) {
    return groups.map((groupItem, groupIndex) => (
      <div key={`${groupItem.group}-${groupIndex}`}>
        {!isEmpty(groupItem.group) && (
          <CollapseMenuItem
            size="small"
            collapsed={!!collapsedMap[groupItem.key]}
            label={groupItem.group}
            optionId={groupItem.key}
            iconClassName={navigationListClasses.cornerIcon}
            collapsedMap={collapsedMap}
            setCollapsedMap={setCollapsedMap}
          />
        )}

        {groupItem.options.map((option, index) => (
          <Box key={`${option.id}-${index}`} space={{ ml: 3 }}>
            <ListItem
              isSelected={isSelected}
              option={option}
              markVariant={markVariant}
              optionComponent={optionComponent}
              onChange={onChange}
            />
          </Box>
        ))}
      </div>
    ));
  }

  return (
    <GroupedVirtuoso
      style={{ height: virtuosoHeight }}
      overscan={100}
      totalCount={renderTotalCount}
      endReached={onLoadMore}
      groupCounts={groupCountsArray}
      groupContent={(index) => {
        return (
          <div className={selectClasses.CollapseMenuItem}>
            <CollapseMenuItem
              collapsed={!!collapsedMap[groups[index].key]}
              label={groups[index].group}
              optionId={groups[index].key}
              iconClassName={navigationListClasses.cornerIcon}
              collapsedMap={collapsedMap}
              setCollapsedMap={setCollapsedMap}
            />
          </div>
        );
      }}
      itemContent={(index) => {
        const option = renderOptions[index];

        if (!option) return <></>;

        return (
          <Box space={{ ml: 3 }}>
            <ListItem
              isSelected={isSelected}
              option={option}
              onChange={onChange}
              markVariant={markVariant}
              optionComponent={optionComponent}
            />
          </Box>
        );
      }}
    />
  );
};
