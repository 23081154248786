import { useReducer } from "react";
import pick from "lodash/pick";
import { ImmerReducer, createActionCreators, createReducerFunction } from "vendor/immer-reducer";

const initialState = {
  page: 0,
  size: 200,
  search: null,
  loaded: false,
  filter: [],
  sort: [],
};

class QueryStateReducer extends ImmerReducer {
  setPage(value) {
    this.draftState.page = value;
  }

  setSize(value) {
    this.draftState.size = value;
  }

  setSearch(value) {
    this.draftState.search = value;
  }

  setLoaded(value) {
    this.draftState.loaded = value;
  }

  setFilter(value) {
    this.draftState.filter = value;
  }

  setSort(value) {
    this.draftState.sort = value;
  }

  setViewState(props) {
    this.draftState = { ...this.draftState, ...pick(props, ["filter", "sort"]) };
  }
}

const reducerAction = createActionCreators(QueryStateReducer);
const queryReducer = createReducerFunction(QueryStateReducer);

export const usePaginationQueryReducer = ({ input, skip }) => {
  const [state, dispatch] = useReducer(queryReducer, {
    ...initialState,
    page: input.page || initialState.page,
    size: input.size || initialState.size,
    search: input.search || initialState.search,
    loaded: skip,
  });

  const setPage = (value) => dispatch(reducerAction.setPage(value));
  const setSize = (value) => dispatch(reducerAction.setSize(value));
  const setSearch = (value) => dispatch(reducerAction.setSearch(value));
  const setLoaded = (value) => dispatch(reducerAction.setLoaded(value));
  const setFilter = (value) => dispatch(reducerAction.setFilter(value));
  const setSort = (value) => dispatch(reducerAction.setSort(value));
  const setViewState = (props) => dispatch(reducerAction.setViewState(props));

  return {
    state,
    ...state,
    setPage,
    setSize,
    setSearch,
    setLoaded,
    setFilter,
    setSort,
    viewState: { filter: state.filter || [], sort: state.sort || [] },
    setViewState,
  };
};
