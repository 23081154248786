import React from "react";
import Typography from "components/Typography";
import { humanize } from "utils/string";

const NameTitle = ({
  component: Component = Typography,
  leftIcon,
  name,
  uid,
  formatting = true,
  variant,
  space,
}) => {
  return (
    <Component
      space={space}
      leftIcon={leftIcon}
      className="ellipses"
      style={{ whiteSpace: "nowrap" }}
      component="span"
      variant={variant}
    >
      <NameTitleContent name={name} uid={uid} formatting={formatting} />
    </Component>
  );
};

export const NameTitleContent = ({ name, uid, formatting = true }) => {
  return (
    <>
      {formatting ? humanize(name) : name}{" "}
      {uid && (
        <Typography component="span" color="grey">
          {uid}
        </Typography>
      )}
    </>
  );
};

export const EntityNameTitle = ({ entity, option }) => {
  const value = option?.label || entity;

  return <NameTitle name={value?.name || value?.label} uid={value?.uid} />;
};

export default NameTitle;
