import React, { useMemo, useEffect, useState } from "react";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import isEmpty from "lodash/isEmpty";
import { FIELD_MODEL_TYPE, TABLE_MODEL_TYPE } from "models/types";
import { LayoutStructure } from "models/abstract/LayoutStructure";
import { useSearchTables } from "models/Table/queries";
import { useSearchFields } from "models/Field/queries";

export const ADD_TABLE_COLUMN_MODE = "ADD_TABLE_COLUMN_MODE";
export const ADD_FIELD_MODE = "ADD_FIELD_MODE";

const BuilderFormLayoutContext = React.createContext(null);

export const useBuilderFormLayout = () => React.useContext(BuilderFormLayoutContext);

export const BuilderFormLayoutProvider = ({
  builderStructure,
  children,
  filterElements = false,
}) => {
  const [formActionIndex, setFormActionIndex] = useState(null);
  const [fieldsStoreMap, setFieldsStoreMap] = useState({});

  const itemsProps = useMemo(
    () => LayoutStructure.fetchItemsProps(builderStructure),
    [builderStructure]
  );

  const { fieldIds, tableIds } = useMemo(
    () => parseBuilderStructure(builderStructure),
    [builderStructure]
  );

  const { tables, loading: loadingTables } = useSearchTables({
    searchInput: {
      filter: filterElements
        ? isEmpty(tableIds)
          ? []
          : [{ field: "id", operation: "IN", value: tableIds.join(",") }]
        : [],
    },
  });

  const { fields, loading: loadingFields } = useSearchFields({
    searchInput: {
      filter: filterElements
        ? isEmpty(fieldIds)
          ? []
          : [{ field: "id", operation: "IN", value: fieldIds.join(",") }]
        : [],
      includeSubfolders: true,
    },
  });

  const searchFieldsQuery = useSearchFields({
    searchInput: {
      includeSubfolders: true,
    },
  });

  const { fields: searchFields, loading: loadingSearchFields } = searchFieldsQuery;

  const selectFieldsQuery = useSearchFields({
    searchInput: {
      includeSubfolders: true,
    },
  });

  const tablesWithItems = useMemo(
    () => tables.filter((table) => table.columns.length > 0),
    [tables]
  );

  const fieldsBuilderItems = useMemo(
    () => searchFields.map((field) => field.buildBuilderItem(itemsProps)),
    [searchFields, itemsProps]
  );

  const [builderItems, allItemsMap] = useMemo(() => {
    const allFields = Object.values(fieldsStoreMap);

    let items = [];
    items = items.concat(tablesWithItems.flatMap((table) => table.buildBuilderItems(itemsProps)));
    items = items.concat(allFields.map((field) => field.buildBuilderItem(itemsProps)));

    const itemsMap = keyBy(items, "id");

    return [items, itemsMap];
  }, [tablesWithItems, fieldsStoreMap, itemsProps]);

  const tablesMap = useMemo(() => keyBy(tables, "id"), [tables]);

  const [builderTableProxyItems, builderTableProxyItemsMap] = useMemo(
    () => parseTablesWithItems(tablesWithItems, itemsProps),
    [tablesWithItems, itemsProps]
  );

  useEffect(() => {
    const fieldsMap = keyBy(searchFields.concat(fields), "id");

    setFieldsStoreMap({ ...fieldsStoreMap, ...fieldsMap });

    return () => setFieldsStoreMap({});
  }, [fieldsChecksum([...searchFields, ...fields, ...selectFieldsQuery.fields])]);

  return (
    <BuilderFormLayoutContext.Provider
      value={{
        builderStructure,
        tables,
        tablesMap,
        fieldsStoreMap,
        tablesWithItems,
        builderTableProxyItems,
        builderTableProxyItemsMap,
        loading: loadingTables || loadingSearchFields || loadingFields,
        allItemsMap,
        loadingTables,
        loadingFields,
        builderItems,
        searchFieldsQuery,
        fieldsBuilderItems,
        selectFieldsQuery,
        formActionIndex,
        setFormActionIndex,
        itemsProps,
      }}
    >
      {children}
    </BuilderFormLayoutContext.Provider>
  );
};

const fieldsChecksum = (fields) =>
  uniq(fields.map((field) => field.id))
    .sort()
    .join(",");

const parseBuilderStructure = (builderStructure) => {
  if (!builderStructure) return { fieldIds: [], tableIds: [] };

  let fieldIdsMap = {};
  let tableIdsMap = {};

  builderStructure.forEach((section) => {
    section.containers.forEach((container) => {
      container.items.forEach((item) => {
        const type = item.path[0]?.type;
        const id = item.path[0]?.id;

        if (type === FIELD_MODEL_TYPE) {
          fieldIdsMap[id] = true;
          return;
        }

        if (type === TABLE_MODEL_TYPE) {
          tableIdsMap[id] = true;
          return;
        }
      });
    });
  });

  return { fieldIds: Object.keys(fieldIdsMap), tableIds: Object.keys(tableIdsMap) };
};

const parseTablesWithItems = (tablesWithItems, itemsProps) => {
  let items = [];

  const itemsMap = tablesWithItems.reduce((result, table) => {
    table.buildBuilderItems(itemsProps).forEach((builderItem) => {
      const item = { tableId: table.id, item: builderItem };
      items = [...items, item];
      result[builderItem.id] = item;
    });

    return result;
  }, {});

  return [items, itemsMap];
};
