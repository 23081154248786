import React, { memo } from "react";
import TableCell from "./TableCell";
import Box from "components/Box";
import Skeleton from "@mui/material/Skeleton";
import { callerField } from "utils/tablePro";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import compact from "lodash/compact";
import JSum from "vendor/jsum";
import clsx from "clsx";

import TableRowActions from "./TableRowActions";
import { DefaultCell } from "../TableCells/Cells";
import { useStateFields } from "../Contexts/StateFieldsContext";
import { useStickFields } from "../Contexts/StickFieldsContext";

export const RowCells = ({
  collapseLevel,
  nestedLevel,
  rowIndex,
  columns,
  entity,
  widthMap,
  classes,
  onChange,
  onSave,
  onRemove,
  collapsed,
  setCollapse,
  draggableProps,
  errors,
  errorsById,
  viewable,
  commentable,
}) => {
  const { stickedColumnId } = useStickFields();
  const { bulkSelect, checkedIds, selectedId } = useStateFields();

  const checked = bulkSelect || checkedIds.includes(entity?.id);
  const selected = selectedId === entity?.id;
  const loading = !entity;

  const rowErrorId = errorsById
    ? entity.id
    : entity?.index || entity?.index === 0
    ? entity.index
    : rowIndex;

  const rowErrors = errors[rowErrorId] || {};

  const entityId = entity?.id;

  if (!entityId) return <></>;

  const rootErrorsMessage = uniq(
    compact(Object.values(rowErrors).map((error) => error?.message))
  ).join(", ");

  return columns.map((column, colIndex) => {
    const value = loading ? null : callerField(entity, column);
    const FieldViewComponent = column.component || DefaultCell;
    const isSystem = ["CHECKBOX", "EDIT"].includes(column.id);
    const cellErrorMessage = rowErrors[column.id]?.message ?? rowErrors[column.id];
    const FieldContainer = colIndex === 1 ? WithActionsContainer : StubContainer;

    return (
      <TableCell
        key={`${column.id}-${rowIndex}-${colIndex}`}
        className={clsx(classes.cell, column.className)}
        rowIndex={rowIndex}
        entityId={entityId}
        column={column}
        columns={columns}
        checked={checked}
        selected={selected}
        stickedColumnId={stickedColumnId}
        widthMap={widthMap}
      >
        {loading && !isSystem ? (
          <Skeleton variant="rect" height="14px" width="35%" />
        ) : (
          <FieldContainer
            entity={entity}
            tableColumn={column}
            viewable={viewable}
            commentable={commentable}
          >
            <FieldViewComponent
              collapseLevel={collapseLevel}
              nestedLevel={nestedLevel}
              rowIndex={rowIndex}
              colIndex={colIndex}
              classes={classes}
              disabled={loading}
              column={column}
              fieldId={column.id}
              entity={entity || {}}
              value={value}
              onChange={onChange}
              onSave={onSave}
              onRemove={onRemove}
              collapsed={collapsed}
              setCollapse={setCollapse}
              draggableProps={draggableProps}
              error={cellErrorMessage}
              rowRootError={column.root ? rootErrorsMessage : null}
            />
          </FieldContainer>
        )}
      </TableCell>
    );
  });
};

const checksum = (value) => JSum.digest(value, "SHA256", "hex");

const WithActionsContainer = ({ children, entity, tableColumn, viewable, commentable }) => {

  return (
    <Box style={{ position: "relative", height: "100%" }}>
      {children}

      <TableRowActions
        entity={entity}
        tableColumn={tableColumn}
        viewable={viewable}
        commentable={commentable}
      />
    </Box>
  );
};

const StubContainer = ({ children }) => <>{children}</>;

const isEqualRow = (prev, next) => {
  if (prev.rowIndex !== next.rowIndex) {
    return false;
  }

  if (!isEqual(prev.className, next.className)) {
    return false;
  }

  if (!isEqual(prev.errors, next.errors)) {
    return false;
  }

  if (!isEqual(prev.errorsById, next.errorsById)) {
    return false;
  }

  if (!isEqual(prev.classes, next.classes)) {
    return false;
  }

  if (!isEqual(prev.onChange, next.onChange)) {
    return false;
  }

  if (!isEqual(prev.onSave, next.onSave)) {
    return false;
  }

  if (!isEqual(prev.onRemove, next.onRemove)) {
    return false;
  }

  if (!isEqual(prev.nestedLevel, next.nestedLevel)) {
    return false;
  }

  if (!isEqual(prev.collapseLevel, next.collapseLevel)) {
    return false;
  }

  if (!isEqual(prev.collapsed, next.collapsed)) {
    return false;
  }

  if (!isEqual(prev.setCollapse, next.setCollapse)) {
    return false;
  }

  if (!isEqual(prev.draggableProps, next.draggableProps)) {
    return false;
  }

  if (!isEqual(prev.entity, next.entity)) {
    return false;
  }

  if (!isEqual(prev.widthMap, next.widthMap)) {
    return false;
  }

  if (checksum(prev.columns) !== checksum(next.columns)) {
    return false;
  }

  return true;
};

export const TableRowCells = memo(RowCells, isEqualRow);
