import React from "react";
import Typography, { TypographyValue, TypographyBoolean } from "components/Typography";
import { AvatarProduct } from "components/Avatar";
import { isFileImage } from "utils/detect";
import FileLabel from "components/Labels/FileLabel";
import PhoneLabel from "components/Labels/PhoneLabel";

import { MEDIA_COMPONENT_TYPE, PHONE_COMPONENT_TYPE, BOOLEAN_COMPONENT_TYPE } from "./types";

export const ComponentTypeView = ({
  hint,
  type,
  label,
  className,
  loading,
  originValue,
  value: parsedValue,
  onClick,
  emptyMessage,
}) => {
  if (type === MEDIA_COMPONENT_TYPE && originValue?.url && isFileImage(originValue.filename)) {
    return (
      <AvatarProduct
        className={className}
        onClick={onClick}
        form="square"
        size="drawerField"
        src={originValue?.url}
      />
    );
  }

  if (type === MEDIA_COMPONENT_TYPE && originValue?.url) {
    return <FileLabel className={className} value={originValue} disabled={loading} />;
  }

  if (type === PHONE_COMPONENT_TYPE) {
    return <PhoneLabel className={className} value={originValue} />;
  }

  if (type === BOOLEAN_COMPONENT_TYPE) {
    return <TypographyBoolean className={className} value={originValue} />;
  }

  return (
    <>
      {label && (
        <Typography space={{ pb: 1 }} variant="inputLabel">
          {label}
        </Typography>
      )}

      <TypographyValue
        emptyMessage={emptyMessage}
        className={className}
        onClick={onClick}
        loading={loading}
        value={parsedValue}
        color={hint ? "hint" : undefined}
      />
    </>
  );
};
