import { TABLE_COLUMN_MODEL_TYPE, TABLE_MODEL_TYPE } from "models/types";

export class MappingPath {
  static separateDelimiter = "#";
  static connectDelimiter = "+";

  static convertToPathId(id, type) {
    return `${id}${MappingPath.connectDelimiter}${type}`;
  }

  static convertPathToPathId(path) {
    if (!path) return null;

    return path
      .map((item) => `${item.id}${MappingPath.connectDelimiter}${item.type}`)
      .join(MappingPath.separateDelimiter);
  }

  static convertPathIdToPath = (id, typeFunc) => {
    if (!id) return [];

    return id.split(MappingPath.separateDelimiter).map((path) => {
      const [id, type] = path.split(MappingPath.connectDelimiter);
      return { id, type: typeFunc ? typeFunc(id, type) : type };
    });
  };

  static fetchFieldId(path) {
    return path[path.length - 1]?.id;
  }

  static fetchFieldType(path) {
    return path[path.length - 1]?.type;
  }

  static fetchFieldTypeFromPathId(pathId) {
    const path = MappingPath.convertPathIdToPath(pathId);
    return path[path.length - 1]?.type;
  }

  static fetchFieldIdFromPathId(pathId) {
    const path = MappingPath.convertPathIdToPath(pathId);
    return path[path.length - 1]?.id;
  }

  static fromEntityToItemPathId(entity) {
    switch (entity.modelType) {
      case TABLE_COLUMN_MODEL_TYPE:
        return [
          `${entity.tableId}${MappingPath.connectDelimiter}${TABLE_MODEL_TYPE}`,
          `${entity.id}${MappingPath.connectDelimiter}${entity.modelType}`,
        ].join(MappingPath.separateDelimiter);
      default:
        return `${entity.id}${MappingPath.connectDelimiter}${entity.modelType}`;
    }
  }

  static fromEntityToItemPath(entity) {
    const pathId = MappingPath.fromEntityToItemPathId(entity);

    return MappingPath.convertPathIdToPath(pathId);
  }
}
