export const onFieldsRemove = ({ ids, collection, property = "id", remove }) => {
  const collectionIds = collection.map((field) => field[property]);
  const removeIds = ids.includes("BULK_SELECT") ? collectionIds : ids;

  let indexes = [];

  removeIds.forEach((id) => {
    const index = collectionIds.indexOf(id);
    if (index >= 0) indexes = [...indexes, index];
  });

  return remove(indexes);
};

export const onFieldsSync = ({ ids, collection, property = "id", onRemove, onAppend }) => {
  const collectionIds = collection.map((field) => field[property]);

  const addedtableFieldsIds = ids.filter((fieldId) => !collectionIds.includes(fieldId));
  const removedtableFieldsIds = collectionIds.filter((fieldId) => !ids.includes(fieldId));

  removedtableFieldsIds.length > 0 && onRemove(removedtableFieldsIds, property);
  addedtableFieldsIds.forEach((fieldId) => onAppend(fieldId));
};
