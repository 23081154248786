import React, { useEffect, useMemo } from "react";
import * as Yup from "yup";
import { setErrors } from "utils/form";
import { useForm } from "react-hook-form";
import { submitProxy, submitCurry } from "utils/form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormLayoutMutations } from "models/FormLayout/mutations";
import { useNotification } from "contexts/Notifications";

import { buildRequestInput } from "./buildRequestInput";
import { mergeResponseInput } from "./mergeResponseInput";

const errorKeyMapper = (key) => {
  return key.replace(/^data(?:\[(\d+)\])?\.(.+)$/, (_, p1, p2) => (p1 ? `${p1}.${p2}` : p2));
};

export const useExecuteFormAction = ({ formLayout, loading }) => {
  const { notifySuccess, notifyError } = useNotification();
  const formFields = [];
  const { executeFormLayoutAction } = useFormLayoutMutations();

  const formSchema = useMemo(() => Yup.object({}), [formFields]);

  const { handleSubmit, control, clearErrors, setError, formState, reset, setValue, getValues } =
    useForm({
      mode: "onChange",
      resolver: yupResolver(formSchema),
    });

  const resetValues = () => {
    reset({});
  };

  const onSubmit = submitProxy((props) => {
    const { input, allItemsMap, actionId, onSuccess, onFailure, filters } = props;

    const action = formLayout?.actions?.find((action) => action.id === actionId);

    if (!action) return;

    const mutationVariables = buildRequestInput({
      formLayout,
      action,
      allItemsMap,
      input,
      filters,
    });

    return executeFormLayoutAction({
      input: { id: formLayout.id, actionId, ...mutationVariables, flatFormat: true },
      onSuccess: (data) => {
        onSuccess && onSuccess(data);

        const newInput = mergeResponseInput({
          structure: formLayout.builderStructure,
          input,
          action,
          response: data.response,
          allItemsMap,
        });

        reset(newInput);

        notifySuccess(`${action.name} executed successfully`);
      },
      onFailure: (errors) => {
        notifyError(errors);
        setErrors(setError, errors, errorKeyMapper);
        onFailure && onFailure(errors);
      },
    });
  });

  useEffect(() => {
    resetValues();
  }, [formLayout?.id, loading, formLayout?.lastModifiedDate]);

  return {
    formLayout,
    loading,
    formState,
    resetValues,
    control,
    setValue,
    getValues,
    clearErrors,
    handleSubmit: submitCurry(handleSubmit, onSubmit),
  };
};

const ExecuteFormContext = React.createContext(null);

export const ExecuteFormProvider = ({ children, ...props }) => {
  return <ExecuteFormContext.Provider value={props}>{children}</ExecuteFormContext.Provider>;
};

export const useExecuteFormContext = () => React.useContext(ExecuteFormContext);
