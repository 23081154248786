import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import JSum from "vendor/jsum";
import { calculateWidth } from "utils/tablePro";

const checksum = (value) => JSum.digest(value, "SHA256", "hex");

const useWidthMap = ({ fields, entities, onChange }) => {
  const [widthMap, setWidthMap] = useState({});

  const handleSetWidthMap = (value) => {
    setWidthMap(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    setWidthMap(calculateWidth(fields, entities));
    // eslint-disable-next-line
  }, [checksum(fields), isEmpty(entities)]);

  return [widthMap, handleSetWidthMap];
};

// export const useSyncTablesWidthMap = ({ main, secondary, loading }) => {
//   const [widthMap, setWidthMap] = useState({});

//   if (main.fields.length !== secondary.fields.length) throw "No match fields length";

//   const mainWidthMap = useWidthMap({
//     entities: main.entities,
//     fields: main.fields,
//     loading,
//   });

//   const secondaryWidthMap = useWidthMap({
//     entities: secondary.entities,
//     fields: secondary.fields,
//     loading,
//   });

//   useEffect(() => {
//     let syncMainWidthMap = {};
//     let syncSecondaryWidthMap = {};

//     main.fields.forEach((mainField, index) => {
//       const secondaryField = secondary.entities[index];
//       const maxWidth = max(mainWidthMap[mainField.id], secondaryWidthMap[secondaryField.id]);

//       syncMainWidthMap[mainField.id] = maxWidth;
//       syncSecondaryWidthMap[secondaryField.id] = maxWidth;
//     });

//     setWidthMap({ main: syncMainWidthMap, secondary: syncSecondaryWidthMap });
//   }, [checksum(mainWidthMap), checksum(secondaryWidthMap)]);

//   return {
//     mainWidthMap: widthMap.main || {},
//     secondaryWidthMap: widthMap.secondary || {},
//   };
// };

export default useWidthMap;
