import React from "react";
import Typography from "components/Typography";
import FormControl from "components/FormControl";
import Box from "components/Box";
import Switch from "components/Switcher";

const SwitchField = ({
  name,
  label,
  verticalLabel,
  error,
  value,
  onChange,
  fullWidth,
  readOnly = false,
  space = {},
  defaultValue,
  ...rest
}) => {
  const handleChange = (_event, value) => {
    onChange && onChange(Boolean(value));
  };

  return (
    <FormControl space={{ mb: 3, ...space }} fullWidth={fullWidth}>
      {label && (
        <Typography space={{ ml: 1 }} error={`${!!error}`} variant="inputLabel">
          {label}
        </Typography>
      )}

      <Box display="flex" alignItems="center">
        <Switch
          {...rest}
          checked={value}
          defaultChecked={defaultValue}
          onChange={readOnly ? null : handleChange}
          inputProps={{ ...rest.inputProps, name }}
        />

        {verticalLabel && (
          <Typography space={{ ml: 1 }} error={`${!!error}`} variant="inputLabel">
            {verticalLabel}
          </Typography>
        )}
      </Box>
    </FormControl>
  );
};

export default SwitchField;
