import { gql } from "@apollo/client";
import { delegate, memoize, serialize } from "utils/entities";
import keyBy from "lodash/keyBy";
import compact from "lodash/compact";
import { FormField } from "models/abstract/FormField";
import { TableAccess } from "./TableAccess";
import { SECONDARY_TABLE_ACCESS_LINK_MODEL_TYPE } from "models/types";

export class SecondaryTableAccessLink {
  constructor(origin) {
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin);

    serialize(this, { secondaryTable: TableAccess, primaryTable: TableAccess });

    this.mapping = this.origin.mapping || [];
  }

  get linkType() {
    return "SECONDARY";
  }

  get modelType() {
    return SECONDARY_TABLE_ACCESS_LINK_MODEL_TYPE;
  }

  get label() {
    return this.secondaryTable.name;
  }

  get mappingColumnsIdsMap() {
    return this.memoize("mappingColumnsIdsMap", () => {
      return this.mapping.reduce(
        (result, field) => ({ ...result, [field.primaryColumnId]: field.secondaryColumnId }),
        {}
      );
    });
  }

  get formFields() {
    return this.memoize("formFields", () => {
      const secondaryColumnMap = keyBy(this.secondaryTable.columns, "id");

      const mappingColumns = this.mapping.map((field) => {
        const secondaryColumn = secondaryColumnMap[field.secondaryColumnId];

        if (!secondaryColumn || field.hidden) return null;

        return secondaryColumn;
      });

      return compact(mappingColumns).map(
        (secondaryColumn) =>
          new FormField({
            id: secondaryColumn.id,
            fieldId: secondaryColumn.fieldId,
            name: secondaryColumn.id,
            label: secondaryColumn.name,
            disabled: false,
            icon: null,
            type: secondaryColumn.type,
            subtype: secondaryColumn.subtype,
            editable: true,
            required: secondaryColumn.mandatory,
            config: secondaryColumn.config,
            placeholder: secondaryColumn.placeholder,
          })
      );
    });
  }
}

SecondaryTableAccessLink.fragment = gql`
  fragment SecondaryTableAccessLinkFragment on SecondaryTableAccessLink {
    id
    name
    uid
    secondaryTable {
      ...TableAccessFragment
    }
    primaryTable {
      ...TableAccessFragment
    }
    mapping {
      primaryColumnId
      secondaryColumnId
      hidden
      overwrite
      dynamic
      lookup
    }
  }

  ${TableAccess.fragment}
`;
