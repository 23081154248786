import React from "react";
import Box from "components/Box";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import { ExternalLink } from "components/LabelButton";

const ExternalLinks = ({ activeId, loading, onClick, entities = [], size, ...props }) => {
  const renderEntities = compact(entities);

  if (loading) return <div>Loading...</div>;
  if (isEmpty(renderEntities)) return <></>;

  const handleClick = (entity) => () => onClick && onClick(entity);

  return (
    <Box {...props}>
      {renderEntities.map((entity, index) => (
        <ExternalLink
          color={activeId && activeId === entity.id ? "darkGrey" : "grey"}
          key={`hyperlink-${index}`}
          onClick={handleClick(entity)}
          size={size}
          space={{ mb: index + 1 !== renderEntities.length ? 1 : 0 }}
        >
          {entity.name || entity.title}
        </ExternalLink>
      ))}
    </Box>
  );
};

export default ExternalLinks;
