import { gql } from "@apollo/client";
import { delegate, memoize, serialize } from "utils/entities";

import { TableAccess } from "./TableAccess";
import { SublistTableAccessLink } from "./SublistTableAccessLink";
import { ConsolidatedTableAccessLink } from "./ConsolidatedTableAccessLink";
import { PrimaryTableAccessLink } from "./PrimaryTableAccessLink";
import { SecondaryTableAccessLink } from "./SecondaryTableAccessLink";

export class TableStructure {
  constructor(origin) {
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin);

    serialize(this, { table: TableAccess });

    this.sublist = this.origin.sublist || [];
    this.secondary = this.origin.secondary || [];
    this.consolidated = this.origin.consolidated || [];
    this.primary = this.origin.primary || [];
  }

  get primaryLinks() {
    return this.memoize("primaryLinks", () =>
      (this.primary || []).map((link) => new PrimaryTableAccessLink(link))
    );
  }

  get tableLinks() {
    return this.memoize("tableLinks", () =>
      [this.subLists, this.secondaryLinks, this.channelLinks, this.consolidatedLink]
        .flat()
        .filter(Boolean)
    );
  }

  get subLists() {
    return this.memoize("subLists", () =>
      this.sublist.map((link) => new SublistTableAccessLink(link))
    );
  }

  get secondaryLinks() {
    return this.memoize("secondaryLinks", () =>
      this.secondary
        .map((link) => new SecondaryTableAccessLink(link))
        .filter((link) => !link.isChannel)
    );
  }

  get channelLinks() {
    return this.memoize("channelLinks", () =>
      this.secondary
        .map((link) => new SecondaryTableAccessLink(link))
        .filter((link) => link.isChannel)
    );
  }

  get consolidatedLink() {
    return this.memoize("consolidatedLink", () =>
      this.consolidated[0] ? new ConsolidatedTableAccessLink(this.consolidated[0]) : null
    );
  }
}

TableStructure.fragment = gql`
  fragment TableStructureFragment on GetFullTableAccessPayload {
    id
    table {
      ...TableAccessFragment
    }
    sublist {
      ...SublistTableAccessLinkFragment
    }
    consolidated {
      ...ConsolidatedTableAccessLinkFragment
    }
    primary {
      ...PrimaryTableAccessLinkFragment
    }
    secondary {
      ...SecondaryTableAccessLinkFragment
    }
  }

  ${TableAccess.fragment}
  ${SublistTableAccessLink.fragment}
  ${ConsolidatedTableAccessLink.fragment}
  ${PrimaryTableAccessLink.fragment}
  ${SecondaryTableAccessLink.fragment}
`;
