import React, { useEffect, useRef } from "react";
import SelectNew from "components/Inputs/Form/SelectNew";
import { useSearchTableViews } from "models/TableView/queries";

const TableViewsSelect = ({
  space,
  root,
  assignDefaultView = false,
  tableId,
  value,
  onChange,
  ...props
}) => {
  const didMountRef = useRef(false);

  const { tableViews, loading, totalCount, onLoadMore, onSearch } = useSearchTableViews({
    searchInput: {
      filter: [{ field: "table.id", operation: "EQ", value: tableId }],
      sort: [{ field: "createdDate", value: "ASC" }],
    },
    skip: !tableId,
  });

  useEffect(() => {
    if (didMountRef.current || value || loading || !assignDefaultView) return;

    const defaultView = tableViews.find((item) => item.byDefault);

    if (defaultView) {
      onChange(defaultView.id);
      didMountRef.current = true;
    }
  }, [value, loading, didMountRef.current]);

  useEffect(() => {
    didMountRef.current = false;
  }, [tableId]);

  const renderOptions = tableViews.map((tableView) => ({
    id: tableView.id,
    label: tableView.byDefault ? "Default" : tableView.name,
  }));

  return (
    <SelectNew
      virtualized
      variant="outlined"
      space={{ mb: 1, ...space }}
      placeholder="Select table"
      {...props}
      value={value}
      onChange={onChange}
      options={renderOptions}
      loading={loading}
      disabled={loading || props.disabled}
      totalCount={totalCount}
      onLoadMore={onLoadMore}
      onSearch={onSearch}
    />
  );
};

export default TableViewsSelect;
