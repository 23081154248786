export const PAGINATION_FRAGMENT = `
  page
  size
  totalPages
  totalElements
`;

export const BULK_ERROR = `
  id
  message
  causes
`;

export const GRAPHQL_ERROR = `
  message
  locations {
    line
    column
    sourceName
  }
  path
  extensions {
    errorType
  }
`;

export const SOURCE_FILE = `
  fileFormat
  fileEncoding
  fileTokenDelimiter
  headers
  lines
  file {
    id
    filename
    label
    url
    size
  }
`;

export const ID_NAME_PAYLOAD = `
  id
  name
  uid
  type
`;

export const TABLE_ATTRIBUTES = `
  id
  description
  createdDate
  lastModifiedDate
  inUse
  name
  uid
  history
  columnsCount
  itemsCount
  allowComments
`;

export const COUNTRY_FRAGMENT = `
  id
  name
`;

export const CURRENCY_FRAGMENT = `
  id
  name
`;

export default {
  PAGINATION_FRAGMENT,
  SOURCE_FILE,
  ID_NAME_PAYLOAD,
  TABLE_ATTRIBUTES,
  COUNTRY_FRAGMENT,
  CURRENCY_FRAGMENT,
};
