import { gql } from "@apollo/client";
import { useMemo } from "react";
import useQuery from "hooks/useQuery";
import usePaginationQuery from "hooks/useQuery/usePaginationQuery";
import uniqWith from "lodash/uniqWith";

import fragments from "../../graphql/fragments";
import { FieldSelect } from "./FieldSelect";
import { FieldShort } from "./FieldShort";
import { FieldFull } from "./FieldFull";

export const GET_FIELDS = gql`
  query SearchFields($input: SearchFieldsInput) {
    searchFields(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...FieldShortFragment
      }
    }
  }

  ${FieldShort.fragment}
`;

export const GET_FIELDS_FOR_OBJECT_FIELD = gql`
  query SearchForObjectField($input: BaseSearchInput) {
    searchForObjectField(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...FieldShortFragment
      }
    }
  }

  ${FieldShort.fragment}
`;

export const GET_FIELDS_SELECT = gql`
  query SearchFieldsSelect($input: SearchFieldsInput) {
    searchFields(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...FieldSelectFragment
      }
    }
  }

  ${FieldSelect.fragment}
`;

export const GET_FIELD = gql`
  query GetField($input: BaseGetInput) {
    getField(input: $input) {
      ...FieldFullFragment
    }
  }

  ${FieldFull.fragment}
`;

const folderFilters = (folderId) => {
  if (!folderId) return [];

  return [
    {
      field: "folder.id",
      operation: "EQ",
      value: folderId || null,
    },
  ];
};

export const useSearchFields = ({ searchInput, folderId = undefined, skip = false } = {}) => {
  const searchInputFilter = searchInput?.filter || [];

  const { content = [], ...props } = usePaginationQuery(GET_FIELDS, {
    input: {
      ...searchInput,
      filter: [...searchInputFilter, ...folderFilters(folderId)],
    },
    skip,
  });

  const fields = useMemo(() => content.map((field) => new FieldShort(field)), [content]);
  const uniqFields = useMemo(() => uniqWith(fields, (prev, next) => prev.id === next.id), [fields]);

  const fieldsOptions = useMemo(
    () => uniqFields.map((field) => [field.id, field.name]),
    [uniqFields]
  );

  return {
    fields,
    uniqFields,
    fieldsOptions,
    ...props,
  };
};

export const useSearchFieldsForObject = ({ searchInput, skip = false } = {}) => {
  const { content = [], ...props } = usePaginationQuery(GET_FIELDS_FOR_OBJECT_FIELD, {
    input: searchInput,
    skip,
  });

  const fields = useMemo(() => content.map((field) => new FieldShort(field)), [content]);

  return { fields, ...props };
};

export const useFieldsSelect = ({ searchInput, skip = false } = {}) => {
  const { content = [], ...props } = usePaginationQuery(GET_FIELDS_SELECT, {
    input: searchInput,
    skip,
  });

  const fields = useMemo(() => content.map((field) => new FieldSelect(field)), [content]);
  const uniqFields = useMemo(() => uniqWith(fields, (prev, next) => prev.id === next.id), [fields]);

  const fieldsOptions = useMemo(
    () => uniqFields.map((field) => [field.id, field.name]),
    [uniqFields]
  );

  return {
    fields,
    uniqFields,
    fieldsOptions,
    ...props,
  };
};

export const useGetField = (fieldId) => {
  const { payload, loading, ...props } = useQuery(GET_FIELD, {
    input: { id: fieldId },
    skip: !fieldId,
  });

  const field = !loading && payload ? new FieldFull(payload) : null;

  return { field, loading, ...props };
};
