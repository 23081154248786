import React, { useEffect, useCallback, useReducer, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { ImmerReducer, createActionCreators, createReducerFunction } from "vendor/immer-reducer";
import { useRouteMap } from "hooks/useRouteMap";

export const StateFieldsContext = React.createContext(null);

const initialState = {
  checkedIds: [],
  bulkSelect: false,
  selectedId: null,
  selectedFieldId: null,
  mode: null,
};

class StateFieldsImmerReducer extends ImmerReducer {
  cleanCheckState() {
    this.draftState.checkedIds = [];
    this.draftState.bulkSelect = false;
  }

  toggleBulkSelect(allEntitiesIds) {
    const nextState = !this.draftState.bulkSelect;

    if (nextState) {
      this.draftState.checkedIds = allEntitiesIds;
    } else {
      this.draftState.checkedIds = [];
    }

    this.draftState.bulkSelect = nextState;
  }

  setCheckedIds(ids) {
    this.draftState.checkedIds = ids;
  }

  toggleCheckedId(entityId) {
    const prevCheckedIds = this.draftState.checkedIds;

    const nextCheckedIds = prevCheckedIds.includes(entityId)
      ? prevCheckedIds.filter((id) => id !== entityId)
      : [...prevCheckedIds, entityId];

    this.draftState.checkedIds = nextCheckedIds;
  }

  cleanSelect() {
    this.draftState = initialState;
  }

  setSelectedId(id) {
    this.draftState.selectedId = id;
  }

  setSelectedFieldId(id) {
    this.draftState.selectedFieldId = id;
  }

  setMode(mode) {
    this.draftState.mode = mode;
  }

  setSelect({ id, fieldId, mode }) {
    this.draftState.selectedId = id;
    this.draftState.selectedFieldId = fieldId;
    this.draftState.mode = mode;
  }

  toggleSelect({ id, fieldId, mode }) {
    if (
      this.draftState.selectedId === id &&
      this.draftState.selectedFieldId === fieldId &&
      this.draftState.mode === mode
    ) {
      this.setSelect({ id: null, fieldId: null, mode: null });
    } else {
      this.setSelect({ id, fieldId, mode });
    }
  }
}

const reducerAction = createActionCreators(StateFieldsImmerReducer);
const stateFieldsReducer = createReducerFunction(StateFieldsImmerReducer);

const StubWrap = ({ children }) => <>{children}</>;

export const StateFieldsProvider = ({
  children,
  totalCount,
  entities,
  selectTags,
  syncParamsProperty,
  actualBulkSelect = false,
  syncSelectState,
}) => {
  const params = useParams() || {};
  const paramsId = syncParamsProperty ? params[syncParamsProperty] : null;
  const entitiesIds = entities.map((entity) => entity.id);
  const entitiesIdsChecksum = entitiesIds.join(",");

  const allEntitiesIds = useMemo(
    () => (actualBulkSelect ? entitiesIds : ["BULK_SELECT"]),
    [actualBulkSelect, entitiesIdsChecksum]
  );

  const tableRef = useRef(null);

  const [state, dispatch] = useReducer(stateFieldsReducer, {
    ...initialState,
    selectedId: paramsId,
  });

  useEffect(() => {
    if (paramsId === state.selectedId) return;

    dispatch(reducerAction.setSelectedId(paramsId));
  }, [paramsId]);

  useEffect(() => {
    if (state.bulkSelect) {
      dispatch(entitiesIds);
    }
  }, [entitiesIdsChecksum]);

  const RouteNavigationWrap = syncParamsProperty ? StateFieldsRouteNavigation : StubWrap;
  const SyncWrap = syncSelectState ? StateFieldsSyncWrap : StubWrap;

  return (
    <StateFieldsContext.Provider
      value={{
        actualBulkSelect,
        allEntitiesIds,
        syncParamsProperty,
        tableRef,
        state,
        dispatch,
        totalCount,
        selectTags,
      }}
    >
      <RouteNavigationWrap paramsId={paramsId}>
        <SyncWrap syncSelectState={syncSelectState}>{children}</SyncWrap>
      </RouteNavigationWrap>
    </StateFieldsContext.Provider>
  );
};

const StateFieldsSyncWrap = ({ syncSelectState, children }) => {
  useSyncIdsEffect({
    selectedTableId: syncSelectState[0],
    setSelectedTableId: syncSelectState[1],
    turnedOn: !!syncSelectState,
  });

  return children;
};

const StateFieldsRouteNavigation = ({ children, paramsId }) => {
  const { assignParamsProperty } = useRouteMap();
  const { state, syncParamsProperty } = React.useContext(StateFieldsContext);

  useEffect(() => {
    if (syncParamsProperty && state.selectedId !== paramsId) {
      assignParamsProperty(syncParamsProperty, state.selectedId);
    }
  }, [state.selectedId, state.selectedFieldId, state.mode]);

  return children;
};

export const useStateFields = () => {
  const { state, dispatch, totalCount, selectTags, tableRef, allEntitiesIds, actualBulkSelect } =
    React.useContext(StateFieldsContext);

  const { checkedIds, bulkSelect, selectedId, selectedFieldId, mode } = state;

  const cleanCheckState = useCallback(() => {
    dispatch(reducerAction.cleanCheckState());
  }, []);

  const toggleBulkSelect = useCallback(() => {
    dispatch(reducerAction.toggleBulkSelect(allEntitiesIds));
  }, [allEntitiesIds.join(",")]);

  const toggleCheckedId = useCallback(
    (entityId) => () => {
      dispatch(reducerAction.toggleCheckedId(entityId));
    },
    []
  );

  const setSelectedFieldId = useCallback((id) => {
    dispatch(reducerAction.setSelectedFieldId(id));
  }, []);

  const setMode = useCallback((mode) => {
    dispatch(reducerAction.setMode(mode));
  }, []);

  const cleanSelect = useCallback(() => {
    dispatch(reducerAction.cleanSelect());
  }, []);

  const setSelectedId = useCallback((id) => {
    dispatch(reducerAction.setSelectedId(id));
  }, []);

  const setSelect = useCallback(({ id, fieldId, mode }) => {
    dispatch(reducerAction.setSelect({ id, fieldId, mode }));
  }, []);

  const toggleSelect = useCallback(({ id, fieldId, mode }) => {
    dispatch(reducerAction.toggleSelect({ id, fieldId, mode }));
  }, []);

  const isSelected = useCallback(
    (id) => checkedIds.includes(id) || bulkSelect,
    [actualBulkSelect, checkedIds.join(","), bulkSelect]
  );

  const moveUpRecord = useCallback(
    (records) => {
      const recordsIds = records.map((item) => item.id);
      const leftRecord = records[recordsIds.indexOf(selectedId) - 1];

      return leftRecord ? () => setSelectedId(leftRecord.id) : null;
    },
    [selectedId]
  );

  const moveDownRecord = useCallback(
    (records) => {
      const recordsIds = records.map((item) => item.id);
      const rightRecord = records[recordsIds.indexOf(selectedId) + 1];

      return rightRecord ? () => setSelectedId(rightRecord.id) : null;
    },
    [selectedId]
  );

  const checkedCount = bulkSelect ? totalCount : checkedIds.length;

  return {
    tableRef,
    setSelect,
    toggleSelect,
    checkedIds,
    bulkSelect,
    cleanCheckState,
    toggleBulkSelect,
    toggleCheckedId,
    checkedCount,
    selectedId,
    setSelectedId,
    selectedFieldId,
    setSelectedFieldId,
    cleanSelect,
    moveUpRecord,
    moveDownRecord,
    setMode,
    mode,
    isSelected,
    selectTags: selectTags || {},
  };
};

export const useSyncIdsEffect = ({ selectedTableId, setSelectedTableId, turnedOn }) => {
  const { state, dispatch } = React.useContext(StateFieldsContext);

  // Refs to keep track of previous values
  const prevSelectedTableIdRef = React.useRef(selectedTableId);
  const prevStateSelectedIdRef = React.useRef(state.selectedId);

  useEffect(() => {
    if (turnedOn) {
      // Only execute if the value has changed
      if (prevSelectedTableIdRef.current !== selectedTableId) {
        if (state.selectedId !== selectedTableId) {
          dispatch(reducerAction.setSelectedId(selectedTableId));
        }
        // Update the ref after handling the change
        prevSelectedTableIdRef.current = selectedTableId;
      }
    }
  }, [selectedTableId, state.selectedId, turnedOn, dispatch]);

  useEffect(() => {
    if (turnedOn) {
      // Only execute if the value has changed
      if (prevStateSelectedIdRef.current !== state.selectedId) {
        if (state.selectedId !== selectedTableId) {
          setSelectedTableId?.(state.selectedId);
        }
        // Update the ref after handling the change
        prevStateSelectedIdRef.current = state.selectedId;
      }
    }
  }, [selectedTableId, state.selectedId, turnedOn, setSelectedTableId]);
};
