import React from "react";
import AuthGuard from "guards/AuthGuard";
import AccessGuard from "guards/AccessGuard";
import DashboardLayout, { CompanyWorkspaceLayer, ProjectWorkspaceLayer } from "layouts/Dashboard";
import SetupLayout from "layouts/Setup";
import BuilderLayout from "layouts/Builder";
import { WorkspaceProvider, ProjectWorkspaceProvider } from "contexts/Workspace";
import { GlobalEntitiesProvider } from "contexts/GlobalEntities";

import {
  RootPage,
  GeneralPage,
  ProfileSettings,
  ProjectMembersPage,
  CompanyMembersPage,
  ChannelsPage,
  ChannelIntegrationPage,
  FieldsPage,
  TablesPage,
  CompanyFormsPage,
  ProjectFormsPage,
  ProjectsNewPage,
  ListsPage,
  TasksPage,
  CreateCompanyPage,
  ProjectEditPage,
  ProjectsViewPage,
  TableViewsViewPage,
  KitsPage,
  DevPage,
  KitsIcons,
  DefaultSuspense,
  LoaderSuspense,
  PrivateSpacePage,
  TasksCreating,
  ChannelAuthStatusPage,
  SelectBuilderPage,
  NewItemLayoutPage,
  EditItemLayoutPage,
  FormLayoutPage,
  SelectAccountTypePage,
} from "./pages";

const DashboardProvider = ({ children }) => (
  <AuthGuard>
    <WorkspaceProvider>
      <GlobalEntitiesProvider>
        <AccessGuard>{children}</AccessGuard>
      </GlobalEntitiesProvider>
    </WorkspaceProvider>
  </AuthGuard>
);

const DashboardLayer = (props) => (
  <DashboardProvider>
    <DashboardLayout {...props} />
  </DashboardProvider>
);

const CompanySetupLayer = () => (
  <AuthGuard>
    <SetupLayout />
  </AuthGuard>
);

const SetupLayer = () => (
  <DashboardProvider>
    <SetupLayout />
  </DashboardProvider>
);

const BuilderLayer = () => (
  <DashboardProvider>
    <ProjectWorkspaceProvider>
      <BuilderLayout />
    </ProjectWorkspaceProvider>
  </DashboardProvider>
);

const multipath = (props) =>
  props.path.map((routePath) => ({
    ...props,
    path: routePath,
  }));

const DEV_ROUTES = process.env.REACT_APP_DEVELOPMENT
  ? [
      {
        path: "kits",
        element: DefaultSuspense(KitsPage),
      },
      {
        path: "dev",
        element: DefaultSuspense(DevPage),
      },
      {
        path: "kitsIcons",
        element: DefaultSuspense(KitsIcons),
      },
    ]
  : [];

const COMPANY_ROUTES = [
  {
    path: ":companyId",
    element: <CompanyWorkspaceLayer />,
    children: [
      {
        path: "settings",
        children: [
          {
            path: "general",
            element: DefaultSuspense(GeneralPage),
          },
          {
            path: "profile",
            element: DefaultSuspense(ProfileSettings),
          },
          {
            path: "members",
            element: DefaultSuspense(CompanyMembersPage),
          },
          {
            path: "forms",
            element: DefaultSuspense(CompanyFormsPage),
          },
          {
            path: "projects",
            element: LoaderSuspense(ProjectsViewPage),
          },
          ...multipath({
            path: ["channels", "channels/:channelBrand", "channels/:channelBrand/:id"],
            element: LoaderSuspense(ChannelsPage),
          }),
          {
            path: "channels/:channelId/authorized",
            element: DefaultSuspense(ChannelAuthStatusPage),
          },
          ...multipath({
            path: ["channels/integration", "channels/integration/:id"],
            element: LoaderSuspense(ChannelIntegrationPage),
          }),
          ...multipath({
            path: ["fields/:folderId", "fields/:folderId/:id"],
            element: LoaderSuspense(FieldsPage),
          }),
          ...multipath({
            path: ["tables", "tables/:id"],
            element: LoaderSuspense(TablesPage),
          }),
        ],
      },
    ],
  },
];

const PROJECT_ROUTES = [
  {
    path: ":companyId/:projectId",
    element: <ProjectWorkspaceLayer />,
    children: [
      {
        path: "",
        element: DefaultSuspense(RootPage),
      },
      {
        path: "tasksCreating",
        element: LoaderSuspense(TasksCreating),
      },
      ...multipath({
        path: [
          "lists/:tableId",
          "lists/:tableId/:id",
          "views/:tableViewId",
          "views/:tableViewId/:id",
        ],
        element: LoaderSuspense(ListsPage),
      }),
      ...multipath({
        path: ["tasks", "tasks/:id"],
        element: LoaderSuspense(TasksPage),
      }),
      {
        path: "settings",
        children: [
          {
            path: "general",
            element: DefaultSuspense(ProjectEditPage),
          },
          {
            path: "members",
            element: DefaultSuspense(ProjectMembersPage),
          },
          {
            path: "forms",
            element: DefaultSuspense(ProjectFormsPage),
          },
          {
            path: "views",
            element: DefaultSuspense(TableViewsViewPage),
          },
          {
            path: "private-space",
            element: DefaultSuspense(PrivateSpacePage),
          },
        ],
      },

      ...DEV_ROUTES,
    ],
  },
];

const routes = [
  {
    path: "/",
    element: <DashboardLayer />,
    children: [...COMPANY_ROUTES, ...PROJECT_ROUTES],
  },

  {
    path: "/companies",
    element: <CompanySetupLayer />,
    children: [
      {
        path: "create",
        element: DefaultSuspense(CreateCompanyPage),
      },
    ],
  },

  {
    path: "/auth",
    element: <SetupLayer />,
    children: [
      {
        path: "select-account-type",
        element: DefaultSuspense(SelectAccountTypePage),
      },
    ],
  },

  {
    path: "/:companyId/projects/create",
    element: <SetupLayer />,
    children: [
      {
        path: "",
        element: DefaultSuspense(ProjectsNewPage),
      },
    ],
  },

  {
    path: "/:companyId/builder",
    element: <BuilderLayer />,
    children: [
      {
        path: "",
        element: DefaultSuspense(SelectBuilderPage),
      },
      ...multipath({
        path: ["form/new", "form/:formLayoutId/edit"],
        element: DefaultSuspense(FormLayoutPage),
      }),
    ],
  },

  {
    path: "/:companyId/:projectId/builder",
    element: <BuilderLayer />,
    children: [
      {
        path: "item/new",
        element: DefaultSuspense(NewItemLayoutPage),
      },
      {
        path: "item/:tableViewId/new",
        element: DefaultSuspense(NewItemLayoutPage),
      },
      {
        path: "item/:itemLayoutId/edit",
        element: DefaultSuspense(EditItemLayoutPage),
      },
    ],
  },
];

export default routes;
