export const FIELD_MODEL_TYPE = "FIELD";
export const TABLE_MODEL_TYPE = "TABLE";
export const TABLE_COLUMN_MODEL_TYPE = "TABLE_COLUMN";
export const TABLE_COLUMN_DEFINITION_MODEL_TYPE = "TABLE_COLUMN_DEFINITION";
export const CHANNEL_MODEL_TYPE = "CHANNEL";
export const CHANNEL_OPERATION_MODEL_TYPE = "CHANNEL_OPERATION";
export const CHANNEL_DEFINITION_MODEL_TYPE = "CHANNEL_DEFINITION";
export const FILE_MODEL_TYPE = "FILE";
export const FILE_COLUMN_MODEL_TYPE = "FILE_COLUMN";
export const COMPANY_MODEL_TYPE = "COMPANY";
export const PROJECT_MODEL_TYPE = "PROJECT";
export const LIST_ITEM_MODEL_TYPE = "LIST_ITEM";
export const TABLE_VIEW_MODEL_TYPE = "TABLE_VIEW";
export const CHANNEL_API_MODEL_TYPE = "CHANNEL_API";

// Not API
export const CONSOLIDATED_TABLE_ACCESS_LINK_MODEL_TYPE = "CONSOLIDATED_TABLE_ACCESS_LINK";
export const SECONDARY_TABLE_ACCESS_LINK_MODEL_TYPE = "SECONDARY_TABLE_ACCESS_LINK";
export const SUB_LIST_TABLE_ACCESS_LINK_MODEL_TYPE = "SUB_LIST_TABLE_ACCESS_LINK";
export const FORM_LAYOUT_MODEL_TYPE = "FORM_LAYOUT";
export const SUB_LIST_MODEL_TYPE = "SUB_LIST";

export const SUB_LIST_LINK_TYPE = "SUB_LIST";
export const SECONDARY_LINK_TYPE = "SECONDARY";
export const CONSOLIDATED_LINK_TYPE = "CONSOLIDATED";

// Mapping
export const FILE_TABLE_MAPPING_TYPE = "FILE_TABLE";
export const TABLE_CHANNEL_MAPPING_TYPE = "TABLE_CHANNEL";
export const CHANNEL_TABLE_MAPPING_TYPE = "CHANNEL_TABLE";
export const TABLE_FILE_MAPPING_TYPE = "TABLE_FILE";
export const FORM_TABLE_MAPPING_TYPE = "FORM_TABLE";
export const FORM_SUBLIST_MAPPING_TYPE = "FORM_SUBLIST";
export const FORM_CHANNEL_MAPPING_TYPE = "FORM_CHANNEL";
export const TABLE_TABLE_MAPPING_TYPE = "TABLE_TABLE";
export const TABLE_SUBLIST_MAPPING_TYPE = "TABLE_SUBLIST";
export const ITEM_TABLE_MAPPING_TYPE = "ITEM_TABLE";
export const ITEM_SUBLIST_MAPPING_TYPE = "ITEM_SUBLIST";
