import React, { useState, useMemo, useEffect } from "react";
import JSum from "vendor/jsum";
import pick from "lodash/pick";
import { reorder, replaceRecordByIndex } from "utils/collection";

import { FilterProvider } from "containers/TableFilter/FilterProvider";
import { SortProvider } from "components/TableSort/SortProvider";

export const ViewsManagerContext = React.createContext(null);
export const useViewsManagerContext = () => React.useContext(ViewsManagerContext);

const columnsChecksum = (columns) => {
  const value = columns.map((column) =>
    Object.values(
      pick(column, ["id", "label", "width", "lock", "hidden", "fieldId", "asTitle"])
    ).join("")
  );

  return JSum.digest(value, "SHA256", "hex");
};

export const ViewsManagerProvider = ({
  columns: initialColumns,
  filterColumns = initialColumns,
  children,
  viewState,
  setViewState,
  onSubmitListing,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { filter, sort } = viewState;
  const [mode, setMode] = useState(null);

  const onOpen = () => setMode("MAIN");
  const onClose = () => setMode(null);
  const toggleOpen = () => (mode ? setMode(null) : setMode("MAIN"));

  const [columns, setColumns] = useState(initialColumns || []);
  const tableColumns = columns.filter((item) => !item.hidden);
  const columnsIds = useMemo(() => columns.map((column) => column.id), [columns]);

  const submitListing = ({ input, onSuccess, onFailure } = {}) => {
    setSubmitting(true);

    if (!onSubmitListing) {
      setViewState({ filter, sort, ...input });
      setSubmitting(false);
      onSuccess && onSuccess();
      return;
    }

    onSubmitListing({
      input: { columns, filter, sort, ...input },
      onSuccess: (data) => {
        setSubmitting(false);
        onSuccess && onSuccess(data);
        input && setViewState({ filter, sort, ...input });
      },
      onFailure: (errors) => {
        setSubmitting(false);
        onFailure && onFailure(errors);
      },
    });
  };

  const applyFilter = (newFilter) => {
    submitListing({ input: { filter: newFilter } });
  };

  const applySort = (newSort) => {
    submitListing({ input: { sort: newSort } });
  };

  const cleanFilter = () => {
    submitListing({ input: { skip: true, filter: [] } });
  };

  const reset = () => {
    submitListing({ input: { sort: [], filter: [], columns: initialColumns } });
  };

  const cleanSort = () => {
    submitListing({ input: { sort: [] } });
  };

  useEffect(() => {
    if (columnsChecksum(initialColumns) === columnsChecksum(columns)) return;

    setColumns(initialColumns);
  }, [columnsChecksum(initialColumns)]);

  return (
    <ViewsManagerContext.Provider
      value={{
        submitting,
        submitListing,
        initialColumns,
        filterColumns,
        columns,
        columnsIds,
        tableColumns,
        setColumns,
        reset,
        filter,
        sort,
        mode,
        setMode,
        open: !!mode,
        onOpen,
        onClose,
        toggleOpen,
        setViewState,
      }}
    >
      <FilterProvider
        columns={filterColumns}
        filter={filter}
        onClean={cleanFilter}
        onApply={applyFilter}
      >
        <SortProvider columns={columns} sort={sort} onClean={cleanSort} onApply={applySort}>
          {children}
        </SortProvider>
      </FilterProvider>
    </ViewsManagerContext.Provider>
  );
};

export const useViewsManager = () => {
  const context = useViewsManagerContext();
  const { open, mode, setMode, columnsIds, columns, setColumns } = context;

  const onMove = (startIndex, endIndex) => {
    const newColumns = reorder(columns, startIndex, endIndex);

    setColumns(newColumns);

    return newColumns;
  };

  const onUpdate = (id, props) => {
    const index = columnsIds.indexOf(id);

    const newColumns = replaceRecordByIndex(columns, props, index);

    setColumns(newColumns);

    return newColumns;
  };

  const onHide = (ids) => {
    const newColumns = columns.map((column) =>
      ids.includes(column.id) ? { ...column, hidden: true } : column
    );

    setColumns(newColumns);

    return newColumns;
  };

  const onChangeWidthMap = (widthMap) => {
    const newColumns = columns.map((column) =>
      widthMap[column.id] ? { ...column, width: widthMap[column.id] } : column
    );

    setColumns(newColumns);

    return newColumns;
  };

  const onStickColumn = (columnId) => {
    const newColumns = columns.map((column) =>
      columnId === column.id ? { ...column, lock: true } : { ...column, lock: false }
    );

    setColumns(newColumns);

    return newColumns;
  };

  const handleClose = () => setMode(null);

  return {
    ...context,
    open,
    mode,
    handleClose,
    onMove,
    onUpdate,
    onHide,
    onChangeWidthMap,
    onStickColumn,
  };
};
