import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import ViewsManager from "components/ViewsManager";
import Divider from "components/Divider";
import MenuItem from "components/Menu/MenuItem";
import { CopyIcon, TrashIcon } from "icons";

import { useViewsManager } from "./Provider";

const ViewsManagerMain = () => {
  const { setMode, sort, filter, columns, onClose, reset } = useViewsManager();

  const onNavigate = (newMode) => () => setMode(newMode);

  const itemLabel = (count, label) => (count === 0 ? "None" : `${count} ${label}`);

  const selectedColumns = columns.filter((column) => !column.hidden);

  return (
    <ViewsManager>
      <ViewsManager.Header onClose={onClose}>
        <Typography variant="h4">Edit view</Typography>
      </ViewsManager.Header>

      <ViewsManager.Footer>
        <ViewsManager.Item
          onClick={onNavigate("COLUMNS")}
          title="Columns"
          buttonText={itemLabel(selectedColumns.length, "selected")}
        />

        <ViewsManager.Item
          onClick={onNavigate("FILTERS")}
          title="Filter"
          buttonText={itemLabel(filter.length, "applied")}
        />

        <ViewsManager.Item
          onClick={onNavigate("SORTS")}
          title="Sort"
          buttonText={itemLabel(sort.length, "applied")}
        />
      </ViewsManager.Footer>

      <Divider variant="light" space={{ my: 1 }} />

      <ViewsManager.Footer>
        <Box space={{ mb: 2 }}>
          <MenuItem size="small" icon={CopyIcon}>
            Copy link
          </MenuItem>

          <MenuItem size="small" onClick={reset} icon={TrashIcon}>
            Reset to default
          </MenuItem>
        </Box>
      </ViewsManager.Footer>
    </ViewsManager>
  );
};

export default ViewsManagerMain;
