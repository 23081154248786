import React, { useMemo, useContext, createContext } from "react";
import keyBy from "lodash/keyBy";

import { useCurrencies } from "hooks/queries/useQueries";
import { useCountries } from "hooks/queries/useQueries";
import { useGetTimezones } from "models/Timezone/queries";

const GlobalEntitiesContext = createContext(null);

export const GlobalEntitiesProvider = ({ children }) => {
  const { currencies, loading: currenciesLoading } = useCurrencies();
  const { countries, loading: countriesLoading } = useCountries();
  const { timezones, loading: timezonesLoading } = useGetTimezones();

  const countriesMap = useMemo(() => keyBy(countries, "id"), [countries]);

  return (
    <GlobalEntitiesContext.Provider
      value={{
        currencies,
        currenciesLoading,
        countries,
        countriesLoading,
        timezones,
        timezonesLoading,
        countriesMap,
      }}
    >
      {children}
    </GlobalEntitiesContext.Provider>
  );
};

export const useGlobalEntities = () => useContext(GlobalEntitiesContext) || {};
