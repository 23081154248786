import React from "react";
import useInputMenu from "components/Inputs/MenuInputs/useInputMenu";

import { ObjectForm } from "./ObjectForm";

export const ObjectInput = (props) => {
  const { onChange } = props;

  const { inputValue, setInputValue, onReset } = useInputMenu(props);

  const handleSave = ({ onClose }) => {
    onChange(inputValue);
    onClose();
  };

  return (
    <ObjectForm
      {...props}
      value={inputValue}
      onChange={setInputValue}
      onSave={handleSave}
      onCancel={onReset}
      menuLabel="Merchant object"
    />
  );
};
