import { gql } from "@apollo/client";
import { delegate, serialize, memoize } from "utils/entities";
import { COUNTRY_FRAGMENT, CURRENCY_FRAGMENT } from "../../graphql/fragments";
import { PermissionsMap } from "models/fragments/PermissionsMap";
import { Timezone } from "models/Timezone/Timezone";

import { COMPANY_MODEL_TYPE } from "models/types";
import { User } from "models/fragments/User";
import { Content } from "models/fragments/Content";
import { CompanyShort } from "./CompanyShort";

export class CompanyFull {
  constructor(origin) {
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin, ["permissions"]);

    serialize(this, { timezone: Timezone, owner: User, avatar: Content, createdBy: User });

    this.permissionsMap = new PermissionsMap(this.origin.permissions || []);
  }

  get timezoneId() {
    return this.timezone?.id;
  }

  get modelType() {
    return COMPANY_MODEL_TYPE;
  }

  get countryId() {
    return this.businessProfile?.country?.id;
  }

  get currencyId() {
    return this?.currency?.id;
  }

  get permissions() {
    return this.memoize("permissions", () => this.permissionsMap.array);
  }

  permissionFor(props) {
    return this.permissionsMap.permissionFor(props);
  }

  hasAdminAccess(props) {
    return this.permissionsMap.hasAdminAccess(props);
  }
}

CompanyFull.fragment = gql`
  fragment CompanyFullFragment on GetCompanyPayload {
    ...CompanyShortFragment

    businessProfile {
      name
      phoneData {
        countryId
        digits
        fullNumber
      }
      website
      country {
        ${COUNTRY_FRAGMENT}
      }
      state
      city
      addressLine1
      addressLine2
      postCode
    }
    avatar {
      ...ContentFragment
    }
    country {
      ${COUNTRY_FRAGMENT}
    }
    currency {
      ${CURRENCY_FRAGMENT}
    }
    createdBy {
      ...UserFragment
    }
    permissions {
      ...PermissionsMapFragment
    }
  }

  ${User.fragment}
  ${CompanyShort.fragment}
  ${Content.fragment}
  ${PermissionsMap.fragment}
`;
