import React, { useMemo } from "react";
import { useWorkspace } from "contexts/Workspace";
import { useGetPermissionsConfig } from "models/PermissionsConfig";
import { AccessManager } from "models/abstract/AccessManager";
import { useAuth } from "contexts/Auth";

const AccessContext = React.createContext(null);

export const AccessManagerProvider = ({ children }) => {
  const { currentProfile } = useAuth();
  const { currentCompany, loading: loadingWorkspace } = useWorkspace();
  const { permissionsConfig, loading: loadingPermissions } = useGetPermissionsConfig();
  const loading = loadingWorkspace || loadingPermissions;

  const accessManager = useMemo(
    () =>
      new AccessManager({
        user: currentProfile,
        company: currentCompany,
        permissionsConfig,
      }),
    [currentCompany, permissionsConfig]
  );

  return (
    <AccessContext.Provider
      value={{
        loading: loading || !accessManager,
        accessManager,
      }}
    >
      {children}
    </AccessContext.Provider>
  );
};

export const useAccessManager = () => React.useContext(AccessContext);
