import React from "react";
import isEmpty from "lodash/isEmpty";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import Select from "components/Inputs/Select";
import { TableNavigationRow } from "components/TablePro/Components/TableNavigationRow";
import { PlusIcon } from "iconsNew";
import { ContainerTable } from "components/DndBuilder/components/Preview/ContainerTable";
import { useFilter } from "containers/TableFilter/FilterProvider";
import useFieldsManager from "containers/useFieldsManager";
import {
  ViewsManagerProvider,
  useViewsManagerContext,
  useViewsManager,
} from "components/ViewsManager/Provider";
import { ViewsManagerDrawer } from "components/ViewsManager/Drawer";

import { useFormProperties, FILTER_MODE_OPTIONS } from "./index";
import { useFormTableContainer } from "./useFormTableContainer";

export const FormContainerTable = ({ sectionProps, containerId, builderItems }) => {
  const { control, clearErrors, viewState, setViewState, disabledFilter } = useFormProperties();

  const { fields = [], ...fieldsProps } = useFieldsManager({
    name: containerId,
    control,
    clearErrors,
    onAddProps: () => ({}),
  });

  const { originColumns, formTableColumns, viewsManagerColumns, entities } = useFormTableContainer({
    fields,
    builderItems,
    filter: disabledFilter ? [] : viewState.filter,
  });

  return (
    <ViewsManagerProvider
      columns={viewsManagerColumns}
      filterColumns={originColumns}
      viewState={viewState}
      setViewState={setViewState}
    >
      <FormContainerTableComponent
        sectionProps={sectionProps}
        containerId={containerId}
        fieldsProps={fieldsProps}
        columns={formTableColumns}
        entities={entities}
      />
    </ViewsManagerProvider>
  );
};

const FormContainerTableComponent = ({
  sectionProps,
  containerId,
  fieldsProps,
  columns,
  entities,
}) => {
  const { columns: viewsManagerColumns } = useViewsManager();
  const { clearErrors, formState, disabledFilter } = useFormProperties();
  const { errors } = formState;

  const noHiddenViewColumnsIds = viewsManagerColumns
    .filter((column) => !column.hidden)
    .map((column) => column.id);

  const visibleColumns = columns.filter((column) => noHiddenViewColumnsIds.includes(column.id));

  const onAdd = () => fieldsProps.append([{}]);

  const onUpdate = ({ entity, props, rowIndex }) => {
    fieldsProps.update(entity.id, props);

    Object.keys(props).forEach((key) => {
      clearErrors(`${rowIndex}.${key}`);
    });
  };

  return (
    <ContainerTable
      containerId={containerId}
      sectionProps={sectionProps}
      entities={entities}
      errors={errors}
      onRemove={fieldsProps.remove}
      onChange={onUpdate}
      onAdd={onAdd}
      columns={visibleColumns}
      viewsManagerComponent={ViewsManagerDrawerComponent}
      tableAddRowComponent={TableAddRecordRow}
      filterToggler={FilterModeToggler}
      disabledFilter={disabledFilter}
    />
  );
};

const FilterModeToggler = () => {
  const { filterMode, setFilterMode } = useFormProperties();

  return (
    <Select
      fullHeight
      size="small"
      value={filterMode}
      onChange={setFilterMode}
      options={FILTER_MODE_OPTIONS}
      space={{ mb: 0, mr: 0.5 }}
      placeholder="Select filter mode"
      menuLabel="Select filter mode"
      allowBlank={false}
      optionComponent={FilterModeLabel}
      selectedLabelComponent={SelectedFilterModeLabel}
      customContentWidth={300}
    />
  );
};

const SelectedFilterModeLabel = ({ option }) => {
  return <Typography>{option.label}</Typography>;
};

const FilterModeLabel = ({ option }) => {
  return (
    <Box>
      <Typography>{option.label}</Typography>

      <Typography variant="body2" style={{ whiteSpace: "normal" }} color="grey">
        {option.description}
      </Typography>
    </Box>
  );
};

const ViewsManagerDrawerComponent = () => {
  const { open } = useViewsManagerContext();

  return <ViewsManagerDrawer open={open} />;
};

const TableAddRecordRow = ({ onAdd }) => {
  const { filters, disabled } = useFilter();

  const enabled = isEmpty(filters) || disabled;

  return (
    <TableNavigationRow>
      <Button
        disabled={!enabled}
        space={{ ml: -2 }}
        leftIcon={PlusIcon}
        size="xs"
        variant="grey"
        onClick={onAdd}
      >
        {enabled ? "Add record" : "Need to disable filter before adding new records"}
      </Button>
    </TableNavigationRow>
  );
};
