import React from "react";
import Box from "components/Box";

import "styles/App/VDivider.scss";

const SIZES = {
  small: 16,
  medium: 32,
  large: 40,
};

const useVDividerStyles = () => ({
  root: "AppVDivider-root",
});

export const VDivider = ({ children, size = "medium", ...props }) => {
  const classes = useVDividerStyles();

  return (
    <Box {...props} className={classes.root} style={{ height: SIZES[size] }}>
      {children}
    </Box>
  );
};

export default VDivider;
