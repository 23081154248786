import React, { useState, useEffect } from "react";
import Box from "components/Box";
import Collapse from "components/Collapse";
import LabelButton from "components/LabelButton";
import IconButton from "components/IconButton";
import CollapseButton from "components/Buttons/CollapseButton";
import MenuButton from "components/Menu/MenuButton";
import Typography from "components/Typography";
import { variantsClassNames } from "theme/utils";
import { ArrowUpRightIcon, MoreHorizontalIcon } from "icons";
import isObject from "lodash/isObject";
import clsx from "clsx";

import "styles/App/CollapseLabel.scss";

export const useCollapseLabelStyles = ({ variant, color } = {}) => ({
  sticky: variantsClassNames("AppCollapseLabel-sticky", { variant, color }),
  content: "AppCollapseLabel-content",
  labelContainer: "AppCollapseLabel-labelContainer",
  labelSpace: "AppCollapseLabel-space",
  label: "AppCollapseLabel-label",
  otherSpace: "AppCollapseLabel-otherSpace",
});

const CollapseLabel = ({
  id,
  label,
  disabled = false,
  className,
  children,
  transition = false,
  onMenuClose,
  moreMenu: MoreMenuContent,
  onOpen = () => null,
  onClose = () => null,
  open: defaultOpen,
  rightLabel,
  error,
  leftIcon,
  iconSize,
  components = {},
  onExternalClick,
  typographyVariant,
  offsetByTitle = false,
  stickyContent = false,
  noBottomOffset = false,
  contentSpacing = false,
  variant,
  color,
}) => {
  const classes = useCollapseLabelStyles({ variant, color });
  const [open, setOpen] = useState(defaultOpen);
  const [anchorEl, setAnchorEl] = useState(null);

  const ContentComponent = components.contentComponent || Box;
  const StickyComponent = components.stickyComponent || Box;

  const toggleContentOpen = () => {
    if (disabled) return;

    const nextOpen = !open;
    setOpen(nextOpen);
    nextOpen ? onOpen() : onClose();
  };

  const onContentOpen = () => {
    if (disabled) return;

    setOpen(true);
    onOpen();
  };

  const toggleContentOpenWitChecking = (event) => {
    // We need this checking to avoidng handling this service when child elements in MoreMenuContent
    const closestParent = event.target?.closest?.('[data-id="disableOpenToggler"]');

    if (closestParent) return;

    toggleContentOpen();
  };

  const disableParent = (event) => {
    if (disabled) return;

    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    onMenuClose?.();
  };

  useEffect(() => {
    if (disabled) {
      setOpen(false);
      return;
    }

    setOpen(defaultOpen);
  }, [defaultOpen, disabled]);

  return (
    <>
      <StickyComponent className={clsx(classes.sticky, { opened: open, contentSpacing })}>
        <Box onClick={toggleContentOpenWitChecking} className={classes.labelContainer}>
          <Box className={clsx(classes.labelSpace)}>
            <CollapseButton disabled={disabled} size="labelSmall" iconSize="small" open={open} />

            <Box>
              <Box display="flex" alignItems="center">
                <LabelButton
                  disabled={disabled}
                  typographyVariant={typographyVariant}
                  leftIcon={leftIcon}
                  iconSize={iconSize}
                  size="small"
                  color={error ? "failure" : "none"}
                  className={clsx(classes.label, className)}
                >
                  {label}
                </LabelButton>

                {onExternalClick && (
                  <IconButton
                    size="labelSmall"
                    iconSize="small"
                    icon={ArrowUpRightIcon}
                    space={{ ml: 1 }}
                    onClick={onExternalClick}
                  />
                )}
              </Box>

              {error && (
                <Typography space={{ mt: 1 }} error="true" variant="helperText">
                  {isObject(error) ? error.message : error}
                </Typography>
              )}
            </Box>

            {MoreMenuContent && (
              <MenuButton
                disabled={disabled}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                onClose={handleCloseMenu}
                space={{ ml: 1 }}
                onClick={disableParent}
                size="labelSmall"
                iconSize="small"
                component={IconButton}
                icon={MoreHorizontalIcon}
              >
                <div data-id="disableOpenToggler">
                  <MoreMenuContent
                    id={id}
                    onContentOpen={onContentOpen}
                    onClose={() => setAnchorEl(null)}
                  />
                </div>
              </MenuButton>
            )}
          </Box>

          <Box className={classes.otherSpace}>
            <br />
          </Box>

          <Box>{rightLabel}</Box>
        </Box>
      </StickyComponent>

      {children && (
        <ContentComponent
          className={clsx(classes.content, { offsetByTitle, stickyContent, noBottomOffset })}
        >
          {transition ? <Collapse in={open}>{children}</Collapse> : open && children}
        </ContentComponent>
      )}
    </>
  );
};

export default CollapseLabel;
