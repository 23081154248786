import { variantsClassNames } from "theme/utils";

import "styles/App/Menu/Menu.scss";
import "styles/App/Menu/MenuItem.scss";

export const useMenuStyles = () => ({
  list: "AppMenu-list",
  paper: "AppMenu-paper",
  header: "AppMenu-header",
  avatarTitle: "AppMenu-avatarTitle",
});

export const usePopoverStyles = () => ({
  list: "AppPopover-list",
  paper: "AppPopover-paper",
});

export const useMenuItemStyles = ({ size = "medium", color } = {}) => ({
  root: variantsClassNames("AppMenuItem-root", { size, color }),
  itemContent: "AppMenuItem-itemContent",
  tickIcon: "AppMenuItem-tickIcon",
  actions: "AppMenuItem-actions",
});
