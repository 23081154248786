import React, { useEffect } from "react";
import clsx from "clsx";

import "styles/App/DndBuilder/Item.scss";

const useDndBuilderItemStyles = () => ({
  wrapper: "AppDndBuilder-Item-wrapper",
  root: "AppDndBuilder-Item-root",
  overlay: "AppDndBuilder-Item-overlay",
});

const ItemComponent = ({
  innerRef,
  component: Component,
  sectionProps,
  builderItem,
  dragOverlay,
  dragging,
  disabled,
  fadeIn,
  index,
  listeners,
  sorting,
  style,
  transition,
  transform,
  value,
  preview,
  onMouseDown,
  selected,
}) => {
  const classes = useDndBuilderItemStyles();

  const handleMouseDown = (event) => {
    event.stopPropagation();
    listeners?.onMouseDown?.(event);
    onMouseDown?.(event);
  };

  useEffect(() => {
    if (!dragOverlay) {
      return;
    }

    document.body.style.cursor = "grabbing";

    return () => {
      document.body.style.cursor = "";
    };
  }, [dragOverlay]);

  return (
    <li
      ref={innerRef}
      className={clsx(classes.wrapper, { fadeIn, sorting, dragOverlay, preview })}
      style={{
        transition,
        "--translate-x": transform ? `${Math.round(transform.x)}px` : undefined,
        "--translate-y": transform ? `${Math.round(transform.y)}px` : undefined,
        "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
        "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
        "--index": index,
      }}
    >
      <div
        className={clsx(classes.root, { dragging, dragOverlay, disabled, preview, selected })}
        style={style}
        data-cypress="draggable-item"
        onMouseDown={handleMouseDown}
        tabIndex={0}
      >
        {!preview && <div className={classes.overlay} />}

        {Component ? (
          <Component id={value} builderItem={builderItem} sectionProps={sectionProps} />
        ) : (
          value
        )}
      </div>
    </li>
  );
};

export const Item = React.memo(ItemComponent);
