import React from "react";
import { BuilderLayout as BuilderLayoutComponent } from "components/BuilderUi/Layout";
import { Outlet } from "react-router-dom";

const BuilderLayout = () => {
  return (
    <BuilderLayoutComponent>
      <Outlet />
    </BuilderLayoutComponent>
  );
};

export default BuilderLayout;
