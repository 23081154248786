import produce from "immer";

let actionTypePrefix = "IMMER_REDUCER";

/**
 * Type guard for detecting actions created by immer reducer
 *
 * @param action any redux action
 * @param immerActionCreator method from a ImmerReducer class
 */
export function isAction(action, immerActionCreator) {
  return action.type === immerActionCreator.type;
}

function isActionFromClass(action, immerReducerClass) {
  if (typeof action.type !== "string") {
    return false;
  }

  if (!action.type.startsWith(actionTypePrefix + ":")) {
    return false;
  }

  const [className, methodName] = removePrefix(action.type).split("#");

  if (className !== getReducerName(immerReducerClass)) {
    return false;
  }

  if (typeof immerReducerClass.prototype[methodName] !== "function") {
    return false;
  }

  return true;
}

export function isActionFrom(action, immerReducerClass) {
  return isActionFromClass(action, immerReducerClass);
}

/**
 * Combine multiple reducers into a single one
 *
 * @param reducers two or more reducer
 */
export function composeReducers(...reducers) {
  return (state, action) => {
    return (
      reducers.reduce((state, subReducer) => {
        if (typeof subReducer === "function") {
          return subReducer(state, action);
        }

        return state;
      }, state) || state
    );
  };
}

/** The actual ImmerReducer class */
export class ImmerReducer {
  static customName;
  state;
  draftState; // Make read only states mutable using Draft

  constructor(draftState, state) {
    this.state = state;
    this.draftState = draftState;
  }
}

function removePrefix(actionType) {
  return actionType.split(":").slice(1).join(":");
}

let KNOWN_REDUCER_CLASSES = [];

const DUPLICATE_INCREMENTS = {};

/**
 * Set customName for classes automatically if there is multiple reducers
 * classes defined with the same name. This can occur accidentaly when using
 * name mangling with minifiers.
 *
 * @param immerReducerClass
 */
function setCustomNameForDuplicates(immerReducerClass) {
  const hasSetCustomName = KNOWN_REDUCER_CLASSES.find((klass) =>
    Boolean(klass === immerReducerClass)
  );

  if (hasSetCustomName) {
    return;
  }

  const duplicateCustomName =
    immerReducerClass.customName &&
    KNOWN_REDUCER_CLASSES.find((klass) =>
      Boolean(klass.customName && klass.customName === immerReducerClass.customName)
    );

  if (duplicateCustomName) {
    throw new Error(
      `There is already customName ${immerReducerClass.customName} defined for ${duplicateCustomName.name}`
    );
  }

  const duplicate = KNOWN_REDUCER_CLASSES.find((klass) => klass.name === immerReducerClass.name);

  if (duplicate && !duplicate.customName) {
    let number = DUPLICATE_INCREMENTS[immerReducerClass.name];

    if (number) {
      number++;
    } else {
      number = 1;
    }

    DUPLICATE_INCREMENTS[immerReducerClass.name] = number;

    immerReducerClass.customName = immerReducerClass.name + "_" + number;
  }

  KNOWN_REDUCER_CLASSES.push(immerReducerClass);
}

/**
 * Convert function arguments to ImmerAction object
 */
function createImmerAction(type, args) {
  if (args.length === 1) {
    return { type, payload: args[0] };
  }

  return {
    type,
    payload: args,
    args: true,
  };
}

/**
 * Get function arguments from the ImmerAction object
 */
function getArgsFromImmerAction(action) {
  if (action.args) {
    return action.payload;
  }

  return [action.payload];
}

function getAllPropertyNames(obj) {
  const proto = Object.getPrototypeOf(obj);
  const inherited = proto ? getAllPropertyNames(proto) : [];
  return Object.getOwnPropertyNames(obj)
    .concat(inherited)
    .filter((propertyName, index, uniqueList) => uniqueList.indexOf(propertyName) === index);
}

export function createActionCreators(immerReducerClass) {
  setCustomNameForDuplicates(immerReducerClass);

  const actionCreators = {};
  const immerReducerProperties = getAllPropertyNames(ImmerReducer.prototype);
  getAllPropertyNames(immerReducerClass.prototype).forEach((key) => {
    if (immerReducerProperties.includes(key)) {
      return;
    }
    const method = immerReducerClass.prototype[key];

    if (typeof method !== "function") {
      return;
    }

    const type = `${actionTypePrefix}:${getReducerName(immerReducerClass)}#${key}`;

    const actionCreator = (...args) => {
      // Make sure only the arguments are passed to the action object that
      // are defined in the method
      return createImmerAction(type, args.slice(0, method.length));
    };
    actionCreator.type = type;
    actionCreators[key] = actionCreator;
  });

  return actionCreators;
}

function getReducerName(klass) {
  const name = klass.customName || klass.name;
  if (!name) {
    throw new Error(
      `immer-reducer failed to get reducer name for a class. Try adding 'static customName = "name"'`
    );
  }
  return name;
}

export function createReducerFunction(immerReducerClass, initialState) {
  setCustomNameForDuplicates(immerReducerClass);

  return function immerReducerFunction(state, action) {
    if (state === undefined) {
      state = initialState;
    }

    if (!isActionFromClass(action, immerReducerClass)) {
      return state;
    }

    if (!state) {
      throw new Error(
        "ImmerReducer does not support undefined state. Pass initial state to createReducerFunction() or createStore()"
      );
    }

    const [_, methodName] = removePrefix(action.type).split("#");

    return produce(state, (draftState) => {
      const reducers = new immerReducerClass(draftState, state);

      reducers[methodName](...getArgsFromImmerAction(action));

      // The reducer replaced the instance with completely new state so
      // make that to be the next state
      if (reducers.draftState !== draftState) {
        return reducers.draftState;
      }

      return draftState;

      // Workaround typing changes in Immer 9.x. This does not actually
      // affect the exposed types by immer-reducer itself.

      // Also using immer internally with anys like this allow us to
      // support multiple versions of immer.
    });
  };
}

export function setPrefix(prefix) {
  actionTypePrefix = prefix;
}

/**
 * INTERNAL! This is only for tests!
 */
export function _clearKnownClasses() {
  KNOWN_REDUCER_CLASSES = [];
}

/**
 * https://webpack.js.org/api/hot-module-replacement/#module-api
 */
if (typeof module !== "undefined") {
  // Clear classes on Webpack Hot Module replacement as it will mess up the
  // duplicate checks appear
  module.hot?.addStatusHandler?.((status) => {
    if (status === "prepare") {
      _clearKnownClasses();
    }
  });
}
