import React from "react";
import Box from "components/Box";
import { Avatar as MuiAvatar, Skeleton } from "@mui/material";
import { AVATAR_SIZES } from "theme/common";
import { BoxIcon } from "icons";
import Typography from "components/Typography";
import withClasses from "components/withClasses";
import isEmpty from "lodash/isEmpty";
import { LazyLoadImage } from "react-lazy-load-image-component";
import userAvatarIconUrl from "images/userAvatar.svg";

import "styles/App/TablePro/LazyImageCell.scss";
import "react-lazy-load-image-component/src/effects/opacity.css";

const AvatarComponent = ({
  innerRef,
  src,
  className,
  lazy = true,
  children,
  variantProps,
  ...props
}) => {
  if (!lazy || !src)
    return (
      <MuiAvatar
        component="div"
        className={className}
        src={children ? null : src || userAvatarIconUrl}
        ref={innerRef}
        {...props}
      >
        {children}
      </MuiAvatar>
    );

  return (
    <LazyLoadImage
      ref={innerRef}
      wrapperClassName={`MuiAvatar-root ${className}`}
      effect="opacity"
      src={src}
    />
  );
};

const Avatar = withClasses(AvatarComponent, "MuiAvatar", {
  color: null,
  size: "medium",
  variant: null,
  form: null,
});

export const AvatarImage = ({ loading, src, size = "small", children, ...props }) =>
  loading ? (
    <Avatar {...props} size={size}>
      <Skeleton height={`${AVATAR_SIZES[size] * 2}px`} width={`${AVATAR_SIZES[size]}px`} />
    </Avatar>
  ) : (
    <Avatar {...props} size={size} src={src}>
      {children}
    </Avatar>
  );

export const AvatarCreatedBy = ({ createdBy, size = "small", loading, ...props }) => (
  <Box display="flex" alignItems="center">
    <AvatarImage
      {...props}
      loading={loading}
      form="round"
      size={size}
      space={{ mr: 1, ...props.space }}
      src={createdBy?.avatar?.url}
    />

    {!loading && <Typography>{createdBy?.fullName || createdBy?.email || "@username"}</Typography>}
  </Box>
);

export const AvatarInitials = ({ name, ...props }) => {
  const names = name?.split(/\W+/) || [];
  const firstLetter = names[0]?.charAt(0);
  const lastLetter = names[1]?.charAt(0);

  let letters = [];
  if (firstLetter) letters = [...letters, firstLetter];
  if (lastLetter) letters = [...letters, lastLetter];

  return (
    <Avatar variant="text" {...props}>
      {letters.join("")}
    </Avatar>
  );
};

export const AvatarProduct = ({
  className,
  loading,
  icon: Icon = BoxIcon,
  src,
  size = "small",
  iconSize,
  iconColor,
  children,
  label,
  imageAlt = "avatar-img",
  ...props
}) => (
  <Box className={className}>
    {loading ? (
      <Avatar {...props} size={size}>
        <Skeleton height={`${AVATAR_SIZES[size] * 2}px`} width={`${AVATAR_SIZES[size]}px`} />
      </Avatar>
    ) : isEmpty(src) ? (
      <Avatar {...props} size={size}>
        <Icon color={iconColor} size={iconSize || size} />
        {children}
      </Avatar>
    ) : (
      <Avatar {...props} size={size}>
        <img alt={imageAlt} className="AppAvatar-img" src={src} />
        {children}
      </Avatar>
    )}

    {label && (
      <Typography space={{ mt: 1 }} color="hint">
        {label}
      </Typography>
    )}
  </Box>
);

export default Avatar;
