import uniq from "lodash/uniq";
import merge from "lodash/merge";
import { filterCollection } from "containers/TableFilter/utils";

import { createDataBatches } from "./buildRequestInput";
import { VARIANT_KEY } from "./mergeResponseInput";

export const filterInputFields = ({ fields, filter }) => {
  const variantRegexp = new RegExp(`_${VARIANT_KEY}_`);

  const variantProperties = uniq(
    fields
      .flatMap((field) => Object.keys(field))
      .filter((item) => item.includes("_VARIANT_"))
      .map((key) => key.split("_VARIANT_")[0])
  );

  const filteredFields = fields.reduce((result, field) => {
    const isNoVariantKeyField = !Object.keys(field).some((key) => key.match(variantRegexp));

    if (isNoVariantKeyField) {
      return [...result, field];
    }

    const items = createDataBatches([field]);

    const newField = items.reduce((itemsResult, item, index) => {
      if (filterCollection([item], filter).length > 0) {
        return merge(itemsResult, replaceVariantProperties({ item, index, variantProperties }));
      } else {
        return itemsResult;
      }
    }, {});

    return [...result, newField];
  }, []);

  return filteredFields;
};

const replaceVariantProperties = ({ item, index, variantProperties }) => {
  let newItem = {};

  Object.keys(item).forEach((key) => {
    if (variantProperties.includes(key)) {
      newItem[`${key}_VARIANT_${index}`] = item[key];
    } else {
      newItem[key] = item[key];
    }
  });

  return newItem;
};
