import React, { useState, useRef } from "react";
import { DraggableCore } from "react-draggable";
import clsx from "clsx";
import "styles/App/ResizeBorder.scss";

const useResizeBorderStyles = () => ({
  resizable: "AppResizeBorder-resizable",
  resizableWrap: "AppResizeBorder-wrap",
  resizableIndicator: "AppResizeBorder-indicator",
});

const ResizeBorder = ({
  onResizing,
  onStopReisizing,
  onMouseMove,
  onMouseOut,
  index,
  className,
  ...rest
}) => {
  const nodeRef = useRef(null);
  const [initX, setInitX] = useState(0);
  const classes = useResizeBorderStyles();

  const onStartReisizing = () => (e, ui) => {
    setInitX(ui.lastX);
  };

  const onDragReisizing = (index) => (e, ui) => {
    onResizing(index, ui.lastX - initX);
    setInitX(ui.lastX);
  };

  return (
    <DraggableCore
      nodeRef={nodeRef}
      axis="x"
      onStart={onStartReisizing(index)}
      onDrag={onDragReisizing(index)}
      onStop={onStopReisizing}
      position={{ x: 0, y: 0 }}
      zIndex={999}
    >
      <div
        ref={nodeRef}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseOut}
        className={clsx(classes.resizable)}
        {...rest}
      >
        <div className={clsx(className, classes.resizableIndicator)}></div>
      </div>
    </DraggableCore>
  );
};

export default ResizeBorder;
