import React, { useState } from "react";
import SelectHeaderActions from "components/TableNavigation/HeaderActions";
import { StateFieldsProvider } from "components/TablePro/Contexts/StateFieldsContext";
import TablePro from "components/TablePro";
import TableNavigation from "components/TableNavigation";
import { DrawerScrollContent } from "components/Drawer/ScrollContent";

export const ContainerTable = ({
  errors,
  columns,
  onChange,
  onRemove,
  onAdd,
  entities,
  checkboxColumn,
  viewsManagerComponent: ViewsManagerComponent,
  tableAddRowComponent,
  filterToggler,
  disabledFilter,
}) => {
  const [search, setSearch] = useState("");

  return (
    <DrawerScrollContent.Wrap labelsList>
      <DrawerScrollContent.Element>
        <StateFieldsProvider entities={entities} totalCount={entities.length}>
          <TableNavigation
            variant="stickyForDrawerCollapseLabel"
            search={search}
            onSearch={setSearch}
            title="Rows"
            totalCount={entities.length}
            filterToggler={filterToggler}
            disabledFilter={disabledFilter}
          />

          <TablePro
            variant="border"
            static
            resizeBorder={false}
            lockableColumns={false}
            editable={false}
            collapseSize={20}
            withCheckboxColumn={checkboxColumn}
            viewable={false}
            multiSelectable
            entities={entities}
            MultiselectHeader={SelectHeaderActions}
            totalCount={entities.length}
            columns={columns}
            errors={errors}
            onChange={onChange}
            onBulkRemove={onRemove}
            onAdd={onAdd}
            tableAddRowComponent={tableAddRowComponent}
          />

          {ViewsManagerComponent && <ViewsManagerComponent />}
        </StateFieldsProvider>
      </DrawerScrollContent.Element>
    </DrawerScrollContent.Wrap>
  );
};
