import { gql } from "@apollo/client";

import { Table } from "./Table";

export class TableShort extends Table {}

TableShort.fragment = gql`
  fragment TableShortFragment on GetFullTablePayload {
    id
    table {
      ...TableFragment
    }
  }

  ${Table.fragment}
`;
