import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import { FormFieldInput } from "containers/FormField/Input";
import CollapseLabel from "components/CollapseLabel";
import { TableIcon } from "iconsNew";
import { TOP_LABEL_FORMAT } from "models/abstract/LayoutStructure";
import { useItemFieldsStyles } from "theme/components/ItemFields";

export const LABEL_WIDTH = 160;

export const BuilderItemFormField = React.memo(
  ({ component, className = "", componentProps, builderItem, sectionProps, ...props }) => {
    const fieldLabelFormat = sectionProps?.fieldLabel;
    const FormFieldComponent = component || FormFieldInput;

    const classes = useItemFieldsStyles();

    return (
      <Box className={className} fullWidth>
        {fieldLabelFormat !== TOP_LABEL_FORMAT && (
          <Typography className={classes.label} style={{ width: LABEL_WIDTH }}>{builderItem.label}</Typography>
        )}

        <Box className={classes.field}>
          <FormFieldComponent
            label={fieldLabelFormat !== TOP_LABEL_FORMAT ? undefined : builderItem.label}
            fullWidth
            name="value"
            variant="outlined"
            formField={builderItem.formField}
            componentProps={componentProps}
            {...props}
          />
        </Box>
      </Box>
    );
  }
);

export const TableLinkItem = ({
  disabled,
  builderItem,
  open,
  onOpen,
  onClose,
  moreMenu = () => <div></div>,
  children,
}) => {
  const tableLink = builderItem.entity;

  return (
    <Box fullWidth>
      <CollapseLabel
        disabled={disabled}
        color="drawerSidebar"
        id={tableLink.id}
        label={builderItem.label}
        leftIcon={TableIcon}
        moreMenu={moreMenu}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        children={children}
      />
    </Box>
  );
};
