import { gql } from "@apollo/client";

import { Item } from "./Item";

export class FullItem extends Item {
  constructor(origin) {
    super(origin.item);

    this.primary = origin.primary || [];
    this.primaryLink = this.primary?.[0]?.table;
    this.primaryItem = this.primary?.[0]?.item ? new Item(this.primary?.[0]?.item) : null;
  }

  get isLinked() {
    return !!this.primaryItem;
  }
}

FullItem.fragment = gql`
  fragment FullItemFragment on GetFullItemPayload {
    item {
      ...ItemFragment
    }
    primary {
      table {
        id
        name
        uid
        type
      }
      item {
        ...ItemFragment
      }
    }
  }

  ${Item.fragment}
`;
