import { gql } from "@apollo/client";
import { useMemo } from "react";
import useQuery from "hooks/useQuery";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import fragments from "graphql/fragments";
import usePaginationQuery from "hooks/useQuery/usePaginationQuery";

import { TABLE_MODEL_TYPE } from "models/types";
import { TableShort } from "./TableShort";
import { TableSelect } from "./TableSelect";
import { TableFull } from "./TableFull";
import { TableSubListSelect } from "./TableSubListSelect";

export const GET_TABLES = gql`
  query SearchTables($input: BaseSearchInput) {
    searchTables(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...TableShortFragment
      }
    }
  }
  ${TableShort.fragment}
`;

export const GET_TABLES_SELECT = gql`
  query SearchTables($input: BaseSearchInput) {
    searchTables(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...TableSelectFragment
      }
    }
  }

  ${TableSelect.fragment}
`;

export const SEARCH_TABLES_SUB_LISTS_SELECT = gql`
  query SearchTables($input: BaseSearchInput) {
    searchTables(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...TableSubListSelectFragment
      }
    }
  }

  ${TableSubListSelect.fragment}
`;

export const GET_TABLE = gql`
  query GetTable($input: BaseGetInput) {
    getTable(input: $input) {
      ...TableFullFragment
    }
  }

  ${TableFull.fragment}
`;

const buildTableSearchInput = ({ searchInput: initialSearchInput = {}, root }) => ({
  ...initialSearchInput,
  filter: compact([
    ...(initialSearchInput?.filter || []),
    root ? { field: "type", value: "ROOT", operation: "EQ" } : null,
  ]),
});

export const useGetTable = (tableId, { skip = false } = {}) => {
  const {
    payload: table,
    loading,
    ...props
  } = useQuery(GET_TABLE, {
    skip: skip || !tableId,
    input: { id: tableId },
  });

  const tableDetails = table ? new TableFull(table) : null;
  const columns = loading ? [] : tableDetails?.columns || [];

  return {
    payload: table,
    table: tableDetails,
    columns,
    loading,
    ...props,
  };
};

export const useSearchTables = ({ searchInput, root, skip = false } = {}) => {
  const {
    content = [],
    loading,
    ...props
  } = usePaginationQuery(GET_TABLES, {
    input: buildTableSearchInput({ searchInput, root }),
    skip,
  });

  const tables = useMemo(() => content.map((table) => new TableShort(table)), [content]);

  return {
    tables,
    loading,
    ...props,
  };
};

export const useTablesSelect = ({ searchInput, root } = {}) => {
  const {
    content = [],
    loading,
    ...props
  } = usePaginationQuery(GET_TABLES_SELECT, {
    input: buildTableSearchInput({ searchInput, root }),
  });

  const tables = useMemo(() => content.map((table) => new TableSelect(table)), [content]);
  const options = useMemo(() => tables.map((table) => [table.id, table.name]), [tables]);

  return {
    tables,
    options,
    loading,
    ...props,
  };
};

export const useTablesSubLists = ({ searchInput, root } = {}) => {
  const {
    content = [],
    loading,
    ...props
  } = usePaginationQuery(SEARCH_TABLES_SUB_LISTS_SELECT, {
    input: buildTableSearchInput({ searchInput, root }),
  });

  const tables = useMemo(() => content.map((table) => new TableSubListSelect(table)), [content]);

  const subListsOptions = useMemo(() => {
    return tables.flatMap((table) => {
      return table.subLists.map((subList) => [subList.id, subList]);
    });
  }, [tables]);

  return {
    tables,
    subListsOptions,
    groupProperty: "tableTag",
    loading,
    ...props,
  };
};

export const useTablesColumns = ({ searchInput, root } = {}) => {
  const {
    content: tables = [],
    loading,
    ...props
  } = usePaginationQuery(GET_TABLES, {
    input: buildTableSearchInput({ searchInput, root }),
  });

  const tablesColumnsOptions = useMemo(() => {
    return flatten(
      tables.map(({ table: tableItem }) => {
        const table = {
          id: tableItem.id,
          name: tableItem.name,
          uid: tableItem.uid,
          tag: `${tableItem.uid}.${tableItem.name}`,
        };

        return (tableItem.columns || []).map((column) => [column.id, { table, ...column }]);
      })
    );
  }, [tables]);

  return {
    tablesColumnsOptions,
    groupProperty: "table.tag",
    loading,
    ...props,
  };
};

export const useTreeTablesColumns = ({ searchInput, root } = {}) => {
  const {
    content = [],
    loading,
    ...props
  } = usePaginationQuery(GET_TABLES, {
    input: buildTableSearchInput({ searchInput, root }),
  });

  const tables = useMemo(() => content.map((table) => new TableShort(table)), [content]);

  const columnsTree = useMemo(() => fetchColumnsTree(tables, 0), [tables]);

  const columnsTreeWithDescendants = useMemo(
    () => columnsTree.filter((table) => table.descendants?.length > 0),
    [columnsTree]
  );

  return {
    tables,
    columnsTree,
    columnsTreeWithDescendants,
    loading,
    ...props,
  };
};

const fetchNestedColumns = (entity) => {
  const type = entity.modelType ?? entity.type;
  return type === TABLE_MODEL_TYPE ? entity.columns : [];
};

const fetchColumnsTree = (entities) =>
  entities.reduce((result, entity) => {
    const nestedColumns = fetchNestedColumns(entity);

    const entityProps = {
      id: entity.id,
      name: entity.name,
      modelType: entity.modelType,
      fieldId: entity.fieldId,
      componentType: entity.formField?.componentType,
    };

    const entityItem =
      nestedColumns?.length > 0
        ? { ...entityProps, descendants: fetchColumnsTree(nestedColumns) }
        : entityProps;

    return [...result, entityItem];
  }, []);
