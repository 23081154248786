import React, { useState, useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const fetchInputProps = (props) => {
  const { ref, inputProps, label, InputProps, ...rest } = props;

  return { inputRef: ref, ...inputProps, ...rest };
};

const DatePickerInput = ({ readOnly = false, value, renderInput, onChange, onClose, format }) => {
  const [open, setOpen] = useState(false);
  const [triggerOnClose, setTriggerOnClose] = useState(false);

  const handleClose = () => {
    if (!open) return;

    setOpen(false);
    setTriggerOnClose(true);
  };

  useEffect(() => {
    if (!triggerOnClose) return;

    onClose && onClose();
    setTriggerOnClose(false);
  }, [triggerOnClose]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        disableCloseOnSelect={true}
        label={null}
        clearable={true}
        open={open}
        value={value}
        onClose={handleClose}
        onChange={readOnly ? null : onChange}
        renderInput={(props) => {
          const inputProps = fetchInputProps(props);

          return renderInput({
            ...inputProps,
            readOnly,
            onClick: () => setOpen(true),
            value: inputProps.value ? moment(inputProps.value).format(format) : inputProps.value,
          });
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerInput;
