import React, { useContext, createContext } from "react";

const FormLayoutNamespaceContext = createContext(null);

export const FormLayoutNamespaceProvider = ({ formLayoutId, children }) => {
  return (
    <FormLayoutNamespaceContext.Provider
      value={{
        formLayoutId,
      }}
    >
      {children}
    </FormLayoutNamespaceContext.Provider>
  );
};

export const useFormLayoutNamespace = () => useContext(FormLayoutNamespaceContext) || {};
