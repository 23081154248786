import React, { useState } from "react";
import Button from "components/Button";
import IconButton from "components/IconButton";
import VDivider from "components/VDivider";
import { ActionDialog } from "components/Dialog";
import { CrossIcon, TrashIcon, ZapIcon } from "iconsNew";
import { CONTENT } from "constants/content";
import { useStateFields } from "components/TablePro/Contexts/StateFieldsContext";

const HeaderActions = ({ onRemove, onAction, confirmation = "false" }) => {
  const { checkedCount, cleanCheckState, checkedIds } = useStateFields();
  const [openRemove, setOpenRemove] = useState(false);

  const handleCloseRemove = () => setOpenRemove(false);
  const handleConfirmRemove = () => setOpenRemove(true);

  const handleRemove = () => {
    onRemove(checkedIds);
    cleanCheckState();
  };

  const contentMessage = `Removing items will clean up the current table. This action does not remove records from the parrent table, if you want to make a permanent deletion select the right option below.`;

  return (
    <>
      <Button variant="primary" size="small" onClick={cleanCheckState} leftIcon={CrossIcon}>
        Deselect&nbsp;<span style={{ minWidth: 10 }}>{checkedCount}</span>
      </Button>

      <VDivider />

      {onAction && checkedIds.length > 0 && (
        <>
          <Button
            variant="primary"
            size="small"
            space={{ ml: 0.5 }}
            onClick={onAction}
            leftIcon={ZapIcon}
          >
            Actions
          </Button>
          <VDivider />
        </>
      )}

      {onRemove && (
        <IconButton
          className=""
          size="small"
          variant="primary"
          onClick={confirmation ? handleConfirmRemove : handleRemove}
          icon={TrashIcon}
        />
      )}

      {confirmation && (
        <ActionDialog
          content={contentMessage}
          title={`Remove record?`}
          open={openRemove}
          onClose={handleCloseRemove}
          actions={
            <Button variant="danger" onClick={handleRemove}>
              {CONTENT.confirmRemove}
            </Button>
          }
        />
      )}
    </>
  );
};

export default HeaderActions;
