import merge from "lodash/merge";
import pick from "lodash/pick";
import { TABLE_CONTAINER_ID } from "components/DndBuilder/Provider";
import { MappingPath } from "models/abstract/MappingPath";
import { TABLE_COLUMN_MODEL_TYPE } from "models/types";
import { OBJECT_TYPE } from "models/abstract/FieldType";

export const VARIANT_KEY = "VARIANT";

export const mergeResponseInput = ({ structure, input, action, response, allItemsMap }) => {
  const keyField = action.response?.rows?.find((row) => !!row.to.key);
  const keyFieldId = keyField?.to?.path?.[keyField?.to?.path?.length - 1]?.id;
  const mappingRows = action.response?.rows?.map((row) => row.to);

  if (!mappingRows || !keyFieldId) return input;

  const listOfValues = convertStructure(response.data);

  const baseItemsMap = buildMapByField(listOfValues, mappingRows, keyFieldId, allItemsMap);

  return mergeByItemsMap({
    structure,
    originalInput: { ...input },
    baseItemsMap,
    keyFieldId,
  });
};

function buildMapByField(listOfValues, mappingRows, keyFieldId, allItemsMap) {
  const map = new Map();

  const mappingRowsMap = mappingRows.reduce((result, item) => {
    result[MappingPath.convertPathToPathId(item.path)] = item;
    return result;
  }, {});

  map.set(keyFieldId, new Map());

  listOfValues.forEach((values) => {
    const inputValue = parseItemToInputValue({ values, mappingRowsMap, allItemsMap });
    const keyValue = inputValue[keyFieldId];

    if (keyValue !== undefined) {
      const keyValues = map.get(keyFieldId).get(keyValue) || [];
      map.get(keyFieldId).set(keyValue, [...keyValues, inputValue]);
    }
  });

  return map;
}

const parseItemToInputValue = ({ values, mappingRowsMap, allItemsMap }) => {
  const mappingRowsKeys = Object.keys(mappingRowsMap);

  return values.reduce((result, item) => {
    const mappingRow = mappingRowsMap[mappingRowsKeys.find((key) => key.includes(item.id))];
    const mappingRowType = mappingRow ? mappingRow.path[mappingRow.path.length - 1].type : null;

    if (mappingRowType === TABLE_COLUMN_MODEL_TYPE) {
      const tableColumn = allItemsMap[MappingPath.convertPathToPathId(mappingRow.path.slice(0, 2))];

      if (tableColumn?.entity?.type === OBJECT_TYPE) {
        const objectId = mappingRow.path.slice(1)[0].id;

        const newValue = {
          columnId: item.id,
          type: tableColumn?.entity.nestedColumnsTypesMap[item.id],
          value: item.value,
        };

        result[objectId] = [...(result[objectId] || []), newValue];

        return result;
      }

      result[item.id] = item.value;

      return result;
    }

    result[item.id] = item.value;

    return result;
  }, {});
};

const mergeByItemsMap = ({ structure, originalInput, baseItemsMap, keyFieldId }) => {
  let flatStructure = [];
  let tableStructures = {};
  let draftInput = { ...originalInput };

  removeVariantFields({ ...originalInput });

  if (!baseItemsMap.get(keyFieldId)) return originalInput;

  const keyValuesMap = baseItemsMap.get(keyFieldId);

  const fechContainerItems = (container) =>
    container.items.map((item) => item.path[item.path.length - 1].id);

  structure.forEach((section) => {
    section.containers.forEach((container) => {
      if (container.type === TABLE_CONTAINER_ID) {
        tableStructures[container.id] = fechContainerItems(container);
      } else {
        flatStructure = [...flatStructure, ...fechContainerItems(container)];
      }
    });
  });

  if (flatStructure.includes(keyFieldId)) {
    const newInput =
      keyValuesMap.get(draftInput[keyFieldId])?.[0] || keyValuesMap.entries().next().value || {};

    const newValue = merge(pick(draftInput, flatStructure), pick(newInput, flatStructure));

    draftInput = { ...draftInput, ...newValue };
  }

  Object.keys(tableStructures).forEach((tableStructureId) => {
    let propertiesWhiteList = tableStructures[tableStructureId].flat();

    if (propertiesWhiteList.includes(keyFieldId)) {
      keyValuesMap.forEach((itemBatch, keyValue) => {
        const newItemValue = parseBatchItem({
          itemBatch,
          properties: propertiesWhiteList,
          draftInput,
        });

        draftInput[tableStructureId] = draftInput[tableStructureId] || [];

        const existItemIndex = draftInput[tableStructureId].findIndex(
          (row) => row[keyFieldId] === keyValue
        );

        if (existItemIndex !== -1) {
          const newValue = merge(
            pick(draftInput[tableStructureId][existItemIndex], propertiesWhiteList),
            pick(newItemValue, propertiesWhiteList)
          );

          draftInput[tableStructureId][existItemIndex] = newValue;
        } else {
          draftInput[tableStructureId].push(pick(newItemValue, propertiesWhiteList));
        }
      });
    }
  });

  return draftInput;
};

const isVariantValue = ({ value1, value2, properties, key }) => {
  if (value1 && value2 && value1 !== value2) return true;

  if (value1 && value2 && value1 === value2) {
    return !!properties.find((property) => property.match(`${key}_${VARIANT_KEY}_`));
  }

  return false;
};

export const parseBatchItem = ({ itemBatch, properties, draftInput }) => {
  let newItemValue = {};

  itemBatch.forEach((item, itemIndex) => {
    Object.keys(item).forEach((key) => {
      const isVariant = isVariantValue({
        value1: item[key],
        value2: newItemValue[key],
        properties,
        key,
      });

      // If value is already exist and changed to replace it with VARIANT
      if (isVariant) {
        const prevValue = newItemValue[key];
        delete newItemValue[key];
        updateVariantField(draftInput, key);
        properties.push(`${key}_${VARIANT_KEY}_0`);
        properties.push(`${key}_${VARIANT_KEY}_${itemIndex}`);

        newItemValue[`${key}_${VARIANT_KEY}_0`] = prevValue;
        newItemValue[`${key}_${VARIANT_KEY}_${itemIndex}`] = item[key];
      } else if (Object.keys(newItemValue).includes(`${key}_${VARIANT_KEY}_0`)) {
        updateVariantField(draftInput, key);
        properties.push(`${key}_${VARIANT_KEY}_${itemIndex}`);
        newItemValue[`${key}_${VARIANT_KEY}_${itemIndex}`] = item[key];
      } else {
        newItemValue[key] = item[key];
      }
    });
  });

  return newItemValue;
};

function convertStructure(data) {
  let result = [];

  data.forEach((item) => {
    item.values.forEach((value) => {
      if (value?.value) {
        result = [...result, ...extractNestedValues(value.value)];
      }
    });
  });

  return result;
}

function extractNestedValues(inputArray) {
  return inputArray.map((item) => {
    const nestedValues = item.values
      .find((v) => Array.isArray(v.value))
      ?.value.map((nestedItem) => nestedItem.values)
      .flat();
    const otherValues = item.values.filter((v) => !Array.isArray(v.value));
    return nestedValues ? [...nestedValues, ...otherValues] : otherValues;
  });
}

function removeVariantFields(obj) {
  if (Array.isArray(obj)) {
    obj.forEach((item) => {
      if (typeof item === "object" && item !== null) {
        removeVariantFields(item); // Recursive call for each item in the array
      }
    });
  } else {
    for (const key in obj) {
      if (key.includes(VARIANT_KEY)) {
        delete obj[key]; // Delete the variant field
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        removeVariantFields(obj[key]); // Recursive call for nested objects
      }
    }
  }
}

function updateVariantField(obj, fieldKey) {
  if (Array.isArray(obj)) {
    obj.forEach((item) => {
      if (typeof item === "object" && item !== null) {
        updateVariantField(item, fieldKey); // Recursive call for each item in the array
      }
    });
  } else {
    for (const key in obj) {
      if (key === fieldKey) {
        obj[`${key}_${VARIANT_KEY}_0`] = obj[key];
        delete obj[key]; // Delete the variant field
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        updateVariantField(obj[key], fieldKey); // Recursive call for nested objects
      }
    }
  }
}
