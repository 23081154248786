import { useMemo } from "react";
import useQuery from "hooks/useQuery";
import isEmpty from "lodash/isEmpty";
import {
  GET_LIST_CHANNEL_API_TYPES,
  GET_LIST_CHANNEL_API_SOURCES,
  GET_COUNTRIES,
  GET_CURRENCIES,
  GET_FIELD_ITEMS,
} from "graphql/queries";
import usePaginationQuery from "hooks/useQuery/usePaginationQuery";
import { LIST_TYPE } from "models/abstract/FieldType";

export const useListChannelApiTypes = ({ channelBrand, taskKind }) => {
  const {
    payload: channelApiTypes = [],
    loading,
    ...props
  } = useQuery(GET_LIST_CHANNEL_API_TYPES, {
    skip: !channelBrand,
    input: { channelBrand, taskKind },
  });

  return { channelApiTypes, loading, ...props };
};

export const useListChannelApiSources = (channelApiTypeId) => {
  const {
    payload: channelApiSources = [],
    loading,
    ...props
  } = useQuery(GET_LIST_CHANNEL_API_SOURCES, {
    skip: !channelApiTypeId,
    input: { channelApiTypeId },
  });

  return { channelApiSources, loading, ...props };
};

export const useCountries = () => {
  const { payload: countries = [], loading, ...props } = useQuery(GET_COUNTRIES);

  return {
    countries,
    loading,
    ...props,
  };
};

export const useCurrencies = () => {
  const { payload: currencies = [], loading, ...props } = useQuery(GET_CURRENCIES);

  return {
    currencies,
    loading,
    ...props,
  };
};

export const useFieldItems = ({ searchInput, fieldId, skip } = {}) => {
  const {
    content: fieldItems = [],
    loading,
    ...props
  } = usePaginationQuery(GET_FIELD_ITEMS, {
    input: { ...searchInput, fieldId, sort: [{ field: "value", value: "ASC" }] },
    skip: !fieldId || skip,
  });

  const fieldItemsOptions = useMemo(
    () => fieldItems.map((fieldItem) => [fieldItem.id, fieldItem.value]),
    [fieldItems]
  );

  return {
    fieldItems,
    fieldItemsOptions,
    loading,
    ...props,
  };
};

export const useChannelMappingFieldItems = ({ searchInput, fieldId, fieldType } = {}) => {
  const {
    content = [],
    loading,
    ...props
  } = usePaginationQuery(GET_FIELD_ITEMS, {
    input: {
      ...searchInput,
      fieldId,
      filter: [{ field: "channelValue", operation: "NE", value: null }],
      sort: [{ field: "value", value: "ASC" }],
    },
    skip: !fieldId || fieldType !== LIST_TYPE,
  });

  const channelFieldItemsMap = content
    .filter((item) => !isEmpty(item.channelValue))
    .reduce(
      (result, item) => ({
        ...result,
        [item.channelValue]: item.value,
      }),
      {}
    );

  return {
    channelFieldItemsMap,
    loading,
    ...props,
  };
};
