import React from "react";
import Box from "components/Box";
import IconButton from "components/IconButton";
import { ReloadRefreshIcon, TrashIcon, MaximizeIcon } from "iconsNew";

import "styles/App/UploadFileActions.scss";

const useUploadFileActionsStyles = () => ({
  root: "AppUploadFileActions-root",
});

const UploadFileActions = ({ handleChange, handleRemove, handleExpand }) => {
  const classes = useUploadFileActionsStyles();

  return (
    <Box className={classes.root}>
      <IconButton size="collapse" color="grey" onClick={handleChange} icon={ReloadRefreshIcon} />
      <IconButton size="collapse" color="grey" onClick={handleRemove} icon={TrashIcon} />
      <IconButton size="collapse" color="grey" onClick={handleExpand} icon={MaximizeIcon} />
    </Box>
  );
};

export default UploadFileActions;
