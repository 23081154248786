export class AccessRule {
  constructor({ any, all }) {
    this.any = any || [];
    this.all = all || [];
  }

  checkAccess({ accessEntity, isAccessible }) {
    return (
      this._checkAnyRules({ isAccessible, accessEntity }) &&
      this._checkAllRules({ isAccessible, accessEntity })
    );
  }

  // PRIVATE

  _checkAnyRules({ accessEntity, isAccessible }) {
    if (this.any.length === 0) return true;

    return this.any.some((permissionType) => isAccessible(permissionType, accessEntity));
  }

  _checkAllRules({ accessEntity, isAccessible }) {
    if (this.all.length === 0) return true;

    return this.all.every((permissionType) => isAccessible(permissionType, accessEntity));
  }
}
