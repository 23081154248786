import { delegate, memoize } from "utils/entities";
import omit from "lodash/omit";
import { MappingPath } from "models/abstract/MappingPath";

export class LayoutStructure {
  constructor(origin) {
    this.origin = origin;

    delegate(this, origin);

    this.cache = {};
    this.memoize = memoize(this.cache);
  }

  static fetchSectionsProps(builderStructure) {
    return (builderStructure || []).reduce(
      (result, section) => ({ ...result, [section.id]: omit(section, ["id", "containers"]) }),
      {}
    );
  }

  static fetchItemsProps(builderStructure) {
    const items = (builderStructure || [])
      .map((section) => section.containers.flatMap((container) => container.items))
      .flat();

    return items.reduce(
      (result, item) => ({ ...result, [item.id]: omit(item, ["id", "path"]) }),
      {}
    );
  }

  static fetchContainersProps(builderStructure) {
    const containers = (builderStructure || []).map((section) => section.containers).flat();

    return containers.reduce(
      (result, container) => ({ ...result, [container.id]: omit(container, ["id", "items"]) }),
      {}
    );
  }

  static parseStructureForInput({ structure, sectionsProps = {} }) {
    return parseStructureForInput({ structure, sectionsProps });
  }

  static fetchItemsPaths = (structure) => {
    return structure.reduce(
      (result, section) => [
        ...result,
        ...section.containers.reduce(
          (result, container) => [...result, ...container.items.map((item) => item.path)],
          []
        ),
      ],
      []
    );
  };

  get builderStructure() {
    return this.origin.schema.map((section) => ({
      ...section,
      containers: section.containers.map((container) => ({
        ...container,
        items: container.items.map((item) => ({
          ...item,
          id: MappingPath.convertPathToPathId(item.path),
        })),
      })),
    }));
  }

  get sectionsProps() {
    return LayoutStructure.fetchSectionsProps(this.builderStructure);
  }

  get containersIds() {
    return this.builderStructure.reduce(
      (result, section) => [...result, ...section.containers.map((container) => container.id)],
      []
    );
  }
}

export const LEFT_LABEL_FORMAT = "LEFT";
export const TOP_LABEL_FORMAT = "TOP";
export const REGULAR_LABEL_FORMAT = "REGULAR";
export const PRIMARY_LABEL_FORMAT = "PRIMARY";

export const FIELD_LABEL_FORMATS_OPTIONS = [
  { id: LEFT_LABEL_FORMAT, label: "Left" },
  { id: TOP_LABEL_FORMAT, label: "Top" },
];

export const FIELD_STYLE_FORMATS_OPTIONS = [
  { id: REGULAR_LABEL_FORMAT, label: "Regular" },
  { id: PRIMARY_LABEL_FORMAT, label: "Primary" },
];

const parseStructureForInput = ({ structure }) => {
  if (!structure) return { schema: [] };

  const parsedStructure = structure.reduce((result, section) => {
    const parsedSection = {
      ...section,
      containers: section.containers.map((container) => ({
        ...container,
        items: container.items,
      })),
    };

    return [...result, parsedSection];
  }, []);

  return { schema: parsedStructure };
};
