import { delegate, memoize, serialize, serializeCollection } from "utils/entities";
import { gql } from "@apollo/client";
import { LayoutStructure } from "models/abstract/LayoutStructure";
import { User } from "models/fragments/User";
import { MemberShort } from "models/Member/MemberShort";
import { FORM_LAYOUT_MODEL_TYPE } from "models/types";

import { FormAction } from "./FormAction";

export class FormLayout {
  constructor(origin) {
    this.origin = origin;

    delegate(this, origin);

    this.cache = {};
    this.memoize = memoize(this.cache);
    this.layoutStructure = new LayoutStructure(this.origin.structure);

    serialize(this, { createdBy: User });
    serializeCollection(this, { actions: FormAction });

    this.sharedMembers = this.members.content.map((member) => new MemberShort(member));
  }

  get modelType() {
    return FORM_LAYOUT_MODEL_TYPE;
  }

  get disableSharing() {
    return false;
  }

  get builderStructure() {
    return this.layoutStructure.builderStructure;
  }

  get containersIds() {
    return this.layoutStructure.containersIds;
  }

  get sectionsProps() {
    return this.layoutStructure.sectionsProps;
  }

  get sharedProjectsTotalCount() {
    return this.projects.totalElements;
  }

  get sharedProjects() {
    return this.origin.projects.content || [];
  }

  get totalSharedMembersCount() {
    return this.members.totalElements;
  }
}

FormLayout.fragment = gql`
  fragment FormLayoutFragment on GetFormLayoutPayload {
    id
    createdDate
    lastModifiedDate
    inUse
    name
    description
    structure
    createdBy {
      ...UserFragment
    }
    projects {
      totalElements
      content {
        id
        name
        uid
        type
      }
    }
    members {
      totalElements
      content {
        id
        user {
          ...UserFragment
        }
        inviteEmail
        status
      }
    }
    actions {
      ...FormActionFragment
    }
    elements {
      path {
        id
        type
      }
    }
  }

  ${User.fragment}
  ${FormAction.fragment}
`;
