import { variantsClassNames } from "theme/utils";

import "styles/App/SvgIconNew.scss";

export const useSvgIconStylesNew = ({
  variant = "default",
  color = "inherit",
  size = "initial",
}) => ({
  root: `AppIcon-root ${variantsClassNames("AppIconNew-root", { variant, color, size })}`,
  box: "AppIconNew-root__box",
});
