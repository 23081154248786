import { TABLE_ATTRIBUTES } from "graphql/fragments";

export const LINK_ATTRIBUTES = `
  id
  uid
  createdDate
  lastModifiedDate
  inUse
  name
  description
`;

export const FORMULA_FRAGMENT = `
  id
  createdDate
  inUse
  fieldId
  value
`;

export const TABLE_SUB_LIST_LINK = `
  ${LINK_ATTRIBUTES}

  componentTable {
    ...TableFragment
  }

  mapping {
    componentColumnId
    lookup
    dynamic
    hidden
  }
  columns {
    ...TableColumnFragment
  }
  formulas {
    ${FORMULA_FRAGMENT}
  }
`;

export const TABLE_SECONDARY_LINK = `
  ${LINK_ATTRIBUTES}

  channel {
    id
    name
  }
  secondaryTable {
    ...TableFragment
  }
  mapping {
    primaryColumnId
    secondaryColumnId
    hidden
    overwrite
    dynamic
    lookup
  }
`;

export const TABLE_BY_FIELD_LINK = `
  ${LINK_ATTRIBUTES}

  keyColumn {
    ...TableColumnFragment
  }
  mapping {
    table {
      ${TABLE_ATTRIBUTES}
    }
    column {
      ...TableColumnFragment
    }
  }
`;

export const TABLE_CONSOLIDATED_LINK = `
  ${LINK_ATTRIBUTES}
  uid
  columns {
    ...TableColumnFragment
  }
  primaryColumns {
    ...TablePrimaryColumnFragment
  }
  formulas {
    ${FORMULA_FRAGMENT}
  }
`;
