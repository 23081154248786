import clsx from "clsx";
import "styles/App/Tabs.scss";

export const useTabsStyles = ({ compact = false } = {}) => ({
  root: "AppTabs-root",
  table: "AppTabs-root__table",
  menu: "AppTabs-root__menu",
  drawer: clsx("AppTabs-root__drawer", { "AppTabs-root__drawer-compact": compact }),
  summary: "AppTabs-root__summary",
});

export const useTabsColorsStyles = () => ({
  grey: "AppTabsColors-grey",
});
