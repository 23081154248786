import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "components/Box";

import "styles/App/DndBuilder/Constructor.scss";

const useDndBuilderConstructorStyles = () => ({
  root: "AppDndBuilder-Constructor-root",
});

const SkeletonBox = () => (
  <Box space={{ mb: 2 }}>
    <Skeleton variant="rect" height="30px" width="100%" />
  </Box>
);

export const ConstructorContainer = ({ children }) => {
  const classes = useDndBuilderConstructorStyles();

  return <div className={classes.root}>{children}</div>;
};

export const ConstructorSkeleton = () => {
  return (
    <Box space={{ mt: 2 }}>
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
    </Box>
  );
};
