import { gql } from "@apollo/client";

export const MappingRowValueFragment = gql`
  fragment MappingRowValueFragment on MappingRowValuePayload {
    path {
      id
      type
    }
    static
    key
  }
`;

export const MAPPING_FRAGMENT = `
    from {
      ...MappingRowValueFragment
    }
    to {
      ...MappingRowValueFragment
    }
    rows {
      from {
        ...MappingRowValueFragment
      }
      to {
        ...MappingRowValueFragment
      }
    }
`;
