import React from "react";
import Box from "components/Box";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { InfoIcon } from "icons";
import ErrorTooltip from "components/Alerts/ErrorTooltip";
import { toString as errorsToString } from "utils/parseErrors";

import CollapseSpace from "./CollapseSpace";

const TableCellContainer =
  (Component) =>
  ({
    collapseLevel,
    nestedLevel,
    colIndex,
    entity,
    classes,
    style,
    className,
    column,
    rowRootError,
    collapsed,
    setCollapse,
    ...props
  }) => {
    const isError = !isEmpty(rowRootError);

    return (
      <Box
        style={style}
        className={clsx(
          classes.cellContainer,
          className,
          { error: isError },
          { "cursor-pointer": setCollapse && colIndex === 1 }
        )}
        onClick={setCollapse && colIndex === 1 && (() => setCollapse(!collapsed))}
      >
        <CollapseSpace
          column={column}
          colIndex={colIndex}
          nestedLevel={nestedLevel}
          collapseLevel={collapseLevel}
          setCollapse={setCollapse}
          collapsed={collapsed}
        />

        <Component {...props} entity={entity} tableColumn={column} nestedLevel={nestedLevel} />

        <TableCellErrorTooltip error={rowRootError} />
      </Box>
    );
  };

export const TableCellErrorTooltip = ({ error, style }) => {
  if (isEmpty(error)) return null;

  return (
    <ErrorTooltip title={errorsToString(error)}>
      <Box
        display="flex"
        alignItems="center"
        style={{ ...style, cursor: "pointer" }}
        space={{ ml: 1 }}
      >
        <InfoIcon color="error" />
      </Box>
    </ErrorTooltip>
  );
};

export default TableCellContainer;
