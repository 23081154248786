import React, { useState } from "react";

import { TableConnectorForm } from "./TableConnectorForm";

export const TableConnectorInput = ({ value, onChange, ...props }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleSave = ({ onClose, newFormValue } = {}) => {
    onChange(newFormValue ?? inputValue);
    onClose?.();
  };

  const handleCancel = () => {
    setInputValue(value);
  };

  return (
    <TableConnectorForm
      {...props}
      value={inputValue}
      onChange={setInputValue}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
};
