import React, { useMemo } from "react";
import { MUI_THEMES, THEMES } from "theme";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import OsProvider from "providers/OsProvider";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import convertToCss from "theme/convertToCss";
import JSum from "vendor/jsum";

import "styles/App.scss";

const cache = createCache({
  key: "mui",
  prepend: true,
});

export const ThemeProvider = ({ children }) => {
  const muiTheme = MUI_THEMES.LIGHT;
  const theme = THEMES.LIGHT;

  const cssTheme = useMemo(() => convertToCss(theme), [JSum.digest(theme, "SHA256", "hex")]);

  return (
    <CacheProvider value={cache}>
      <style>{`:root { ${cssTheme} }`}</style>

      <MuiThemeProvider theme={muiTheme}>
        <OsProvider>{children}</OsProvider>
      </MuiThemeProvider>
    </CacheProvider>
  );
};
