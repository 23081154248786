import React, { useState, useEffect } from "react";
import Box from "components/Box";
import SelectNew from "components/Inputs/Select";
import Input from "components/Inputs/Input";
import moment from "moment";

const TYME_OPTIONS = [
  ["hours", "Hours"],
  ["days", "Days"],
  ["weeks", "Weeks"],
  ["months", "Months"],
  ["years", "Years"],
];

const DaysInput = ({ past, space, value: inputValue = 30, size, onChange, variant }) => {
  const operator = past ? -1 : 1;
  const [value, setValue] = useState(inputValue);
  const [timeType, setTimeType] = useState("days");

  useEffect(() => {
    if (!value || !timeType) return;

    const date = moment().add(operator * value, timeType);

    if (date) {
      onChange(date.valueOf());
    }
    // eslint-disable-next-line
  }, [value, timeType]);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      <Input
        space={{ mr: 1.5, ...space, mb: 0 }}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        size={size}
        type="number"
        placeholder="setValue"
        variant={variant}
      />

      <SelectNew
        space={{ mr: 1.5, ...space, mb: 0 }}
        value={timeType}
        onChange={(value) => setTimeType(value)}
        placeholder="selectValue"
        size={size}
        options={TYME_OPTIONS}
        variant={variant}
      />
    </Box>
  );
};

export default DaysInput;
