import { useMemo } from "react";
import { gql } from "@apollo/client";
import useQuery from "hooks/useQuery";
import usePaginationQuery from "hooks/useQuery/usePaginationQuery";

import { FormLayout } from "./FormLayout";

export const SEARCH_FORM_LAYOUTS = gql`
  query SearchFormLayouts($input: BaseSearchInput) {
    searchFormLayouts(input: $input) {
      content {
        ...FormLayoutFragment
      }
    }
  }
  ${FormLayout.fragment}
`;

export const GET_FORM_LAYOUT = gql`
  query GetFormLayout($input: BaseGetInput) {
    getFormLayout(input: $input) {
      ...FormLayoutFragment
    }
  }
  ${FormLayout.fragment}
`;

export const useSearchFormLayouts = ({ searchInput } = {}) => {
  const { content = [], ...rest } = usePaginationQuery(SEARCH_FORM_LAYOUTS, {
    input: {
      ...searchInput,
      sort: [{ field: "createdDate", value: "ASC" }],
    },
  });

  const formLayouts = useMemo(() => content.map((data) => new FormLayout(data)), [content]);

  return { formLayouts, content, ...rest };
};

export const useGetFormLayout = (formLayoutId) => {
  const { payload, loading, ...props } = useQuery(GET_FORM_LAYOUT, {
    input: { id: formLayoutId },
    skip: !formLayoutId,
  });

  const formLayout = payload ? new FormLayout(payload) : null;

  return { formLayout, loading, ...props };
};
