import React from "react";
import { useMenuItemStyles } from "theme/components/Menu";
import Box from "components/Box";
import Typography from "components/Typography";
import { DragIcon, NumIcon } from "iconsNew";
import Skeleton from "@mui/material/Skeleton";
import clsx from "clsx";

import "styles/App/DndBuilder/ListItem.scss";

const useDndBuilderListItemStyles = () => ({
  wrapper: "AppDndBuilder-ListItem-wrapper",
  root: "AppDndBuilder-ListItem-root",
  dragIcon: "AppDndBuilder-ListItem-dragIcon",
});

const StorageListItemComponent = ({
  innerRef,
  dragOverlay,
  placeholder,
  dragging,
  disabled,
  fadeIn,
  listeners,
  sorting,
  style,
  label,
  icon: Icon,
}) => {
  const menuItemClasses = useMenuItemStyles();
  const classes = useDndBuilderListItemStyles();

  const onMouseDown = (event) => {
    event.stopPropagation();
    listeners?.onMouseDown?.(event);
  };

  return (
    <li ref={innerRef} className={clsx(classes.wrapper, { fadeIn, sorting })}>
      <Box fullWidth display="flex" alignItems="center">
        {!placeholder && (
          <div className={classes.dragIcon} {...listeners}>
            <DragIcon color="grey" />
          </div>
        )}

        <div
          className={clsx(menuItemClasses.root, classes.root, {
            dragging,
            placeholder,
            dragOverlay,
            disabled,
          })}
          style={{ ...style, flex: "1 1 auto" }}
          data-cypress="draggable-item"
          onMouseDown={onMouseDown}
          tabIndex={0}
        >
          <Box display="flex" alignItems="center" style={{ flexGrow: "1" }}>
            {Icon && <Icon space={{ mr: 1 }} size="small" />}

            <Typography className={classes.itemContent} component="div" variant="body1">
              {label}
            </Typography>
          </Box>
        </div>
      </Box>
    </li>
  );
};

export const StorageListItem = React.memo(StorageListItemComponent);

export const SkeletonStorageListItem = ({ style }) => {
  const menuItemClasses = useMenuItemStyles();
  const classes = useDndBuilderListItemStyles();

  return (
    <li className={clsx(classes.wrapper)}>
      <Box fullWidth display="flex" alignItems="center">
        <div className={classes.dragIcon}>
          <DragIcon color="grey" />
        </div>

        <div
          className={clsx(menuItemClasses.root, classes.root)}
          style={{ ...style, flex: "1 1 auto" }}
          tabIndex={0}
        >
          <Box display="flex" alignItems="center" style={{ flexGrow: "1" }}>
            <NumIcon space={{ mr: 1 }} size="small" />

            <Typography
              style={{ width: "100%" }}
              className={classes.itemContent}
              component="div"
              variant="body1"
            >
              <Skeleton style={{ width: "100%", color: "transparent" }} variant="rect" />
            </Typography>
          </Box>
        </div>
      </Box>
    </li>
  );
};
