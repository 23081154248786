import React from "react";
import Box from "components/Box";
import { CircleArrowDownIcon } from "icons";
import { useTableFilters } from "theme/components/TableFilters";
import LabelButton from "components/LabelButton";
import { ComponentTypeView } from "components/ComponentType/ComponentTypeView";
import { humanize } from "utils/string";
import clsx from "clsx";

export const FilterLabel = ({ disabled, readOnly = false, filter, filterValue, ...props }) => {
  const classes = useTableFilters();

  const { formField, label } = filter || {};

  return (
    <>
      <LabelButton
        disabled={disabled}
        disableRipple
        component="div"
        leftIcon={CircleArrowDownIcon}
        className={clsx(classes.label, { readOnly })}
        color="purple"
        {...props}
      >
        <Box component="span" display="flex" alignItems="center" className={classes.labelTitle}>
          <Box space={{ mr: 0.5 }} className="ellipsesTitle">
            {`${humanize(label)} :`}
          </Box>

          <ComponentTypeView
            space={{ mr: 0.5 }}
            className="ellipsesTitle"
            type={formField?.componentType}
            originValue={filterValue}
            value={formField?.parseValue(filterValue)}
          />
        </Box>
      </LabelButton>
    </>
  );
};
