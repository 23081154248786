import React from "react";
import Box from "components/Box";
import { draggableItemStyle } from "theme/components/Draggable";
import VerticalMove from "components/Draggable/VerticalMove";

import FieldItem from "./FieldItem";

const DraggableColumnItem = React.forwardRef((props, ref) => (
  <FieldItem {...props} innerRef={ref} />
));

const FieldsList = ({ draggable = true, classes, innerRef, fields, onMove, onChange, ...rest }) => {
  const onDragEnd = (startIndex, endIndex) => onMove(startIndex, endIndex);

  const handleChangeItem = (index) => (newProps) => {
    onChange(index, newProps);
  };

  const DraggableWrap = draggable ? VerticalMove : Box;

  const draggableProps = draggable
    ? {
        useDraggableElement: true,
        onDragEnd: onDragEnd,
        getItemStyle: draggableItemStyle,
      }
    : {};

  return (
    <Box ref={innerRef} {...rest}>
      <DraggableWrap {...draggableProps}>
        {fields.map((field) => (
          <DraggableColumnItem
            key={`Draggable-${field.id}`}
            draggableIndex={field.index}
            index={field.index}
            onChange={handleChangeItem(field.index)}
            field={field}
            classes={classes}
          />
        ))}
      </DraggableWrap>
    </Box>
  );
};

export default FieldsList;
