import React from "react";
import { Table as MuiTable } from "@mui/material";
import Box from "components/Box";
import { PlusIcon } from "icons";
import Typography, { TypographyValue } from "components/Typography";
import ExternalLinks from "components/ExternalLinks";
import Button from "components/Button";
import withStyles from "components/withStyles";
import { SPACING } from "theme/common";

const Table = withStyles(({ innerRef, ...props }) => <MuiTable ref={innerRef} {...props} />);

export const LabelsGridContainer = ({ children, space, onLine = false }) => (
  <Table
    space={space}
    style={{
      width: onLine ? "100%" : null,
      borderCollapse: "separate",
      borderSpacing: 0,
    }}
  >
    <tbody>{children}</tbody>
  </Table>
);

const DEFAULT_OFFSET = {
  none: 0,
  small: SPACING,
  medium: SPACING * 2,
};

export const LabelsGridItems = ({
  children,
  weight = "normal",
  minWidth,
  style,
  noPaddingForLastItem = true,
  offsetSize = "medium",
  verticalAlign = "middle",
}) => {
  return (
    <>
      {React.Children.map(children, (child, index) => {
        if (!child) return;

        const childrensCount = React.Children.count(children);
        const paddingBottom =
          noPaddingForLastItem && childrensCount >= 1 && index === childrensCount - 1
            ? 0
            : DEFAULT_OFFSET[offsetSize];

        const {
          label,
          labelIcon: LabelIcon,
          labelHide,
          labelColor = "grey",
          ...childProps
        } = child.props || {};

        if (labelHide) return;

        return (
          <tr style={style} key={`LabelsGrid-${index}`}>
            <td style={{ paddingBottom, verticalAlign }}>
              <Box display="flex" space={{ pr: 4 }} style={{ minWidth }}>
                <Typography
                  leftIcon={
                    LabelIcon
                      ? (props) => <LabelIcon {...props} size="small" space={{ mr: 1.5 }} />
                      : null
                  }
                  component="div"
                  weight={weight}
                  style={{ whiteSpace: "nowrap" }}
                  color={labelColor}
                >
                  {label}
                </Typography>
              </Box>
            </td>

            <td style={{ paddingBottom, verticalAlign, width: "100%" }}>
              <Box display="flex">
                {React.cloneElement(child, {
                  ...childProps,
                  label: null,
                  space: { ...childProps.space, mb: 0 },
                })}
              </Box>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export const LabelsGridOneLine = ({
  children,
  weight = "normal",
  minWidth,
  offsetSize = "medium",
  noPaddingForLastItem = true,
}) => {
  return (
    <>
      {React.Children.map(children, (child, index) => {
        if (!child) return;

        const {
          label,
          labelIcon: LabelIcon,
          labelHide,
          labelSpace,
          labelColor = "grey",
          ...childProps
        } = child.props || {};

        const childrensCount = React.Children.count(children);

        const paddingBottom =
          noPaddingForLastItem && childrensCount > 1 && index === childrensCount - 1
            ? 0
            : DEFAULT_OFFSET[offsetSize];

        if (labelHide) return;

        return (
          <>
            <tr key={`LabelsGrid-label-${index}`}>
              <td>
                <Box style={{ minWidth }} space={{ mb: 1 }} display="flex">
                  <Typography
                    leftIcon={
                      LabelIcon
                        ? (props) => (
                            <LabelIcon
                              {...props}
                              size="small"
                              space={{ mr: DEFAULT_OFFSET[offsetSize], ...labelSpace }}
                            />
                          )
                        : null
                    }
                    component="div"
                    weight={weight}
                    style={{ whiteSpace: "nowrap" }}
                    color={labelColor}
                  >
                    {label}
                  </Typography>
                </Box>
              </td>
            </tr>

            <tr key={`LabelsGrid-value-${index}`}>
              <td style={{ paddingBottom, width: "100%" }}>
                <Box space={{ mb: DEFAULT_OFFSET[offsetSize], ...labelSpace }} display="flex">
                  {React.cloneElement(child, {
                    ...childProps,
                    label: null,
                    style: { marginBottom: 0 },
                  })}
                </Box>
              </td>
            </tr>
          </>
        );
      })}
    </>
  );
};

const LabelsGrid = ({
  children,
  onLine = false,
  weight = "noram",
  minWidth,
  offsetSize = "medium",
  verticalAlign = "middle",
  space,
  noPaddingForLastItem = true,
}) => {
  const LabelGridItemComponent = onLine ? LabelsGridOneLine : LabelsGridItems;

  return (
    <LabelsGridContainer space={space} onLine={onLine}>
      <LabelGridItemComponent
        offsetSize={offsetSize}
        minWidth={minWidth}
        weight={weight}
        verticalAlign={verticalAlign}
        noPaddingForLastItem={noPaddingForLastItem}
      >
        {children}
      </LabelGridItemComponent>
    </LabelsGridContainer>
  );
};

export const ExternalLinksGridItem = ({
  entities,
  loading,
  labelHide,
  label,
  addTitle,
  onAdd,
  onClick,
  minWidth,
  offsetSize = "medium",
  noPaddingForLastItem,
}) => {
  if (!onAdd && entities.length === 0) return <></>;

  return (
    <LabelsGridItems
      noPaddingForLastItem={noPaddingForLastItem}
      offsetSize={offsetSize}
      minWidth={minWidth}
    >
      <TypographyValue
        component="div"
        label={label}
        labelHide={labelHide}
        loading={loading}
        value={
          <>
            <ExternalLinks
              onClick={onClick}
              size="large"
              space={{ mb: onAdd ? 1 : 0 }}
              entities={entities}
            />

            {onAdd && (
              <Button onClick={onAdd} leftIcon={PlusIcon} variant="grey" size="compact">
                {addTitle}
              </Button>
            )}
          </>
        }
      />
    </LabelsGridItems>
  );
};

export const ExternalLinkGridItem = ({
  entity,
  loading,
  labelHide,
  label,
  addTitle,
  onAdd,
  onClick,
  minWidth,
}) => {
  return (
    <LabelsGridItems minWidth={minWidth}>
      <TypographyValue
        component="div"
        label={label}
        labelHide={labelHide}
        loading={loading}
        value={
          <>
            {entity && (
              <ExternalLinks onClick={onClick} size="large" space={{ mb: 0 }} entities={[entity]} />
            )}

            {!entity && (
              <Button onClick={onAdd} leftIcon={PlusIcon} variant="grey" size="compact">
                {addTitle}
              </Button>
            )}
          </>
        }
      />
    </LabelsGridItems>
  );
};

export default LabelsGrid;
