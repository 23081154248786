import moment from "moment";

export const FORMATS = {
  time: "h:mm a",
  date: "MM/DD/yyyy",
  dateTime: "MM/DD/yyyy h:mm a",
  softDateTime: "DD MMM, HH:mm",
};

export const timeAgo = (value) => (value ? moment(value).fromNow() : "");

export const timeString = (value, format = null) =>
  value ? moment(value).format(format || FORMATS.dateTime) : "";

export const softDateTime = (value, format = null) =>
  value ? moment(value).format(format || FORMATS.softDateTime) : "";

export const dateString = (value, format = null) =>
  value ? moment(value).format(format || FORMATS.date) : "";

export const fromNow = (value) => (value ? moment(value).fromNow() : "");

export const parseBackendTimeFormat = (value) => {
  if (value !== null && typeof value === "string") {
    return value.replaceAll("d", "D").replaceAll("KK", "kk");
  }
  console.error("Invalid value:", value);
  return value;
};
