import { gql } from "@apollo/client";
import { delegate, memoize, serialize, serializeCollection } from "utils/entities";
import keyBy from "lodash/keyBy";
import compact from "lodash/compact";
import { SUB_LIST_TABLE_ACCESS_LINK_MODEL_TYPE, SUB_LIST_LINK_TYPE } from "models/types";

import { FormField } from "models/abstract/FormField";
import { TableColumn } from "models/fragments/TableColumn";
import { TableAccess } from "./TableAccess";

export class SublistTableAccessLink {
  constructor(origin) {
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin);

    this.mapping = this.origin.mapping || [];

    serialize(this, { componentTable: TableAccess });
    serializeCollection(this, { columns: TableColumn });
  }

  get linkType() {
    return SUB_LIST_LINK_TYPE;
  }

  get modelType() {
    return SUB_LIST_TABLE_ACCESS_LINK_MODEL_TYPE;
  }

  get formFields() {
    return this.memoize("formFields", () => {
      const columnsMap = keyBy(this.componentTable.columns, "id");

      const columnsFormFields = this.mapping.map((column) => {
        const tableColumn = columnsMap[column.componentColumnId];

        if (!tableColumn || column.hidden) return null;

        return new FormField({
          id: tableColumn.id,
          fieldId: tableColumn.fieldId,
          name: tableColumn.id,
          label: tableColumn.name,
          disabled: false,
          icon: null,
          type: tableColumn.type,
          subtype: tableColumn.subtype,
          editable: false,
          required: tableColumn.mandatory,
          config: tableColumn.config,
          placeholder: tableColumn.placeholder,
        });
      });

      const additionalFormFields = this._additionalColumns.map(
        (column) =>
          new FormField({
            id: column.id,
            fieldId: column.fieldId,
            name: column.id,
            label: column.name,
            disabled: false,
            icon: null,
            type: column.type,
            subtype: column.subtype,
            editable: true && !column.blank,
            required: column.mandatory,
            config: column.config,
            placeholder: column.placeholder,
          })
      );

      return compact([columnsFormFields, additionalFormFields].flat());
    });
  }

  // Private

  get _additionalColumns() {
    return this.memoize("additionalColumns", () => {
      const columnsValue = this.origin.columns || [];
      const lastModifiedDate = this.origin.lastModifiedDate;

      return columnsValue.map((column) => new TableColumn(column, { lastModifiedDate }));
    });
  }
}

SublistTableAccessLink.fragment = gql`
  fragment SublistTableAccessLinkFragment on SublistTableAccessLink {
    id
    name
    uid
    componentTable {
      ...TableAccessFragment
    }
    mapping {
      componentColumnId
      hidden
      dynamic
      lookup
    }
    columns {
      ...TableColumnFragment
    }
  }

  ${TableAccess.fragment}
  ${TableColumn.fragment}
`;
