import React from "react";
import { Collapse as MuiCollapse } from "@mui/material";

const Collapse = ({ in: open, children, ...rest }) => {
  return (
    <MuiCollapse in={open} {...rest} unmountOnExit>
      {children}
    </MuiCollapse>
  );
};

export default Collapse;
