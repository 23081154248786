import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";

export const replaceKeys = (object, mapper) =>
  Object.keys(object).reduce((result, key) => {
    result[mapper[key]] = object[key];

    return result;
  }, {});

export const flatCompact = (array) => compact([array].flat());

export const flipKeyValue = (data) =>
  Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]));

export const flattenTree = (tree) => {
  const flatStructure = [];

  const flatten = (node) => {
    flatStructure.push(node);

    if (node.descendants && node.descendants.length > 0) {
      node.descendants.forEach((child) => flatten(child));
    }
  };

  tree.forEach((node) => flatten(node));

  return flatStructure;
};

export const replaceKeysDeep = (obj, mapper) => {
  const transformed = {};

  for (const key in obj) {
    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      transformed[key] = replaceKeysDeep(obj[key], mapper);
    } else {
      transformed[key] = obj[key];
    }
  }

  for (const key in mapper) {
    if (transformed[mapper[key]]) {
      transformed[key] = transformed[mapper[key]];
      delete transformed[mapper[key]];
    }
  }

  return transformed;
};

export const noEmptyValues = (values) => values.filter((value) => !isEmpty(value));
export const noEmptyValue = (values) => noEmptyValues(values)[0];
