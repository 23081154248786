import React from "react";
import { Box as MuiBox } from "@mui/material";
import withStyles from "components/withStyles";
import clsx from "clsx";
import { contentStyles } from "theme/components/Content";

const Box = ({
  labelSpace,
  labelHide,
  innerRef,
  className,
  fullHeightContainer = false,
  fullHeightScroll = false,
  fullHeight = false,
  pageContent = false,
  pageTable = false,
  fullWidth = false,
  ...props
}) => {
  const contentClasses = contentStyles;

  return (
    <MuiBox
      className={clsx(className, {
        [contentClasses.container]: fullHeightContainer,
        [contentClasses.fullHeightScroll]: fullHeightScroll,
        [contentClasses.fullHeight]: fullHeight,
        [contentClasses.fullWidth]: fullWidth,
        [contentClasses.pageContent]: pageContent,
        [contentClasses.pageTable]: pageTable,
      })}
      ref={innerRef}
      {...props}
    />
  );
};

const useBoxScrollContainer = () => ({
  container: "AppBoxScroll-container",
  content: "AppBoxScroll-content",
  body: "AppBoxScroll-body",
});

export const BoxScrollFrame = ({ children, ...props }) => {
  const classes = useBoxScrollContainer();

  return (
    <Box {...props} className={classes.container}>
      <div className={classes.content}>
        <div className={classes.body}>{children}</div>
      </div>
    </Box>
  );
};

export default withStyles(Box);
