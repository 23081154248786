import React from "react";
import Box from "components/Box";
import Button from "components/Button";
import clsx from "clsx";
import { PlusIcon } from "iconsNew";
import { useTablePro } from "../Provider";

import { useTableProStyles } from "theme/components/TablePro";

export const TableNavigationActionBar = ({ children }) => {
  const classes = useTableProStyles();

  return <Box className={classes.actionBar}>{children}</Box>;
};

export const TableNavigationRow = ({ className: rowClassName, children, border, multiselect }) => {
  const { classes, headerClasses } = useTablePro();

  return (
    <div
      className={clsx(headerClasses.row, rowClassName, { border, multiselect, navigation: true })}
    >
      <Box style={{ position: "sticky", left: 0 }}>
        <Box style={{ minHeight: 35 }} className={classes.cellContainer}>
          {children}
        </Box>
      </Box>
    </div>
  );
};

export const TableAddRecordRow = ({ onAdd }) => {
  return (
    <TableNavigationRow>
      <Button space={{ ml: -2 }} leftIcon={PlusIcon} size="xs" variant="grey" onClick={onAdd}>
        Add record
      </Button>
    </TableNavigationRow>
  );
};
