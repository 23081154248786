import React, { useRef, useState, useEffect } from "react";
import { Select as MuiSelect, Fade } from "@mui/material";
import clsx from "clsx";
import JSum from "vendor/jsum";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import compact from "lodash/compact";
import orderBy from "lodash/orderBy";
import keyBy from "lodash/keyBy";
import { flatCompact } from "utils/object";
import FormControl from "components/FormControl";
import { stopPropagation } from "utils/input";
import { useSelectStyles } from "theme/components/Select";
import { useSearchMenu } from "theme/components/SearchMenu";
import Box from "components/Box";
import Typography from "components/Typography";
import Input from "components/Inputs/Input";
import { ChevronDownIcon, PlusIcon } from "iconsNew";
import Label from "components/Label";
import { searchOptions, searchTreeEntitiesFilter } from "utils/search";
import { SelectOptions } from "components/Inputs/Select/SelectOptions";
import { useOsContext } from "providers/OsProvider";

import useSelect from "./useSelect";
import { useFreeSoloOptions } from "./useFreeSolo";
import RenderTags, { optionName } from "./RenderTags";

import "styles/App/SearchMenu.scss";

const MIN_CONTENT_WIDTH = 200;
const CLOSE_TIMEOUT = 750;

export const useSelectMenuStyles = () => ({
  paper: "AppSearchMenu-paper--select",
  contentContainer: "AppSearchMenu-contentContainer--select",
  searchContainer: "AppSearchMenu-searchContainer--select",
  CollapseMenuItem: "AppSearchMenu-CollapseMenuItem--root",
});

const skipCloseElement = (event) => {
  return (
    event?.target && event.target.closest(".AppSearchMenu-container") && event.type === "click"
  );
};

const SelectNew = ({
  name,
  searchable = true,
  removeableLabel = false,
  multiple = false,
  virtualized = false,
  renderTagsInSearch = false,
  optionVariant = "text",
  markVariant = "tick",
  label,
  variant,
  rightIcon,
  innerRef,
  size = "medium",
  space = {},
  className,
  formControlClassName,
  disabled = false,
  fullHeight = false,
  fullWidth = false,
  placeholder = "Select option",
  leftIcon,
  value: inputValue,
  onChange: onChangeInput,
  onChangeHook,
  onClean,
  onOpen,
  onClose,
  freeSolo,
  parseFreeSolo,
  closeOnChange = undefined,
  options: initialOptions = [],
  valueOptions: initialValueOptions = [],
  skipOptions: initialSkipOptions = [],
  usedOptions: initialUsedOptions = [],
  optionComponent: OptionComponent,
  selectedLabelComponent: SelectedLabelComponent,
  hint,
  error,
  showError,
  footer: Footer,
  groupProperty,
  treeProperty,
  onSearch,
  totalCount: inputTotalCount,
  menuLabel = "Select option",
  emptyMenuLabel,
  onClickItem,
  loading = false,
  style,
  menuContentWidth,
  searchProperties = [],
  allowBlank = true,
  customContentWidth,
  onLoadMore,
}) => {
  const { isNotMacOS } = useOsContext();
  const inputRef = useRef();
  const isApiAutocomplete = onSearch && onLoadMore;
  const searchableOffset = searchable || renderTagsInSearch;
  const classes = useSelectStyles({ size, variant });
  const menuClasses = useSearchMenu();
  const selectClasses = useSelectMenuStyles();

  const [open, setOpen] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [cachedValueOptions, setCachedValueOptions] = useState([]);
  const anchorRef = useRef();
  const [anchorEl, setAnchorEl] = useState();
  const [contentWidth, setContentWidth] = useState(undefined);
  const needToClose = closeOnChange ?? !multiple;

  const { value, parseSelectedValue, isNoneSelected, isSelectedValue } = useSelect({
    inputValue,
    onClean,
    multiple,
  });

  const skipOptionsIds = parseOptions(initialSkipOptions).map((option) => option.id);
  const usedOptionsIds = parseOptions(initialUsedOptions).map((option) => option.id);

  const allSkipOptionsIds = [
    ...skipOptionsIds,
    ...usedOptionsIds.filter((optionId) => !isSelectedValue(optionId)),
  ];

  const parsedOptions = parseOptions(initialOptions).filter(
    (option) => !allSkipOptionsIds.includes(option.id)
  );
  const parsedOptionsMap = keyBy(parsedOptions, "id");

  const handleChange = (selectedValue) => {
    const newValue = parseSelectedValue(selectedValue);

    if (!allowBlank && isEmpty(newValue)) return;

    needToClose && handleSelectClose();
    onChangeInput && onChangeInput(newValue);
    onChangeHook && onChangeHook(newValue);

    const cachedValueOptions = flatCompact(newValue).map((value) => parsedOptionsMap[value]);
    setCachedValueOptions(compact(cachedValueOptions));
  };

  const onAddFreeSolo = (value) => {
    handleChange(value);
    setSearch("");
  };

  const { addFreeSoloOption, freeSoloOptions } = useFreeSoloOptions({
    parseFreeSolo,
    onAdd: onAddFreeSolo,
    optionsMap: parsedOptionsMap,
    value,
    freeSolo,
  });

  const { aggregatedOptions, unmetOptionsCount } = aggregateOptions({
    valueOptions: parseOptions(initialValueOptions),
    cachedValueOptions,
    freeSoloOptions,
    options: parsedOptions,
  });

  const IconComponent = () => {
    return multiple ? (
      <PlusIcon className="rightIcon bordered" />
    ) : (
      <ChevronDownIcon className="rightIcon" />
    );
  };

  const searchFunction = treeProperty ? searchTreeEntitiesFilter : searchOptions;

  const sortedOptions = sortOptions({
    options: isApiAutocomplete ? aggregatedOptions : aggregatedOptions,
    isLoadMore: !!onLoadMore,
  });

  const renderOptions = isApiAutocomplete
    ? sortedOptions
    : searchFunction(
        sortedOptions,
        search,
        compact([groupProperty, "name", "label", ...searchProperties])
      );

  const totalCount = inputTotalCount
    ? inputTotalCount - allSkipOptionsIds.length + unmetOptionsCount
    : renderOptions.length;

  const isRenderedTagsInSearch = renderTagsInSearch && !isEmpty(value);

  const OptionLabelComponent =
    optionVariant === "label" ? LabelWrap(OptionComponent) : OptionComponent;

  const TagLabelComponent = SelectedLabelComponent || OptionComponent;

  const handleOpen = () => {
    onOpen && onOpen(anchorRef.current);
    setAnchorEl(anchorRef.current);
    setOpen(true);
  };

  const handleSelectClose = (event) => {
    if (skipCloseElement(event)) return;
    handleClose();
    onClose && onClose();
  };

  const handleClose = () => {
    setSearch("");
    setOpen(false);
    setAnchorEl(null);
  };

  const handleRemove = (_event, option) => {
    const newValue = parseSelectedValue(option.id);

    onChangeInput && onChangeInput(newValue);
    onChangeHook && onChangeHook(newValue);
  };

  const handleClickItem = (event, option) => {
    onClickItem(event, option, { onClose: handleClose });
  };

  useEffect(() => {
    const anchorWidth = customContentWidth
      ? customContentWidth
      : Math.max(anchorRef.current?.getBoundingClientRect()?.width, MIN_CONTENT_WIDTH);

    setContentWidth(menuContentWidth ?? anchorWidth);
  }, [menuContentWidth, open, JSum.digest(value, "SHA256", "hex")]);

  // The fix for MUI autofocus bug
  // Discussion: https://github.com/mui/material-ui/issues/33004
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (open && inputRef.current) {
        const inputEl = inputRef.current.getElementsByTagName("input")[0];
        inputEl?.focus();
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [open]);

  useEffect(() => {
    if (loadingMore || !onSearch || search === undefined) return;

    if (search?.length <= 2) {
      onSearch("");
      return;
    }

    const timeOutId = setTimeout(() => onSearch(search), 500);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [search]);

  const mainInputHeight = anchorRef.current?.nextSibling?.getBoundingClientRect()?.height;

  const inpytStyle = isRenderedTagsInSearch
    ? {
        paddingTop: 0,
      }
    : {
        minHeight: mainInputHeight,
        paddingTop: 0,
        paddingBottom: 0,
      };

  return (
    <FormControl
      space={{ mb: 1, ...space }}
      fullWidth={fullWidth}
      size={size}
      variant="outlined"
      hint={hint}
      error={error}
      showError={showError}
      style={style}
      className={formControlClassName}
    >
      {label && (
        <Box mb={1}>
          <Typography error={`${!!error}`} variant="caption">
            {label}
          </Typography>
        </Box>
      )}

      <Box className="anchorEl" ref={anchorRef} />

      <MuiSelect
        name={name}
        error={!!error}
        ref={innerRef}
        disabled={disabled}
        displayEmpty={true}
        classes={classes}
        className={clsx(className, {
          [classes.placeholder]: isNoneSelected,
          searchable,
        })}
        onClose={handleSelectClose}
        onOpen={handleOpen}
        open={open}
        value={value}
        placeholder={placeholder}
        IconComponent={rightIcon ? rightIcon : IconComponent}
        renderValue={(selectedValue) => (
          <RenderTags
            classes={classes}
            optionVariant={optionVariant}
            placeholder={placeholder}
            placeholderIcon={leftIcon}
            options={aggregatedOptions}
            value={selectedValue}
            isMarkSelect={variant === "ghostOutlined"}
            optionComponent={TagLabelComponent}
            onRemoveItem={removeableLabel && handleRemove}
            onClickItem={onClickItem && handleClickItem}
          />
        )}
        MenuProps={{
          ...(searchableOffset ? { anchorEl: anchorEl, getContentAnchorEl: null } : {}),
          TransitionComponent: Fade,
          TransitionProps: { unmountOnExit: true, timeout: CLOSE_TIMEOUT / 10 },
          classes: menuClasses,
          MenuListProps: {
            className: menuClasses.list,
            style: { minWidth: contentWidth },
          },
          PaperProps: {
            className: clsx(
              selectClasses.paper,
              { topOffset: !searchableOffset },
              { "custom-scroll": isNotMacOS }
            ),
            style: { minWidth: contentWidth },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        <Box className={menuClasses.container}>
          <Box className={selectClasses.searchContainer}>
            {isRenderedTagsInSearch && (
              <Box className={classes.searchValueTags}>
                <RenderTags
                  classes={classes}
                  optionVariant="label"
                  options={aggregatedOptions}
                  value={value}
                  optionComponent={TagLabelComponent}
                  onRemoveItem={removeableLabel && handleRemove}
                  onClickItem={onClickItem && handleClickItem}
                />
              </Box>
            )}

            {searchable && (
              <Input
                innerRef={inputRef}
                inputProps={{
                  alt: "selectInput",
                  style: inpytStyle,
                }}
                space={{ mb: 0 }}
                fullWidth
                autoFocus
                placeholder="Search"
                variant="ghost"
                size={size}
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                onKeyDown={freeSolo ? addFreeSoloOption : stopPropagation}
              />
            )}
          </Box>

          <MenuLabel
            loading={loading}
            options={renderOptions}
            search={search}
            freeSolo={freeSolo}
            menuLabel={menuLabel}
            emptyMenuLabel={emptyMenuLabel}
          />

          <SelectOptions
            searchable={searchable}
            loading={loading}
            isFooter={!!Footer}
            fullHeight={fullHeight}
            virtualized={virtualized}
            isSelected={isSelectedValue}
            options={renderOptions}
            allSkipOptionsIds={allSkipOptionsIds}
            totalCount={totalCount}
            onChange={handleChange}
            groupProperty={groupProperty}
            treeProperty={treeProperty}
            optionComponent={OptionLabelComponent}
            markVariant={markVariant}
            onLoadMore={onLoadMore}
            loadingMore={loadingMore}
            setLoadingMore={setLoadingMore}
          />
        </Box>

        {Footer && (
          <Box className={menuClasses.footer}>
            <Footer onClose={handleClose} onSelectClose={handleSelectClose} />
          </Box>
        )}
      </MuiSelect>
    </FormControl>
  );
};

const MenuLabel = ({ loading, options, search, freeSolo, menuLabel, emptyMenuLabel }) => {
  if (loading) return <></>;

  const renderMenuLabel = isEmpty(options)
    ? freeSolo && !isEmpty(search)
      ? emptyMenuLabel || "Didn't find? Tap to select the value"
      : "No options"
    : menuLabel;

  if (!renderMenuLabel) return <></>;

  return (
    <Typography space={{ mx: 1.5, my: 1 }} color="grey" variant="body2">
      {renderMenuLabel}
    </Typography>
  );
};

const parseOptions = (options) =>
  Array.isArray(options[0])
    ? options.map((option) => ({ id: option[0], label: option[1] }))
    : options;

const LabelWrap =
  (Component) =>
  ({ children, ...props }) => {
    return Component ? (
      <Label size="small">
        <Component {...props} />
      </Label>
    ) : (
      <Label size="small" {...props}>
        {props.option.label}
      </Label>
    );
  };

export const useDebounceAnchor = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [closeTimeoutId, setCloseTimeoutId] = useState(null);

  const onOpen = (element) => {
    if (closeTimeoutId !== null) clearTimeout(closeTimeoutId);

    setAnchorEl(element);
  };

  const onClose = () => {
    if (closeTimeoutId !== null) clearTimeout(closeTimeoutId);

    setCloseTimeoutId(
      setTimeout(() => {
        setAnchorEl(null);
      }, CLOSE_TIMEOUT)
    );
  };

  return { anchorEl, onOpen, onClose };
};

const aggregateOptions = ({
  valueOptions = [],
  options = [],
  freeSoloOptions = [],
  cachedValueOptions = [],
}) => {
  const optionsIds = options.map((option) => option.id);

  const unmentValueOptions = uniqBy([...valueOptions, ...cachedValueOptions], "id").filter(
    (option) => !optionsIds.includes(option.id)
  );

  return {
    aggregatedOptions: [...freeSoloOptions, ...unmentValueOptions, ...options],
    unmetOptionsCount: freeSoloOptions.length + unmentValueOptions.length,
  };
};

const sortOptions = ({ options, isLoadMore }) => {
  if (isLoadMore) return options;

  return orderBy(options, (option) => optionName(option)?.toLowerCase?.(), "asc");
};

export default SelectNew;
