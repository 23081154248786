import React from "react";

import { Item } from "../Item";

export const SortableItems = ({ sectionsProps, allItemsMap, components, items }) => {
  return items.map((itemId, index) => (
    <Item
      preview
      key={`${itemId}-item`}
      component={components.item}
      sectionProps={sectionsProps}
      builderItem={allItemsMap[itemId]}
      id={itemId}
      index={index}
    />
  ));
};
