import React from "react";
import Typography from "components/Typography";
import clsx from "clsx";

import { ActionsTooltip } from "./ActionsTooltip";

import "styles/App/DndBuilder/Section.scss";

const useDndBuilderSectionStyles = () => ({
  root: "AppDndBuilder-Section-root",
  content: "AppDndBuilder-Section-content",
  header: "AppDndBuilder-Section-header",
  list: "AppDndBuilder-Section-list",
  placeholder: "AppDndBuilder-Section-placeholder",
});

export const Section = ({
  children,
  handleProps,
  hover,
  onClick,
  onRemove,
  title,
  style,
  selected,
  innerRef,
  placeholder,
  useTitle,
  preview,
  ...props
}) => {
  const classes = useDndBuilderSectionStyles();

  const hideHeader = preview && !title;

  return (
    <div
      {...props}
      ref={innerRef}
      style={style}
      className={clsx(classes.root, {
        selected,
        hover,
        placeholder,
        preview,
      })}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
    >
      <div className={classes.content}>
        {!hideHeader && (
          <div className={classes.header}>
            <SectionTitle title={title} useTitle={useTitle} />

            <ActionsTooltip handleProps={handleProps} onRemove={onRemove} />
          </div>
        )}

        <ul className={classes.list}>{children}</ul>
      </div>
    </div>
  );
};

export const SectionTitle = ({ useTitle, title }) => {
  return (
    <Typography variant="h4" weight="bold">
      {useTitle ? title || "Untitled section" : "No title"}
    </Typography>
  );
};

export const SectionPlaceholder = ({ id, innerRef, style, hover, disabled }) => {
  const classes = useDndBuilderSectionStyles();

  return (
    <div
      id={id}
      ref={innerRef}
      style={style}
      className={clsx(classes.placeholder, { hover, disabled })}
    />
  );
};
