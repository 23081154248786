import { delegate, memoize } from "utils/entities";
import keyBy from "lodash/keyBy";
import compact from "lodash/compact";
import { convertFieldsToProperties } from "models/Table/utils";

import { TableColumn } from "models/fragments/TableColumn";

export class SubListLink {
  constructor(origin, table) {
    this.table = table;
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);
    this.linkType = "subList";

    delegate(this, origin, ["columns", "mapping"]);
  }

  get tableTag() {
    return `${this.table.uid}.${this.table.name}`;
  }

  get tableId() {
    return this.table.id;
  }

  get label() {
    return this.componentTable.name;
  }

  get mapping() {
    return this.origin.mapping || [];
  }

  get mappingColumns() {
    return this.memoize("mappingColumns", () => {
      const columnsMap = keyBy(this.origin.componentTable.columns, "id");
      const mappingColumnsMap = keyBy(this.mapping, "componentColumnId");

      const mappingFields = this.mapping.map((column) => {
        const tableColumn = columnsMap[column.componentColumnId];

        if (!tableColumn) return null;

        return {
          ...tableColumn,
          componentColumnId: column.componentColumnId,
          lastModifiedDate: this.origin.lastModifiedDate,
          hidden: column.hidden,
          lookup: column.lookup,
          dynamic: column.dynamic,
        };
      });

      const componentFields = this.origin.componentTable.columns.map((column) => {
        const mappingColumn = mappingColumnsMap[column.id];

        if (mappingColumn) return null;

        return {
          ...column,
          lastModifiedDate: this.origin.lastModifiedDate,
          hidden: true,
          lookup: false,
          dynamic: false,
        };
      });

      return compact([...mappingFields, ...componentFields]);
    });
  }

  get additionalColumns() {
    return this.memoize("additionalColumns", () => {
      const columnsValue = this.origin.columns || [];
      const lastModifiedDate = this.origin.lastModifiedDate;

      return columnsValue.map((column) => new TableColumn(column, { lastModifiedDate }));
    });
  }

  get allColumnsCount() {
    const mappingColumnsCount = this.mappingColumns.filter((field) => !field.hidden).length;

    return mappingColumnsCount + this.additionalColumns.length;
  }

  get formulasMap() {
    return this.memoize("formulasMap", () => {
      return (this.formulas || []).reduce(
        (result, formula) => ({ ...result, [formula.fieldId]: formula.value }),
        {}
      );
    });
  }

  get formulaPropertiesGroups() {
    return this.memoize("formulaPropertiesGroups", () => {
      const subListFieldsGroup = this.buildFormulaPropertiesGroup({
        name: this.name,
        filter: (field) => !field.blank,
      });

      return [subListFieldsGroup];
    });
  }

  buildFormulaPropertiesGroup({ name, filter = () => true, withPrefix = false } = {}) {
    const mappingColumns = this.mappingColumns.filter(filter);
    const additionalColumns = this.additionalColumns.filter(filter);

    return {
      title: name || `Sub-list ${this.name}`,
      properties: convertFieldsToProperties([mappingColumns, additionalColumns].flat(), {
        prefix: withPrefix ? this.uid : null,
      }),
    };
  }
}
