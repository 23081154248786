import React from "react";
import { DrawerLayout } from "components/Drawer/Layout";
import { DrawerSidebar } from "components/Drawer/Sidebar";
import { Drawer } from "components/Drawer";
import { FormLayoutNamespaceProvider } from "contexts/FormLayoutNamespace";
import { useGetFormLayout } from "models/FormLayout/queries";
import {
  BuilderFormLayoutProvider,
  useBuilderFormLayout,
} from "containers/FormLayout/BuilderFormLayoutProvider";

import { useExecuteFormAction, ExecuteFormProvider } from "./form/useExecuteFormAction";
import { FormInputsLayout } from "./FormInputsLayout";

export const ExecuteFormLayoutDrawer = ({ size = "large", open, onClose, formLayoutId }) => {
  const { formLayout, loading } = useGetFormLayout(formLayoutId);
  const builderStructure = formLayout?.builderStructure || [];

  return (
    <Drawer fullHeight size={size} open={open} onClose={onClose}>
      <FormLayoutNamespaceProvider formLayoutId={formLayoutId}>
        <BuilderFormLayoutProvider filterElements builderStructure={builderStructure}>
          <ExecuteFormLayout onClose={onClose} formLayout={formLayout} loading={loading} />
        </BuilderFormLayoutProvider>
      </FormLayoutNamespaceProvider>
    </Drawer>
  );
};

const ExecuteFormLayout = ({ formLayout, onClose, loading }) => {
  const { loading: builderLoading, allItemsMap } = useBuilderFormLayout();
  const isLoading = builderLoading || loading;

  const form = useExecuteFormAction({
    formLayout,
    loading: isLoading,
    allItemsMap,
    onSuccess: onClose,
  });

  return (
    <DrawerLayout
      loading={isLoading}
      onClose={onClose}
      onDiscard={onClose}
      headerProps={{
        hideMovingActions: true,
      }}
    >
      <DrawerSidebar.Content size="large" fullWidth>
        <ExecuteFormProvider {...form}>
          <FormInputsLayout loading={isLoading} />
        </ExecuteFormProvider>
      </DrawerSidebar.Content>
    </DrawerLayout>
  );
};
