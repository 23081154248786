import { gql } from "@apollo/client";
import { delegate, serialize, memoize } from "utils/entities";
import { User } from "models/fragments/User";
import { FIELD_MODEL_TYPE } from "models/types";

import { BuilderItem } from "models/abstract/BuilderItem";
import { FormField } from "models/abstract/FormField";
import { FieldTypeConfig, FIELD_TYPE_CONFIG } from "models/abstract/FieldTypeConfig";
import { FieldType } from "models/abstract/FieldType";
import { MappingPath } from "models/abstract/MappingPath";

export class FieldShort {
  constructor(origin) {
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, origin);

    serialize(this, { createdBy: User });
  }

  get modelType() {
    return FIELD_MODEL_TYPE;
  }

  buildBuilderItem(itemsProps = {}) {
    return new BuilderItem({
      entity: this,
      ...itemsProps[MappingPath.fromEntityToItemPathId(this)],
    });
  }

  get fieldType() {
    return this.memoize(
      "fieldType",
      () => new FieldType({ type: this.type, subtype: this.subtype })
    );
  }

  get subtype() {
    return this._fieldTypeConfig.subtype;
  }

  get hasInitialValue() {
    return this._fieldTypeConfig.hasInitialValue;
  }

  get initialValue() {
    return this._fieldTypeConfig.initialValue;
  }

  get config() {
    return this._fieldTypeConfig.config;
  }

  get formField() {
    return this.memoize(
      "formField",
      () =>
        new FormField({
          id: this.id,
          name: this.id,
          type: this.type,
          subtype: this.subtype,
          config: this.config,
          fieldId: this.id,
          label: this.name,
          placeholder: this.placeholder,
        })
    );
  }

  // PRIVATE

  get _fieldTypeConfig() {
    return this.memoize("_fieldTypeConfig", () => new FieldTypeConfig({ ...this.origin }));
  }
}

FieldShort.fragment = gql`
  fragment FieldShortFragment on GetFieldPayload {
    id
    createdDate
    lastModifiedDate
    inUse
    name
    description
    placeholder
    uid
    type
    folderId
    ${FIELD_TYPE_CONFIG}
    createdBy {
      ...UserFragment
    }
  }

  ${User.fragment}
`;
