import { gql } from "@apollo/client";
import fragments from "graphql/fragments";
import useQuery from "hooks/useQuery";
import { useMemo } from "react";
import usePaginationQuery from "hooks/useQuery/usePaginationQuery";
import { ListStructure } from "models/ListItem/ListStructure";

import { TableView } from "./TableView";

export const SEARCH_TABLE_VIEWS = gql`
  query searchTableViews($input: BaseSearchInput) {
    searchTableViews(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...TableViewFragment
      }
    }
  }
  ${TableView.fragment}
`;

export const GET_TABLE_VIEW = gql`
  query getTableView($input: BaseGetInput) {
    getTableView(input: $input) {
      ...TableViewFragment
    }
  }
  ${TableView.fragment}
`;

export const useSearchTableViews = ({ searchInput, ...props } = {}) => {
  const { content = [], ...rest } = usePaginationQuery(SEARCH_TABLE_VIEWS, {
    input: searchInput,
    ...props,
  });

  const tableViews = useMemo(() => content.map((data) => new TableView(data)), [content]);

  return { tableViews, content, ...rest };
};

export const useGetTableView = (id) => {
  const { payload, loading, ...props } = useQuery(GET_TABLE_VIEW, {
    skip: !id,
    input: { id },
  });

  const tableView = payload ? new TableView(payload) : null;
  const listStructure = tableView ? new ListStructure({ tableView }) : null;

  return {
    payload,
    listStructure,
    tableView,
    loading,
    ...props,
  };
};
