import { variantsClassNames } from "theme/utils";

import "styles/App/TableNavigation.scss";

export const useTableNavigationStyles = ({ variant } = {}) => ({
  root: variantsClassNames("AppTableNavigation-root", { variant }),
  searchInput: "AppTableNavigation-searchInput",
  leftPanel: "AppTableNavigation-leftPanel",
  rightPanel: "AppTableNavigation-rightPanel",
  filtersLabels: variantsClassNames("AppTableNavigation-filtersLabels", { variant }),
});
