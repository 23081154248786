import "styles/App/Builder/Builder.scss";

export const useBuilderStyles = () => ({
  root: "AppBuilder-root",
  mainContainer: "AppBuilder-mainContainer",
  mainContentWrapper: "AppBuilder-content-wrap",
  mainContent: "AppBuilder-content",
  mainCanvas: "AppBuilder-canvas",
  constructorContainer: "AppBuilder-constructorContainer",
  mainFooter: "AppBuilder-footer",
});
