import { SvgProxy } from "./SvgIcon";

import { ReactComponent as SkuDefault } from "./variables/sku.svg";
import { ReactComponent as SkuWide } from "./variables/sku_wide.svg";
import { ReactComponent as SkuBg } from "./variables/sku_bg.svg";
import { ReactComponent as TextDefault } from "./variables/text.svg";
import { ReactComponent as TextWide } from "./variables/text_wide.svg";
import { ReactComponent as TextBg } from "./variables/text_bg.svg";
import { ReactComponent as RichTextDefault } from "./variables/rich_text.svg";
import { ReactComponent as RichTextWide } from "./variables/rich_text_wide.svg";
import { ReactComponent as RichTextBg } from "./variables/rich_text_bg.svg";
import { ReactComponent as MediaDefault } from "./variables/media.svg";
import { ReactComponent as MediaWide } from "./variables/media_wide.svg";
import { ReactComponent as MediaBg } from "./variables/media_bg.svg";
import { ReactComponent as CheckboxDefault } from "./variables/checkbox.svg";
import { ReactComponent as CheckboxWide } from "./variables/checkbox_wide.svg";
import { ReactComponent as CheckboxCheckDefault } from "./variables/checkbox_check.svg";
import { ReactComponent as CheckboxCheckWide } from "./variables/checkbox_check_wide.svg";
import { ReactComponent as CheckboxCheckBg } from "./variables/checkbox_check_bg.svg";
import { ReactComponent as CheckboxCheckFill } from "./variables/checkbox_check_fill.svg";
import { ReactComponent as SelectDefault } from "./variables/select.svg";
import { ReactComponent as SelectWide } from "./variables/select_wide.svg";
import { ReactComponent as SelectBg } from "./variables/select_bg.svg";
import { ReactComponent as DateDefault } from "./variables/date.svg";
import { ReactComponent as DateWide } from "./variables/date_wide.svg";
import { ReactComponent as DateBg } from "./variables/date_bg.svg";
import { ReactComponent as PhoneDefault } from "./variables/phone.svg";
import { ReactComponent as PhoneWide } from "./variables/phone_wide.svg";
import { ReactComponent as PhoneBg } from "./variables/phone_bg.svg";
import { ReactComponent as TagDefault } from "./variables/tag.svg";
import { ReactComponent as TagWide } from "./variables/tag_wide.svg";
import { ReactComponent as TagBg } from "./variables/tag_bg.svg";
import { ReactComponent as EmailDefault } from "./variables/email.svg";
import { ReactComponent as EmailWide } from "./variables/email_wide.svg";
import { ReactComponent as EmailBg } from "./variables/email_bg.svg";
import { ReactComponent as LinkDefault } from "./variables/link.svg";
import { ReactComponent as LinkWide } from "./variables/link_wide.svg";
import { ReactComponent as LinkBg } from "./variables/link_bg.svg";
import { ReactComponent as NumDefault } from "./variables/num.svg";
import { ReactComponent as NumWide } from "./variables/num_wide.svg";
import { ReactComponent as NumBg } from "./variables/num_bg.svg";
import { ReactComponent as CurrencyDefault } from "./variables/currency.svg";
import { ReactComponent as CurrencyWide } from "./variables/currency_wide.svg";
import { ReactComponent as CurrencyBg } from "./variables/currency_bg.svg";
import { ReactComponent as PercentDefault } from "./variables/percent.svg";
import { ReactComponent as PercentWide } from "./variables/percent_wide.svg";
import { ReactComponent as PercentBg } from "./variables/percent_bg.svg";
import { ReactComponent as TimeDefault } from "./variables/time.svg";
import { ReactComponent as TimeWide } from "./variables/time_wide.svg";
import { ReactComponent as TimeBg } from "./variables/time_bg.svg";
import { ReactComponent as FormulaDefault } from "./variables/formula.svg";
import { ReactComponent as FormulaWide } from "./variables/formula_wide.svg";
import { ReactComponent as FormulaBg } from "./variables/formula_bg.svg";
import { ReactComponent as LookupDefault } from "./variables/lookup.svg";
import { ReactComponent as LookupWide } from "./variables/lookup_wide.svg";
import { ReactComponent as LookupBg } from "./variables/lookup_bg.svg";
import { ReactComponent as RollupDefault } from "./variables/rollup.svg";
import { ReactComponent as RollupWide } from "./variables/rollup_wide.svg";
import { ReactComponent as RollupBg } from "./variables/rollup_bg.svg";
import { ReactComponent as ObjectDefault } from "./variables/object.svg";
import { ReactComponent as ObjectWide } from "./variables/object_wide.svg";
import { ReactComponent as ObjectBg } from "./variables/object_bg.svg";
import { ReactComponent as IdDefault } from "./variables/id.svg";
import { ReactComponent as IdWide } from "./variables/id_wide.svg";
import { ReactComponent as IdBg } from "./variables/id_bg.svg";
import { ReactComponent as KeyDefault } from "./variables/key.svg";
import { ReactComponent as KeyWide } from "./variables/key_wide.svg";
import { ReactComponent as KeyBg } from "./variables/key_bg.svg";
import { ReactComponent as MessageSquareDefault } from "./variables/message_square.svg";
import { ReactComponent as MessageSquareWide } from "./variables/message_square_wide.svg";
import { ReactComponent as MessageSquareBg } from "./variables/message_square_bg.svg";
import { ReactComponent as ZapDefault } from "./variables/zap.svg";
import { ReactComponent as ZapWide } from "./variables/zap_wide.svg";
import { ReactComponent as ZapBg } from "./variables/zap_bg.svg";
import { ReactComponent as ZapSquareDefault } from "./variables/zap_square.svg";
import { ReactComponent as ZapSquareWide } from "./variables/zap_square_wide.svg";
import { ReactComponent as ZapSquareBg } from "./variables/zap_square_bg.svg";
import { ReactComponent as AutoincrementDefault } from "./variables/autoincrement.svg";
import { ReactComponent as AutoincrementWide } from "./variables/autoincrement_wide.svg";
import { ReactComponent as AutoincrementBg } from "./variables/autoincrement_bg.svg";
import { ReactComponent as FieldDefault } from "./variables/field.svg";
import { ReactComponent as FieldWide } from "./variables/field_wide.svg";
import { ReactComponent as FieldBg } from "./variables/field_bg.svg";
import { ReactComponent as SearchDefault } from "./variables/search.svg";
import { ReactComponent as SearchWide } from "./variables/search_wide.svg";
import { ReactComponent as SearchBg } from "./variables/search_bg.svg";
import { ReactComponent as TargetRequestDefault } from "./variables/target_request.svg";
import { ReactComponent as TargetRequestWide } from "./variables/target_request_wide.svg";
import { ReactComponent as TargetRequestBg } from "./variables/target_request_bg.svg";
import { ReactComponent as TableEditDefault } from "./variables/table_edit.svg";
import { ReactComponent as TableEditWide } from "./variables/table_edit_wide.svg";
import { ReactComponent as TableUpdateDefault } from "./variables/table_update.svg";
import { ReactComponent as TableUpdateWide } from "./variables/table_update_wide.svg";
import { ReactComponent as TableZapDefault } from "./variables/table_zap.svg";
import { ReactComponent as TableZapWide } from "./variables/table_zap_wide.svg";
import { ReactComponent as TargetResponseDefault } from "./variables/target_response.svg";
import { ReactComponent as TargetResponseWide } from "./variables/target_response_wide.svg";
import { ReactComponent as TargetResponseBg } from "./variables/target_response_bg.svg";
import { ReactComponent as ConnectorDefault } from "./variables/connector.svg";
import { ReactComponent as ConnectorWide } from "./variables/connector_wide.svg";
import { ReactComponent as ConnectorBg } from "./variables/connector_bg.svg";
import { ReactComponent as TableDefault } from "./variables/table.svg";
import { ReactComponent as TableWide } from "./variables/table_wide.svg";
import { ReactComponent as TableBg } from "./variables/table_bg.svg";
import { ReactComponent as StorageDefault } from "./variables/storage.svg";
import { ReactComponent as StorageWide } from "./variables/storage_wide.svg";
import { ReactComponent as StorageBg } from "./variables/storage_bg.svg";
import { ReactComponent as LayersDefault } from "./variables/layers.svg";
import { ReactComponent as LayersWide } from "./variables/layers_wide.svg";
import { ReactComponent as LayersBg } from "./variables/layers_bg.svg";
import { ReactComponent as StarDefault } from "./variables/star.svg";
import { ReactComponent as StarWide } from "./variables/star_wide.svg";
import { ReactComponent as StarBg } from "./variables/star_bg.svg";
import { ReactComponent as BoxDefault } from "./variables/box.svg";
import { ReactComponent as BoxWide } from "./variables/box_wide.svg";
import { ReactComponent as BoxBg } from "./variables/box_bg.svg";
import { ReactComponent as CircleDefault } from "./variables/circle.svg";
import { ReactComponent as CircleWide } from "./variables/circle_wide.svg";
import { ReactComponent as CircleBg } from "./variables/circle_bg.svg";
import { ReactComponent as LockDefault } from "./variables/lock.svg";
import { ReactComponent as LockWide } from "./variables/lock_wide.svg";
import { ReactComponent as LockBg } from "./variables/lock_bg.svg";
import { ReactComponent as LockOpenDefault } from "./variables/lock_open.svg";
import { ReactComponent as LockOpenWide } from "./variables/lock_open_wide.svg";
import { ReactComponent as LockOpenBg } from "./variables/lock_open_bg.svg";
import { ReactComponent as EditDefault } from "./variables/edit.svg";
import { ReactComponent as EditWide } from "./variables/edit_wide.svg";
import { ReactComponent as EditBg } from "./variables/edit_bg.svg";
import { ReactComponent as ReloadRefreshDefault } from "./variables/reload_refresh.svg";
import { ReactComponent as ReloadRefreshWide } from "./variables/reload_refresh_wide.svg";
import { ReactComponent as ReloadRefreshBg } from "./variables/reload_refresh_bg.svg";
import { ReactComponent as MultiplyDefault } from "./variables/multiply.svg";
import { ReactComponent as MultiplyWide } from "./variables/multiply_wide.svg";
import { ReactComponent as MultiplyBg } from "./variables/multiply_bg.svg";
import { ReactComponent as DivideDefault } from "./variables/divide.svg";
import { ReactComponent as DivideWide } from "./variables/divide_wide.svg";
import { ReactComponent as DivideBg } from "./variables/divide_bg.svg";
import { ReactComponent as FolderDefault } from "./variables/folder.svg";
import { ReactComponent as FolderWide } from "./variables/folder_wide.svg";
import { ReactComponent as FolderUploadDefault } from "./variables/folder_upload.svg";
import { ReactComponent as FolderUploadWide } from "./variables/folder_upload_wide.svg";
import { ReactComponent as FolderSyncDefault } from "./variables/folder_sync.svg";
import { ReactComponent as FolderSyncWide } from "./variables/folder_sync_wide.svg";
import { ReactComponent as FolderPlusDefault } from "./variables/folder_plus.svg";
import { ReactComponent as FolderPlusWide } from "./variables/folder_plus_wide.svg";
import { ReactComponent as FileDefault } from "./variables/file.svg";
import { ReactComponent as FileWide } from "./variables/file_wide.svg";
import { ReactComponent as FileUploadDefault } from "./variables/file_upload.svg";
import { ReactComponent as FileUploadWide } from "./variables/file_upload_wide.svg";
import { ReactComponent as FileSyncDefault } from "./variables/file_sync.svg";
import { ReactComponent as FileSyncWide } from "./variables/file_sync_wide.svg";
import { ReactComponent as FileEditDefault } from "./variables/file_edit.svg";
import { ReactComponent as FileEditWide } from "./variables/file_edit_wide.svg";
import { ReactComponent as FileAddDefault } from "./variables/file_add.svg";
import { ReactComponent as FileAddWide } from "./variables/file_add_wide.svg";
import { ReactComponent as UserDefault } from "./variables/user.svg";
import { ReactComponent as UserWide } from "./variables/user_wide.svg";
import { ReactComponent as UserBg } from "./variables/user_bg.svg";
import { ReactComponent as UserAddDefault } from "./variables/user_add.svg";
import { ReactComponent as UserAddWide } from "./variables/user_add_wide.svg";
import { ReactComponent as UserZapDefault } from "./variables/user_zap.svg";
import { ReactComponent as UserZapWide } from "./variables/user_zap_wide.svg";
import { ReactComponent as UserEditDefault } from "./variables/user_edit.svg";
import { ReactComponent as UserEditWide } from "./variables/user_edit_wide.svg";
import { ReactComponent as UserUpdateDefault } from "./variables/user_update.svg";
import { ReactComponent as UserUpdateWide } from "./variables/user_update_wide.svg";
import { ReactComponent as UsersDefault } from "./variables/users.svg";
import { ReactComponent as UsersWide } from "./variables/users_wide.svg";
import { ReactComponent as TemplateDefault } from "./variables/template.svg";
import { ReactComponent as TemplateWide } from "./variables/template_wide.svg";
import { ReactComponent as TemplateBg } from "./variables/template_bg.svg";
import { ReactComponent as GridOrMenuDefault } from "./variables/grid_or_menu.svg";
import { ReactComponent as GridOrMenuWide } from "./variables/grid_or_menu_wide.svg";
import { ReactComponent as GridOrMenuBg } from "./variables/grid_or_menu_bg.svg";
import { ReactComponent as CopyDefault } from "./variables/copy.svg";
import { ReactComponent as CopyWide } from "./variables/copy_wide.svg";
import { ReactComponent as CopyBg } from "./variables/copy_bg.svg";
import { ReactComponent as TaskDefault } from "./variables/task.svg";
import { ReactComponent as TaskWide } from "./variables/task_wide.svg";
import { ReactComponent as SettingsDefault } from "./variables/settings.svg";
import { ReactComponent as SettingsWide } from "./variables/settings_wide.svg";
import { ReactComponent as TrashDefault } from "./variables/trash.svg";
import { ReactComponent as TrashWide } from "./variables/trash_wide.svg";
import { ReactComponent as DoubleCircleDefault } from "./variables/double_circle.svg";
import { ReactComponent as DoubleCircleWide } from "./variables/double_circle_wide.svg";
import { ReactComponent as TargetDefault } from "./variables/target.svg";
import { ReactComponent as TargetWide } from "./variables/target_wide.svg";
import { ReactComponent as BookDefault } from "./variables/book.svg";
import { ReactComponent as BookWide } from "./variables/book_wide.svg";
import { ReactComponent as BookmarkDefault } from "./variables/bookmark.svg";
import { ReactComponent as BookmarkWide } from "./variables/bookmark_wide.svg";
import { ReactComponent as HomeDefault } from "./variables/home.svg";
import { ReactComponent as HomeWide } from "./variables/home_wide.svg";
import { ReactComponent as HomeBg } from "./variables/home_bg.svg";
import { ReactComponent as InboxDefault } from "./variables/inbox.svg";
import { ReactComponent as InboxWide } from "./variables/inbox_wide.svg";
import { ReactComponent as InboxBg } from "./variables/inbox_bg.svg";
import { ReactComponent as InfoDefault } from "./variables/info.svg";
import { ReactComponent as InfoWide } from "./variables/info_wide.svg";
import { ReactComponent as InfoBg } from "./variables/info_bg.svg";
import { ReactComponent as HelpCircleDefault } from "./variables/help_circle.svg";
import { ReactComponent as HelpCircleWide } from "./variables/help_circle_wide.svg";
import { ReactComponent as HelpCircleBg } from "./variables/help_circle_bg.svg";
import { ReactComponent as AlertTriangleDefault } from "./variables/alert_triangle.svg";
import { ReactComponent as AlertTriangleWide } from "./variables/alert_triangle_wide.svg";
import { ReactComponent as AlertTriangleBg } from "./variables/alert_triangle_bg.svg";
import { ReactComponent as MultilineTextDefault } from "./variables/multiline_text.svg";
import { ReactComponent as MultilineTextWide } from "./variables/multiline_text_wide.svg";
import { ReactComponent as MultilineTextBg } from "./variables/multiline_text_bg.svg";

import { ReactComponent as ArrowDownDefault } from "./other/arrow_down.svg";
import { ReactComponent as ArrowDownWide } from "./other/arrow_down_wide.svg";
import { ReactComponent as ArrowUpDefault } from "./other/arrow_up.svg";
import { ReactComponent as ArrowUpWide } from "./other/arrow_up_wide.svg";
import { ReactComponent as ArrowDownSmallDefault } from "./other/arrow_down_small.svg";
import { ReactComponent as ArrowDownSmallWide } from "./other/arrow_down_small_wide.svg";
import { ReactComponent as ArrowUpSmallDefault } from "./other/arrow_up_small.svg";
import { ReactComponent as ArrowUpSmallWide } from "./other/arrow_up_small_wide.svg";
import { ReactComponent as ArrowLeftDefault } from "./other/arrow_left.svg";
import { ReactComponent as ArrowLeftWide } from "./other/arrow_left_wide.svg";
import { ReactComponent as ArrowLeftSmallDefault } from "./other/arrow_left_small.svg";
import { ReactComponent as ArrowLeftSmallWide } from "./other/arrow_left_small_wide.svg";
import { ReactComponent as ArrowRightDefault } from "./other/arrow_right.svg";
import { ReactComponent as ArrowRightWide } from "./other/arrow_right_wide.svg";
import { ReactComponent as ArrowRightSmallDefault } from "./other/arrow_right_small.svg";
import { ReactComponent as ArrowRightSmallWide } from "./other/arrow_right_small_wide.svg";
import { ReactComponent as ArrowUpLeftDefault } from "./other/arrow_up_left.svg";
import { ReactComponent as ArrowUpLeftWide } from "./other/arrow_up_left_wide.svg";
import { ReactComponent as ArrowUpRightDefault } from "./other/arrow_up_right.svg";
import { ReactComponent as ArrowUpRightWide } from "./other/arrow_up_right_wide.svg";
import { ReactComponent as RedoDefault } from "./other/redo.svg";
import { ReactComponent as RedoWide } from "./other/redo_wide.svg";
import { ReactComponent as UndoDefault } from "./other/undo.svg";
import { ReactComponent as UndoWide } from "./other/undo_wide.svg";
import { ReactComponent as CornerUpLeftDefault } from "./other/corner_up_left.svg";
import { ReactComponent as CornerUpLeftWide } from "./other/corner_up_left_wide.svg";
import { ReactComponent as CornerUpRightDefault } from "./other/corner_up_right.svg";
import { ReactComponent as CornerUpRightWide } from "./other/corner_up_right_wide.svg";
import { ReactComponent as MoveUpDefault } from "./other/move_up.svg";
import { ReactComponent as MoveUpWide } from "./other/move_up_wide.svg";
import { ReactComponent as MoveDownDefault } from "./other/move_down.svg";
import { ReactComponent as MoveDownWide } from "./other/move_down_wide.svg";
import { ReactComponent as MaximizeDefault } from "./other/maximize.svg";
import { ReactComponent as MaximizeWide } from "./other/maximize_wide.svg";
import { ReactComponent as MinimizeDefault } from "./other/minimize.svg";
import { ReactComponent as MinimizeWide } from "./other/minimize_wide.svg";
import { ReactComponent as ExternalLinkDefault } from "./other/external_link.svg";
import { ReactComponent as ExternalLinkWide } from "./other/external_link_wide.svg";
import { ReactComponent as CompareDefault } from "./other/compare.svg";
import { ReactComponent as CompareWide } from "./other/compare_wide.svg";
import { ReactComponent as ImportExportDefault } from "./other/import_export.svg";
import { ReactComponent as ChevronDownDefault } from "./other/chevron_down.svg";
import { ReactComponent as ChevronDownWide } from "./other/chevron_down_wide.svg";
import { ReactComponent as ChevronUpDefault } from "./other/chevron_up.svg";
import { ReactComponent as ChevronUpWide } from "./other/chevron_up_wide.svg";
import { ReactComponent as ChevronExpandDefault } from "./other/chevron_expand.svg";
import { ReactComponent as ChevronExpandWide } from "./other/chevron_expand_wide.svg";
import { ReactComponent as ChevronsLeftDefault } from "./other/chevrons_left.svg";
import { ReactComponent as ChevronsLeftWide } from "./other/chevrons_left_wide.svg";
import { ReactComponent as ChevronsRightDefault } from "./other/chevrons_right.svg";
import { ReactComponent as ChevronsRightWide } from "./other/chevrons_right_wide.svg";
import { ReactComponent as CornerDownDefault } from "./other/corner_down.svg";
import { ReactComponent as CornerUpDefault } from "./other/corner_up.svg";
import { ReactComponent as CornerRightDefault } from "./other/corner_right.svg";
import { ReactComponent as CornerLeftDefault } from "./other/corner_left.svg";
import { ReactComponent as MoreHorizontalDefault } from "./other/more_horizontal.svg";
import { ReactComponent as MoreVerticalDefault } from "./other/more_vertical.svg";
import { ReactComponent as DragDefault } from "./other/drag.svg";
import { ReactComponent as MoveDefault } from "./other/move.svg";
import { ReactComponent as SpinnerDefault } from "./other/spinner.svg";
import { ReactComponent as ChannelStrokeDefault } from "./other/channel_stroke.svg";
import { ReactComponent as ChannelStrokeWide } from "./other/channel_stroke_wide.svg";
import { ReactComponent as PartOfDefault } from "./other/part_of.svg";
import { ReactComponent as PartOfWide } from "./other/part_of_wide.svg";
import { ReactComponent as NestedInDefault } from "./other/nested_in.svg";
import { ReactComponent as NestedInWide } from "./other/nested_in_wide.svg";
import { ReactComponent as EyeDefault } from "./other/eye.svg";
import { ReactComponent as EyeWide } from "./other/eye_wide.svg";
import { ReactComponent as EyeOffDefault } from "./other/eye_off.svg";
import { ReactComponent as EyeOffWide } from "./other/eye_off_wide.svg";
import { ReactComponent as DotFillDefault } from "./other/dot_fill.svg";
import { ReactComponent as DotDefault } from "./other/dot.svg";
import { ReactComponent as BranchArrowDefault } from "./other/branch_arrow.svg";
import { ReactComponent as BranchArrowWide } from "./other/branch_arrow_wide.svg";
import { ReactComponent as BranchArrowDownDefault } from "./other/branch_arrow_down.svg";
import { ReactComponent as BranchArrowDownWide } from "./other/branch_arrow_down_wide.svg";
import { ReactComponent as MappingArrowHorDefault } from "./other/mapping_arrow_hor.svg";
import { ReactComponent as FilterCrossDefault } from "./other/filter_cross.svg";
import { ReactComponent as FilterCrossWide } from "./other/filter_cross_wide.svg";
import { ReactComponent as FilterPlusDefault } from "./other/filter_plus.svg";
import { ReactComponent as FilterPlusWide } from "./other/filter_plus_wide.svg";
import { ReactComponent as FilterDefault } from "./other/filter.svg";
import { ReactComponent as FilterWide } from "./other/filter_wide.svg";
import { ReactComponent as Filter2Default } from "./other/filter2.svg";
import { ReactComponent as Filter2Wide } from "./other/filter2_wide.svg";
import { ReactComponent as Filter3Default } from "./other/filter3.svg";
import { ReactComponent as Filter3Wide } from "./other/filter3_wide.svg";
import { ReactComponent as Filter4Default } from "./other/filter4.svg";
import { ReactComponent as Filter4Wide } from "./other/filter4_wide.svg";
import { ReactComponent as SortArrowsDefault } from "./other/sort_arrows.svg";
import { ReactComponent as SortArrowsWide } from "./other/sort_arrows_wide.svg";
import { ReactComponent as SortUpDefault } from "./other/sort_up.svg";
import { ReactComponent as SortUpWide } from "./other/sort_up_wide.svg";
import { ReactComponent as SortDownDefault } from "./other/sort_down.svg";
import { ReactComponent as SortDownWide } from "./other/sort_down_wide.svg";
import { ReactComponent as PlusDefault } from "./other/plus.svg";
import { ReactComponent as PlusWide } from "./other/plus_wide.svg";
import { ReactComponent as PlusCircleDefault } from "./other/plus_circle.svg";
import { ReactComponent as PlusCircleWide } from "./other/plus_circle_wide.svg";
import { ReactComponent as CrossDefault } from "./other/cross.svg";
import { ReactComponent as CrossWide } from "./other/cross_wide.svg";
import { ReactComponent as CrossSmallDefault } from "./other/cross_small.svg";
import { ReactComponent as CrossSmallWide } from "./other/cross_small_wide.svg";
import { ReactComponent as CrossCircleDefault } from "./other/cross_circle.svg";
import { ReactComponent as CrossCircleWide } from "./other/cross_circle_wide.svg";
import { ReactComponent as CheckmarkDefault } from "./other/checkmark.svg";
import { ReactComponent as CheckmarkWide } from "./other/checkmark_wide.svg";
import { ReactComponent as CheckmarkCircleDefault } from "./other/checkmark_circle.svg";
import { ReactComponent as CheckmarkCircleWide } from "./other/checkmark_circle_wide.svg";
import { ReactComponent as PlayCircleDefault } from "./other/play_circle.svg";
import { ReactComponent as PlayCircleWide } from "./other/play_circle_wide.svg";
import { ReactComponent as HoldOnDefault } from "./other/hold_on.svg";
import { ReactComponent as HoldOnWide } from "./other/hold_on_wide.svg";
import { ReactComponent as ColumnLeftDefault } from "./other/column_left.svg";
import { ReactComponent as ColumnLeftWide } from "./other/column_left_wide.svg";
import { ReactComponent as ColumnsDefault } from "./other/columns.svg";
import { ReactComponent as ColumnsWide } from "./other/columns_wide.svg";
import { ReactComponent as ColumnRightDefault } from "./other/column_right.svg";
import { ReactComponent as ColumnRightWide } from "./other/column_right_wide.svg";
import { ReactComponent as VerticalColumnsDefault } from "./other/vertical_columns.svg";
import { ReactComponent as VerticalColumnsWide } from "./other/vertical_columns_wide.svg";
import { ReactComponent as LayoutDefault } from "./other/layout.svg";
import { ReactComponent as LayoutWide } from "./other/layout_wide.svg";
import { ReactComponent as CardDefault } from "./other/card.svg";
import { ReactComponent as CardWide } from "./other/card_wide.svg";
import { ReactComponent as ImageDefault } from "./other/image.svg";
import { ReactComponent as ImageWide } from "./other/image_wide.svg";
import { ReactComponent as SendDefault } from "./other/send.svg";
import { ReactComponent as SendWide } from "./other/send_wide.svg";
import { ReactComponent as ActivityDefault } from "./other/activity.svg";
import { ReactComponent as ActivityWide } from "./other/activity_wide.svg";
import { ReactComponent as MonitorDefault } from "./other/monitor.svg";
import { ReactComponent as MonitorWide } from "./other/monitor_wide.svg";
import { ReactComponent as CodeDefault } from "./other/code.svg";
import { ReactComponent as CodeWide } from "./other/code_wide.svg";
import { ReactComponent as SupportDefault } from "./other/support.svg";
import { ReactComponent as SupportWide } from "./other/support_wide.svg";
import { ReactComponent as ArchiveDefault } from "./other/archive.svg";
import { ReactComponent as ArchiveWide } from "./other/archive_wide.svg";
import { ReactComponent as PinDefault } from "./other/pin.svg";
import { ReactComponent as PinWide } from "./other/pin_wide.svg";
import { ReactComponent as ShieldDefault } from "./other/shield.svg";
import { ReactComponent as ShieldWide } from "./other/shield_wide.svg";
import { ReactComponent as SlashDefault } from "./other/slash.svg";
import { ReactComponent as SlashWide } from "./other/slash_wide.svg";
import { ReactComponent as FlagDefault } from "./other/flag.svg";
import { ReactComponent as FlagWide } from "./other/flag_wide.svg";
import { ReactComponent as SadFaceDefault } from "./other/sad_face.svg";
import { ReactComponent as SadFaceWide } from "./other/sad_face_wide.svg";
import { ReactComponent as SmileFaceDefault } from "./other/smile_face.svg";
import { ReactComponent as SmileFaceWide } from "./other/smile_face_wide.svg";
import { ReactComponent as ThumbsDownDefault } from "./other/thumbs_down.svg";
import { ReactComponent as ThumbsDownWide } from "./other/thumbs_down_wide.svg";
import { ReactComponent as ThumbsUpDefault } from "./other/thumbs_up.svg";
import { ReactComponent as ThumbsUpWide } from "./other/thumbs_up_wide.svg";
import { ReactComponent as GlobeDefault } from "./other/globe.svg";
import { ReactComponent as GlobeWide } from "./other/globe_wide.svg";
import { ReactComponent as ToggleRightDefault } from "./other/toggle_right.svg";
import { ReactComponent as ToggleRightWide } from "./other/toggle_right_wide.svg";
import { ReactComponent as CoffeeDefault } from "./other/coffee.svg";
import { ReactComponent as CoffeeWide } from "./other/coffee_wide.svg";
import { ReactComponent as FormDefault } from "./other/form.svg";

const SKU_VARIANTS = {
  default: SkuDefault,
  wide: SkuWide,
  background: SkuBg,
};
const TEXT_VARIANTS = {
  default: TextDefault,
  wide: TextWide,
  background: TextBg,
};
const RICH_TEXT_VARIANTS = {
  default: RichTextDefault,
  wide: RichTextWide,
  background: RichTextBg,
};
const MEDIA_VARIANTS = {
  default: MediaDefault,
  wide: MediaWide,
  background: MediaBg,
};
const CHECKBOX_VARIANTS = {
  default: CheckboxDefault,
  wide: CheckboxWide,
};
const CHECKBOX_CHECK_VARIANTS = {
  default: CheckboxCheckDefault,
  wide: CheckboxCheckWide,
  background: CheckboxCheckBg,
};
const CHECKBOX_CHECK_FILL = {
  default: CheckboxCheckFill,
};
const SELECT_VARIANTS = {
  default: SelectDefault,
  wide: SelectWide,
  background: SelectBg,
};
const DATE_VARIANTS = {
  default: DateDefault,
  wide: DateWide,
  background: DateBg,
};
const PHONE_VARIANTS = {
  default: PhoneDefault,
  wide: PhoneWide,
  background: PhoneBg,
};
const TAG_VARIANTS = {
  default: TagDefault,
  wide: TagWide,
  background: TagBg,
};
const EMAIL_VARIANTS = {
  default: EmailDefault,
  wide: EmailWide,
  background: EmailBg,
};
const LINK_VARIANTS = {
  default: LinkDefault,
  wide: LinkWide,
  background: LinkBg,
};
const NUM_VARIANTS = {
  default: NumDefault,
  wide: NumWide,
  background: NumBg,
};
const CURRENCY_VARIANTS = {
  default: CurrencyDefault,
  wide: CurrencyWide,
  background: CurrencyBg,
};
const PERCENT_VARIANTS = {
  default: PercentDefault,
  wide: PercentWide,
  background: PercentBg,
};
const TIME_VARIANTS = {
  default: TimeDefault,
  wide: TimeWide,
  background: TimeBg,
};
const FORMULA_VARIANTS = {
  default: FormulaDefault,
  wide: FormulaWide,
  background: FormulaBg,
};
const LOOKUP_VARIANTS = {
  default: LookupDefault,
  wide: LookupWide,
  background: LookupBg,
};
const ROLLUP_VARIANTS = {
  default: RollupDefault,
  wide: RollupWide,
  background: RollupBg,
};
const OBJECT_VARIANTS = {
  default: ObjectDefault,
  wide: ObjectWide,
  background: ObjectBg,
};
const ID_VARIANTS = {
  default: IdDefault,
  wide: IdWide,
  background: IdBg,
};
const KEY_VARIANTS = {
  default: KeyDefault,
  wide: KeyWide,
  background: KeyBg,
};
const MESSAGE_SQUARE_VARIANTS = {
  default: MessageSquareDefault,
  wide: MessageSquareWide,
  background: MessageSquareBg,
};
const ZAP_VARIANTS = {
  default: ZapDefault,
  wide: ZapWide,
  background: ZapBg,
};
const ZAP_SQUARE_VARIANTS = {
  default: ZapSquareDefault,
  wide: ZapSquareWide,
  background: ZapSquareBg,
};
const AUTOINCREMENT_VARIANTS = {
  default: AutoincrementDefault,
  wide: AutoincrementWide,
  background: AutoincrementBg,
};
const FIELD_VARIANTS = {
  default: FieldDefault,
  wide: FieldWide,
  background: FieldBg,
};
const SEARCH_VARIANTS = {
  default: SearchDefault,
  wide: SearchWide,
  background: SearchBg,
};
const TARGET_REQUEST_VARIANTS = {
  default: TargetRequestDefault,
  wide: TargetRequestWide,
  background: TargetRequestBg,
};
const TABLE_EDIT_VARIANTS = {
  default: TableEditDefault,
  wide: TableEditWide,
};
const TABLE_UPDATE_VARIANTS = {
  default: TableUpdateDefault,
  wide: TableUpdateWide,
};
const TABLE_ZAP_VARIANTS = {
  default: TableZapDefault,
  wide: TableZapWide,
};
const TARGET_RESPONSE_VARIANTS = {
  default: TargetResponseDefault,
  wide: TargetResponseWide,
  background: TargetResponseBg,
};
const CONNECTOR_VARIANTS = {
  default: ConnectorDefault,
  wide: ConnectorWide,
  background: ConnectorBg,
};
const TABLE_VARIANTS = {
  default: TableDefault,
  wide: TableWide,
  background: TableBg,
};
const STORAGE_VARIANTS = {
  default: StorageDefault,
  wide: StorageWide,
  background: StorageBg,
};
const LAYERS_VARIANTS = {
  default: LayersDefault,
  wide: LayersWide,
  background: LayersBg,
};
const STAR_VARIANTS = {
  default: StarDefault,
  wide: StarWide,
  background: StarBg,
};
const BOX_VARIANTS = {
  default: BoxDefault,
  wide: BoxWide,
  background: BoxBg,
};
const CIRCLE_VARIANTS = {
  default: CircleDefault,
  wide: CircleWide,
  background: CircleBg,
};
const LOCK_VARIANTS = {
  default: LockDefault,
  wide: LockWide,
  background: LockBg,
};
const LOCK_OPEN_VARIANTS = {
  default: LockOpenDefault,
  wide: LockOpenWide,
  background: LockOpenBg,
};
const EDIT_VARIANTS = {
  default: EditDefault,
  wide: EditWide,
  background: EditBg,
};
const RELOAD_REFRESH_VARIANTS = {
  default: ReloadRefreshDefault,
  wide: ReloadRefreshWide,
  background: ReloadRefreshBg,
};
const MULTIPLY_VARIANTS = {
  default: MultiplyDefault,
  wide: MultiplyWide,
  background: MultiplyBg,
};
const DIVIDE_VARIANTS = {
  default: DivideDefault,
  wide: DivideWide,
  background: DivideBg,
};
const FOLDER_VARIANTS = {
  default: FolderDefault,
  wide: FolderWide,
};
const FOLDER_UPLOAD_VARIANTS = {
  default: FolderUploadDefault,
  wide: FolderUploadWide,
};
const FOLDER_SYNC_VARIANTS = {
  default: FolderSyncDefault,
  wide: FolderSyncWide,
};
const FOLDER_PLUS_VARIANTS = {
  default: FolderPlusDefault,
  wide: FolderPlusWide,
};
const FILE_VARIANTS = {
  default: FileDefault,
  wide: FileWide,
};
const FILE_UPLOAD_VARIANTS = {
  default: FileUploadDefault,
  wide: FileUploadWide,
};
const FILE_SYNC_VARIANTS = {
  default: FileSyncDefault,
  wide: FileSyncWide,
};
const FILE_EDIT_VARIANTS = {
  default: FileEditDefault,
  wide: FileEditWide,
};
const FILE_ADD_VARIANTS = {
  default: FileAddDefault,
  wide: FileAddWide,
};
const USER_VARIANTS = {
  default: UserDefault,
  wide: UserWide,
  background: UserBg,
};
const USER_ADD_VARIANTS = {
  default: UserAddDefault,
  wide: UserAddWide,
};
const USER_ZAP_VARIANTS = {
  default: UserZapDefault,
  wide: UserZapWide,
};
const USER_EDIT_VARIANTS = {
  default: UserEditDefault,
  wide: UserEditWide,
};
const USER_UPDATE_VARIANTS = {
  default: UserUpdateDefault,
  wide: UserUpdateWide,
};
const USERS_VARIANTS = {
  default: UsersDefault,
  wide: UsersWide,
};
const TEMPLATE_VARIANTS = {
  default: TemplateDefault,
  wide: TemplateWide,
  background: TemplateBg,
};
const GRID_OR_MENU_VARIANTS = {
  default: GridOrMenuDefault,
  wide: GridOrMenuWide,
  background: GridOrMenuBg,
};
const COPY_VARIANTS = {
  default: CopyDefault,
  wide: CopyWide,
  background: CopyBg,
};
const TASK_VARIANTS = {
  default: TaskDefault,
  wide: TaskWide,
};
const SETTINGS_VARIANTS = {
  default: SettingsDefault,
  wide: SettingsWide,
};
const TRASH_VARIANTS = {
  default: TrashDefault,
  wide: TrashWide,
};
const DOUBLE_CIRCLE_VARIANTS = {
  default: DoubleCircleDefault,
  wide: DoubleCircleWide,
};
const TARGET_VARIANTS = {
  default: TargetDefault,
  wide: TargetWide,
};
const BOOK_VARIANTS = {
  default: BookDefault,
  wide: BookWide,
};
const BOOKMARK_VARIANTS = {
  default: BookmarkDefault,
  wide: BookmarkWide,
};
const HOME_VARIANTS = {
  default: HomeDefault,
  wide: HomeWide,
  background: HomeBg,
};
const INBOX_VARIANTS = {
  default: InboxDefault,
  wide: InboxWide,
  background: InboxBg,
};
const INFO_VARIANTS = {
  default: InfoDefault,
  wide: InfoWide,
  background: InfoBg,
};
const HELP_CIRCLE_VARIANTS = {
  default: HelpCircleDefault,
  wide: HelpCircleWide,
  background: HelpCircleBg,
};
const ALERT_TRIANGLE_VARIANTS = {
  default: AlertTriangleDefault,
  wide: AlertTriangleWide,
  background: AlertTriangleBg,
};
const MULTILINE_TEXT_VARIANTS = {
  default: MultilineTextDefault,
  wide: MultilineTextWide,
  background: MultilineTextBg,
};

const ARROW_DOWN_VARIANTS = {
  default: ArrowDownDefault,
  wide: ArrowDownWide,
};
const ARROW_UP_VARIANTS = {
  default: ArrowUpDefault,
  wide: ArrowUpWide,
};
const ARROW_DOWN_SMALL_VARIANTS = {
  default: ArrowDownSmallDefault,
  wide: ArrowDownSmallWide,
};
const ARROW_UP_SMALL_VARIANTS = {
  default: ArrowUpSmallDefault,
  wide: ArrowUpSmallWide,
};
const ARROW_LEFT_VARIANTS = {
  default: ArrowLeftDefault,
  wide: ArrowLeftWide,
};
const ARROW_LEFT_SMALL_VARIANTS = {
  default: ArrowLeftSmallDefault,
  wide: ArrowLeftSmallWide,
};
const ARROW_RIGHT_VARIANTS = {
  default: ArrowRightDefault,
  wide: ArrowRightWide,
};
const ARROW_RIGHT_SMALL_VARIANTS = {
  default: ArrowRightSmallDefault,
  wide: ArrowRightSmallWide,
};
const ARROW_UP_LEFT_VARIANTS = {
  default: ArrowUpLeftDefault,
  wide: ArrowUpLeftWide,
};
const ARROW_UP_RIGHT_VARIANTS = {
  default: ArrowUpRightDefault,
  wide: ArrowUpRightWide,
};
const REDO_VARIANTS = {
  default: RedoDefault,
  wide: RedoWide,
};
const UNDO_VARIANTS = {
  default: UndoDefault,
  wide: UndoWide,
};
const CORNER_UP_LEFT_VARIANTS = {
  default: CornerUpLeftDefault,
  wide: CornerUpLeftWide,
};
const CORNER_UP_RIGHT_VARIANTS = {
  default: CornerUpRightDefault,
  wide: CornerUpRightWide,
};
const MOVE_UP_VARIANTS = {
  default: MoveUpDefault,
  wide: MoveUpWide,
};
const MOVE_DOWN_VARIANTS = {
  default: MoveDownDefault,
  wide: MoveDownWide,
};
const MAXIMIZE_VARIANTS = {
  default: MaximizeDefault,
  wide: MaximizeWide,
};
const MINIMIZE_VARIANTS = {
  default: MinimizeDefault,
  wide: MinimizeWide,
};
const EXTERNAL_LINK_VARIANTS = {
  default: ExternalLinkDefault,
  wide: ExternalLinkWide,
};
const COMPARE_VARIANTS = {
  default: CompareDefault,
  wide: CompareWide,
};
const IMPORT_EXPORT_VARIANTS = {
  default: ImportExportDefault,
};
const CHEVRON_DOWN_VARIANTS = {
  default: ChevronDownDefault,
  wide: ChevronDownWide,
};
const CHEVRON_UP_VARIANTS = {
  default: ChevronUpDefault,
  wide: ChevronUpWide,
};
const CHEVRON_EXPAND_VARIANTS = {
  default: ChevronExpandDefault,
  wide: ChevronExpandWide,
};
const CHEVRONS_LEFT_VARIANTS = {
  default: ChevronsLeftDefault,
  wide: ChevronsLeftWide,
};
const CHEVRONS_RIGHT_VARIANTS = {
  default: ChevronsRightDefault,
  wide: ChevronsRightWide,
};
const CORNER_DOWN_VARIANTS = {
  default: CornerDownDefault,
};
const CORNER_UP_VARIANTS = {
  default: CornerUpDefault,
};
const CORNER_RIGHT_VARIANTS = {
  default: CornerRightDefault,
};
const CORNER_LEFT_VARIANTS = {
  default: CornerLeftDefault,
};
const MORE_HORIZONTAL_VARIANTS = {
  default: MoreHorizontalDefault,
};
const MORE_VERTICAL_VARIANTS = {
  default: MoreVerticalDefault,
};
const DRAG_VARIANTS = {
  default: DragDefault,
};
const MOVE_VARIANTS = {
  default: MoveDefault,
};
const SPINNER_VARIANTS = {
  default: SpinnerDefault,
};
const CHANNEL_STROKE_VARIANTS = {
  default: ChannelStrokeDefault,
  wide: ChannelStrokeWide,
};
const PART_OF_VARIANTS = {
  default: PartOfDefault,
  wide: PartOfWide,
};
const NESTED_IN_VARIANTS = {
  default: NestedInDefault,
  wide: NestedInWide,
};
const EYE_VARIANTS = {
  default: EyeDefault,
  wide: EyeWide,
};
const EYE_OFF_VARIANTS = {
  default: EyeOffDefault,
  wide: EyeOffWide,
};
const DOT_FILL_VARIANTS = {
  default: DotFillDefault,
};
const DOT_VARIANTS = {
  default: DotDefault,
};
const BRANCH_ARROW_VARIANTS = {
  default: BranchArrowDefault,
  wide: BranchArrowWide,
};
const BRANCH_ARROW_DOWN_VARIANTS = {
  default: BranchArrowDownDefault,
  wide: BranchArrowDownWide,
};
const MAPPING_ARROW_HOR_VARIANTS = {
  default: MappingArrowHorDefault,
};
const FILTER_CROSS_VARIANTS = {
  default: FilterCrossDefault,
  wide: FilterCrossWide,
};
const FILTER_PLUS_VARIANTS = {
  default: FilterPlusDefault,
  wide: FilterPlusWide,
};
const FILTER_VARIANTS = {
  default: FilterDefault,
  wide: FilterWide,
};
const FILTER2_VARIANTS = {
  default: Filter2Default,
  wide: Filter2Wide,
};
const FILTER3_VARIANTS = {
  default: Filter3Default,
  wide: Filter3Wide,
};
const FILTER4_VARIANTS = {
  default: Filter4Default,
  wide: Filter4Wide,
};
const SORT_ARROWS_VARIANTS = {
  default: SortArrowsDefault,
  wide: SortArrowsWide,
};
const SORT_UP_VARIANTS = {
  default: SortUpDefault,
  wide: SortUpWide,
};
const SORT_DOWN_VARIANTS = {
  default: SortDownDefault,
  wide: SortDownWide,
};
const PLUS_VARIANTS = {
  default: PlusDefault,
  wide: PlusWide,
};
const PLUS_CIRCLE_VARIANTS = {
  default: PlusCircleDefault,
  wide: PlusCircleWide,
};
const CROSS_VARIANTS = {
  default: CrossDefault,
  wide: CrossWide,
};
const CROSS_SMALL_VARIANTS = {
  default: CrossSmallDefault,
  wide: CrossSmallWide,
};
const CROSS_CIRCLE_VARIANTS = {
  default: CrossCircleDefault,
  wide: CrossCircleWide,
};
const CHECKMARK_VARIANTS = {
  default: CheckmarkDefault,
  wide: CheckmarkWide,
};
const CHECKMARK_CIRCLE_VARIANTS = {
  default: CheckmarkCircleDefault,
  wide: CheckmarkCircleWide,
};
const PLAY_CIRCLE_VARIANTS = {
  default: PlayCircleDefault,
  wide: PlayCircleWide,
};
const HOLD_ON_VARIANTS = {
  default: HoldOnDefault,
  wide: HoldOnWide,
};
const COLUMN_LEFT_VARIANTS = {
  default: ColumnLeftDefault,
  wide: ColumnLeftWide,
};
const COLUMNS_VARIANTS = {
  default: ColumnsDefault,
  wide: ColumnsWide,
};
const COLUMN_RIGHT_VARIANTS = {
  default: ColumnRightDefault,
  wide: ColumnRightWide,
};
const VERTICAL_COLUMNS_VARIANTS = {
  default: VerticalColumnsDefault,
  wide: VerticalColumnsWide,
};
const LAYOUT_VARIANTS = {
  default: LayoutDefault,
  wide: LayoutWide,
};
const CARD_VARIANTS = {
  default: CardDefault,
  wide: CardWide,
};
const IMAGE_VARIANTS = {
  default: ImageDefault,
  wide: ImageWide,
};
const SEND_VARIANTS = {
  default: SendDefault,
  wide: SendWide,
};
const ACTIVITY_VARIANTS = {
  default: ActivityDefault,
  wide: ActivityWide,
};
const MONITOR_VARIANTS = {
  default: MonitorDefault,
  wide: MonitorWide,
};
const CODE_VARIANTS = {
  default: CodeDefault,
  wide: CodeWide,
};
const SUPPORT_VARIANTS = {
  default: SupportDefault,
  wide: SupportWide,
};
const ARCHIVE_VARIANTS = {
  default: ArchiveDefault,
  wide: ArchiveWide,
};
const PIN_VARIANTS = {
  default: PinDefault,
  wide: PinWide,
};
const SHIELD_VARIANTS = {
  default: ShieldDefault,
  wide: ShieldWide,
};
const SLASH_VARIANTS = {
  default: SlashDefault,
  wide: SlashWide,
};
const FLAG_VARIANTS = {
  default: FlagDefault,
  wide: FlagWide,
};
const SAD_FACE_VARIANTS = {
  default: SadFaceDefault,
  wide: SadFaceWide,
};
const SMILE_FACE_VARIANTS = {
  default: SmileFaceDefault,
  wide: SmileFaceWide,
};
const THUMBS_DOWN_VARIANTS = {
  default: ThumbsDownDefault,
  wide: ThumbsDownWide,
};
const THUMBS_UP_VARIANTS = {
  default: ThumbsUpDefault,
  wide: ThumbsUpWide,
};
const GLOBE_VARIANTS = {
  default: GlobeDefault,
  wide: GlobeWide,
};
const TOGGLE_RIGHT_VARIANTS = {
  default: ToggleRightDefault,
  wide: ToggleRightWide,
};
const COFFEE_VARIANTS = {
  default: CoffeeDefault,
  wide: CoffeeWide,
};
const FORM_VARIANTS = {
  default: FormDefault,
};

const SvgIconVariantBuilder =
  (mapping) =>
  ({ variant = "default", ...props }) => {
    const Component = mapping[variant] || mapping.default;
    return SvgProxy(Component)(props);
  };

export const SkuIcon = SvgIconVariantBuilder(SKU_VARIANTS);
export const TextIcon = SvgIconVariantBuilder(TEXT_VARIANTS);
export const RichTextIcon = SvgIconVariantBuilder(RICH_TEXT_VARIANTS);
export const MediaIcon = SvgIconVariantBuilder(MEDIA_VARIANTS);
export const CheckboxIcon = SvgIconVariantBuilder(CHECKBOX_VARIANTS);
export const CheckboxCheckIcon = SvgIconVariantBuilder(CHECKBOX_CHECK_VARIANTS);
export const CheckboxCheckFillIcon = SvgIconVariantBuilder(CHECKBOX_CHECK_FILL);
export const SelectIcon = SvgIconVariantBuilder(SELECT_VARIANTS);
export const DateIcon = SvgIconVariantBuilder(DATE_VARIANTS);
export const PhoneIcon = SvgIconVariantBuilder(PHONE_VARIANTS);
export const TagIcon = SvgIconVariantBuilder(TAG_VARIANTS);
export const EmailIcon = SvgIconVariantBuilder(EMAIL_VARIANTS);
export const LinkIcon = SvgIconVariantBuilder(LINK_VARIANTS);
export const NumIcon = SvgIconVariantBuilder(NUM_VARIANTS);
export const CurrencyIcon = SvgIconVariantBuilder(CURRENCY_VARIANTS);
export const PercentIcon = SvgIconVariantBuilder(PERCENT_VARIANTS);
export const TimeIcon = SvgIconVariantBuilder(TIME_VARIANTS);
export const FormulaIcon = SvgIconVariantBuilder(FORMULA_VARIANTS);
export const LookupIcon = SvgIconVariantBuilder(LOOKUP_VARIANTS);
export const RollupIcon = SvgIconVariantBuilder(ROLLUP_VARIANTS);
export const ObjectIcon = SvgIconVariantBuilder(OBJECT_VARIANTS);
export const IdIcon = SvgIconVariantBuilder(ID_VARIANTS);
export const KeyIcon = SvgIconVariantBuilder(KEY_VARIANTS);
export const MessageSquareIcon = SvgIconVariantBuilder(MESSAGE_SQUARE_VARIANTS);
export const ZapIcon = SvgIconVariantBuilder(ZAP_VARIANTS);
export const ZapSquareIcon = SvgIconVariantBuilder(ZAP_SQUARE_VARIANTS);
export const AutoincrementIcon = SvgIconVariantBuilder(AUTOINCREMENT_VARIANTS);
export const FieldIcon = SvgIconVariantBuilder(FIELD_VARIANTS);
export const SearchIcon = SvgIconVariantBuilder(SEARCH_VARIANTS);
export const TargetRequestIcon = SvgIconVariantBuilder(TARGET_REQUEST_VARIANTS);
export const TableEditIcon = SvgIconVariantBuilder(TABLE_EDIT_VARIANTS);
export const TableUpdateIcon = SvgIconVariantBuilder(TABLE_UPDATE_VARIANTS);
export const TableZapIcon = SvgIconVariantBuilder(TABLE_ZAP_VARIANTS);
export const TargetResponseIcon = SvgIconVariantBuilder(TARGET_RESPONSE_VARIANTS);
export const ConnectorIcon = SvgIconVariantBuilder(CONNECTOR_VARIANTS);
export const TableIcon = SvgIconVariantBuilder(TABLE_VARIANTS);
export const StorageIcon = SvgIconVariantBuilder(STORAGE_VARIANTS);
export const LayersIcon = SvgIconVariantBuilder(LAYERS_VARIANTS);
export const StarIcon = SvgIconVariantBuilder(STAR_VARIANTS);
export const BoxIcon = SvgIconVariantBuilder(BOX_VARIANTS);
export const CircleIcon = SvgIconVariantBuilder(CIRCLE_VARIANTS);
export const LockIcon = SvgIconVariantBuilder(LOCK_VARIANTS);
export const LockOpenIcon = SvgIconVariantBuilder(LOCK_OPEN_VARIANTS);
export const EditIcon = SvgIconVariantBuilder(EDIT_VARIANTS);
export const ReloadRefreshIcon = SvgIconVariantBuilder(RELOAD_REFRESH_VARIANTS);
export const MultiplyIcon = SvgIconVariantBuilder(MULTIPLY_VARIANTS);
export const DivideIcon = SvgIconVariantBuilder(DIVIDE_VARIANTS);
export const FolderIcon = SvgIconVariantBuilder(FOLDER_VARIANTS);
export const FolderUploadIcon = SvgIconVariantBuilder(FOLDER_UPLOAD_VARIANTS);
export const FolderSyncIcon = SvgIconVariantBuilder(FOLDER_SYNC_VARIANTS);
export const FolderPlusIcon = SvgIconVariantBuilder(FOLDER_PLUS_VARIANTS);
export const FileIcon = SvgIconVariantBuilder(FILE_VARIANTS);
export const FileUploadIcon = SvgIconVariantBuilder(FILE_UPLOAD_VARIANTS);
export const FileSyncIcon = SvgIconVariantBuilder(FILE_SYNC_VARIANTS);
export const FileEditIcon = SvgIconVariantBuilder(FILE_EDIT_VARIANTS);
export const FileAddIcon = SvgIconVariantBuilder(FILE_ADD_VARIANTS);
export const UserIcon = SvgIconVariantBuilder(USER_VARIANTS);
export const UserAddIcon = SvgIconVariantBuilder(USER_ADD_VARIANTS);
export const UserZapIcon = SvgIconVariantBuilder(USER_ZAP_VARIANTS);
export const UserEditIcon = SvgIconVariantBuilder(USER_EDIT_VARIANTS);
export const UserUpdateIcon = SvgIconVariantBuilder(USER_UPDATE_VARIANTS);
export const UsersIcon = SvgIconVariantBuilder(USERS_VARIANTS);
export const TemplateIcon = SvgIconVariantBuilder(TEMPLATE_VARIANTS);
export const GridOrMenuIcon = SvgIconVariantBuilder(GRID_OR_MENU_VARIANTS);
export const CopyIcon = SvgIconVariantBuilder(COPY_VARIANTS);
export const TaskIcon = SvgIconVariantBuilder(TASK_VARIANTS);
export const SettingsIcon = SvgIconVariantBuilder(SETTINGS_VARIANTS);
export const TrashIcon = SvgIconVariantBuilder(TRASH_VARIANTS);
export const DoubleCircleIcon = SvgIconVariantBuilder(DOUBLE_CIRCLE_VARIANTS);
export const TargetIcon = SvgIconVariantBuilder(TARGET_VARIANTS);
export const BookIcon = SvgIconVariantBuilder(BOOK_VARIANTS);
export const BookmarkIcon = SvgIconVariantBuilder(BOOKMARK_VARIANTS);
export const HomeIcon = SvgIconVariantBuilder(HOME_VARIANTS);
export const InboxIcon = SvgIconVariantBuilder(INBOX_VARIANTS);
export const InfoIcon = SvgIconVariantBuilder(INFO_VARIANTS);
export const HelpCircleIcon = SvgIconVariantBuilder(HELP_CIRCLE_VARIANTS);
export const AlertTriangleIcon = SvgIconVariantBuilder(ALERT_TRIANGLE_VARIANTS);
export const MultilineTextIcon = SvgIconVariantBuilder(MULTILINE_TEXT_VARIANTS);

export const ArrowDownIcon = SvgIconVariantBuilder(ARROW_DOWN_VARIANTS);
export const ArrowUpIcon = SvgIconVariantBuilder(ARROW_UP_VARIANTS);
export const ArrowDownSmallIcon = SvgIconVariantBuilder(ARROW_DOWN_SMALL_VARIANTS);
export const ArrowUpSmallIcon = SvgIconVariantBuilder(ARROW_UP_SMALL_VARIANTS);
export const ArrowLeftIcon = SvgIconVariantBuilder(ARROW_LEFT_VARIANTS);
export const ArrowLeftSmallIcon = SvgIconVariantBuilder(ARROW_LEFT_SMALL_VARIANTS);
export const ArrowRightIcon = SvgIconVariantBuilder(ARROW_RIGHT_VARIANTS);
export const ArrowRightSmallIcon = SvgIconVariantBuilder(ARROW_RIGHT_SMALL_VARIANTS);
export const ArrowUpLeftIcon = SvgIconVariantBuilder(ARROW_UP_LEFT_VARIANTS);
export const ArrowUpRightIcon = SvgIconVariantBuilder(ARROW_UP_RIGHT_VARIANTS);
export const RedoIcon = SvgIconVariantBuilder(REDO_VARIANTS);
export const UndoIcon = SvgIconVariantBuilder(UNDO_VARIANTS);
export const CornerUpLeftIcon = SvgIconVariantBuilder(CORNER_UP_LEFT_VARIANTS);
export const CornerUpRightIcon = SvgIconVariantBuilder(CORNER_UP_RIGHT_VARIANTS);
export const MoveUpIcon = SvgIconVariantBuilder(MOVE_UP_VARIANTS);
export const MoveDownIcon = SvgIconVariantBuilder(MOVE_DOWN_VARIANTS);
export const MaximizeIcon = SvgIconVariantBuilder(MAXIMIZE_VARIANTS);
export const MinimizeIcon = SvgIconVariantBuilder(MINIMIZE_VARIANTS);
export const ExternalLinkIcon = SvgIconVariantBuilder(EXTERNAL_LINK_VARIANTS);
export const CompareIcon = SvgIconVariantBuilder(COMPARE_VARIANTS);
export const ImportExportIcon = SvgIconVariantBuilder(IMPORT_EXPORT_VARIANTS);
export const ChevronDownIcon = SvgIconVariantBuilder(CHEVRON_DOWN_VARIANTS);
export const ChevronUpIcon = SvgIconVariantBuilder(CHEVRON_UP_VARIANTS);
export const ChevronExpandIcon = SvgIconVariantBuilder(CHEVRON_EXPAND_VARIANTS);
export const ChevronsLeftIcon = SvgIconVariantBuilder(CHEVRONS_LEFT_VARIANTS);
export const ChevronsRightIcon = SvgIconVariantBuilder(CHEVRONS_RIGHT_VARIANTS);
export const CornerDownIcon = SvgIconVariantBuilder(CORNER_DOWN_VARIANTS);
export const CornerUpIcon = SvgIconVariantBuilder(CORNER_UP_VARIANTS);
export const CornerRightIcon = SvgIconVariantBuilder(CORNER_RIGHT_VARIANTS);
export const CornerLeftIcon = SvgIconVariantBuilder(CORNER_LEFT_VARIANTS);
export const MoreHorizontalIcon = SvgIconVariantBuilder(MORE_HORIZONTAL_VARIANTS);
export const MoreVerticalIcon = SvgIconVariantBuilder(MORE_VERTICAL_VARIANTS);
export const DragIcon = SvgIconVariantBuilder(DRAG_VARIANTS);
export const MoveIcon = SvgIconVariantBuilder(MOVE_VARIANTS);
export const SpinnerIcon = SvgIconVariantBuilder(SPINNER_VARIANTS);
export const ChannelStrokeIcon = SvgIconVariantBuilder(CHANNEL_STROKE_VARIANTS);
export const PartOfIcon = SvgIconVariantBuilder(PART_OF_VARIANTS);
export const NestedInIcon = SvgIconVariantBuilder(NESTED_IN_VARIANTS);
export const EyeIcon = SvgIconVariantBuilder(EYE_VARIANTS);
export const EyeOffIcon = SvgIconVariantBuilder(EYE_OFF_VARIANTS);
export const DotFillIcon = SvgIconVariantBuilder(DOT_FILL_VARIANTS);
export const DotIcon = SvgIconVariantBuilder(DOT_VARIANTS);
export const BranchArrowIcon = SvgIconVariantBuilder(BRANCH_ARROW_VARIANTS);
export const BranchArrowDownIcon = SvgIconVariantBuilder(BRANCH_ARROW_DOWN_VARIANTS);
export const MappingArrowHorIcon = SvgIconVariantBuilder(MAPPING_ARROW_HOR_VARIANTS);
export const FilterCrossIcon = SvgIconVariantBuilder(FILTER_CROSS_VARIANTS);
export const FilterPlusIcon = SvgIconVariantBuilder(FILTER_PLUS_VARIANTS);
export const FilterIcon = SvgIconVariantBuilder(FILTER_VARIANTS);
export const Filter2Icon = SvgIconVariantBuilder(FILTER2_VARIANTS);
export const Filter3Icon = SvgIconVariantBuilder(FILTER3_VARIANTS);
export const Filter4Icon = SvgIconVariantBuilder(FILTER4_VARIANTS);
export const SortArrowsIcon = SvgIconVariantBuilder(SORT_ARROWS_VARIANTS);
export const SortUpIcon = SvgIconVariantBuilder(SORT_UP_VARIANTS);
export const SortDownIcon = SvgIconVariantBuilder(SORT_DOWN_VARIANTS);
export const PlusIcon = SvgIconVariantBuilder(PLUS_VARIANTS);
export const PlusCircleIcon = SvgIconVariantBuilder(PLUS_CIRCLE_VARIANTS);
export const CrossIcon = SvgIconVariantBuilder(CROSS_VARIANTS);
export const CrossSmallIcon = SvgIconVariantBuilder(CROSS_SMALL_VARIANTS);
export const CrossCircleIcon = SvgIconVariantBuilder(CROSS_CIRCLE_VARIANTS);
export const CheckmarkIcon = SvgIconVariantBuilder(CHECKMARK_VARIANTS);
export const CheckmarkCircleIcon = SvgIconVariantBuilder(CHECKMARK_CIRCLE_VARIANTS);
export const PlayCircleIcon = SvgIconVariantBuilder(PLAY_CIRCLE_VARIANTS);
export const HoldOnIcon = SvgIconVariantBuilder(HOLD_ON_VARIANTS);
export const ColumnLeftIcon = SvgIconVariantBuilder(COLUMN_LEFT_VARIANTS);
export const ColumnsIcon = SvgIconVariantBuilder(COLUMNS_VARIANTS);
export const ColumnRightIcon = SvgIconVariantBuilder(COLUMN_RIGHT_VARIANTS);
export const VerticalColumnsIcon = SvgIconVariantBuilder(VERTICAL_COLUMNS_VARIANTS);
export const LayoutIcon = SvgIconVariantBuilder(LAYOUT_VARIANTS);
export const CardIcon = SvgIconVariantBuilder(CARD_VARIANTS);
export const ImageIcon = SvgIconVariantBuilder(IMAGE_VARIANTS);
export const SendIcon = SvgIconVariantBuilder(SEND_VARIANTS);
export const ActivityIcon = SvgIconVariantBuilder(ACTIVITY_VARIANTS);
export const MonitorIcon = SvgIconVariantBuilder(MONITOR_VARIANTS);
export const CodeIcon = SvgIconVariantBuilder(CODE_VARIANTS);
export const SupportIcon = SvgIconVariantBuilder(SUPPORT_VARIANTS);
export const ArchiveIcon = SvgIconVariantBuilder(ARCHIVE_VARIANTS);
export const PinIcon = SvgIconVariantBuilder(PIN_VARIANTS);
export const ShieldIcon = SvgIconVariantBuilder(SHIELD_VARIANTS);
export const SlashIcon = SvgIconVariantBuilder(SLASH_VARIANTS);
export const FlagIcon = SvgIconVariantBuilder(FLAG_VARIANTS);
export const SadFaceIcon = SvgIconVariantBuilder(SAD_FACE_VARIANTS);
export const SmileFaceIcon = SvgIconVariantBuilder(SMILE_FACE_VARIANTS);
export const ThumbsDownIcon = SvgIconVariantBuilder(THUMBS_DOWN_VARIANTS);
export const ThumbsUpIcon = SvgIconVariantBuilder(THUMBS_UP_VARIANTS);
export const GlobeIcon = SvgIconVariantBuilder(GLOBE_VARIANTS);
export const ToggleRightIcon = SvgIconVariantBuilder(TOGGLE_RIGHT_VARIANTS);
export const CoffeeIcon = SvgIconVariantBuilder(COFFEE_VARIANTS);
export const FormIcon = SvgIconVariantBuilder(FORM_VARIANTS);
