import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import ViewsManager from "components/ViewsManager";
import Divider from "components/Divider";
import MenuItem from "components/Menu/MenuItem";
import { PlusIcon, TrashIcon } from "icons";
import { useViewsManagerStyles } from "theme/components/ViewsManager";

import { useSort } from "components/TableSort/SortProvider";
import { SortsLabels } from "components/TableSort/SortsLabels";
import { SortsSearchMenu } from "components/TableSort/SortsMenu";
import { useViewsManager } from "./Provider";

const ViewsManagerSort = () => {
  const { sorts, onClean } = useSort();
  const classes = useViewsManagerStyles();
  const { onClose } = useViewsManager();

  return (
    <ViewsManager>
      <ViewsManager.Header onClose={onClose}>
        <Typography variant="h4">Sort</Typography>
      </ViewsManager.Header>

      <ViewsManager.SubHeader>
        <Box>
          <Typography space={{ mb: 1 }} color="grey">{`${sorts.length} applied`}</Typography>

          <SortsLabels itemProps={{ className: classes.label }} />
        </Box>
      </ViewsManager.SubHeader>

      <Divider variant="light" space={{ my: 1 }} />

      <ViewsManager.Body>
        <SortsSearchMenu
          button={MenuItem}
          buttonProps={{ icon: PlusIcon, size: "small" }}
          buttonTitle="Add sort"
        />

        <MenuItem size="small" onClick={onClean} icon={TrashIcon}>
          Clear all
        </MenuItem>
      </ViewsManager.Body>
    </ViewsManager>
  );
};

export default ViewsManagerSort;
