import React, { useState } from "react";
import keyBy from "lodash/keyBy";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import Box from "components/Box";
import MenuItem from "components/Menu/MenuItem";
import { PlusThinIcon } from "icons";
import SelectNew from "components/Inputs/Select";
import { useDialog } from "utils/state";
import { Item } from "models/ListItem/Item";
import { useSearchItemsOptionsByTableColumn } from "models/ListItem/queries";

import { TableConnectorItemDrawer } from "./TableConnectorItemDrawer";

export const TableConnectorForm = ({
  formField,
  value,
  onCancel,
  onChange,
  onSave,
  footer: Footer,
  tableViewId,
  menuInput = false,
  ...props
}) => {
  const [search, setSearch] = useState("");

  const primaryColumnIds = formField.config.columns
    .filter((column) => column.primary)
    .map((column) => column.id);

  const allColumnIds = formField.config.columns.map((column) => column.id);

  const tableConnectorItemsQuery = useSearchItemsOptionsByTableColumn({
    tableViewId,
    columnId: formField.id,
    skip: isEmpty(search),
  });

  const onSearch = (value) => {
    setSearch(value);
    tableConnectorItemsQuery.onSearch(value);
  };

  const cleanSearch = () => onSearch("");

  const options = tableConnectorItemsQuery.listItems.map((item) => ({
    id: item.id,
    label: { item, primaryColumnIds },
  }));

  const optionsMap = keyBy(options, "id");

  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [openedDrawer, toggleDrawer, closeDrawer] = useDialog(false);
  const formValue = value || [];
  const formValueMap = keyBy(formValue, (item) => item.id || item.index);
  const selectedItem = formValue[selectedItemIndex];

  const handleCancel = () => {
    onCancel();
    handleClose();
  };

  const handleClose = () => {
    setSelectedItemIndex(null);
    closeDrawer();
  };

  const handleClickItem = (_event, option, { onClose }) => {
    const optionItem = option.label.item;

    const itemIndex = formValue.findIndex((item) =>
      optionItem.id ? item.id === optionItem.id : item.index === optionItem.index
    );

    setSelectedItemIndex(itemIndex);
    onClose();
    toggleDrawer();
  };

  const handleAddItem = ({ onClose }) => {
    if (!formField.config.isWriteable) return;

    const newItem = { index: `TEMP_ID_${Date.now()}`, id: null, values: [] };
    const newValue = formField.config.isMultiple ? [...formValue, newItem] : [newItem];
    const newItemIndex = newValue.findIndex((item) => item.index === newItem.index);

    onChange(newValue);
    setSelectedItemIndex(newItemIndex);
    onClose();
    toggleDrawer();
  };

  const handleChangeItem = (newValues) => {
    const itemIndex = formValue.findIndex((item) =>
      selectedItem.id ? item.id === selectedItem.id : item.index === selectedItem.index
    );

    const newItem = { ...selectedItem, values: newValues };

    if (formField.config.isMultiple) {
      onChange(Object.assign([], formValue, { [itemIndex]: newItem }));
    } else {
      onChange([newItem]);
    }
  };

  const onChangeSelect = (value) => {
    const ids = compact([value].flat());

    const newFormValue = ids.reduce((result, id) => {
      if (formValueMap[id]) {
        return [...result, formValueMap[id]];
      }

      const option = optionsMap[id];

      if (!option) return result;

      const values = option.label.item.values.filter((value) =>
        allColumnIds.includes(value.columnId)
      );

      return [...result, { id: option.id, values }];
    }, []);

    onChange(newFormValue);
    !menuInput && onSave({ newFormValue });
  };

  const selectValueOptions = formValue.map((item) => ({
    id: item.id ?? item.index,
    label: { item, primaryColumnIds },
  }));

  const selectValue = value ? flatten([value]).map((item) => item.id ?? item.index) : [];

  return (
    <Box>
      <SelectNew
        {...props}
        multiple={formField.config.isMultiple}
        closeOnChange={false}
        renderTagsInSearch
        removeableLabel={!menuInput}
        options={options}
        placeholder={formField.placeholder}
        valueOptions={selectValueOptions}
        value={selectValue}
        loading={tableConnectorItemsQuery.loading}
        onLoadMore={tableConnectorItemsQuery.onLoadMore}
        onSearch={onSearch}
        onClose={cleanSearch}
        totalCount={tableConnectorItemsQuery.totalCount}
        onClickItem={handleClickItem}
        optionVariant="label"
        markVariant={null}
        optionComponent={ItemValueLabel}
        onChange={onChangeSelect}
        menuLabel={`Select value from ${formField.label}`}
        footer={({ onClose }) => (
          <>
            {formField.config.isWriteable && (
              <MenuItem size="small" onClick={() => handleAddItem({ onClose })} icon={PlusThinIcon}>
                Add new item
              </MenuItem>
            )}

            {Footer && <Footer onClose={onClose} />}
          </>
        )}
      />

      <TableConnectorItemDrawer
        open={openedDrawer && !!selectedItem}
        formField={formField}
        value={selectedItem?.values || []}
        onChange={handleChangeItem}
        onCancel={handleCancel}
        onSave={() => onSave({ onClose: handleClose })}
        onClose={closeDrawer}
      />
    </Box>
  );
};

const ItemValueLabel = ({ option }) => {
  const { item, primaryColumnIds } = option.label;

  return Item.getListItemTitle({ item, columnIds: primaryColumnIds, length: 32 });
};
