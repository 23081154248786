import React, { useState } from "react";
import ResizeBorder from "components/ResizeBorder";

const MAX_WIDTH = 5000;
const MIN_WIDTH = 50;

export const ResizeFieldBorder = ({ height, fieldId, widthMap, setWidthMap }) => {
  const cellWidth = widthMap[fieldId];
  const [defaultWidth] = useState(cellWidth);

  const onBorderResizing = (index, resizeWidth) => {
    const newWidth = cellWidth + resizeWidth;

    if (newWidth <= MIN_WIDTH || newWidth >= MAX_WIDTH) return;

    setWidthMap({ ...widthMap, [fieldId]: newWidth });
  };

  const resetToDefault = () => setWidthMap({ ...widthMap, [fieldId]: defaultWidth });

  return (
    <ResizeBorder style={{ height }} onDoubleClick={resetToDefault} onResizing={onBorderResizing} />
  );
};
