import React from "react";
import clsx from "clsx";
import takeWhile from "lodash/takeWhile";
import findLast from "lodash/findLast";
import { CHECKBOX_COLUMN_ID } from "../TableCells/Cells";

const TableCell = ({
  className,
  widthMap,
  column,
  columns,
  children,
  checked,
  selected,
  stickedColumnId,
  dragProps,
  dragActive,
  rowIndex,
}) => {
  const cellWidth = widthMap[column.id];

  const lastSticked = findLast(columns, (item) => item.id === stickedColumnId);

  const previousStickedFields = lastSticked
    ? takeWhile(columns, (h) => h.id !== lastSticked.id)
    : [];

  const previousFields = takeWhile(columns, (h) => h.id !== column.id);
  const isBeforeSticked = previousStickedFields.find((item) => item.id === column.id);

  const sticked = column.id === stickedColumnId || isBeforeSticked;

  const previousFieldsWidth = previousFields.reduce(
    (sum, prevField) => sum + 0 + (prevField.width ? prevField.width : widthMap[prevField.id]),
    0
  );

  const widthMapStyle = {
    left: previousFieldsWidth || 0,
    width: cellWidth,
    maxWidth: column.id === CHECKBOX_COLUMN_ID ? cellWidth : null,
    flex: `1 1 ${cellWidth}px`,
  };

  return (
    <div
      {...dragProps}
      style={widthMapStyle}
      className={clsx(className, {
        checkbox: column.id === CHECKBOX_COLUMN_ID,
        overflow: column.overflow,
        lastSticked: column.id === lastSticked?.id,
        firstRow: rowIndex === 0,
        sticked: column.id === CHECKBOX_COLUMN_ID || sticked,
        checked,
        selected,
        dragActive,
      })}
    >
      {children}
    </div>
  );
};

export default TableCell;
