import React from "react";
import Box from "components/Box";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import IconButton from "components/IconButton";
import { CrossIcon, EditThinIcon, ArrowUpRightIcon, ArrowDownIcon } from "icons";
import Label from "components/Label";

import "styles/App/InputWithActions.scss";

export const useInputWithActionsStyles = () => ({
  container: "InputWithActions-container",
  input: "InputWithActions-input",
  label: "InputWithActions-label",
  actions: "InputWithActions-actions",
});

export const InputWithActions = React.forwardRef(({ iconSize, actions, ...props }, ref) => {
  const actionClasses = useInputWithActionsStyles();

  return (
    <div className={actionClasses.container}>
      <input ref={ref} {...props} />

      {!isEmpty(actions) && <InputActions {...actions} />}
    </div>
  );
});

const useSelectMaskInputStyles = () => ({
  container: "SelectMaskInput-container",
  element: "SelectMaskInput-element",
  icon: "SelectMaskInput-icon",
});

const useLabelValueInputStyles = () => ({
  container: "LabelValueInput-container",
  label: "LabelValueInput-label",
});

export const GhostSelectInput = React.forwardRef(({ iconSize, ...props }, ref) => {
  const classes = useSelectMaskInputStyles();

  return (
    <div className={classes.container}>
      <input ref={ref} {...props} />

      <p className={classes.element}>
        {isEmpty(props.value) ? props.placeholder : props.value}
        <ArrowDownIcon className={classes.icon} size={iconSize} />
      </p>
    </div>
  );
});

export const LabelValueInput = React.forwardRef(({ iconSize, actions, ...props }, ref) => {
  const classes = useLabelValueInputStyles();
  const actionClasses = useInputWithActionsStyles();
  const withActions = !isEmpty(actions);

  return (
    <div
      className={clsx({
        [classes.container]: !isEmpty(props.value),
        [actionClasses.container]: withActions,
      })}
    >
      <input ref={ref} {...props} />

      {!isEmpty(props.value) && <Label className={classes.label}>{props.value}</Label>}
      {withActions && <InputActions {...actions} />}
    </div>
  );
});

export const InputActions = ({ onOpen, onEdit, onRemove }) => {
  const actionClasses = useInputWithActionsStyles();

  const handleEdit = (event) => {
    event.stopPropagation();
    onEdit && onEdit(event);
  };

  const handleOpen = (event) => {
    event.stopPropagation();
    onOpen && onOpen(event);
  };

  const handleRemove = (event) => {
    event.stopPropagation();
    onRemove && onRemove(event);
  };

  return (
    <Box className={actionClasses.actions}>
      {onEdit && (
        <IconButton
          icon={(props) => <EditThinIcon {...props} color="grey" />}
          onClick={handleEdit}
          size="table"
        />
      )}

      {onOpen && (
        <IconButton
          icon={(props) => <ArrowUpRightIcon {...props} color="grey" />}
          onClick={handleOpen}
          size="table"
        />
      )}

      {onRemove && (
        <IconButton
          icon={(props) => <CrossIcon {...props} color="grey" />}
          onClick={handleRemove}
          size="table"
        />
      )}
    </Box>
  );
};
