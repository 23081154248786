import React from "react";
import { NotificationsProvider } from "./contexts/Notifications";
import { HelmetProvider } from "react-helmet-async";
import ApolloProvider from "providers/ApolloProvider";

import routes from "./routes";
import { ThemeProvider } from "./theme/ThemeProvider";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "nprogress/nprogress.css";

const router = createBrowserRouter(routes);

if (process.env.NODE_ENV === "development") {
  window.addEventListener("error", (event) => {
    if (event.message.match(/ResizeObserver/)) {
      event.preventDefault();
      event.stopImmediatePropagation();
      document.getElementById("webpack-dev-server-client-overlay")?.remove();
    }
  });
}

const App = () => {
  return (
    <ThemeProvider>
      <NotificationsProvider>
        <ApolloProvider>
          <HelmetProvider>
            <RouterProvider router={router} />
          </HelmetProvider>
        </ApolloProvider>
      </NotificationsProvider>
    </ThemeProvider>
  );
};

export default App;
