import React from "react";
import Button from "components/Button";

const UploadFileButton = ({
  component: Component,
  onUpload,
  disabled,
  name,
  id,
  multiple,
  accept,
  children,
  ...props
}) => {
  const buttonId = id ? id : `upload-file-button-${name}`;

  const onFileAttach = (event) => {
    const {
      target: { validity, files },
    } = event;

    if (!validity.valid) return;

    onUpload && onUpload(multiple ? files : files[0]);
    event.target.value = null;
  };

  const ButtonComponent = Component || Button;

  return (
    <>
      <input
        style={{ display: "none" }}
        id={buttonId}
        name={name}
        disabled={disabled}
        onChange={onFileAttach}
        type="file"
        multiple={multiple}
        accept={accept}
      />
      <ButtonComponent {...props} disabled={disabled} component="label" htmlFor={buttonId}>
        {children}
      </ButtonComponent>
    </>
  );
};

export default UploadFileButton;
