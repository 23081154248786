import { gql } from "@apollo/client";
import useMutation from "hooks/useMutation";
import { refetchQueries, removeFragment } from "hooks/useQuery/usePaginationQuery";
import { useApolloClient } from "@apollo/client";
import { useWorkspace } from "contexts/Workspace";
import { useFormLayoutNamespace } from "contexts/FormLayoutNamespace";
import { GRAPHQL_ERROR } from "graphql/fragments";
import { MAPPING_FRAGMENT, MappingRowValueFragment } from "models/fragments/Mapping";

import { SEARCH_FORM_LAYOUTS } from "./queries";
import { FormLayout } from "./FormLayout";

const CREATE_FORM_LAYOUT = gql`
  mutation CreateFormLayout($input: CreateFormLayoutInput) {
    createFormLayout(input: $input) {
      ...FormLayoutFragment
    }
  }

  ${FormLayout.fragment}
`;

const UPDATE_FORM_LAYOUT = gql`
  mutation UpdateFormLayout($input: UpdateFormLayoutInput) {
    updateFormLayout(input: $input) {
      ...FormLayoutFragment
    }
  }

  ${FormLayout.fragment}
`;

const DELETE_FORM_LAYOUT = gql`
  mutation UpdateFormLayout($input: SingleDeleteInput) {
    deleteFormLayout(input: $input) {
      success
    }
  }
`;

export const SHARE_FORM_LAYOUT = gql`
  mutation ShareFormLayout($input: ShareFormLayoutInput) {
    shareFormLayout(input: $input) {
      success
    }
  }
`;

export const EXECUTE_FORM_LAYOUT_ACTION = gql`
  mutation ExecuteFormLayoutAction($input: ExecuteFormLayoutActionInput) {
    executeFormLayoutAction(input: $input) {
      totalDataRows
      successDataRows
      errorDataRows
      errors {
        ${GRAPHQL_ERROR}
      }
      request {
        total
        success
        failed
        data
        errors {
          ${GRAPHQL_ERROR}
        }
      }
      response {
        total
        success
        failed
        data
        errors {
          ${GRAPHQL_ERROR}
        }
      }
    }
  }
`;

export const CREATE_OR_UPDATE_FORM_LAYOUT_ACTION = gql`
  mutation CreateOrUpdateFormLayoutAction($input: CreateOrUpdateFormLayoutActionInput) {
    createOrUpdateFormLayoutAction(input: $input) {
      id
      type
      name
      request {
        ${MAPPING_FRAGMENT}
      }
      response {
        ${MAPPING_FRAGMENT}
      }
    }
  }

  ${MappingRowValueFragment}
`;

export const useFormLayoutMutations = () => {
  const apolloClient = useApolloClient();
  const { companyId } = useWorkspace();
  const { formLayoutId } = useFormLayoutNamespace();

  const [updateFormLayout] = useMutation(UPDATE_FORM_LAYOUT);
  const [createFormLayout] = useMutation(CREATE_FORM_LAYOUT);
  const [removeFormLayout] = useMutation(DELETE_FORM_LAYOUT);
  const [shareFormLayout] = useMutation(SHARE_FORM_LAYOUT);
  const [executeFormLayoutAction] = useMutation(EXECUTE_FORM_LAYOUT_ACTION);
  const [createOrUpdateFormLayoutAction] = useMutation(CREATE_OR_UPDATE_FORM_LAYOUT_ACTION);

  const handleCreateFormLayout = ({ input, onSuccess, onFailure }) => {
    return createFormLayout({
      variables: { input: { ...input, namespace: { companyId } } },
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      onFailure,
      refetchQueries: refetchQueries(apolloClient, [SEARCH_FORM_LAYOUTS]),
      awaitRefetchQueries: true,
    });
  };

  const handleUpdateFormLayout = ({ input, onSuccess, onFailure }) => {
    return updateFormLayout({
      variables: { input: { ...input, namespace: { companyId } } },
      onSuccess,
      onFailure,
    });
  };

  const handleCreateOrUpdateFormLayoutAction = ({ input, onSuccess, onFailure }) => {
    return createOrUpdateFormLayoutAction({
      variables: { input: { ...input, namespace: { companyId } } },
      onSuccess,
      onFailure,
    });
  };

  const handleExecuteFormLayoutAction = ({ input, onSuccess, onFailure }) => {
    return executeFormLayoutAction({
      variables: { input },
      onSuccess,
      onFailure,
    });
  };

  const handleRemoveFormLayout = ({ id, onSuccess, onFailure }) => {
    return removeFormLayout({
      variables: { input: { id } },
      onSuccess,
      onFailure,
      update: (cache, data) => {
        removeFragment(apolloClient, data, [id], "GetFormLayoutPayload", [SEARCH_FORM_LAYOUTS]);
      },
    });
  };

  const shareFormLayoutToProject = ({ input, onSuccess, onFailure }) => {
    return shareFormLayout({
      variables: { input },
      onSuccess,
      onFailure,
      refetchQueries: refetchQueries(apolloClient, [SEARCH_FORM_LAYOUTS]),
      awaitRefetchQueries: true,
    });
  };

  return {
    shareFormLayoutToProject,
    updateFormLayout: handleUpdateFormLayout,
    createFormLayout: handleCreateFormLayout,
    removeFormLayout: handleRemoveFormLayout,
    executeFormLayoutAction: handleExecuteFormLayoutAction,
    createOrUpdateFormLayoutAction: handleCreateOrUpdateFormLayoutAction,
  };
};
