export const CONTENT = {
  dashboard: {
    title: "Dashboard title",
    description: "Dashboard description",
  },
  fields: {
    title: "Fields",
  },
  tables: {
    title: "Tables",
  },
  tasks: {
    title: "Tasks",
    description:
      "Run tasks to import fields or values, or sync your data channels with ultimate to schedule regular import or export tasks  ",
  },
  lists: {
    title: "Lists",
  },
  tableView: {
    confirmRemoveTitle: "Remove Table View?",
    confirmRemoveContent:
      "Removing table view. If you want to make a permanent deletion select the right option below.",
  },
  tableLink: {
    confirmTitle: "Remove table link?",
    confirmContent:
      "Removing table link. If you want to make a permanent deletion select the right option below.",
  },
  confirmRemoveRecords: {
    title: "Remove records?",
    content:
      "Removing table items will clean up the current table. This action does not remove records from the parrent table, if you want to make a permanent deletion select the right option below.",
  },
  confirmRemoveFolders: {
    title: "Remove folders?",
    content:
      "Removing table will clean up the current table. This action also remove fields inside the folder.",
  },
  confirmRemoveTable: {
    title: "Remove table?",
    content:
      "Removing table will clean up the current table. This action also remove fields inside the folder.",
  },
  saveMappingTemplate: {
    title: "Save mapping table?",
    save: "Save",
    notSave: "Don't Save",
    content:
      "This mapping will not be saved as a table, if you want to save this mapping give it a name",
  },
  updateMappingTemplate: {
    title: "Update mapping table?",
    save: "Update",
    notSave: "Don't Update",
    content: "The mapping updates hasn't been saved, if you want to save your changes click Update",
  },
  confirmRemove: "Confirm Remove",
  removeFromAll: "Remove from all",
  removeFromTable: "Remove from this table",
};

export default CONTENT;
