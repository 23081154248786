import React, { useState } from "react";

const AuthFormContext = React.createContext();

export const AuthFormProvider = ({ children }) => {
  const [verifiedEmail, setVerifiedEmail] = useState(null);

  return (
    <AuthFormContext.Provider value={{ verifiedEmail, setVerifiedEmail }}>{children}</AuthFormContext.Provider>
  );
};

export const useAuthFormContext = () => React.useContext(AuthFormContext);
