import { useState } from "react";

const usePagination = ({
  totalCount,
  pageSize = totalCount,
  collapseSize,
  entities,
  onLoadMore,
}) => {
  // Pagination
  const [collapseMode, setCollapseMode] = useState(!!collapseSize);
  const isCollapseMode = collapseMode && entities.length > collapseSize;

  const [page, setPage] = useState(0);
  const pageOffset = page * pageSize;
  const nextPageOffset = pageSize * (page + 1);
  const totalPages = Math.ceil(totalCount / pageSize);
  const fetchedPages = entities.length / pageSize;

  const pageEntities = entities.slice(pageOffset, nextPageOffset);

  const elementsOffset = pageOffset + pageEntities.length;
  const restElements = totalCount - elementsOffset;
  const nextElements = restElements > pageSize ? pageSize : restElements;

  const prevElements = page === 0 ? 0 : pageSize;

  const isAllowPrevPage = page !== 0;
  const isAllowNextPage = page + 1 !== totalPages && pageOffset !== totalCount;

  const isCollapsedPage =
    collapseSize && !isCollapseMode && page === 0 && entities.length > collapseSize;

  const handlePrevPage = isCollapsedPage
    ? () => {
        setCollapseMode(true);
      }
    : isAllowPrevPage
    ? () => {
        setPage(page - 1);
      }
    : null;

  const handleNextPage = isCollapseMode
    ? () => {
        setCollapseMode(false);
      }
    : isAllowNextPage
    ? () => {
        const nextPage = page + 1;

        if (nextPage < fetchedPages) {
          setPage(nextPage);
        } else {
          onLoadMore({ onSuccess: () => setPage(nextPage) });
        }
      }
    : null;

  return {
    page,
    pageSize,
    pageEntities: isCollapseMode ? pageEntities.slice(0, collapseSize) : pageEntities,
    nextElements: isCollapseMode ? pageEntities.length - collapseSize : nextElements,
    prevElements,
    handlePrevPage,
    handleNextPage,
    pageOffset,
    collapseMode: isCollapseMode,
    isCollapsedPage,
  };
};

export default usePagination;
