import React from "react";
import Box from "components/Box";
import { FlagIcon } from "icons";
import Typography from "components/Typography";
import { nameToFlag } from "utils/countries";
import { ICON_SIZES } from "theme/common";
import Input from "components/Inputs/Input";
import InputForm from "components/Inputs/Form/Input";
import SelectNew from "components/Inputs/Select";
import SelectNewForm from "components/Inputs/Form/SelectNew";
import { USA_STATES } from "constants/address";
import { useGlobalEntities } from "contexts/GlobalEntities";

const CountriesSelect = ({
  allowBlank = true,
  space,
  placeholder,
  onlyIcons = false,
  size,
  countriesOptions,
  ...props
}) => {
  const SelectComponent = props.control ? SelectNewForm : SelectNew;
  const { countries } = useGlobalEntities();

  const options = (countriesOptions ?? countries).map((country) => ({
    id: country.id,
    label: country,
  }));

  return (
    <SelectComponent
      space={{ mb: 0, ...space }}
      allowBlank={allowBlank}
      searchable
      virtualized
      size={size}
      variant="outlined"
      optionComponent={CountryLabel}
      selectedLabelComponent={onlyIcons ? OnlyIconPlaceholder : CountryPlaceholder}
      searchProperties={["name", "callingCode", "numIso", "code2"]}
      {...props}
      placeholder={<EmptyIconPlaceholder hideLabel={onlyIcons} placeholder={placeholder} />}
      options={options}
    />
  );
};

export const StatesSelect = ({ countryId, space, ...props }) => {
  const SelectComponent = props.control ? SelectNewForm : SelectNew;
  const InputComponent = props.control ? InputForm : Input;

  if (countryId === "USA") {
    return (
      <SelectComponent
        space={{ mb: 0, ...space }}
        searchable
        variant="outlined"
        {...props}
        options={USA_STATES.map((state) => ({ id: state, label: state }))}
      />
    );
  }

  return <InputComponent space={{ mb: 0, ...space }} variant="outlined" {...props} />;
};

export const CountryIcon = (name) =>
  name && nameToFlag(name)
    ? (props) => (
        <Box
          {...props}
          style={{
            ...props.style,
            fontSize: ICON_SIZES.flag,
            lineHeight: 1,
            marginBottom: -3,
          }}
        >
          {nameToFlag(name)}
        </Box>
      )
    : (props) => <FlagIcon {...props} size="xs" />;

const EmptyIconPlaceholder = ({ hideLabel, placeholder }) => {
  return <CountryPlaceholder hideLabel={hideLabel} option={{ label: { name: placeholder } }} />;
};

export const CountryPlaceholder = ({ iconSpace, option, title, hideLabel = false }) => {
  const label = option?.label?.name;
  const Icon = CountryIcon(label);

  return (
    <Box display="flex" alignItems="center">
      <Icon space={{ mr: 1, ...iconSpace }} size="small" className="placeholder" />

      <Typography style={hideLabel ? { visibility: "hidden", width: 0 } : {}}>
        {title || label}
      </Typography>
    </Box>
  );
};

const OnlyIconPlaceholder = ({ option } = {}) => <CountryPlaceholder option={option} hideLabel />;

const CountryLabel = ({ option }) => {
  const label = option?.label?.name;

  return <Typography leftIcon={CountryIcon(label)}>{label}</Typography>;
};

export default CountriesSelect;
