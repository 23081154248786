import React, { useState } from "react";
import { Drawer as MuiDrawer } from "@mui/material";
import { useDrawerStyles } from "theme/components/Drawer";
import withClasses from "components/withClasses";
import { useLayout } from "contexts/Layout";
import clsx from "clsx";
import { useOnEscEffect } from "utils/effects";
import { useOsContext } from "providers/OsProvider";

const DrawerContext = React.createContext(null);

const DrawerComponent = withClasses(
  ({ innerRef, variantProps, ...props }) => <MuiDrawer {...props} />,
  "MuiDrawer",
  {
    size: null,
  }
);

const SKIP_TAGS = ["INPUT"];

const transition = (_node, _duration) => {
  // node.style.webkitTransition = theme.transitions.create(["-webkit-transform", "width"], {
  //   easing: theme.transitions.easing.easeOut,
  //   duration: duration,
  // });
  // node.style.transition = theme.transitions.create(["-webkit-transform", "width"], {
  //   easing: theme.transitions.easing.easeOut,
  //   duration: duration,
  // });
};

const TransitionSlideProps = {
  onEntering: (node) => transition(node, "250ms"),
};

const NoTransitionSlideProsp = {
  onEntering: (node) => transition(node, "0ms"),
  onExiting: (node) => transition(node, "0ms"),
  onExit: (node) => transition(node, "0ms"),
};

export const SideDrawer = ({ open, children, ...props }) => {
  return (
    <MuiDrawer
      className={clsx({ opened: open })}
      variant="persistent"
      anchor="right"
      open={open}
      onClose={props.onClose}
      BackdropProps={{
        invisible: true,
        className: "hidden",
      }}
      {...props}
      SlideProps={{
        unmountOnExit: true,
      }}
    >
      {children}
    </MuiDrawer>
  );
};

export const Drawer = ({
  innerRef,
  height,
  children,
  size: initialSize,
  open,
  fullHeight: initialFullHeight,
  anchor = "right",
  PaperProps = {},
  transition = true,
  disableBackdropClick = true,
  disableEscapeKeyDown = false,
  hiddenBackdrop = false,
  onClose,
  position = "persistent",
  delayOpening = true,
  ...props
}) => {
  const { fullScreen, setFullScreen, toggleFullScreen } = useLayout() || {};

  const fullHeight = fullScreen || initialFullHeight;
  const size = fullScreen ? "fullScreen" : initialSize;
  const { isNotMacOS } = useOsContext();
  const classes = useDrawerStyles({ size });

  const handleClose = (event, reason) => {
    if (!open) return;
    if (disableBackdropClick && reason === "backdropClick") return false;
    if (disableEscapeKeyDown && reason === "escapeKeyDown") return false;
    if (typeof onClose === "function") onClose();
  };

  useOnEscEffect(innerRef, (event) => {
    return SKIP_TAGS.includes(event.target?.tagName) ? null : handleClose(event, "escapeKeyDown");
  });

  const sliderProps = transition ? TransitionSlideProps : NoTransitionSlideProsp;

  return (
    <DrawerComponent
      ref={innerRef}
      {...props}
      open={open}
      classes={{
        root: clsx(classes.root, { "custom-scroll": isNotMacOS }),
        modal: classes.modal,
        paper: classes.paper,
        body: classes.body,
      }}
      variant={position}
      PaperProps={{
        ...PaperProps,
        style: { ...PaperProps.style, height },
        className: clsx({ fullHeight }),
      }}
      SlideProps={{ ...sliderProps, unmountOnExit: true }}
      BackdropProps={{
        invisible: true,
        className: hiddenBackdrop ? classes.hiddenBackdrop : null,
      }}
      anchor={anchor}
      onClose={handleClose}
    >
      <DrawerProvider
        size={size}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        toggleFullScreen={toggleFullScreen}
        onClose={onClose}
      >
        {children}
      </DrawerProvider>
    </DrawerComponent>
  );
};

export const DrawerProvider = ({ children, size, onClose, fullScreen, toggleFullScreen }) => {
  const [visibleSummary, setSummary] = useState(true);

  const toggleSummary = () => setSummary((prev) => !prev);

  const context = {
    size,
    fullScreen,
    toggleFullScreen,
    visibleSummary,
    toggleSummary,
    onClose,
  };

  return <DrawerContext.Provider value={context}>{children}</DrawerContext.Provider>;
};

export const useDrawer = () => React.useContext(DrawerContext);
