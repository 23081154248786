import React, { useState } from "react";

const LayoutContext = React.createContext(null);

export const storeOpenedSidebar = (opened) => {
  window.localStorage.setItem("openedSidebar", opened ? "true" : "");
};

export const LayoutProvider = ({ children }) => {
  const defaultOpenedSidebar = Boolean(window.localStorage.getItem("openedSidebar"));
  const [openedSidebar, setOpenedSidebar] = useState(defaultOpenedSidebar);
  const [prevOpenedSidebar, setPrevOpenedSidebar] = useState(defaultOpenedSidebar);

  const [fixedOpenedSidebar, setFixedOpenedSidebar] = useState(false);
  const [hoveredSidebar, setHoveredSidebar] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const changeOpenedSidebar = (opened) => {
    setOpenedSidebar(opened);
    storeOpenedSidebar(opened);
  };

  const toggleFullScreen = () => {
    if (fullScreen && prevOpenedSidebar) {
      changeOpenedSidebar(true);
    } else {
      changeOpenedSidebar(false);
    }

    setFullScreen(!fullScreen);
  };

  const toggleOpenedSidebar = () => {
    const nextState = !openedSidebar;

    setPrevOpenedSidebar(nextState);
    changeOpenedSidebar(nextState);
    setFullScreen(false);
  };

  const toggleFixedOpenedSidebar = () => {
    const nextValue = !fixedOpenedSidebar;

    if (nextValue) {
      setFixedOpenedSidebar(nextValue);
    } else {
      setTimeout(() => {
        setFixedOpenedSidebar(nextValue);
      }, 200);
    }
  };

  const value = {
    openedSidebar,
    toggleOpenedSidebar,
    toggleFixedOpenedSidebar,
    fixedOpenedSidebar,
    hoveredSidebar,
    setHoveredSidebar,
    fullScreen,
    setFullScreen,
    toggleFullScreen,
  };

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export const useLayout = () => React.useContext(LayoutContext);
