import React from "react";
import Box from "components/Box";
import { useTableNavigationStyles } from "theme/components/TableNavigation";
import IconButton from "components/IconButton";
import Button from "components/Button";
import TitleCount from "components/TitleCount";
import {
  FullScreenIcon,
  DoubleArrowsLeftIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  ArrowUpRightIcon,
} from "icons";
import { StateFieldsContext } from "components/TablePro/Contexts/StateFieldsContext";
import { useFilter } from "containers/TableFilter/FilterProvider";
import VDivider from "components/VDivider";
import isEmpty from "lodash/isEmpty";
import { useStateFields } from "components/TablePro/Contexts/StateFieldsContext";
import { FiltersSearchMenu } from "containers/TableFilter/FiltersMenu";
import { FiltersLabelsNavigation } from "containers/TableFilter/FiltersLabels";
import { useViewsManagerContext } from "components/ViewsManager/Provider";

import SearchInput from "./SearchInput";

const TableNavigation = ({
  children,
  title,
  totalCount = 0,
  variant = "default",
  toggleSort,
  toggleFilter,
  onSearch,
  search,
  customActions = false,
  headerActions: HeaderActions,
  headerProps = {},
  onHide,
  onFullScreen,
  onManage,
  filterToggler: FilterToggler,
  disabledFilter,
  showFilterManager = true,
  showViewManager = true,
  ...props
}) => {
  const filterManager = useFilter() || null;
  const viewManager = useViewsManagerContext() || null;
  const classes = useTableNavigationStyles({ variant });
  const isStateFieldsContext = !isEmpty(React.useContext(StateFieldsContext));

  return (
    <>
      <Box className={classes.root} {...props} space={{ ...props.space }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          className={classes.leftPanel}
        >
          {title && <TitleCount title={title} totalCount={totalCount} />}

          {FilterToggler && <FilterToggler />}

          {!customActions && children}
        </Box>

        <Box className={classes.rightPanel}>
          {customActions ? (
            children
          ) : (
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {HeaderActions && isStateFieldsContext && (
                <CountLabel headerActions={HeaderActions} headerProps={headerProps} />
              )}

              {onSearch && (
                <SearchInput
                  hiddable
                  className={classes.searchInput}
                  space={{ mb: 0, mr: 0.5 }}
                  search={search}
                  onSearch={onSearch}
                />
              )}

              {showFilterManager && filterManager && <FiltersSearchMenu />}

              {showViewManager && viewManager && (
                <Button
                  size="compact"
                  variant="grey"
                  onClick={viewManager.toggleOpen}
                  space={{ mr: 1 }}
                  leftIcon={() => (viewManager.open ? <ChevronsRightIcon /> : <ChevronsLeftIcon />)}
                />
              )}

              {onManage && (
                <Button
                  size="compact"
                  variant="grey"
                  onClick={onManage}
                  space={{ mr: 1 }}
                  leftIcon={ArrowUpRightIcon}
                >
                  Manage
                </Button>
              )}

              {onHide && (
                <IconButton
                  onClick={onHide}
                  space={{ mr: 1 }}
                  size="small"
                  icon={DoubleArrowsLeftIcon}
                />
              )}

              {onFullScreen && (
                <IconButton
                  onClick={onFullScreen}
                  space={{ mr: 1 }}
                  size="small"
                  icon={FullScreenIcon}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>

      {filterManager && (
        <FiltersLabelsNavigation className={classes.filtersLabels} disabled={disabledFilter} />
      )}
    </>
  );
};

const CountLabel = ({ headerActions: HeaderActions, headerProps }) => {
  const { checkedCount } = useStateFields();

  if (checkedCount <= 0) return null;

  return (
    <>
      <HeaderActions {...headerProps} />
      <VDivider size="small" space={{ mx: 2 }} />
    </>
  );
};

export default TableNavigation;
