import React from "react";
import Input from "components/Inputs/Input";
import Checkbox from "components/Inputs/Checkbox";
import DateInput from "components/Inputs/DateInput";
import DateTimeInputComponent from "components/Inputs/DateTimeInput";
import TimeInput from "components/Inputs/TimeInput";
import ImageInput from "components/Inputs/ImageInput";
import PhoneInput from "components/Inputs/PhoneInput";
import FileInput from "components/Inputs/FileInput";
import SelectNew from "components/Inputs/Select";

import {
  BOOLEAN_COMPONENT_TYPE,
  AUTO_INCREMENT_COMPONENT_TYPE,
  STRING_COMPONENT_TYPE,
  TEXT_COMPONENT_TYPE,
  NUMBER_COMPONENT_TYPE,
  MULTI_SELECT_COMPONENT_TYPE,
  SINGLE_SELECT_COMPONENT_TYPE,
  OBJECT_COMPONENT_TYPE,
  PHONE_COMPONENT_TYPE,
  EMAIL_COMPONENT_TYPE,
  HYPERLINK_COMPONENT_TYPE,
  DATE_COMPONENT_TYPE,
  TIME_COMPONENT_TYPE,
  DATE_TIME_COMPONENT_TYPE,
  MEDIA_COMPONENT_TYPE,
  FORMULA_COMPONENT_TYPE,
  TABLE_CONNECTOR_COMPONENT_TYPE,
} from "./types";

export const ComponentTypeInput = ({
  name,
  type,
  value,
  onChange,
  disabled,
  config = {},
  variant = "outlined",
  ...props
}) => {
  const Input = COMPONENT_TYPES_INPUTS[type];

  if (!Input) {
    return (
      <>
        <p>No inputs</p>
        <p>Component type:</p>
        <p>{type?.replaceAll("_", " ")}</p>
      </>
    );
  }

  return (
    <Input
      space={{ mb: 0 }}
      name={name}
      type={type}
      disabled={disabled}
      placeholder={"Add value..."}
      variant={variant}
      value={value ?? ""}
      onChange={onChange}
      config={config}
      {...props}
    />
  );
};

// Inputs

export const StringInput = Input;

export const TextInput = ({ type, config = {}, ...props }) => (
  <Input
    {...props}
    type="text"
    inputProps={{
      min: config.minValue,
      max: config.maxValue,
    }}
  />
);

export const NumberInput = ({ type, config = {}, ...props }) => (
  <Input
    {...props}
    type="number"
    inputProps={{
      step: config.step,
      min: config.minValue,
      max: config.maxValue,
    }}
  />
);

export const DATE_TIME_COMPONENT_INPUTS = {
  [DATE_TIME_COMPONENT_TYPE]: DateTimeInputComponent,
  [DATE_COMPONENT_TYPE]: DateInput,
  [TIME_COMPONENT_TYPE]: TimeInput,
};

export const DateTimeInput = ({ type, config = {}, ...props }) => {
  const Component = DATE_TIME_COMPONENT_INPUTS[type];

  return <Component {...props} format={config.format} />;
};

export const CheckboxInput = ({ type, config, ...props }) => {
  return <Checkbox {...props} />;
};

export const MediaInput = ({ type, config = {}, ...props }) => {
  const MediaComponent = config.isImage ? ImageInput : FileInput;

  return <MediaComponent {...props} />;
};

export const ListInput = ({
  type,
  config = {},
  selectComponent: SelectComponent = SelectNew,
  ...props
}) => {
  return (
    <SelectComponent
      optionVariant="label"
      variant="outlined"
      {...props}
      options={config.options ?? props.options}
      multiple={config.multiple ?? props.multiple}
    />
  );
};

const MultiListInput = (props) => <ListInput {...props} multiple />;

export const COMPONENT_TYPES_INPUTS = {
  [BOOLEAN_COMPONENT_TYPE]: CheckboxInput,
  [TEXT_COMPONENT_TYPE]: TextInput,
  [NUMBER_COMPONENT_TYPE]: NumberInput,
  [MULTI_SELECT_COMPONENT_TYPE]: MultiListInput,
  [SINGLE_SELECT_COMPONENT_TYPE]: ListInput,
  [STRING_COMPONENT_TYPE]: StringInput,
  [PHONE_COMPONENT_TYPE]: PhoneInput,
  [EMAIL_COMPONENT_TYPE]: StringInput,
  [HYPERLINK_COMPONENT_TYPE]: StringInput,
  [DATE_TIME_COMPONENT_TYPE]: DateTimeInput,
  [DATE_COMPONENT_TYPE]: DateTimeInput,
  [TIME_COMPONENT_TYPE]: DateTimeInput,
  [MEDIA_COMPONENT_TYPE]: MediaInput,

  // Dynamic types
  [AUTO_INCREMENT_COMPONENT_TYPE]: null,
  [FORMULA_COMPONENT_TYPE]: null,
  [OBJECT_COMPONENT_TYPE]: null,
  [TABLE_CONNECTOR_COMPONENT_TYPE]: null,
};
