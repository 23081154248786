import { delegate, memoize } from "utils/entities";
import keyBy from "lodash/keyBy";
import compact from "lodash/compact";

import { Table } from "../Table";

export class SecondaryLink {
  constructor(origin, table) {
    this.table = table;
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);
    this.linkType = "secondary";

    delegate(this, origin, ["mapping", "secondaryTable"]);
  }

  get label() {
    return this.secondaryTable.name;
  }

  get secondaryTable() {
    return new Table(this.origin.secondaryTable);
  }

  get mapping() {
    return this.origin.mapping || [];
  }

  get mappingColumnsIdsMap() {
    return this.memoize("mappingColumnsIdsMap", () => {
      return this.mapping.reduce(
        (result, field) => ({ ...result, [field.primaryColumnId]: field.secondaryColumnId }),
        {}
      );
    });
  }

  get mappingColumns() {
    return this.memoize("mappingColumns", () => {
      const mappingFiedsMap = keyBy(this.mapping, "secondaryColumnId");
      const primaryColumnMap = keyBy(this._primaryTable.columns, "id");
      const secondaryColumnMap = keyBy(this.secondaryTable.columns, "id");

      const mappingFields = this.mapping.map((field) => {
        const secondaryColumn = secondaryColumnMap[field.secondaryColumnId];

        if (!secondaryColumn) return null;

        return {
          primaryColumn: primaryColumnMap[field.primaryColumnId],
          secondaryColumn,
          hidden: field.hidden,
          lookup: field.lookup,
          dynamic: field.dynamic,
          overwrite: field.overwrite,
        };
      });

      const componentFields = this.secondaryTable.columns.map((secondaryColumn) => {
        const mappingField = mappingFiedsMap[secondaryColumn.id];

        if (mappingField) return null;

        return {
          secondaryColumn,
          primaryColumn: null,
          hidden: true,
          lookup: false,
          dynamic: false,
          overwrite: false,
        };
      });

      return compact([...mappingFields, ...componentFields]);
    });
  }

  // PRIVATE

  get _primaryTable() {
    return new Table(this.table);
  }
}
