import { memoize } from "utils/entities";
import { genRanHex } from "utils/string";
import compact from "lodash/compact";

import { LEFT_LABEL_FORMAT } from "models/abstract/LayoutStructure";
import { BuilderItem } from "models/abstract/BuilderItem";
import { MappingPath } from "models/abstract/MappingPath";

export class ListStructure {
  constructor({ tableView }) {
    this.tableView = tableView;
    this.cache = {};
    this.memoize = memoize(this.cache);
  }

  get builderStructure() {
    return this.memoize("builderStructure", () => {
      return buildDefaultBuilderStructure({
        tableColumns: this.tableView.viewTableColumns,
        subLists: this.tableView.tableStructure.subLists,
        secondaryLinks: this.tableView.tableStructure.secondaryLinks,
        channelLinks: this.tableView.tableStructure.channelLinks,
        consolidatedLink: this.tableView.tableStructure.consolidatedLink,
      });
    });
  }

  get tableStructure() {
    return this.tableView.tableStructure;
  }

  get checksum() {
    return this.tableView.viewTableColumns.map((field) => field.id).join(".");
  }

  get name() {
    return this.tableView.name;
  }

  get tableId() {
    return this.tableView.tableId;
  }

  get tableViewId() {
    return this.tableView.id;
  }

  get tableName() {
    return this.tableView.tableName;
  }

  get builderItems() {
    const items = compact(
      [
        this.tableView.viewTableColumns,
        this.tableView.tableStructure.subLists,
        this.tableView.tableStructure.secondaryLinks,
        this.tableView.tableStructure.channelLinks,
        this.tableView.tableStructure.consolidatedLink,
      ].flat()
    );

    return items.map((item) => new BuilderItem({ entity: item }));
  }

  get formFields() {
    return this.memoize("formFields", () => {
      return this.tableView.viewTableColumns.map((column) => column.formField);
    });
  }

  get columnsCount() {
    return this.tableView.viewTableColumns.length;
  }

  get listTypeColumnsFieldIds() {
    return this._listTypeColumns.map((column) => column.fieldId);
  }

  // PRIVATE

  get _listTypeColumns() {
    return this.tableView.viewTableColumns.filter((column) => column.fieldType.isList);
  }
}

const generateUid = () => genRanHex(30);

const buildDefaultBuilderStructure = ({
  tableColumns,
  subLists,
  secondaryLinks,
  channelLinks,
  consolidatedLink,
}) => {
  const halfLength = Math.ceil(tableColumns.length / 2);
  const firstChunkItems = tableColumns.slice(0, halfLength);
  const secondChunkItems = tableColumns.slice(halfLength, tableColumns.length);

  let builderStructure = [
    {
      id: generateUid(),
      fieldLabel: LEFT_LABEL_FORMAT,
      containers: [
        {
          id: generateUid(),
          items: firstChunkItems.map((item) => ({ path: MappingPath.fromEntityToItemPath(item) })),
        },
        {
          id: generateUid(),
          items: secondChunkItems.map((item) => ({ path: MappingPath.fromEntityToItemPath(item) })),
        },
      ],
    },
  ];

  const buildLinkSection = (title, tableLinks) => ({
    id: generateUid(),
    title: title,
    useTitle: true,
    containers: [
      {
        id: generateUid(),
        items: tableLinks.map((item) => ({ path: MappingPath.fromEntityToItemPath(item) })),
      },
    ],
  });

  if (subLists.length > 0) {
    builderStructure.push(buildLinkSection("Sub-lists", subLists));
  }

  if (secondaryLinks.length > 0) {
    builderStructure.push(buildLinkSection("Secondary links", secondaryLinks));
  }

  if (channelLinks.length > 0) {
    builderStructure.push(buildLinkSection("Channel links", channelLinks));
  }

  if (consolidatedLink) {
    builderStructure.push(buildLinkSection("Consolidated link", [consolidatedLink]));
  }

  return builderStructure;
};
