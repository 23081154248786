import React, { useEffect } from "react";
import Box from "components/Box";
import { FieldsLoader } from "components/Loaders";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";
import LabelsGrid from "components/LabelsGrid";
import { useSearchFieldsForObject } from "models/Field/queries";
import { FormField } from "models/abstract/FormField";
import { FormFieldInput } from "containers/FormField/Input";
import { Drawer } from "components/Drawer";
import { DrawerLayout } from "components/Drawer/Layout";
import { DrawerSidebar } from "components/Drawer/Sidebar";

const LABELS_GRID_PROPS = { minWidth: 130, offsetSize: "small" };

const ObjectInputContent = ({ formField, value, onCancel, onChange, onSave }) => {
  const { objectFormFields, loading } = useObjectFormFields(formField);

  const valueMap = keyBy(value, "columnId");

  const handleFieldChange = (index, itemValue) => {
    const newItemValue = itemValue?.target ? itemValue.target.value : itemValue;
    const columnId = objectFormFields[index].id;

    const newValue = Object.assign({}, valueMap, {
      [columnId]: buildObjectFieldValue(objectFormFields[index], newItemValue),
    });

    onChange(Object.values(newValue));
  };

  useEffect(() => {
    if (loading || !isEmpty(value)) return;

    onChange(buildDefaultObjectValues(objectFormFields));
  }, [loading]);

  return (
    <DrawerLayout
      isEdit
      nested
      onDiscard={onCancel}
      onSave={onSave}
      headerProps={{
        title: "Customize column properties",
        icon: () => <DrawerSidebar.BackButton onClick={onCancel} />,
        hideNavigation: true,
      }}
    >
      <DrawerSidebar.Content fullWidth>
        <LabelsGrid {...LABELS_GRID_PROPS}>
          {loading && <FieldsLoader fields={objectFormFields} />}

          {!loading &&
            objectFormFields.map((formFieldItem, index) => (
              <Box
                fullWidth
                key={`${index}-${formFieldItem.name}`}
                label={formFieldItem.label}
                labelIcon={formFieldItem.icon}
              >
                <FormFieldInput
                  fullWidth
                  name={formFieldItem.name}
                  formField={formFieldItem}
                  onChange={(value) => handleFieldChange(index, value)}
                  value={valueMap[formFieldItem.name]?.value}
                />
              </Box>
            ))}
        </LabelsGrid>
      </DrawerSidebar.Content>
    </DrawerLayout>
  );
};

const buildObjectFieldValue = (formFieldItem, value) => ({
  columnId: formFieldItem.id,
  type: formFieldItem.type,
  value,
});

const buildDefaultObjectValues = (formFields) => {
  return formFields.map((formFieldItem) =>
    buildObjectFieldValue(formFieldItem, formFieldItem.initialFormValue)
  );
};

export const useObjectFormFields = (formField) => {
  const { fields = [], loading } = useSearchFieldsForObject();
  const fieldsMap = keyBy(fields, "id");
  const configFields = formField.config.fields;

  const objectFormFields = configFields.reduce((result, item) => {
    const fieldFormField = fieldsMap[item.fieldId]?.formField;

    if (!fieldFormField) return result;

    if (formField.id === formField.fieldId) {
      return [...result, fieldFormField];
    }

    const nestedFormField = new FormField({
      id: item.columnId,
      name: item.columnId,
      fieldId: item.fieldId,
      type: fieldFormField.type,
      subtype: fieldFormField.subtype,
      config: fieldFormField.config,
      placeholder: fieldFormField.placeholder,
      label: fieldsMap[item.fieldId]?.name || "Can't fetch name",
    });

    return [...result, nestedFormField];
  }, []);

  return { objectFormFields, loading };
};

export const ObjectInputDrawer = ({ open, onClose, ...props }) => {
  return (
    <Drawer
      position="temporary"
      PaperProps={{ style: { boxShadow: "none" } }}
      size="small"
      hiddenBackdrop
      open={open}
      onClose={onClose}
      fullHeight
    >
      <ObjectInputContent {...props} onClose={onClose} />
    </Drawer>
  );
};
