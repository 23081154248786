import { gql } from "@apollo/client";
import { delegate } from "utils/entities";

export class FieldSelect {
  constructor(origin) {
    this.origin = origin;

    delegate(this, origin);
  }
}

FieldSelect.fragment = gql`
  fragment FieldSelectFragment on GetFieldPayload {
    id
    name
    type
  }
`;
