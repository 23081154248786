import React from "react";
import DateTimePicker from "components/DatePickers/DateTimePicker";
import Input from "components/Inputs/Input";
import pick from "lodash/pick";
import moment from "moment";
import { FORMATS } from "utils/time";

const DateTimeInput = ({
  readOnly,
  value,
  onChange,
  fullWidth,
  space,
  size,
  variant,
  onClose,
  format = FORMATS.dateTime,
  placeholder,
  ...props
}) => {
  const inputProps = pick(props, [
    "error",
    "invalid",
    "isDirty",
    "isTouched",
    "label",
    "showError",
  ]);

  const dateTime = value ? Number(moment(value).valueOf()) : null;

  const handleChange = (newValue) => {
    onChange && onChange(Number(newValue.valueOf()));
  };

  return (
    <DateTimePicker
      readOnly={readOnly}
      value={dateTime}
      onChange={handleChange}
      onClose={onClose}
      format={format}
      renderInput={(dateProps) => {
        return (
          <Input
            {...dateProps}
            {...inputProps}
            fullWidth={fullWidth}
            variant={variant}
            space={{ mb: 0, ...space }}
            size={size}
            placeholder={placeholder || dateProps.placeholder}
          />
        );
      }}
    />
  );
};

export default DateTimeInput;
