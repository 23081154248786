import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import clsx from "clsx";

import { TableRowCells } from "./TableRowCells";
import { useTablePro } from "../Provider";
import { TableRowStub } from "./TableRowsStub";
import { useDragDrop } from "./DraggableTableRows";

export const TableProRow = ({ rowIndex, draggableProps }) => {
  const {
    withDescendants,
    classes,
    columns,
    entities,
    widthMap,
    onChange,
    onSave,
    onRemove,
    onDragDropRow,
    errorsById,
    errors,
    openedDescendantsByDefault,
    checkboxColumn,
  } = useTablePro();

  const entity = entities[rowIndex];

  if (!entity || entity?.loadingSkeleton) {
    return (
      <TableRowStub classes={classes} rowIndex={rowIndex} widthMap={widthMap} columns={columns} />
    );
  }

  return (
    <TableRow
      collapseLevel={withDescendants ? 1 : 0}
      openedDescendantsByDefault={openedDescendantsByDefault}
      nestedLevel={0}
      rowIndex={rowIndex}
      columns={columns}
      entity={entity}
      widthMap={widthMap}
      classes={classes}
      onChange={onChange}
      onSave={onSave}
      onRemove={onRemove}
      onDragDropRow={onDragDropRow}
      errorsById={errorsById}
      errors={errors}
      draggableProps={draggableProps}
      checkboxColumn={checkboxColumn}
    />
  );
};

const TableRow = ({
  collapseLevel,
  nestedLevel = 0,
  rowIndex,
  columns,
  entity,
  widthMap,
  classes,
  onChange,
  onSave,
  onRemove,
  onDragDropRow,
  errorsById,
  errors,
  draggableProps,
  openedDescendantsByDefault,
  checkboxColumn,
}) => {
  const [collapse, setCollapse] = useState(openedDescendantsByDefault ? false : true);
  const withDescendants = !isEmpty(entity.descendants);
  const draggableRow = onDragDropRow && !withDescendants;
  const TableRowComponent = draggableRow ? TableDraggableRowComponent : "div";
  const rowProps = draggableRow ? { entityId: entity.id, rowIndex, onDragDropRow } : {};

  const columnViewable = checkboxColumn?.viewable || false;
  const columnCommentable = checkboxColumn?.commentable || false;

  return (
    <>
      <TableRowComponent {...rowProps} className={classes.row}>
        <TableRowCells
          collapseLevel={collapseLevel}
          nestedLevel={nestedLevel}
          rowIndex={rowIndex}
          columns={columns}
          entity={entity}
          widthMap={widthMap}
          classes={classes}
          onChange={onChange}
          onSave={onSave}
          onRemove={onRemove}
          errorsById={errorsById}
          errors={errors}
          draggableProps={draggableProps}
          collapsed={withDescendants ? collapse : null}
          setCollapse={withDescendants ? setCollapse : null}
          viewable={columnViewable}
          commentable={columnCommentable}
        />
      </TableRowComponent>

      {!collapse && withDescendants && (
        <div>
          {entity.descendants.map((nestedEntity, nestedRowIndex) => (
            <TableRow
              key={nestedEntity.id}
              collapseLevel={collapseLevel}
              openedDescendantsByDefault={openedDescendantsByDefault}
              onDragDropRow={onDragDropRow}
              nestedLevel={nestedLevel + 1}
              rowIndex={nestedRowIndex}
              columns={columns}
              entity={nestedEntity}
              widthMap={widthMap}
              classes={classes}
              errorsById={errorsById}
              errors={errors}
              onChange={onChange}
              onSave={onSave}
              onRemove={onRemove}
            />
          ))}
        </div>
      )}
    </>
  );
};

const TableDraggableRowComponent = ({ rowIndex, onDragDropRow, entityId, ...props }) => {
  const { dragActive, ...dragProps } = useDragDrop({
    onDragDrop: onDragDropRow,
    rowIndex,
    entityId,
  });

  return <div {...props} className={clsx(props.className, { dragActive })} {...dragProps} />;
};
