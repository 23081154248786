import isEmpty from "lodash/isEmpty";
import { OBJECT_TYPE, PHONE_NUMBER_SUB_TYPE } from "models/abstract/FieldType";
import { formFieldMutatiomInputValue } from "containers/toMutationValues";

export const parseEntityValue = (entityId, value, entitiesMap, mutationFiles) => {
  const fieldType = entitiesMap[entityId]?.fieldType;
  const fieldValue = parseValue({ pathId: entityId, value, entitiesMap, mutationFiles });

  if (fieldType?.type === OBJECT_TYPE && fieldValue.length > 0) {
    return fieldValue.map((valueItem) => ({
      id: valueItem.columnId,
      value: valueItem.value,
    }));
  }

  if (fieldValue === undefined) {
    return [];
  }

  return [{ id: entityId, value: fieldValue }];
};

const parseValue = ({ pathId, value, entitiesMap, mutationFiles }) => {
  if (!entitiesMap[pathId]) return value;

  const fieldType = entitiesMap[pathId].fieldType;
  const parsedValue = formFieldMutatiomInputValue(fieldType.type, value, mutationFiles);

  if (fieldType.subtype === PHONE_NUMBER_SUB_TYPE && isEmpty(parsedValue.value)) {
    return "";
  }

  return parsedValue;
};
