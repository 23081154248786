import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { humanize } from "utils/string";
import { MappingLinkIcon } from "icons";
import Link from "components/Link";
import TableCellContainer from "components/TablePro/Components/TableCellContainer";
import { TypographyBoolean } from "components/Typography";
import Label from "components/Label";

import Typography from "./CellTypography";
import CheckboxLabel from "../HeaderCells/CheckboxLabel";
import CheckboxCell from "./CheckboxCell";

export const DefaultCell = TableCellContainer(({ value, children }) => (
  <Typography hiddableContent>{children || value?.toString()}</Typography>
));

export const TitleCell = TableCellContainer(({ value }) => (
  <Typography hiddableContent>{humanize(value)}</Typography>
));

export const LabelCell = TableCellContainer(({ value }) => (
  <Label size="small" color="grey">
    {humanize(value)}
  </Label>
));

export const SkeletonCell = TableCellContainer(() => (
  <Skeleton variant="rect" height="14px" width="35%" />
));

export const BooleanCell = TableCellContainer(({ value }) => <TypographyBoolean value={value} />);

export const IconCell = TableCellContainer(({ tableColumn }) => {
  const Icon = tableColumn.icon;

  return <Icon size={tableColumn.size || "medium"} />;
});

export const LinkCell = TableCellContainer(({ tableColumn, entity, value, children }) => {
  return (
    <Link to={tableColumn.to({ entity })}>
      <Typography hiddableContent>{children || value?.toString()}</Typography>
    </Link>
  );
});

export const LINK_COLUMN = {
  id: "",
  unSort: true,
  label: "",
  component: IconCell,
  icon: MappingLinkIcon,
  size: "large",
  width: 40,
};

export const CHECKBOX_COLUMN_ID = "CHECKBOX";
export const EDIT_COLUMN_ID = "EDIT";

export const CheckboxColumn = ({ multiSelectable, viewable, commentable, editable, draggable }) => {
  let width = 36 + 15;
  if (draggable) width += 36;

  return {
    id: CHECKBOX_COLUMN_ID,
    unSort: true,
    align: "left",
    label: CheckboxLabel,
    component: CheckboxCell,
    width: width,
    multiSelectable,
    viewable,
    commentable,
    editable,
    draggable,
  };
};
