import { delegate, memoize } from "utils/entities";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import { timeString } from "utils/time";
import { inputStep } from "utils/input";
import { ComponentTypeYup } from "./ComponentTypeYup";
import { Item } from "models/ListItem/Item";

import {
  FieldType,
  NUMBER_DOT_SEPARATOR_TYPE,
  NUMBER_COMMA_SEPARATOR_TYPE,
  DECIMAL_SUB_TYPE,
  EMAIL_ADDRESS_SUB_TYPE,
  HYPERLINK_SUB_TYPE,
  PHONE_NUMBER_SUB_TYPE,
  NUMBER_TYPE,
  CHECKBOX_TYPE,
  SINGLE_LINE_TEXT_TYPE,
  MULTI_LINE_TEXT_TYPE,
  DATETIME_TYPE,
  LIST_TYPE,
  DATE_SUB_TYPE,
  TIME_SUB_TYPE,
  DATETIME_SUB_TYPE,
  MEDIA_TYPE,
  OBJECT_TYPE,
  AUTO_INCREMENT_TYPE,
  TABLE_CONNECTOR_TYPE,
  FORMULA_TYPE,
  DYNAMIC_SUB_TYPE,
  isFileMediaFormat,
} from "./FieldType";

import {
  BOOLEAN_COMPONENT_TYPE,
  AUTO_INCREMENT_COMPONENT_TYPE,
  STRING_COMPONENT_TYPE,
  TEXT_COMPONENT_TYPE,
  NUMBER_COMPONENT_TYPE,
  MULTI_SELECT_COMPONENT_TYPE,
  SINGLE_SELECT_COMPONENT_TYPE,
  OBJECT_COMPONENT_TYPE,
  PHONE_COMPONENT_TYPE,
  EMAIL_COMPONENT_TYPE,
  HYPERLINK_COMPONENT_TYPE,
  DATE_COMPONENT_TYPE,
  TIME_COMPONENT_TYPE,
  DATE_TIME_COMPONENT_TYPE,
  MEDIA_COMPONENT_TYPE,
  FORMULA_COMPONENT_TYPE,
  TABLE_CONNECTOR_COMPONENT_TYPE,
} from "components/ComponentType/types";

export class FormField {
  constructor({
    id,
    name,
    fieldId,
    label,
    disabled = false,
    icon = null,
    required = false,
    blank = false,
    lookup = false,
    type,
    subtype,
    editable = false,
    config = {},
    placeholder,
  }) {
    const origin = {
      id,
      name,
      fieldId,
      label,
      disabled,
      icon,
      required,
      blank,
      lookup,
      type,
      subtype,
      editable,
      config,
      placeholder,
    };

    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, origin, ["editable"]);
  }

  get key() {
    return this._fieldType.key;
  }

  get isDynamicList() {
    return (
      this.type === LIST_TYPE &&
      this.config.tableId &&
      this.config.columnId &&
      this.subtype === DYNAMIC_SUB_TYPE
    );
  }

  get hasInitialValue() {
    if ([EMAIL_ADDRESS_SUB_TYPE, PHONE_NUMBER_SUB_TYPE].includes(this.subtype)) return false;

    if (this.type === LIST_TYPE) return !isEmpty(this.config.defaultValue);

    return Object.prototype.hasOwnProperty.call(this.config, "initialValue");
  }

  get initialValue() {
    if (!this.hasInitialValue) return null;

    if (this.type === LIST_TYPE) return this.parseValue(this.config.defaultValue);

    return this.parseValue(this.config.initialValue);
  }

  get initialFormValue() {
    return buildInitialFormValue(this);
  }

  parseValue(value) {
    return parseTypeValue({
      type: this.type,
      subtype: this.subtype,
      config: this.config,
      value,
    });
  }

  get inputName() {
    return this.name || this.id;
  }

  get editable() {
    return this._fieldType.isEditableType && this.origin.editable;
  }

  get yup() {
    return this._componentTypeYup.yup;
  }

  get componentType() {
    return fetchComponentType({ type: this.type, subtype: this.subtype, config: this.config });
  }

  get componentTypeConfig() {
    return buildComponentInputConfig(this);
  }

  // PRIVATE

  get _fieldType() {
    return new FieldType({ type: this.type, subtype: this.subtype });
  }

  get _componentTypeYup() {
    return new ComponentTypeYup({
      componentType: this.componentType,
      componentTypeConfig: this.componentTypeConfig,
      required: this.required,
      editable: this.editable,
    });
  }
}

const parseTypeValue = ({ type, subtype, value, config }) => {
  if (!value) return value;

  let primaryColumnIds = [];

  switch (type) {
    case MEDIA_TYPE:
      return value?.filename || value?.name;
    case NUMBER_TYPE:
      if (subtype === DECIMAL_SUB_TYPE) {
        return `${parseFloat(value)
          .toFixed(config.precision)
          .replace(".", NUMBER_SEPARATORS[config.separator] || ".")}`;
      }

      return parseInt(value);
    case SINGLE_LINE_TEXT_TYPE:
      if (subtype === PHONE_NUMBER_SUB_TYPE) {
        return [value?.countryId, value?.value].join(" ");
      }

      return value;
    case MULTI_LINE_TEXT_TYPE:
      return value;
    case DATETIME_TYPE:
      return timeString(value, config.format);
    case CHECKBOX_TYPE:
      return Boolean(value);
    case OBJECT_TYPE:
      if (isEmpty(value)) return null;

      return Object.values(value)
        .map((item) => parseTypeValue({ type: item.type, config: {}, value: item.value }))
        .join(", ");
    case TABLE_CONNECTOR_TYPE:
      if (isEmpty(value)) return null;

      primaryColumnIds = config.columns.filter((item) => item.primary).map((item) => item.id);

      return compact([value].flat())
        .map((item) => Item.getListItemTitle({ item, columnIds: primaryColumnIds }))
        .join(", ");
    case LIST_TYPE:
      return compact([value].flat())
        .map((item) => item.value)
        .join(", ");
    default:
      return value;
  }
};

const NUMBER_SEPARATORS = {
  [NUMBER_DOT_SEPARATOR_TYPE]: ".",
  [NUMBER_COMMA_SEPARATOR_TYPE]: ",",
};

export const fetchComponentType = ({ type, subtype, config }) => {
  switch (type) {
    case NUMBER_TYPE:
      return NUMBER_COMPONENT_TYPE;

    case SINGLE_LINE_TEXT_TYPE:
      if (subtype === PHONE_NUMBER_SUB_TYPE) return PHONE_COMPONENT_TYPE;
      if (subtype === EMAIL_ADDRESS_SUB_TYPE) return EMAIL_COMPONENT_TYPE;
      if (subtype === HYPERLINK_SUB_TYPE) return HYPERLINK_COMPONENT_TYPE;
      return STRING_COMPONENT_TYPE;

    case MULTI_LINE_TEXT_TYPE:
      return TEXT_COMPONENT_TYPE;

    case DATETIME_TYPE:
      if (subtype === DATE_SUB_TYPE) return DATE_COMPONENT_TYPE;
      if (subtype === TIME_SUB_TYPE) return TIME_COMPONENT_TYPE;
      if (subtype === DATETIME_SUB_TYPE) return DATE_TIME_COMPONENT_TYPE;
      throw `Unsupported field view subtype: ${subtype}`;

    case CHECKBOX_TYPE:
      return BOOLEAN_COMPONENT_TYPE;

    case LIST_TYPE:
      return config.isMultiple ? MULTI_SELECT_COMPONENT_TYPE : SINGLE_SELECT_COMPONENT_TYPE;

    case MEDIA_TYPE:
      return MEDIA_COMPONENT_TYPE;

    case OBJECT_TYPE:
      return OBJECT_COMPONENT_TYPE;

    case AUTO_INCREMENT_TYPE:
      return AUTO_INCREMENT_COMPONENT_TYPE;

    case TABLE_CONNECTOR_TYPE:
      return TABLE_CONNECTOR_COMPONENT_TYPE;

    case FORMULA_TYPE:
      return FORMULA_COMPONENT_TYPE;

    default:
      throw `Unsupported field view type: ${type}`;
  }
};

const buildInitialFormValue = (formField) => {
  if (!formField.hasInitialValue) return null;

  if (formField.type === LIST_TYPE) {
    return [formField.config.defaultValue].flat().map((item) => ({
      id: item.fieldItemId || item.value,
      value: item.value,
    }));
  }

  return formField.config.initialValue;
};

const buildComponentInputConfig = (formField) => {
  let config = { defaultValue: formField.initialFormValue };

  switch (formField.type) {
    case NUMBER_TYPE:
      config = {
        ...config,
        type: "number",
        step: inputStep(formField.config.precision || 0),
        minValue: formField.config.range?.minValue,
        maxValue: formField.config.range?.maxValue,
      };
      break;

    case SINGLE_LINE_TEXT_TYPE:
      config = {
        ...config,
        minValue: formField.config.minLength,
        maxValue: formField.config.maxLength,
      };
      break;

    case MULTI_LINE_TEXT_TYPE:
      config = {
        ...config,
        minValue: formField.config.minLength,
        maxValue: formField.config.maxLength,
      };
      break;

    case DATETIME_TYPE:
      config = {
        ...config,
        minValue: formField.config.minValue,
        maxValue: formField.config.maxValue,
        format: formField.config.format,
      };
      break;

    case MEDIA_TYPE:
      config = {
        ...config,
        isImage: !!(formField.config.formats || []).find((format) => isFileMediaFormat(format)),
        minValue: formField.config.minValue,
        maxValue: formField.config.maxValue,
      };
      break;
    default:
      return config;
  }

  return config;
};
