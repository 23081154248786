import React, { useState, useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { ArrowLeftIcon, ArrowRightIcon, ArrowDownIcon, BellIcon } from "icons";
import moment from "moment";
import { FORMATS } from "utils/time";

const fetchInputProps = (props) => {
  const { ref, inputProps, label, InputProps, ...rest } = props;

  return { inputRef: ref, ...inputProps, type: null, ...rest };
};

const TimePickerInput = ({ value, renderInput, onChange, onClose, format }) => {
  const [open, setOpen] = useState(false);
  const [triggerOnClose, setTriggerOnClose] = useState(false);

  const handleClose = () => {
    if (!open) return;

    setOpen(false);
    setTriggerOnClose(true);
  };

  useEffect(() => {
    if (!triggerOnClose) return;

    onClose && onClose();
    setTriggerOnClose(false);
  }, [triggerOnClose]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        disableCloseOnSelect={true}
        components={{
          LeftArrowIcon: ArrowLeftIcon,
          RightArrowIcon: ArrowRightIcon,
          SwitchViewIcon: ArrowDownIcon,
        }}
        timeIcon={<BellIcon />}
        label={null}
        clearable={true}
        open={open}
        value={value}
        onClose={handleClose}
        onChange={(newValue) => {
          onChange && onChange(newValue);
        }}
        renderInput={(props) => {
          const inputProps = fetchInputProps(props);

          return renderInput({
            ...inputProps,
            onClick: () => setOpen(true),
            value: inputProps.value
              ? moment(inputProps.value, [FORMATS.time]).format(format)
              : inputProps.value,
          });
        }}
      />
    </LocalizationProvider>
  );
};

export default TimePickerInput;
