import React, { useState } from "react";
import Button from "components/Button";
import { buildInitialState } from "components/DndBuilder/Provider";
import { Preview } from "components/DndBuilder/components/Preview";
import { LayoutStructure } from "models/abstract/LayoutStructure";
import { TABLE_COLUMN_MODEL_TYPE, FIELD_MODEL_TYPE } from "models/types";
import { BuilderItemFormField } from "containers/FormLayout/BuilderItemComponent";
import { useBuilderFormLayout } from "containers/FormLayout/BuilderFormLayoutProvider";
import { FolderFillIcon } from "icons";

import { FormActionFieldInput } from "./FormActionFieldInput";
import { FormContainerTable } from "./FormContainerTable";
import { useExecuteFormContext } from "../form/useExecuteFormAction";

const DISABLED_FILTER_MODE = "DISABLED_FILTER_MODE";
const ENABLED_FOR_VIEW_FILTER_MODE = "ENABLED_FOR_VIEW_FILTER_MODE";
const ENABLED_FOR_ALL_FILTER_MODE = "ENABLED_FOR_ALL_FILTER_MODE";

export const FILTER_MODE_OPTIONS = [
  { id: DISABLED_FILTER_MODE, label: "Disabled", description: "Disable filters" },
  {
    id: ENABLED_FOR_VIEW_FILTER_MODE,
    label: "Enabled for View",
    description: "Apply filters for view only",
  },
  {
    id: ENABLED_FOR_ALL_FILTER_MODE,
    label: "Enabled for All",
    description: "Apply filters for view and actions",
  },
];

const FormPropertiesContext = React.createContext(null);
export const useFormProperties = () => React.useContext(FormPropertiesContext);

export const FormInputsLayout = ({ loading }) => {
  const [filterMode, setFilterMode] = useState(ENABLED_FOR_VIEW_FILTER_MODE);
  const [viewState, setViewState] = useState({ filter: [], sort: [] });

  const { builderStructure, builderItems } = useBuilderFormLayout();
  const { control, getValues, clearErrors, formState, formLayout, handleSubmit, setValue } =
    useExecuteFormContext();

  const actions = formLayout?.actions || [];

  const values = getValues();

  const builderState = buildInitialState(builderStructure);
  const sectionsProps = LayoutStructure.fetchSectionsProps(builderStructure);
  const containersProps = LayoutStructure.fetchContainersProps(builderStructure);

  const { sections, containers, items } = builderState || {};
  const allItemsMap = builderItems.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
  const valueColumnsList = builderItems.map((builderItem) => builderItem.entity.id);

  const submitActionForm = ({ action, additionalProps }) => {
    handleSubmit({
      allItemsMap,
      actionId: action.id,
      additionalProps,
      filters: filterMode === ENABLED_FOR_ALL_FILTER_MODE ? viewState.filter || [] : [],
    })();
  };

  const onSubmitAction = ({ action }) => {
    submitActionForm({ action });
  };

  const formProps = {
    formState,
    setValue,
    control,
    loading,
    values,
    clearErrors,
    valueColumnsList,
    viewState,
    setViewState,
    filterMode,
    setFilterMode,
    disabledFilter: filterMode === DISABLED_FILTER_MODE,
  };

  const actionsProps = {
    onSubmit: onSubmitAction,
  };

  return (
    <FormPropertiesContext.Provider value={formProps}>
      <Preview
        isExecutableForm
        title={formLayout?.name}
        icon={() => <FolderFillIcon space={{ mr: 1 }} size="large" color="dark" />}
        loading={loading}
        sections={sections}
        components={COMPONENTS}
        sectionsProps={sectionsProps}
        containersProps={containersProps}
        actionsProps={actionsProps}
        allItemsMap={allItemsMap}
        containers={containers}
        items={items}
        actions={actions}
      />
    </FormPropertiesContext.Provider>
  );
};

const FormFieldItem = ({ builderItem, sectionProps }) => {
  if (
    builderItem?.modelType === TABLE_COLUMN_MODEL_TYPE ||
    builderItem?.modelType === FIELD_MODEL_TYPE
  ) {
    return <FieldElement builderItem={builderItem} sectionProps={sectionProps} />;
  }

  return <div>{`Unparsed ${builderItem?.id} - ${builderItem?.modelType}`}</div>;
};

export const FieldElement = ({ builderItem, sectionProps }) => {
  const { loading, control, values } = useFormProperties();

  const formField = builderItem.formField;

  return (
    <BuilderItemFormField
      component={FormActionFieldInput}
      componentProps={{ builderItem }}
      builderItem={builderItem}
      sectionProps={sectionProps}
      name={formField.name}
      editMode={true}
      control={control}
      formField={formField}
      value={values[formField.name]}
      loading={loading}
    />
  );
};

const FormLayoutAction = ({ action, space, actionsProps }) => {
  const { formState } = useExecuteFormContext();

  const { isSubmitting } = formState;

  return (
    <Button
      disabled={isSubmitting}
      space={space}
      variant="outlined"
      onClick={() => actionsProps.onSubmit({ action })}
    >
      {action.label}
    </Button>
  );
};

const COMPONENTS = {
  item: FormFieldItem,
  action: FormLayoutAction,
  containerTable: FormContainerTable,
};
