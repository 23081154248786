import React from "react";
import SlashScreen from "components/SlashScreen";
import { AccessManagerProvider } from "contexts/AccessManager";
import { useRouteMap } from "hooks/useRouteMap";
import isEmpty from "lodash/isEmpty";

import * as paths from "routes/path";

export const PAGE_ACCESS_RULES = {
  [paths.SELECT_ACCOUNT_TYPE]: [],
  [paths.COMPANY_GENERAL]: [],
  [paths.COMPANY_GENERAL]: [],
  [paths.COMPANY_PROFILE]: [],
  [paths.COMPANY_MEMBERS]: [],
  [paths.COMPANY_PROJECTS]: [],
  [paths.COMPANY_CHANNELS]: [],
  [paths.COMPANY_CHANNEL]: [],
  [paths.COMPANY_ALL_FIELDS]: [],
  [paths.COMPANY_FOLDER_FIELDS]: [],
  [paths.COMPANY_TABLES]: [],
  [paths.COMPANY_TABLE]: [],
  [paths.PROJECT_ROOT]: [],
  [paths.PROJECT_LIST]: [],
  [paths.PROJECT_LIST_ITEM]: [],
  [paths.PROJECT_GENERAL]: [],
  [paths.PROJECT_MEMBERS]: [],
  [paths.PROJECT_LISTINGS]: [],
  [paths.COMPANIES_CREATE]: [],
  [paths.PROJECT_CREATE]: [],
};

const AccessGuard = ({ children }) => {
  const { currentRoutePath } = useRouteMap();

  const pageAccessRules = PAGE_ACCESS_RULES[currentRoutePath] || [];

  // if (currentMember && pageAccessRules.find((accessRule) => false)) {
  //   return <AccessDenied />;
  // }

  return (
    <>
      {isEmpty(pageAccessRules) ? null : <SlashScreen />}
      {children}
    </>
  );
};

const AccessGuardWrap = (props) => (
  <AccessManagerProvider>
    <AccessGuard {...props} />
  </AccessManagerProvider>
);

export default AccessGuardWrap;
