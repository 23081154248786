import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import UploadFileButton from "components/UploadFileButton";
import UploadAvatar from "components/UploadAvatar";
import { PlusIcon } from "iconsNew";
import isObject from "lodash/isObject";
import CircularProgress from "components/CircularProgress";
import UploadFileActions from "./UploadFileActions";

const isFile = (value) => value instanceof File || value instanceof Blob;

const ImageInput = ({ name, label, space, error, value, onChange, loading, readOnly }) => {
  return (
    <Box style={{ width: "100%" }} display="flex" flexDirection="column">
      {value ? (
        <Box style={{ width: "100%" }}>
          <UploadAvatar
            name={name}
            onUpload={onChange}
            disabled={readOnly || loading}
            loading={loading}
            size="drawerField"
            src={value ? (isFile(value) ? URL.createObjectURL(value) : value.url) : null}
            accept="image/png, image/gif, image/jpeg"
            imageAlt={value.filename || value.name}
          >
            <UploadFileActions handleRemove={() => onChange(null)} />
          </UploadAvatar>
        </Box>
      ) : (
        <>
          <UploadFileButton
            space={space}
            disabled={readOnly || loading}
            style={{ width: "100%", maxWidth: 80 }}
            name={name}
            size="small"
            onUpload={onChange}
            leftIcon={loading ? CircularProgress : PlusIcon}
            variant="outlined"
            accept="image/png, image/gif, image/jpeg"
          >
            {label || "Add"}
          </UploadFileButton>
        </>
      )}

      {error && (
        <Typography space={{ mt: 1 }} error={`${!!error}`} variant="helperText">
          {isObject(error) ? error.message : error}
        </Typography>
      )}
    </Box>
  );
};

export default ImageInput;
