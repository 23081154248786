import React, { useState } from "react";
import { ArrowUpRightIcon } from "icons";
import { Skeleton } from "@mui/material";
import { useDialog } from "utils/state";
import { MenuInput } from "components/Menu/MenuInput";
import MenuItem from "components/Menu/MenuItem";
import keyBy from "lodash/keyBy";
import LabelsGrid from "components/LabelsGrid";
import { TypeTextIcon } from "icons";
import { TypographyValue } from "components/Typography";
import { FormFieldView } from "containers/FormField/View";

import { ObjectInputDrawer, useObjectFormFields } from "./ObjectInputDrawer";

export const ObjectForm = ({
  formField,
  value,
  onChange,
  onCancel,
  onSave,
  error,
  fullWidth,
  contentComponent: ContentComponent,
  drawerComponent: DrawerComponent,
  ...props
}) => {
  const formValue = value || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const parsedValue = formField.parseValue(formValue);
  const [openedDrawer, toggleDrawer, closeDrawer] = useDialog(false);

  const handleOpenDrawer = () => {
    toggleDrawer();
    setAnchorEl(null);
  };

  const handleCancel = () => {
    onCancel();
    closeDrawer();
  };

  return (
    <>
      <MenuInput
        {...props}
        space={{ mb: 0 }}
        value={parsedValue}
        anchorEl={anchorEl}
        placeholder={formField.placeholder}
        setAnchorEl={setAnchorEl}
        actions={{ onEdit: toggleDrawer }}
        error={error}
        height={200}
        fullWidth={fullWidth}
        footer={
          <MenuItem size="small" onClick={handleOpenDrawer} icon={ArrowUpRightIcon}>
            Edit
          </MenuItem>
        }
      >
        <ObjectFieldsGrid formField={formField} value={formValue} />
      </MenuInput>

      <ObjectInputDrawer
        formField={formField}
        value={formValue}
        onChange={onChange}
        onCancel={handleCancel}
        onSave={() => onSave({ onClose: closeDrawer })}
        open={openedDrawer}
      />
    </>
  );
};

const ObjectFieldsGrid = ({ formField, value }) => {
  const { objectFormFields, loading } = useObjectFormFields(formField);
  const valueMap = keyBy(value, "columnId");

  return (
    <LabelsGrid offsetSize="medium">
      {objectFormFields.map((nestedFormField, index) => (
        <TypographyValue
          key={`${nestedFormField?.id}-${index}`}
          labelIcon={TypeTextIcon}
          label={loading ? <Skeleton width="100px" variant="rect" /> : nestedFormField?.label}
          style={{ minWidth: 75 }}
          value={objectFieldValue({ formField: nestedFormField, valueMap })}
        />
      ))}
    </LabelsGrid>
  );
};

const objectFieldValue = ({ formField, valueMap }) => {
  return <FormFieldView formField={formField} value={valueMap[formField.id]?.value} />;
};
