import React, { useState } from "react";
import Button from "components/Button";
import Typography from "components/Typography";
import MenuItem from "components/Menu/MenuItem";
import { humanize } from "utils/string";
import Box from "components/Box";
import Menu from "components/Menu";
import { SearchIcon } from "icons";
import { searchFilter } from "utils/search";
import clsx from "clsx";
import Input from "components/Inputs/Input";
import { useSearchMenu } from "theme/components/SearchMenu";

import { useSort } from "./SortProvider";

const MenuItemColumn = ({ entity, onClick }) => (
  <MenuItem onClick={onClick}>
    <Typography>{humanize(entity.label)}</Typography>
  </MenuItem>
);

const SearchButton = (props) => (
  <Button variant="grey" size="compact" {...props}>
    Sort
  </Button>
);

export const SortsSearchMenu = ({
  label,
  menuItem: MenuItem = MenuItemColumn,
  button: Button = SearchButton,
  buttonTitle,
  buttonProps,
}) => {
  const { columns, sortsColumnsIds, onAdd } = useSort();
  const classes = useSearchMenu();
  const [search, setSearch] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
    setSearch("");
  };

  const sortColumns = searchFilter(
    columns.filter((column) => !sortsColumnsIds.includes(column.id)),
    search
  );

  const onSelect = (entity) => {
    onAdd(entity.id);
    handleClose();
  };

  return (
    <>
      <Button
        id="searchMenu-button"
        aria-controls={open ? "searchMenu-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpen}
        {...buttonProps}
        disabled={buttonProps.disabled || sortColumns.length <= 0}
      >
        {buttonTitle || "Sort"}
      </Button>

      <Menu
        id="searchMenu-menu"
        aria-labelledby="SearchMenu-button"
        MenuListProps={{ className: classes.list }}
        PaperProps={{ className: classes.paper }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box className={classes.container}>
          <Box
            style={{ maxWidth: 216 }}
            className={clsx(classes.contentContainer, classes.scrollContainer)}
          >
            <Box className={classes.searchContainer}>
              <Input
                size="small"
                space={{ mb: 0 }}
                fullWidth
                leftIcon={SearchIcon}
                placeholder="searchByName"
                variant="outlined"
                onChange={(event) => setSearch(event.target.value || "")}
              />
            </Box>

            <Typography
              variant="caption"
              component="div"
              weight="title"
              color="grey"
              space={{ mb: 1, ml: 1 }}
            >
              {label}
            </Typography>

            <Box className={classes.scrollContent} space={{ pb: 1 }}>
              {sortColumns.map((entity, index) => (
                <MenuItem
                  key={`searchMenu-item-${entity.id}-${index}`}
                  entity={entity}
                  onClick={() => onSelect && onSelect(entity)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Menu>
    </>
  );
};
