import React from "react";
import { ListItem as MuiListItem, Skeleton } from "@mui/material";
import withClasses from "components/withClasses";
import Box from "components/Box";

const useListItemStyles = () => ({
  root: "AppListItem-root",
  itemContent: "AppListItem-itemContent",
});

const ListItemComponent = ({
  labelSpace,
  labelHide,
  innerRef,
  variantProps,
  icon: Icon,
  loading,
  children,
  ...props
}) => {
  const classes = useListItemStyles();

  return (
    <MuiListItem ref={innerRef} {...props} classes={{ root: classes.root }}>
      {Icon && <Icon space={{ mr: 1 }} size="medium" />}

      <Box
        className={classes.itemContent}
        display="flex"
        alignItems="center"
        style={{ flex: "1 1 auto" }}
      >
        {loading ? <Skeleton variant="rect" height="15px" width="100%" /> : children}
      </Box>
    </MuiListItem>
  );
};

export const ListItemLoading = () => <Skeleton height="25px" width="100%" />;

export default withClasses(ListItemComponent, "MuiListItem", {});
