import React, { useLayoutEffect } from "react";
import usePagination from "hooks/usePagination";

import TableEmpty from "../Components/TableEmpty";
import TablePagination from "../Components/TablePagination";
import { TableNavigationRow, TableAddRecordRow } from "../Components/TableNavigationRow";
import { TableProRow } from "../Components/TableRow";
import TableHeader from "../Components/TableHeader";
import TableRowsStub from "../Components/TableRowsStub";
import DraggableTableRows from "../Components/DraggableTableRows";
import { useTablePro } from "../Provider";

const StaticTable = ({ pageSize = 100, collapseSize }) => {
  const {
    loadingMore,
    setLoadingMore,
    totalCount,
    loading,
    entities,
    onLoadMore,
    draggable,
    tableRef,
    setTableHeight,
    onAdd,
    tableAddRowComponent,
  } = useTablePro();

  const TableAddRowComponent = tableAddRowComponent || TableAddRecordRow;

  const handleLoadMore = ({ onSuccess }) => {
    if (!onLoadMore) {
      return onSuccess && onSuccess();
    }

    setLoadingMore(true);

    onLoadMore().then(() => {
      setLoadingMore(false);
      onSuccess && onSuccess();
    });
  };

  const pageTableHeight = tableRef?.current?.getBoundingClientRect()?.height || 0;

  useLayoutEffect(() => {
    setTableHeight(pageTableHeight);
  }, [pageTableHeight]);

  // Pagination
  const pagination = usePagination({
    pageSize,
    collapseSize,
    entities,
    onLoadMore: handleLoadMore,
    totalCount,
  });

  const renderEntities = pageSize ? pagination.pageEntities : entities;

  const TableRowsComponent = draggable ? DraggableTableRows : TableRows;

  if (renderEntities.length === 0) return <TableEmpty />;

  const isPagination = pageSize && collapseSize > 0 && collapseSize < totalCount;
  const isTopPagination =
    isPagination && (pagination.handlePrevPage || pagination.handleCollapsePage);
  const isBottomPagination = isPagination && pagination.handleNextPage;

  return (
    <>
      <TableHeader />

      {isTopPagination && (
        <TableNavigationRow border>
          <TablePagination.Top pagination={pagination} />
        </TableNavigationRow>
      )}

      <TableRowsComponent renderCount={renderEntities.length} pagination={pagination} />

      <TableRowsStub rowsCount={15} />

      {isBottomPagination && (
        <TableNavigationRow>
          <TablePagination.Bottom pagination={pagination} loading={loading || loadingMore} />
        </TableNavigationRow>
      )}

      {onAdd && <TableAddRowComponent onAdd={onAdd} />}
    </>
  );
};

const TableRows = ({ renderCount, pagination }) => {
  return (
    <>
      {Array(renderCount)
        .fill(null)
        .map((_, entityIndex) => {
          const rowIndex =
            pagination.pageOffset === 0 ? entityIndex : entityIndex + pagination.pageOffset;

          return <TableProRow key={`entity-${rowIndex}`} rowIndex={rowIndex} />;
        })}
    </>
  );
};

export default StaticTable;
