import React from "react";
import { DragIcon, MoreHorizontalIcon, PlusIcon } from "iconsNew";

import "styles/App/DndBuilder/ActionsTooltip.scss";

const useDndBuilderActionsTooltipStyles = () => ({
  root: "AppDndBuilder-ActionsTooltip-root",
  action: "AppDndBuilder-ActionsTooltip-action",
});

export const ActionsTooltip = ({ handleProps, onAddObject }) => {
  const classes = useDndBuilderActionsTooltipStyles();

  return (
    <div className={classes.root}>
      <Action {...handleProps} icon={DragIcon} />
      <Action onClick={onAddObject} icon={PlusIcon} />
      <Action icon={MoreHorizontalIcon} />
    </div>
  );
};

const Action = React.forwardRef(({ onClick, icon: Icon, ...props }, ref) => {
  const classes = useDndBuilderActionsTooltipStyles();

  return (
    <button ref={ref} {...props} onClick={onClick} className={classes.action} tabIndex={0}>
      {Icon && <Icon />}
    </button>
  );
});
