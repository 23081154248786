import { useState } from "react";
import { useOnClickOutsideEffect, useOnEscEffect, useOnEnterEffect } from "utils/effects";
import { useDidMountEffect } from "utils/effects";
import { useNotification } from "contexts/Notifications";
import isEqual from "lodash/isEqual";
import JSum from "vendor/jsum";

const useCellInput = ({ value, onChange, onSave, column, entity, rowIndex }) => {
  const { notifyError } = useNotification();
  const [inputValue, setInputValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const reset = () => setInputValue(value);

  const handleChange = (newValue) => {
    const props = { [column.id]: newValue };

    onChange({ entity, rowIndex, props, tableColumn: column, onFailure: reset });
  };

  const handleSave = ({ onClose } = {}) => {
    const oldValue = value;

    handleChange(inputValue);

    if (!onSave) {
      onClose();
      return;
    }

    setLoading(true);

    onSave({
      onSuccess: () => {
        setLoading(false);
        onClose && onClose();
      },
      onFailure: (errors) => {
        setLoading(false);
        handleChange(oldValue);
        notifyError(errors.submit || errors);
      },
    })();
  };

  useDidMountEffect(() => {
    if (isEqual(value, inputValue)) return;

    setInputValue(value);
  }, [JSum.digest(value, "SHA256", "hex")]);

  return {
    inputValue,
    setInputValue,
    loading,
    reset,
    onReset: reset,
    onChange: handleChange,
    onSave: handleSave,
  };
};

export const useInputEffects = (ref, { onClose, onSave, onReset }) => {
  const handleReset = () => {
    onReset && onReset();
    onClose();
  };

  const handleSave = () => {
    onSave({ onClose });
  };

  useOnEscEffect(ref, handleReset);
  useOnClickOutsideEffect(ref, handleSave);
  useOnEnterEffect(ref, handleSave);
};

export const onInputKeyDown = ({ onClose, onSave, onReset }) => {
  const handleReset = () => {
    onReset && onReset();
    onClose();
  };

  const handleSave = () => {
    onSave({ onClose });
  };

  return (event) => {
    // Enter
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handleSave();
    }

    // Esc
    if (event.keyCode === 27) {
      event.preventDefault();
      handleReset();
    }
  };
};

export default useCellInput;
